import React, { useRef } from 'react'
import { useTheme } from 'react-jss'

import { useOutsideAlerter } from '../../../hooks'
import { LightTheme } from '../../../theme'
import { Column } from '../../column'
import { DropdownItem, DropdownItemData } from '../dropdown-item'
import { ItemsListProps } from './items-list.types'
import { useStyle } from './items-list.styles'

export const ItemsList = ({
  className = '',
  open,
  active,
  data,
  width,
  height,
  position,
  isScrollable,
  onChange,
  onOpen
}:
ItemsListProps) => {
  const itemsRef = useRef<HTMLDivElement>(null)
  const usualRef = isScrollable ? null : itemsRef

  const theme: LightTheme = useTheme()
  const classes = useStyle({
    width,
    height,
    theme
  })

  const handleOnChange = (value: DropdownItemData) => {
    const { onClick } = value
    const isSelected = String(active) === value.id
    if (onChange && !isSelected) {
      onChange(value)
    }

    if (onClick) {
      onClick(value)
    }
  }

  useOutsideAlerter(itemsRef, open, onOpen(false))

  return (
    <Column
      className={`${className} ${classes.itemsContainer} ${classes[position]}`}
      ref={usualRef}
    >
      {data.map((item) => {
        const activeItem = String(active) === item.id
        return (
          <DropdownItem
            key={`dropdown_${item.id}`}
            item={item}
            isScrollable={isScrollable}
            disabled={activeItem}
            onClick={handleOnChange}
          />
        )
      })}
    </Column>
  )
}
