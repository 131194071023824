import React, { FC, useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import {
  Content,
  Column,
  Input,
  Dropdown,
  DropdownItemData,
  getDropdownData,
  getCustomSettings,
  getUpcomingSetting,
  getSetting,
  changeCurrentValues,
  getIsValid,
  getError,
  getCurrentDropdown,
  setError,
  getSliderId,
  handleOnCheckDropdownErrors,
  RadioButtonGroup,
  ComponentTextProps,
  CustomRadioButtonGroup
} from '../..'
import { Row } from '../../row'
import { Policy, Restrictions } from '../../../services'
import { State } from '../../../store'
import {
  APPLY_CAPS_TO_USED_RADIO_IDS,
  customStyles,
  EXCLUDED_SECOND_TIME_SALES_RADIO_IDS,
  EXCLUDE_NON_QUALIFIED_SELLERS_RADIO_IDS,
  INCOME_CAP_DROPDOWN_IDS,
  MSRP_CAP_DROPDOWN_IDS,
  NUMBER_VALUES_IDS,
  RADIO_ID_DEPENDENCIES,
  SECOND_TIME_SALES_TYPE_RADIO_IDS
} from '../constants'
import {
  formatValue,
  getBooleanValue,
  getLabel,
  getSettingValue
} from '../helpers'
import {
  arrayProps,
  dollarIconProps,
  scalarProps,
  yearIconProps,
  yearsIconProps,
  percentIconProps,
  option1Props,
  option2Props
} from '../label-props'
import { getMsrpValuesFlags } from './helpers'
import {
  MsrpCapValues,
  MsrpCapIds,
  InputsPolicyProps,
  CheckAllErrorsProps,
  InputTypeValue
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const MSRPCap: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions, catalog, bauDefaults } = useSelector(
    (state: State) => ({
      restrictions: state.policy.restrictions as Restrictions,
      catalog: state.policy.catalog,
      bauDefaults: state.policy.bauDefaults
    })
  )

  const dropdowns: any = {
    usMsrpCapType: getDropdownData(MsrpCapIds.US_MSRP_CAP_TYPE, catalog),
    usIncomeCapType: getDropdownData(MsrpCapIds.US_INCOME_CAP_TYPE, catalog)
  }

  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: MsrpCapValues) => getUpcomingSetting(id, settingsList),
    [settingsList]
  )

  // start of manipulating with current settings
  const getMsrpCap = useCallback(
    (): Partial<Policy> => ({
      usCapType: handleGetUpcomingSetting(MsrpCapIds.CAP_TYPE),
      msrpCap: handleGetUpcomingSetting(MsrpCapIds.MSRP),
      incomeCap: handleGetUpcomingSetting(MsrpCapIds.INCOME_CAP),
      softCapEndYears: handleGetUpcomingSetting(
        MsrpCapIds.SOFT_CAP_MAX_DURATION
      ),
      tieredMsrpCap: handleGetUpcomingSetting(MsrpCapIds.TIERED_MSRP_CAP),
      tieredMsrpSoftCap: handleGetUpcomingSetting(
        MsrpCapIds.TIERED_MSRP_SOFT_CAP
      ),
      softCapStartYear: handleGetUpcomingSetting(
        MsrpCapIds.SOFT_CAP_START_YEAR
      ),
      singleCap: handleGetUpcomingSetting(MsrpCapIds.MAGI_CAP_SINGLE),
      hoHCap: handleGetUpcomingSetting(MsrpCapIds.MAGI_CAP_HEADHOUSE),
      jointCap: handleGetUpcomingSetting(MsrpCapIds.MAGI_CAP_JOINT),
      magiSoftCap: handleGetUpcomingSetting(MsrpCapIds.MAGI_SOFT_CAP),
      newEvStartYear: handleGetUpcomingSetting(
        MsrpCapIds.SOFT_CAP_STARTING_YEAR
      ),
      applyCapsToUsed: handleGetUpcomingSetting(MsrpCapIds.APPLY_CAPS_TO_USED),
      magiCapUsedOption: handleGetUpcomingSetting(
        MsrpCapIds.MAGI_CAP_USED_OPTION
      ),
      bonusUsedBatteryIncentive: handleGetUpcomingSetting(
        MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE
      ),
      batteryDegradationRate: handleGetUpcomingSetting(
        MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG
      ),
      excludeNondealersUsed: handleGetUpcomingSetting(
        MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS
      ),
      percentExcludedUsed: handleGetUpcomingSetting(
        MsrpCapIds.PERCENTAGE_EXCLUDED
      ),
      excludeSecondTimeSales: handleGetUpcomingSetting(
        MsrpCapIds.EXCLUDED_SECOND_TIME_SALES
      ),
      secondSalesType: handleGetUpcomingSetting(
        MsrpCapIds.SECOND_TIME_SALES_TYPE
      ),
      fiftyPercYear: handleGetUpcomingSetting(MsrpCapIds.YEAR_RATE_RICH_50),
      assUsApplyUsed: handleGetUpcomingSetting(
        MsrpCapIds.APPLY_US_ASSEMBLY_REQ_TO_USED_EVS
      ),
      assUsType: handleGetUpcomingSetting(MsrpCapIds.ASS_US_TYPE),
      usMsrpCapType: handleGetUpcomingSetting(MsrpCapIds.US_MSRP_CAP_TYPE),
      usSoftCap: handleGetUpcomingSetting(MsrpCapIds.US_SOFT_CAP),
      usIncomeCapType: handleGetUpcomingSetting(MsrpCapIds.US_INCOME_CAP_TYPE),
      magiPhaseOption: handleGetUpcomingSetting(MsrpCapIds.MAGI_PHASE_OPTION),
      magiPhase1Years: handleGetUpcomingSetting(MsrpCapIds.MAGI_PHASE1_YEARS),
      singleCapPhase2: handleGetUpcomingSetting(MsrpCapIds.SINGLE_CAP_PHASE2),
      hoHCapPhase2: handleGetUpcomingSetting(MsrpCapIds.HOH_CAP_PHASE2),
      jointCapPhase2: handleGetUpcomingSetting(MsrpCapIds.JOINT_CAP_PHASE2),
      magiPhase2Years: handleGetUpcomingSetting(MsrpCapIds.MAGI_PHASE2_YEARS),
      singleCapPhase3: handleGetUpcomingSetting(MsrpCapIds.SINGLE_CAP_PHASE3),
      hoHCapPhase3: handleGetUpcomingSetting(MsrpCapIds.HOH_CAP_PHASE3),
      jointCapPhase3: handleGetUpcomingSetting(MsrpCapIds.JOINT_CAP_PHASE3)
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getMsrpCap()
  const [msrpCap, changeMsrpCap] = useState(fields)

  // when we press 'save' we get updated data from main 'update-policy' component
  // so we need to update our defaults for 'undo' fields state
  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeMsrpCap(getMsrpCap())
    changeSettings(s)
  }, [defaults, updates])
  // eof of manipulating with current settings

  // METHODS
  const getIsExist = (id: MsrpCapValues) => msrpCap[id] !== undefined
  const getIsDefault = (id: MsrpCapValues) =>
    msrpCap[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (id: MsrpCapValues, flag: InputTypeValue) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeMsrpCap({
      ...msrpCap,
      [id]: value
    })
    changeSettings(updSettings)
    onChange({ [id]: value })
  }

  const handleOnBlur = (id: MsrpCapValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnDropdownChange = (id: MsrpCapValues) => (
    value: DropdownItemData
  ) => {
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeMsrpCap({
      ...msrpCap,
      [id]: Number(value.id)
    })
    changeSettings(updSettings)
    onChange({ [id]: Number(value.id) })
  }

  const handleUndoClick = (id: MsrpCapValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value

      changeMsrpCap({
        ...msrpCap,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnGetCurrentDropdown = (value: number, id: string) =>
    getCurrentDropdown({
      dropdowns,
      value,
      id
    })
  const handleOnGetError = (id: MsrpCapValues) => getError(id, error)
  const getEmptyDropdownStyle = (id: MsrpCapIds) =>
    getIsExist(id) ? undefined : customStyles

  // check errors in dependencies
  const handleOnCheckAllErrors = ({
    values,
    radioId,
    ids
  }: CheckAllErrorsProps): ComponentTextProps | undefined => {
    if (!values[radioId]) {
      return undefined
    }

    const errors: any = ids.map((id: string) => getError(id, error))
    return errors.find((props?: ComponentTextProps) => props)
  }

  const handleOnRadioChange = (id: string) => (status: boolean) => {
    changeMsrpCap({
      ...msrpCap,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const getIsUpdateInBauValues = useCallback(
    (key: keyof Policy) => {
      const isUndefined = typeof updates[key] === 'undefined'
      const isEqual = updates[key] === bauDefaults[key]

      return !isUndefined && isEqual
    },
    [updates, bauDefaults]
  )

  const getAttentionIconState = useCallback(
    (id: keyof Policy): object => {
      const isRadioOff = !msrpCap[id]
      const bauKeys = Object.keys(bauDefaults)
      const newKeys = Object.keys(updates)
      const dependencyKeys = RADIO_ID_DEPENDENCIES[id]
      const isBauSetted = dependencyKeys.find(getIsUpdateInBauValues)
      const isBauValuesFromDependency = dependencyKeys.find((depId: string) =>
        bauKeys.includes(depId)
      )
      let isIconVisible = Boolean(
        isRadioOff && isBauSetted && isBauValuesFromDependency
      )

      if (isBauSetted) {
        return {
          statusIcon: isIconVisible,
          attentionText:
            'Underlying inputs were changed to BAU.\nOpen the section to review \nand/or discard changes.',
          attentionTx: 'policy.radio.warning'
        }
      }

      const isNewValuesFromDependency = dependencyKeys.find((depId: string) =>
        newKeys.includes(depId)
      )
      isIconVisible = Boolean(isRadioOff && isNewValuesFromDependency)

      return {
        statusIcon: isIconVisible,
        attentionText:
          'Underlying inputs were changed,\nthis may affect policy output.\nOpen the section to review \nand/or discard changes.',
        attentionTx: 'policy.radio.warning2'
      }
    },
    [bauDefaults, updates, msrpCap, getIsUpdateInBauValues]
  )

  const getCorrectValueById = useCallback(
    ({ settingId = '', value = false }) => {
      const hasSetting = String(value) && settingId

      if (!hasSetting) {
        return {}
      }

      const settingValue =
        hasSetting && NUMBER_VALUES_IDS.includes(settingId)
          ? getSettingValue(value)
          : value

      if (settingId && settings) {
        changeSettings({
          ...settings,
          [settingId]: settingValue
        })
      }
      return settingValue
    },
    [settings]
  )

  const getSwitcherValue = ({ settingId = '', value = false }) => {
    const settingValue = getCorrectValueById({ settingId, value })
    const settingUpdate = settingId && { [settingId]: settingValue }
    return settingUpdate
  }

  const handleOnCustomRadioChange = (id: MsrpCapIds) => (value: boolean) => {
    const settingUpdate = getSwitcherValue({
      settingId: id,
      value
    }) as Partial<Policy>
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeMsrpCap({
      ...msrpCap,
      ...settingUpdate
    })
    changeSettings(updSettings)
    onChange(settingUpdate)
  }
  // eof METHODS

  const {
    isMsrpVisible,
    isIncomeVisible,
    isTieredVisible,
    isUsSoftCapVisible,
    isMagiVisible,
    isMagiPhase1Visible,
    isMagiPhase2Visible,
    isAssUsApplyUsed
  }: any = getMsrpValuesFlags({ dropdowns, msrpCap })

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Row className={classes.cell}>
            <Dropdown
              className={classes.dropdown}
              custom={getEmptyDropdownStyle(MsrpCapIds.US_MSRP_CAP_TYPE)}
              label={getLabel(MsrpCapIds.US_MSRP_CAP_TYPE, restrictions).text}
              preset="body"
              data={dropdowns.usMsrpCapType}
              defaultValue={handleOnGetCurrentDropdown(
                Number(msrpCap.usMsrpCapType),
                MsrpCapIds.US_MSRP_CAP_TYPE
              )}
              description={
                restrictions[MsrpCapIds.US_MSRP_CAP_TYPE]?.description
              }
              errorProps={handleOnCheckDropdownErrors({
                ids: MSRP_CAP_DROPDOWN_IDS,
                error
              })}
              disabled={!msrpCap.usMsrpCapType}
              isUndo={getIsDefault(MsrpCapIds.US_MSRP_CAP_TYPE)}
              onChange={handleOnDropdownChange(MsrpCapIds.US_MSRP_CAP_TYPE)}
              onUndoClick={handleUndoClick(MsrpCapIds.US_MSRP_CAP_TYPE)}
            />
          </Row>
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {isMsrpVisible && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.MSRP}
              labelProps={getLabel(MsrpCapIds.MSRP, restrictions)}
              defaultValue={msrpCap.msrpCap}
              isDollar
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.MSRP)}
              description={restrictions[MsrpCapIds.MSRP]?.description}
              isUndo={getIsDefault(MsrpCapIds.MSRP)}
              errorProps={handleOnGetError(MsrpCapIds.MSRP)}
              onBlur={handleOnBlur(MsrpCapIds.MSRP)}
              onChange={handleOnChange(MsrpCapIds.MSRP, InputTypeValue.DOLLAR)}
              onUndoClick={handleUndoClick(MsrpCapIds.MSRP)}
            />
          )}
          {isUsSoftCapVisible && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.US_SOFT_CAP}
              labelProps={getLabel(MsrpCapIds.US_SOFT_CAP, restrictions)}
              defaultValue={msrpCap.usSoftCap}
              isDollar
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.US_SOFT_CAP)}
              description={restrictions[MsrpCapIds.US_SOFT_CAP]?.description}
              isUndo={getIsDefault(MsrpCapIds.US_SOFT_CAP)}
              errorProps={handleOnGetError(MsrpCapIds.US_SOFT_CAP)}
              onBlur={handleOnBlur(MsrpCapIds.US_SOFT_CAP)}
              onChange={handleOnChange(
                MsrpCapIds.US_SOFT_CAP,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.US_SOFT_CAP)}
            />
          )}
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Row className={classes.cell}>
            <Dropdown
              className={classes.dropdown}
              custom={getEmptyDropdownStyle(MsrpCapIds.US_INCOME_CAP_TYPE)}
              label={getLabel(MsrpCapIds.US_INCOME_CAP_TYPE, restrictions).text}
              preset="body"
              data={dropdowns.usIncomeCapType}
              defaultValue={handleOnGetCurrentDropdown(
                Number(msrpCap.usIncomeCapType),
                MsrpCapIds.US_INCOME_CAP_TYPE
              )}
              description={
                restrictions[MsrpCapIds.US_INCOME_CAP_TYPE]?.description
              }
              errorProps={handleOnCheckDropdownErrors({
                ids: INCOME_CAP_DROPDOWN_IDS,
                error
              })}
              disabled={!msrpCap.usIncomeCapType}
              isUndo={getIsDefault(MsrpCapIds.US_INCOME_CAP_TYPE)}
              onChange={handleOnDropdownChange(MsrpCapIds.US_INCOME_CAP_TYPE)}
              onUndoClick={handleUndoClick(MsrpCapIds.US_INCOME_CAP_TYPE)}
            />
          </Row>
          {isIncomeVisible && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.INCOME_CAP}
              labelProps={getLabel(MsrpCapIds.INCOME_CAP, restrictions)}
              defaultValue={msrpCap.incomeCap}
              isDollar
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.INCOME_CAP)}
              description={restrictions[MsrpCapIds.INCOME_CAP]?.description}
              disabled={!isIncomeVisible}
              isUndo={getIsDefault(MsrpCapIds.INCOME_CAP)}
              errorProps={handleOnGetError(MsrpCapIds.INCOME_CAP)}
              onBlur={handleOnBlur(MsrpCapIds.INCOME_CAP)}
              onChange={handleOnChange(
                MsrpCapIds.INCOME_CAP,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.INCOME_CAP)}
            />
          )}
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {isTieredVisible && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={MsrpCapIds.TIERED_MSRP_CAP}
                labelProps={getLabel(MsrpCapIds.TIERED_MSRP_CAP, restrictions)}
                defaultValue={msrpCap.tieredMsrpCap}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(MsrpCapIds.TIERED_MSRP_CAP)}
                description={
                  restrictions[MsrpCapIds.TIERED_MSRP_CAP]?.description
                }
                disabled={!isTieredVisible}
                isUndo={getIsDefault(MsrpCapIds.TIERED_MSRP_CAP)}
                errorProps={handleOnGetError(MsrpCapIds.TIERED_MSRP_CAP)}
                onBlur={handleOnBlur(MsrpCapIds.TIERED_MSRP_CAP)}
                onChange={handleOnChange(
                  MsrpCapIds.TIERED_MSRP_CAP,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(MsrpCapIds.TIERED_MSRP_CAP)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={MsrpCapIds.TIERED_MSRP_SOFT_CAP}
                labelProps={getLabel(
                  MsrpCapIds.TIERED_MSRP_SOFT_CAP,
                  restrictions
                )}
                defaultValue={msrpCap.tieredMsrpSoftCap}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(MsrpCapIds.TIERED_MSRP_SOFT_CAP)}
                description={
                  restrictions[MsrpCapIds.TIERED_MSRP_SOFT_CAP]?.description
                }
                disabled={!isTieredVisible}
                isUndo={getIsDefault(MsrpCapIds.TIERED_MSRP_SOFT_CAP)}
                errorProps={handleOnGetError(MsrpCapIds.TIERED_MSRP_SOFT_CAP)}
                onBlur={handleOnBlur(MsrpCapIds.TIERED_MSRP_SOFT_CAP)}
                onChange={handleOnChange(
                  MsrpCapIds.TIERED_MSRP_SOFT_CAP,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(MsrpCapIds.TIERED_MSRP_SOFT_CAP)}
              />
            </>
          )}
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {isMagiVisible && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={MsrpCapIds.MAGI_PHASE_OPTION}
                labelProps={getLabel(
                  MsrpCapIds.MAGI_PHASE_OPTION,
                  restrictions
                )}
                isYear
                defaultValue={msrpCap.magiPhaseOption}
                isFocused={getIsExist(MsrpCapIds.MAGI_PHASE_OPTION)}
                description={
                  restrictions[MsrpCapIds.MAGI_PHASE_OPTION]?.description
                }
                disabled={!getIsExist(MsrpCapIds.MAGI_PHASE_OPTION)}
                isUndo={getIsDefault(MsrpCapIds.MAGI_PHASE_OPTION)}
                errorProps={handleOnGetError(MsrpCapIds.MAGI_PHASE_OPTION)}
                onBlur={handleOnBlur(MsrpCapIds.MAGI_PHASE_OPTION)}
                onChange={handleOnChange(
                  MsrpCapIds.MAGI_PHASE_OPTION,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(MsrpCapIds.MAGI_PHASE_OPTION)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={MsrpCapIds.MAGI_CAP_SINGLE}
                labelProps={getLabel(MsrpCapIds.MAGI_CAP_SINGLE, restrictions)}
                isDollar
                defaultValue={msrpCap.singleCap}
                iconProps={dollarIconProps}
                isFocused={getIsExist(MsrpCapIds.MAGI_CAP_SINGLE)}
                description={
                  restrictions[MsrpCapIds.MAGI_CAP_SINGLE]?.description
                }
                disabled={!getIsExist(MsrpCapIds.MAGI_CAP_SINGLE)}
                isUndo={getIsDefault(MsrpCapIds.MAGI_CAP_SINGLE)}
                errorProps={handleOnGetError(MsrpCapIds.MAGI_CAP_SINGLE)}
                onBlur={handleOnBlur(MsrpCapIds.MAGI_CAP_SINGLE)}
                onChange={handleOnChange(
                  MsrpCapIds.MAGI_CAP_SINGLE,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(MsrpCapIds.MAGI_CAP_SINGLE)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={MsrpCapIds.MAGI_CAP_HEADHOUSE}
                labelProps={getLabel(
                  MsrpCapIds.MAGI_CAP_HEADHOUSE,
                  restrictions
                )}
                isDollar
                defaultValue={msrpCap.hoHCap}
                iconProps={dollarIconProps}
                isFocused={getIsExist(MsrpCapIds.MAGI_CAP_HEADHOUSE)}
                description={
                  restrictions[MsrpCapIds.MAGI_CAP_HEADHOUSE]?.description
                }
                disabled={!getIsExist(MsrpCapIds.MAGI_CAP_HEADHOUSE)}
                isUndo={getIsDefault(MsrpCapIds.MAGI_CAP_HEADHOUSE)}
                errorProps={handleOnGetError(MsrpCapIds.MAGI_CAP_HEADHOUSE)}
                onBlur={handleOnBlur(MsrpCapIds.MAGI_CAP_HEADHOUSE)}
                onChange={handleOnChange(
                  MsrpCapIds.MAGI_CAP_HEADHOUSE,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(MsrpCapIds.MAGI_CAP_HEADHOUSE)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={MsrpCapIds.MAGI_CAP_JOINT}
                labelProps={getLabel(MsrpCapIds.MAGI_CAP_JOINT, restrictions)}
                isDollar
                defaultValue={msrpCap.jointCap}
                iconProps={dollarIconProps}
                isFocused={getIsExist(MsrpCapIds.MAGI_CAP_JOINT)}
                description={
                  restrictions[MsrpCapIds.MAGI_CAP_JOINT]?.description
                }
                disabled={!getIsExist(MsrpCapIds.MAGI_CAP_JOINT)}
                isUndo={getIsDefault(MsrpCapIds.MAGI_CAP_JOINT)}
                errorProps={handleOnGetError(MsrpCapIds.MAGI_CAP_JOINT)}
                onBlur={handleOnBlur(MsrpCapIds.MAGI_CAP_JOINT)}
                onChange={handleOnChange(
                  MsrpCapIds.MAGI_CAP_JOINT,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(MsrpCapIds.MAGI_CAP_JOINT)}
              />
            </>
          )}
        </Row>
        {isMagiPhase1Visible && (
          <Row
            className={`${classes.row} ${classes.responsiveRow}`}
            justifyContent="flex-start"
          >
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.MAGI_PHASE1_YEARS}
              labelProps={getLabel(MsrpCapIds.MAGI_PHASE1_YEARS, restrictions)}
              isYear
              defaultValue={msrpCap.magiPhase1Years}
              iconProps={yearsIconProps}
              isFocused={getIsExist(MsrpCapIds.MAGI_PHASE1_YEARS)}
              description={
                restrictions[MsrpCapIds.MAGI_PHASE1_YEARS]?.description
              }
              disabled={!getIsExist(MsrpCapIds.MAGI_PHASE1_YEARS)}
              isUndo={getIsDefault(MsrpCapIds.MAGI_PHASE1_YEARS)}
              errorProps={handleOnGetError(MsrpCapIds.MAGI_PHASE1_YEARS)}
              onBlur={handleOnBlur(MsrpCapIds.MAGI_PHASE1_YEARS)}
              onChange={handleOnChange(
                MsrpCapIds.MAGI_PHASE1_YEARS,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.MAGI_PHASE1_YEARS)}
            />
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.SINGLE_CAP_PHASE2}
              labelProps={getLabel(MsrpCapIds.SINGLE_CAP_PHASE2, restrictions)}
              isDollar
              defaultValue={msrpCap.singleCapPhase2}
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.SINGLE_CAP_PHASE2)}
              description={
                restrictions[MsrpCapIds.SINGLE_CAP_PHASE2]?.description
              }
              disabled={!getIsExist(MsrpCapIds.SINGLE_CAP_PHASE2)}
              isUndo={getIsDefault(MsrpCapIds.SINGLE_CAP_PHASE2)}
              errorProps={handleOnGetError(MsrpCapIds.SINGLE_CAP_PHASE2)}
              onBlur={handleOnBlur(MsrpCapIds.SINGLE_CAP_PHASE2)}
              onChange={handleOnChange(
                MsrpCapIds.SINGLE_CAP_PHASE2,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.SINGLE_CAP_PHASE2)}
            />
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.HOH_CAP_PHASE2}
              labelProps={getLabel(MsrpCapIds.HOH_CAP_PHASE2, restrictions)}
              isDollar
              defaultValue={msrpCap.hoHCapPhase2}
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.HOH_CAP_PHASE2)}
              description={restrictions[MsrpCapIds.HOH_CAP_PHASE2]?.description}
              disabled={!getIsExist(MsrpCapIds.HOH_CAP_PHASE2)}
              isUndo={getIsDefault(MsrpCapIds.HOH_CAP_PHASE2)}
              errorProps={handleOnGetError(MsrpCapIds.HOH_CAP_PHASE2)}
              onBlur={handleOnBlur(MsrpCapIds.HOH_CAP_PHASE2)}
              onChange={handleOnChange(
                MsrpCapIds.HOH_CAP_PHASE2,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.HOH_CAP_PHASE2)}
            />
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.JOINT_CAP_PHASE2}
              labelProps={getLabel(MsrpCapIds.JOINT_CAP_PHASE2, restrictions)}
              isDollar
              defaultValue={msrpCap.jointCapPhase2}
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.JOINT_CAP_PHASE2)}
              description={
                restrictions[MsrpCapIds.JOINT_CAP_PHASE2]?.description
              }
              disabled={!getIsExist(MsrpCapIds.JOINT_CAP_PHASE2)}
              isUndo={getIsDefault(MsrpCapIds.JOINT_CAP_PHASE2)}
              errorProps={handleOnGetError(MsrpCapIds.JOINT_CAP_PHASE2)}
              onBlur={handleOnBlur(MsrpCapIds.JOINT_CAP_PHASE2)}
              onChange={handleOnChange(
                MsrpCapIds.JOINT_CAP_PHASE2,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.JOINT_CAP_PHASE2)}
            />
          </Row>
        )}
        {isMagiPhase2Visible && (
          <Row
            className={`${classes.row} ${classes.responsiveRow}`}
            justifyContent="flex-start"
          >
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.MAGI_PHASE2_YEARS}
              labelProps={getLabel(MsrpCapIds.MAGI_PHASE2_YEARS, restrictions)}
              isYear
              defaultValue={msrpCap.magiPhase2Years}
              iconProps={yearsIconProps}
              isFocused={getIsExist(MsrpCapIds.MAGI_PHASE2_YEARS)}
              description={
                restrictions[MsrpCapIds.MAGI_PHASE2_YEARS]?.description
              }
              disabled={!getIsExist(MsrpCapIds.MAGI_PHASE2_YEARS)}
              isUndo={getIsDefault(MsrpCapIds.MAGI_PHASE2_YEARS)}
              errorProps={handleOnGetError(MsrpCapIds.MAGI_PHASE2_YEARS)}
              onBlur={handleOnBlur(MsrpCapIds.MAGI_PHASE2_YEARS)}
              onChange={handleOnChange(
                MsrpCapIds.MAGI_PHASE2_YEARS,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.MAGI_PHASE2_YEARS)}
            />
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.SINGLE_CAP_PHASE3}
              labelProps={getLabel(MsrpCapIds.SINGLE_CAP_PHASE3, restrictions)}
              isDollar
              defaultValue={msrpCap.singleCapPhase3}
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.SINGLE_CAP_PHASE3)}
              description={
                restrictions[MsrpCapIds.SINGLE_CAP_PHASE3]?.description
              }
              disabled={!getIsExist(MsrpCapIds.SINGLE_CAP_PHASE3)}
              isUndo={getIsDefault(MsrpCapIds.SINGLE_CAP_PHASE3)}
              errorProps={handleOnGetError(MsrpCapIds.SINGLE_CAP_PHASE3)}
              onBlur={handleOnBlur(MsrpCapIds.SINGLE_CAP_PHASE3)}
              onChange={handleOnChange(
                MsrpCapIds.SINGLE_CAP_PHASE3,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.SINGLE_CAP_PHASE3)}
            />
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.HOH_CAP_PHASE3}
              labelProps={getLabel(MsrpCapIds.HOH_CAP_PHASE3, restrictions)}
              isDollar
              defaultValue={msrpCap.hoHCapPhase3}
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.HOH_CAP_PHASE3)}
              description={restrictions[MsrpCapIds.HOH_CAP_PHASE3]?.description}
              disabled={!getIsExist(MsrpCapIds.HOH_CAP_PHASE3)}
              isUndo={getIsDefault(MsrpCapIds.HOH_CAP_PHASE3)}
              errorProps={handleOnGetError(MsrpCapIds.HOH_CAP_PHASE3)}
              onBlur={handleOnBlur(MsrpCapIds.HOH_CAP_PHASE3)}
              onChange={handleOnChange(
                MsrpCapIds.HOH_CAP_PHASE3,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.HOH_CAP_PHASE3)}
            />
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={MsrpCapIds.JOINT_CAP_PHASE3}
              labelProps={getLabel(MsrpCapIds.JOINT_CAP_PHASE3, restrictions)}
              isDollar
              defaultValue={msrpCap.jointCapPhase3}
              iconProps={dollarIconProps}
              isFocused={getIsExist(MsrpCapIds.JOINT_CAP_PHASE3)}
              description={
                restrictions[MsrpCapIds.JOINT_CAP_PHASE3]?.description
              }
              disabled={!getIsExist(MsrpCapIds.JOINT_CAP_PHASE3)}
              isUndo={getIsDefault(MsrpCapIds.JOINT_CAP_PHASE3)}
              errorProps={handleOnGetError(MsrpCapIds.JOINT_CAP_PHASE3)}
              onBlur={handleOnBlur(MsrpCapIds.JOINT_CAP_PHASE3)}
              onChange={handleOnChange(
                MsrpCapIds.JOINT_CAP_PHASE3,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(MsrpCapIds.JOINT_CAP_PHASE3)}
            />
          </Row>
        )}
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={MsrpCapIds.SOFT_CAP_START_YEAR}
            labelProps={getLabel(MsrpCapIds.SOFT_CAP_START_YEAR, restrictions)}
            isYear
            defaultValue={msrpCap.softCapStartYear}
            iconProps={yearIconProps}
            isFocused={getIsExist(MsrpCapIds.SOFT_CAP_START_YEAR)}
            description={
              restrictions[MsrpCapIds.SOFT_CAP_START_YEAR]?.description
            }
            disabled={!getIsExist(MsrpCapIds.SOFT_CAP_START_YEAR)}
            isUndo={getIsDefault(MsrpCapIds.SOFT_CAP_START_YEAR)}
            errorProps={handleOnGetError(MsrpCapIds.SOFT_CAP_START_YEAR)}
            onBlur={handleOnBlur(MsrpCapIds.SOFT_CAP_START_YEAR)}
            onChange={handleOnChange(
              MsrpCapIds.SOFT_CAP_START_YEAR,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(MsrpCapIds.SOFT_CAP_START_YEAR)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={MsrpCapIds.SOFT_CAP_MAX_DURATION}
            labelProps={getLabel(
              MsrpCapIds.SOFT_CAP_MAX_DURATION,
              restrictions
            )}
            isYears
            defaultValue={msrpCap.softCapEndYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(MsrpCapIds.SOFT_CAP_MAX_DURATION)}
            description={
              restrictions[MsrpCapIds.SOFT_CAP_MAX_DURATION]?.description
            }
            disabled={!getIsExist(MsrpCapIds.SOFT_CAP_MAX_DURATION)}
            isUndo={getIsDefault(MsrpCapIds.SOFT_CAP_MAX_DURATION)}
            errorProps={handleOnGetError(MsrpCapIds.SOFT_CAP_MAX_DURATION)}
            onBlur={handleOnBlur(MsrpCapIds.SOFT_CAP_MAX_DURATION)}
            onChange={handleOnChange(
              MsrpCapIds.SOFT_CAP_MAX_DURATION,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(MsrpCapIds.SOFT_CAP_MAX_DURATION)}
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(MsrpCapIds.APPLY_CAPS_TO_USED, restrictions)}
              value={msrpCap.applyCapsToUsed}
              disabled={!getIsExist(MsrpCapIds.APPLY_CAPS_TO_USED)}
              isUndo={getIsDefault(MsrpCapIds.APPLY_CAPS_TO_USED)}
              description={
                restrictions[MsrpCapIds.APPLY_CAPS_TO_USED]?.description
              }
              errorProps={handleOnCheckAllErrors({
                values: msrpCap,
                radioId: MsrpCapIds.APPLY_CAPS_TO_USED,
                ids: APPLY_CAPS_TO_USED_RADIO_IDS,
                error
              })}
              {...getAttentionIconState(MsrpCapIds.APPLY_CAPS_TO_USED)}
              onChange={handleOnRadioChange(MsrpCapIds.APPLY_CAPS_TO_USED)}
              onUndoClick={handleUndoClick(MsrpCapIds.APPLY_CAPS_TO_USED)}
            />
          </Column>
          {msrpCap.applyCapsToUsed && (
            <>
              <Column
                className={`${classes.cell} ${classes.selectionControlsContainer}`}
                alignItems="flex-start"
              >
                <CustomRadioButtonGroup
                  className={classes.customRadio}
                  labelProps={getLabel(
                    MsrpCapIds.MAGI_CAP_USED_OPTION,
                    restrictions
                  )}
                  firstLabelProps={option1Props}
                  secondLabelProps={option2Props}
                  value={getBooleanValue(msrpCap.magiCapUsedOption)}
                  description={
                    restrictions[MsrpCapIds.MAGI_CAP_USED_OPTION]?.description
                  }
                  isUndo={getIsDefault(MsrpCapIds.MAGI_CAP_USED_OPTION)}
                  onChange={handleOnCustomRadioChange(
                    MsrpCapIds.MAGI_CAP_USED_OPTION
                  )}
                  onUndoClick={handleUndoClick(MsrpCapIds.MAGI_CAP_USED_OPTION)}
                />
              </Column>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE}
                labelProps={getLabel(
                  MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE,
                  restrictions
                )}
                defaultValue={msrpCap.bonusUsedBatteryIncentive}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE)}
                description={
                  restrictions[MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE]
                    ?.description
                }
                disabled={!getIsExist(MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE)}
                isUndo={getIsDefault(MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE)}
                errorProps={handleOnGetError(
                  MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE
                )}
                onBlur={handleOnBlur(MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE)}
                onChange={handleOnChange(
                  MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(
                  MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG}
                labelProps={getLabel(
                  MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG,
                  restrictions
                )}
                isPercent
                defaultValue={msrpCap.batteryDegradationRate}
                iconProps={percentIconProps}
                isFocused={getIsExist(MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG)}
                description={
                  restrictions[MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG]
                    ?.description
                }
                disabled={!getIsExist(MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG)}
                isUndo={getIsDefault(MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG)}
                errorProps={handleOnGetError(
                  MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG
                )}
                onBlur={handleOnBlur(MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG)}
                onChange={handleOnChange(
                  MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(
                  MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG
                )}
              />
              <Column
                className={`${classes.cell} ${classes.selectionControlsContainer}`}
                alignItems="flex-start"
              >
                <RadioButtonGroup
                  {...getLabel(
                    MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS,
                    restrictions
                  )}
                  value={msrpCap.excludeNondealersUsed}
                  disabled={
                    !getIsExist(MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS)
                  }
                  isUndo={getIsDefault(
                    MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS
                  )}
                  description={
                    restrictions[MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS]
                      ?.description
                  }
                  errorProps={handleOnCheckAllErrors({
                    values: msrpCap,
                    radioId: MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS,
                    ids: EXCLUDE_NON_QUALIFIED_SELLERS_RADIO_IDS,
                    error
                  })}
                  {...getAttentionIconState(
                    MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS
                  )}
                  onChange={handleOnRadioChange(
                    MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS
                  )}
                  onUndoClick={handleUndoClick(
                    MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS
                  )}
                />
              </Column>
              {msrpCap.excludeNondealersUsed && (
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={MsrpCapIds.PERCENTAGE_EXCLUDED}
                  labelProps={getLabel(
                    MsrpCapIds.PERCENTAGE_EXCLUDED,
                    restrictions
                  )}
                  isPercent
                  defaultValue={msrpCap.percentExcludedUsed}
                  iconProps={percentIconProps}
                  isFocused={getIsExist(MsrpCapIds.PERCENTAGE_EXCLUDED)}
                  description={
                    restrictions[MsrpCapIds.PERCENTAGE_EXCLUDED]?.description
                  }
                  disabled={!getIsExist(MsrpCapIds.PERCENTAGE_EXCLUDED)}
                  isUndo={getIsDefault(MsrpCapIds.PERCENTAGE_EXCLUDED)}
                  errorProps={handleOnGetError(MsrpCapIds.PERCENTAGE_EXCLUDED)}
                  onBlur={handleOnBlur(MsrpCapIds.PERCENTAGE_EXCLUDED)}
                  onChange={handleOnChange(
                    MsrpCapIds.PERCENTAGE_EXCLUDED,
                    InputTypeValue.PERCENT
                  )}
                  onUndoClick={handleUndoClick(MsrpCapIds.PERCENTAGE_EXCLUDED)}
                />
              )}
              <Column
                className={`${classes.cell} ${classes.selectionControlsContainer}`}
                alignItems="flex-start"
              >
                <RadioButtonGroup
                  {...getLabel(
                    MsrpCapIds.EXCLUDED_SECOND_TIME_SALES,
                    restrictions
                  )}
                  value={msrpCap.excludeSecondTimeSales}
                  disabled={!getIsExist(MsrpCapIds.EXCLUDED_SECOND_TIME_SALES)}
                  isUndo={getIsDefault(MsrpCapIds.EXCLUDED_SECOND_TIME_SALES)}
                  errorProps={handleOnCheckAllErrors({
                    values: msrpCap,
                    radioId: MsrpCapIds.EXCLUDED_SECOND_TIME_SALES,
                    ids: EXCLUDED_SECOND_TIME_SALES_RADIO_IDS,
                    error
                  })}
                  description={
                    restrictions[MsrpCapIds.EXCLUDED_SECOND_TIME_SALES]
                      ?.description
                  }
                  {...getAttentionIconState(
                    MsrpCapIds.EXCLUDED_SECOND_TIME_SALES
                  )}
                  onChange={handleOnRadioChange(
                    MsrpCapIds.EXCLUDED_SECOND_TIME_SALES
                  )}
                  onUndoClick={handleUndoClick(
                    MsrpCapIds.EXCLUDED_SECOND_TIME_SALES
                  )}
                />
              </Column>
              {msrpCap.excludeSecondTimeSales && (
                <>
                  <Column
                    className={`${classes.cell} ${classes.selectionControlsContainer}`}
                    alignItems="flex-start"
                  >
                    <CustomRadioButtonGroup
                      className={classes.customRadio}
                      labelProps={getLabel(
                        MsrpCapIds.SECOND_TIME_SALES_TYPE,
                        restrictions
                      )}
                      firstLabelProps={scalarProps}
                      secondLabelProps={arrayProps}
                      value={getBooleanValue(msrpCap.secondSalesType)}
                      isUndo={getIsDefault(MsrpCapIds.SECOND_TIME_SALES_TYPE)}
                      description={
                        restrictions[MsrpCapIds.SECOND_TIME_SALES_TYPE]
                          ?.description
                      }
                      errorProps={handleOnCheckAllErrors({
                        values: msrpCap,
                        radioId: MsrpCapIds.SECOND_TIME_SALES_TYPE,
                        ids: SECOND_TIME_SALES_TYPE_RADIO_IDS,
                        error
                      })}
                      {...getAttentionIconState(
                        MsrpCapIds.SECOND_TIME_SALES_TYPE
                      )}
                      onChange={handleOnCustomRadioChange(
                        MsrpCapIds.SECOND_TIME_SALES_TYPE
                      )}
                      onUndoClick={handleUndoClick(
                        MsrpCapIds.SECOND_TIME_SALES_TYPE
                      )}
                    />
                  </Column>
                  {msrpCap.secondSalesType === 2 && (
                    <Input
                      alignItems="flex-start"
                      className={classes.cell}
                      id={MsrpCapIds.YEAR_RATE_RICH_50}
                      labelProps={getLabel(
                        MsrpCapIds.YEAR_RATE_RICH_50,
                        restrictions
                      )}
                      isPercent
                      defaultValue={msrpCap.fiftyPercYear}
                      iconProps={percentIconProps}
                      isFocused={getIsExist(MsrpCapIds.YEAR_RATE_RICH_50)}
                      description={
                        restrictions[MsrpCapIds.YEAR_RATE_RICH_50]?.description
                      }
                      disabled={!getIsExist(MsrpCapIds.YEAR_RATE_RICH_50)}
                      isUndo={getIsDefault(MsrpCapIds.YEAR_RATE_RICH_50)}
                      errorProps={handleOnGetError(
                        MsrpCapIds.YEAR_RATE_RICH_50
                      )}
                      onBlur={handleOnBlur(MsrpCapIds.YEAR_RATE_RICH_50)}
                      onChange={handleOnChange(
                        MsrpCapIds.YEAR_RATE_RICH_50,
                        InputTypeValue.PERCENT
                      )}
                      onUndoClick={handleUndoClick(
                        MsrpCapIds.YEAR_RATE_RICH_50
                      )}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {isAssUsApplyUsed && (
            <Column
              className={`${classes.cell} ${classes.selectionControlsContainer}`}
              alignItems="flex-start"
            >
              <RadioButtonGroup
                {...getLabel(
                  MsrpCapIds.APPLY_US_ASSEMBLY_REQ_TO_USED_EVS,
                  restrictions
                )}
                value={msrpCap.assUsApplyUsed}
                disabled={
                  !getIsExist(MsrpCapIds.APPLY_US_ASSEMBLY_REQ_TO_USED_EVS)
                }
                isUndo={getIsDefault(
                  MsrpCapIds.APPLY_US_ASSEMBLY_REQ_TO_USED_EVS
                )}
                onChange={handleOnRadioChange(
                  MsrpCapIds.APPLY_US_ASSEMBLY_REQ_TO_USED_EVS
                )}
                onUndoClick={handleUndoClick(
                  MsrpCapIds.APPLY_US_ASSEMBLY_REQ_TO_USED_EVS
                )}
              />
            </Column>
          )}
        </Row>
      </Content>
    </Column>
  )
}
