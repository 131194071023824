import { InputTypeValue, LABELS } from '.'
import {
  ChangeValuesProps,
  CheckValidProps,
  CoincidenceProps,
  CurrentDropdownProps,
  CustomSettingsProps,
  DropdownItemData,
  DropdownItemsData,
  ErrorType,
  FleetAgeYearValidateProps,
  RangeProps,
  SetErrorProps,
  SettingsType,
  ComponentTextProps,
  NewEvEndYearsVisibilityProps,
  TaxCreditSarProps,
  InvalidValuesProps,
  GetPolicyNameProps,
  GetHasRegionTaxesProps,
  CheckAllErrorsProps,
  CheckDropdownErrorsProps
} from '..'
import {
  CatalogItem,
  CatalogProperties,
  DecodedErrorResponse,
  Policy,
  PolicyEntries,
  PolicyKeys,
  Restrictions,
  User
} from '../../services'
import { ParsedErrorProps } from './agree-modal'
import { BOOLEAN_WEIGHT, SLIDER_VALUES, VALUES_TO_DELETE } from './constants'

export const getColorDarkBlue = (
  textProps: ComponentTextProps
): ComponentTextProps => ({
  color: 'darkBlue',
  ...textProps
})

export const getColorBorder = (
  textProps: ComponentTextProps
): ComponentTextProps => ({
  color: 'border',
  ...textProps
})

export const getColorText = (
  textProps: ComponentTextProps
): ComponentTextProps => ({
  color: 'text',
  ...textProps
})

export const getColorInactive = (
  textProps: ComponentTextProps
): ComponentTextProps => ({
  color: 'inactive',
  ...textProps
})

export const range = ({ start, end, step = 1 }: RangeProps) => {
  const allNumbers = [start, end, step].every(Number.isFinite)
  let backStep = step

  if (!allNumbers) {
    throw new TypeError('range() expects only finite numbers as arguments.')
  }

  if (step <= 0) {
    throw new Error('step must be a number greater than 0.')
  }

  if (start > end) {
    backStep = -step
  }

  const length = Math.floor(Math.abs((end - start) / backStep)) + 1

  return Array.from(Array(length), (x, index) => start + index * backStep)
}

export const checkOnDifference = (
  firstData: SettingsType,
  secondData: SettingsType | Partial<Policy>
) => {
  if (firstData && secondData) {
    const isDifferent = Object.entries(secondData).find(
      ([key, value]: PolicyEntries): boolean => {
        // @ts-ignore
        return firstData[key] !== value
      }
    )
    return Boolean(isDifferent)
  }
  return false
}

export const getDropdownData = (
  id: string,
  catalog: CatalogProperties | {}
): DropdownItemsData => {
  const dropdownsList = Object.entries(catalog)
  const item = dropdownsList.length
    ? dropdownsList.find(([key]) => key === id)
    : undefined
  const optionsList = item ? item[1] : []

  if (!optionsList.length) {
    return []
  }
  const data = optionsList.map((option: CatalogItem) => ({
    id: String(option[0]),
    value: option[1],
    name: option[1],
    onClick: () => {}
  }))
  return data
}

// get default policy value
export const getSetting = (id: string, defaults: any) => {
  if (defaults.length) {
    // important for undo strict comparison (ex. "1" and 1)
    const value = defaults.find((setting: any) => setting.id === id)
    return Math.floor(Number(value)) ? Number(value) : value
  }
  return undefined
}

export const getError = (
  id: string,
  error?: ErrorType
): ComponentTextProps | undefined => {
  if (error && error.id === id) {
    return error.textProps
  }

  return undefined
}

// fields validation rules
export const getIsValid = ({ id, value, restrictions }: CheckValidProps) => {
  const rules = restrictions as Restrictions
  if (rules) {
    const rule = rules[id]
    const { min, max, step = 1 } = rule
    const alignedValue = value.replace(/[,]/g, '') // .

    const isResidue = Boolean(alignedValue % step)
    const isInt = step === 1 && /^[+-]?[0-9]+$/.test(String(alignedValue))
    const isMixed =
      step === 0.000001 &&
      /^[+-]?([0-9]*[.])?[0-9]+$/.test(String(alignedValue))
    const isThousand =
      step === 1000 && alignedValue.slice(alignedValue.length - 3) === '000'
    const isFiveHundred =
      step === 500 && /^[+-]?[0-9]+$/.test(String(alignedValue)) && !isResidue
    const isFiveThousand =
      step === 5000 && /^[+-]?[0-9]+$/.test(String(alignedValue)) && !isResidue

    const isChecked =
      isInt || isMixed || isThousand || isFiveHundred || isFiveThousand

    const isValid =
      Number(alignedValue) >= min &&
      Number(alignedValue) <= max &&
      Boolean(isChecked)

    return {
      isValid,
      rule: {
        step: 0,
        ...rule
      }
    }
  }

  return {
    isValid: false
  }
}

export const fleetAgeYearValidate = ({
  id,
  value,
  years,
  restrictions
}: FleetAgeYearValidateProps) => {
  const rules = restrictions as Restrictions
  if (rules) {
    const rule = rules[id]
    const { min } = rule
    const max = years
    const isValid = Number(value) >= min && Number(value) <= max
    return {
      isValid,
      rule: {
        ...rule,
        min,
        max
      }
    }
  }
  return {
    isValid: false
  }
}

export const getCustomSettings = ({
  defaults,
  updates
}: CustomSettingsProps) => {
  // all values
  const settingsList = Object.entries({
    ...defaults,
    ...updates
  })
  const defaultsList = Object.entries(defaults)
  // default values with custom option 'isOverrided'
  const customDefaults = defaultsList.map(([key, value]) => ({
    id: key,
    value,
    isOverrided: false
  }))
  // values with custom option 'isOverrided'
  const customSettings = settingsList.map(([key, value]) => ({
    id: key,
    value,
    isOverrided: false
  }))

  return {
    customDefaults,
    settingsList,
    customSettings
  }
}

// easy fix to avoid NaN when converting undefined
export const getUpcomingSetting = (
  id: string,
  settingsList: PolicyEntries[]
): any => {
  const setting = settingsList.length
    ? settingsList.find(([key]) => key === id)
    : undefined
  const value = setting ? setting[1] : undefined

  switch (value) {
    case Math.floor(Number(value)):
      return Number(value)
    default:
      return value
  }
}

export const changeCurrentValues = ({
  id,
  isOverrided,
  settings
}: ChangeValuesProps) => {
  return settings.map((setting: any) => {
    if (setting.id === id) {
      return {
        ...setting,
        isOverrided
      }
    }
    return setting
  })
}

export const getCurrentDropdown = ({
  dropdowns,
  value,
  id
}: CurrentDropdownProps) => {
  if (dropdowns) {
    const all = dropdowns[id]
    const current =
      all && all.find((item: DropdownItemData) => Number(item.id) === value)
    return current
  }
  return undefined
}

export const getSettingValue = (value: boolean) =>
  value ? BOOLEAN_WEIGHT[String(value)] : BOOLEAN_WEIGHT.false

export const getBooleanValue = (value?: number) => (value ? value === 1 : false)

export const hasCoincidence = ({
  option,
  value,
  dropdownId,
  getDropdown
}: CoincidenceProps) => {
  const currentValue = getDropdown(value, dropdownId)
  if (currentValue && currentValue.name) {
    const name = currentValue.name.toLowerCase()
    return name.includes(option)
  }
  return false
}

export const getIsEqual = ({
  option,
  value,
  dropdownId,
  getDropdown
}: CoincidenceProps) => {
  const currentValue = getDropdown(value, dropdownId)
  if (currentValue && currentValue.name) {
    const name = currentValue.name.toLowerCase()
    return name === option
  }
  return false
}

export const setError = ({
  id,
  isValid,
  rule,
  sliderId,
  onError
}: SetErrorProps) => {
  if (isValid) {
    onError({ errorId: id })
  } else {
    onError({ errorId: id, values: rule, sliderId })
  }
}

export const isNumeric = (value: string) => /^-?\d+$/.test(value)

export const cleanUpSettings = (
  currentSettings: Partial<Policy> | SettingsType
) => {
  const cleanedSettings = currentSettings

  if (cleanedSettings) {
    VALUES_TO_DELETE.forEach((setting: keyof Policy) => {
      delete cleanedSettings[setting]
    })
  }
  return cleanedSettings
}

export const getIsRegionalOptionsAllowed = (user: User | {}) => {
  if (user && Object.keys(user).length) {
    const u = user as User
    return u.dataAggregationLevel === 2
  }
  return false
}

export const getIsNewEvEndYearsVisible = ({
  value,
  id,
  options,
  getDropdown
}: NewEvEndYearsVisibilityProps) => {
  const selectedOption = getDropdown(value, id)

  const name = selectedOption ? selectedOption.name : ''
  const isOneOfCorrectValues = options.find((option: string) =>
    name.includes(option)
  )
  return Boolean(isOneOfCorrectValues)
}

export const getAlignedValuesByType = (
  values: Partial<Policy> | SettingsType
) => {
  const alignedValues: any = {}

  if (!values) {
    return values
  }

  Object.entries(values).forEach((value: any) => {
    const key = value[0]
    const val = value[1]
    const isNotBoolean = typeof val !== 'boolean'
    // possible zero value, also true as number
    const convertedToNumber = Number(val)
    const isNumberNaN = Number.isNaN(convertedToNumber)

    if (!isNumberNaN && isNotBoolean) {
      alignedValues[key] = Number(val)
    } else {
      alignedValues[key] = val
    }
  })
  return alignedValues
}

export const getIsTaxCreditSar = ({
  id1,
  id2,
  currentValues,
  updates
}: TaxCreditSarProps) => {
  const value1 = currentValues[id1] || updates[id1] || 0
  const value2 = currentValues[id2] || updates[id2] || 0

  return Boolean(Number(value1) + Number(value2) > 0)
}

export const getIsTaxCreditRegionSar = ({
  id1,
  id2,
  currentValues,
  updates
}: TaxCreditSarProps) => {
  const value1 = currentValues[id1] || updates[id1] || 0
  const value2 = currentValues[id2] || updates[id2] || 0

  return Boolean(Number(value1)) && Boolean(Number(value2) > 0)
}

export const getErrorsInString = (
  invalidValuesOnFailure: any
): ComponentTextProps => {
  const isErrorsExist = Object.keys(invalidValuesOnFailure).length
  let errors = ''

  if (isErrorsExist) {
    errors = Object.entries(invalidValuesOnFailure)
      .map(([, value]: any) => value)
      .join('<br>')

    return {
      text: errors,
      preset: 'body'
    }
  }

  return {
    tx: 'policy.smthWentWrong',
    text: 'Something went wrong',
    preset: 'h1'
  }
}

export const getIsValuesValid = (
  values: DecodedErrorResponse
): ParsedErrorProps[] => {
  const responseLength = Object.keys(values).length
  const invalidValuesList: ParsedErrorProps[] = []

  if (responseLength) {
    Object.entries(values).forEach(([key, value]: InvalidValuesProps) => {
      if (!value.valid) {
        invalidValuesList.push({ id: key, ...value })
      }
    })
  }
  return invalidValuesList
}

export const getPolicyName = ({
  updates,
  defaults
}: GetPolicyNameProps): string => {
  if (Object.keys(defaults).length) {
    const updatesList = updates as Partial<Policy>
    if (updatesList.name) {
      return updatesList.name
    }
    return defaults.name
  }
  return ''
}

export const getHasRegionTaxes = ({
  defaults,
  updates,
  catalog
}: GetHasRegionTaxesProps) => {
  let isRegionHasTaxes = ''
  const isCatalog = Object.keys(catalog).length
  const isDefaults = Object.keys(defaults).length

  if (isCatalog && isDefaults) {
    const values = catalog as CatalogProperties
    const defValues = defaults as Policy

    values.regionsWithSalesTax.forEach((item: any) => {
      const hasRegion = item.includes(updates.region || defValues.region)
      if (hasRegion) {
        isRegionHasTaxes = hasRegion
      }
    })
  }
  return isRegionHasTaxes
}

export const handleOnCheckAllErrors = ({
  values,
  radioId,
  ids,
  error
}: CheckAllErrorsProps): ComponentTextProps | undefined => {
  if (values[radioId]) {
    return undefined
  }

  const errors: any = ids.map((id: string) => getError(id, error))
  return errors.find((props?: ComponentTextProps) => props)
}

export const handleOnCheckCustomRadioErrors = ({
  ids,
  error
}: CheckAllErrorsProps): ComponentTextProps | undefined => {
  const errors: any = ids.map((id: string) => getError(id, error))
  return errors.find((props?: ComponentTextProps) => props)
}

export const handleOnCheckDropdownErrors = ({
  ids,
  error
}: CheckDropdownErrorsProps): ComponentTextProps | undefined => {
  const errors: any = ids.map((id: string) => getError(id, error))
  return errors.find((props?: ComponentTextProps) => props)
}

export const getSliderId = (id: string) =>
  Object.entries(SLIDER_VALUES)
    .map(([key, arr]) => {
      const c = arr.includes(id)
      if (c) {
        return key
      }
      return undefined
    })
    .find((x: any) => Boolean(x))

export const cleanUpFromComma = (values: Partial<Policy> | SettingsType) => {
  const alignedValues: any = {}
  if (values) {
    Object.entries(values).forEach(([key, value]: any) => {
      if (typeof value === 'string' && value.includes(',')) {
        const newValue = value.replace(/[,]/g, '')
        alignedValues[key] = newValue
      }
    })
  }

  return {
    ...values,
    ...alignedValues
  }
}

export const getLabel = (
  id: PolicyKeys,
  restrictions: Restrictions
): ComponentTextProps => {
  const beTitle = restrictions[id]?.title
  return beTitle
    ? {
        text: beTitle,
        color: 'darkBlue'
      }
    : {
        ...LABELS[id],
        color: 'darkBlue'
      }
}

export const formatDollars = (pureString: string) => {
  const letterSymbolRegex = new RegExp(/[A-Za-z!@#$%^&*/,()]/g)
  const thousandsRegex = new RegExp(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g)

  const fixedInt = pureString.replace(letterSymbolRegex, '')
  const formattedThousands = fixedInt.replace(thousandsRegex, '$1,')
  return formattedThousands
}

export const formatToFloat = (pureString: string) => {
  const letterSymbolRegex = new RegExp(/[A-Za-z!@#$%^&*/,()]/g)
  const alignedString = pureString.replace(letterSymbolRegex, '')
  return isNumeric(alignedString) ? Number(alignedString) : alignedString
}

export const formatToNumber = (pureString: string): string | number => {
  const letterSymbolRegex = new RegExp(/[A-Za-z!@#$%^&*/,.()]/g)
  const alignedString = pureString.replace(letterSymbolRegex, '')
  return isNumeric(alignedString) ? Number(alignedString) : alignedString
}

export const formatValue = (value: string, flag: InputTypeValue) => {
  let alignedValue: string | number = value
  if (flag === InputTypeValue.DOLLAR) {
    alignedValue = formatDollars(value)
  }

  const isNumbers =
    flag === InputTypeValue.YEAR ||
    flag === InputTypeValue.YEARS ||
    flag === InputTypeValue.EVS
  if (isNumbers) {
    alignedValue = formatToNumber(value)
  }

  if (flag === InputTypeValue.PERCENT) {
    alignedValue = formatToFloat(value)
  }

  return String(alignedValue)
}
