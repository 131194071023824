import React, {
  MouseEvent,
  FC,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { useResponsive } from '../../providers'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { Warning } from '../warning'
import { SliderMenuProps } from './slider-menu.types'
import { useStyle } from './slider-menu.styles'

export const SliderMenu: FC<SliderMenuProps> = ({
  className = '',
  containerClassName = '',
  iconName,
  iconColor,
  textColor,
  text,
  tx,
  preset = 'h3',
  dropdownIconName = 'black',
  isOpen = false,
  disabled = false,
  warningTextProps,
  children,
  sliderId,
  minHeight = 169,
  isRevert,
  onRevert,
  onClick,
  onStateChange
}) => {
  const { isTablet } = useResponsive()
  const itemsContainerRef = useRef<HTMLDivElement>(null)

  const itemsContainer = itemsContainerRef.current
  const containerHeight = itemsContainer
    ? itemsContainer.offsetHeight
    : minHeight
  const [height, changeHeight] = useState(containerHeight)

  const [open, changeOpen] = useState(isOpen)
  const [isDisabled, changeIsDisabled] = useState(disabled)

  const isWarningVisible = isTablet ? false : Boolean(warningTextProps)
  const theme: LightTheme = useTheme()
  const classes = useStyle({
    open,
    height: containerHeight || height,
    disabled: isDisabled,
    theme
  })
  const iconFill = iconColor ? theme.colors[iconColor] : undefined
  const titleClass = iconName ? classes.title : ''

  const handleOnClick = useCallback(() => {
    if (!disabled) {
      const state = !open
      changeOpen(state)

      if (onClick && sliderId) {
        onClick(state)
      }

      if (onStateChange && sliderId) {
        onStateChange(sliderId, state)
      }
    }
  }, [open, disabled, onClick])

  const getOffsetHeight = () => {
    const container = itemsContainerRef.current
    const calcHeight = container ? container.offsetHeight : 0
    changeHeight(calcHeight || minHeight)

    return calcHeight
  }

  const handleOnRevert = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ): void => {
    event.stopPropagation()

    if (onRevert) {
      onRevert()
    }
  }

  useEffect(() => {
    changeIsDisabled(disabled)
  }, [disabled])

  useEffect(() => {
    changeOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    // avoid rendering for all sliders, especially closed sliders
    if (open && !isDisabled) {
      const sliderHeight = getOffsetHeight()

      // reset height when optional inputs are added/removed and offsetHeight == 0
      // *look how React ref work
      if (sliderHeight) {
        setTimeout(() => {
          getOffsetHeight()
        }, 2000)
      }
    }
  }, [itemsContainerRef, children, open, isDisabled])

  return (
    <Column className={`${className} ${classes.container}`} fullWidth>
      <Row
        className={classes.row}
        justifyContent="space-between"
        onClick={handleOnClick}
      >
        <Row>
          {iconName && <Icon name={iconName} fill={iconFill} />}
          <Text
            className={titleClass}
            text={text}
            tx={tx}
            preset={preset}
            color={textColor}
          />
        </Row>
        <Row>
          {isWarningVisible && (
            <Warning className={classes.warning} textProps={warningTextProps} />
          )}
          {isRevert && (
            <Row className={classes.replyAll} onClick={handleOnRevert}>
              <Icon name={IconName.REPLY_ALL} fill="purple" />
              <Text
                className={classes.reset}
                tx="reset.policy.title"
                text="Reset"
                color="purple"
              />
            </Row>
          )}
          <Icon
            className={classes.arrow}
            name={IconName.ARROW_DROP_DOWN}
            fill={dropdownIconName}
          />
        </Row>
      </Row>
      <Column
        className={`${containerClassName} ${classes.itemsContainer}`}
        justifyContent="flex-start"
      >
        <Column ref={itemsContainerRef}>{children}</Column>
      </Column>
    </Column>
  )
}
