import React, { FC } from 'react'

import { Column, AffordabilityTool } from '../../components'

export const AffordabilityToolPage: FC = () => {
  return (
    <Column justifyContent="flex-start">
      <AffordabilityTool />
    </Column>
  )
}
