import React, { FC, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Text } from '../text'
import { Column } from '../column'
import { PolicyCreators, State } from '../../store'
import { Row } from '../row'
import { Spinner } from '../spinner'
import { Button } from '../button'
import { IconButton } from '../icon-button'
import { IconName } from '../icon'
import { CardItemsList } from './card-items-list'
import { CardListProps } from './card-list.types'
import { useStyle } from './card-list.styles'

const LIMIT = 8

export const CardList: FC<CardListProps> = ({
  className,
  draggable,
  orderBy,
  current,
  valuesAmount,
  onAddToCompare
}) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const { isLoaded, data, next, previous } = useSelector((state: State) => ({
    ...state.app,
    data: state.policy.data,
    next: state.policy.next,
    previous: state.policy.previous
  }))

  const isLimitExceeded = current > LIMIT

  const handleOnLoadMore = useCallback(() => {
    dispatch(
      PolicyCreators.getPoliciesRequest({
        offset: current,
        limit: LIMIT,
        ordering: orderBy
      })
    )
  }, [current, orderBy])

  const handleOnScrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    const isAllowedToScroll = current && previous
    if (isAllowedToScroll && buttonRef.current) {
      buttonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }, [current])

  useEffect(() => {
    return () => {
      dispatch(PolicyCreators.getPoliciesRequest())
    }
  }, [])

  return (
    <Column fullWidth justifyContent="flex-start">
      {isLimitExceeded && <Row className={classes.gradient} fullWidth />}
      <Column
        ref={ref}
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
      >
        <Column fullWidth>
          <CardItemsList
            data={data}
            draggable={draggable}
            onAddToCompare={onAddToCompare}
          />
        </Column>
        <Row className={classes.spinner} fullHeight>
          <Spinner isLoading={!isLoaded} />
        </Row>
        <Row ref={buttonRef}>
          <Button
            className={classes.loadMore}
            preset="secondary"
            disabled={!next}
            onClick={handleOnLoadMore}
          >
            <Text
              text="Load more {part} of {count}"
              tx="policy.loadMore"
              values={valuesAmount}
              color="text"
              preset="body"
            />
          </Button>
        </Row>
      </Column>
      {isLimitExceeded && (
        <IconButton
          className={classes.goUp}
          iconName={IconName.ARROW_BACK}
          fill="darkBlue"
          preset="up"
          onClick={handleOnScrollToTop}
        />
      )}
    </Column>
  )
}
