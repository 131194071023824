import React, { FC } from 'react'
import { IconProps } from './types'

import logo from './logo.png'

export const Logo: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={173}
      height={64}
      viewBox="0 0 173 64"
      fill="none"
      {...props}
    >
      <image width={173} height={64} xlinkHref={logo} />
    </svg>
  )
}
