import { createReducer } from 'reduxsauce'
import { Policy } from '../../../services'

import { PolicyTypes, PolicyActionTypes } from '../../actions'
import {
  PolicyWithProperties,
  PolicyState,
  AffordabilityState,
  AdditionalGraphState
} from './policy.types'
import {
  changeDraggableState,
  checkOnPrevioslyDraggableData,
  setDraggableState
} from './utils'

/* ------------- Reducers ------------- */

const INITIAL_STATE: PolicyState = {
  isCreateLoaded: true,
  isUpdateLoaded: true,
  isReportLoaded: true,
  currentId: '',
  data: [],
  count: 0,
  next: '',
  previous: '',
  compareIds: [],
  comparePolicies: [],
  plot: '',
  defaultPolicy: {},
  catalog: {},
  regions: [],
  bauDefaults: {},
  restrictions: {},
  createError: '',
  updateError: '',
  pdfReport: '',
  reportError: '',
  error: ''
}

// TODO: rewrite state with empty values, it can cause issues
const AFFORDABILITY_INITIAL_STATE: AffordabilityState = {
  isLoaded: true,
  id: '',
  plot: '',
  png: '',
  newOrUsed: 1,
  purchaseOrLease: 1,
  evTypeBev: true,
  evTypePhev: true,
  hhsize: 1,
  error: ''
}

const ADDITIONAL_GRAPH_INITIAL_STATE: AdditionalGraphState = {
  isLoaded: true,
  plot: '',
  error: ''
}

export const policyCompareAdd = (
  state = INITIAL_STATE,
  action: PolicyTypes.PolicyCompareAddAction
) => {
  if (action.payload) {
    const { id } = action.payload
    return {
      ...state,
      data: changeDraggableState(state, id, false)
    }
  }

  return state
}

export const policyCompareRemove = (
  state = INITIAL_STATE,
  action: PolicyTypes.PolicyCompareRemoveAction
) => {
  if (action.payload) {
    const { id } = action.payload

    return {
      ...state,
      data: changeDraggableState(state, id, true)
    }
  }

  return state
}

export const getPoliciesSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetPoliciesActionSuccess
) => {
  const { results, count, next, previous } = action.payload
  // TODO: check this if, suppose that is never used
  if (action.payload.reset) {
    const data = checkOnPrevioslyDraggableData(results, state.compareIds)

    return {
      ...state,
      currentId: '',
      data,
      count,
      next,
      previous
    }
  }

  if (action.payload) {
    const updatedResults = [...state.data, ...results]
    const data = checkOnPrevioslyDraggableData(updatedResults, state.compareIds)

    return {
      ...state,
      currentId: '',
      data,
      count,
      next,
      previous
    }
  }

  return {
    ...state,
    data: []
  }
}

export const getPoliciesFailure = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetPoliciesActionFailure
) => {
  if (action.payload) {
    return {
      ...state,
      currentId: '',
      data: [],
      next: '',
      previous: ''
    }
  }

  return state
}

export const getPoliciesByPagesSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetPoliciesActionSuccess
) => {
  const { results, count, next, previous } = action.payload
  // TODO: check this if, suppose that is never used
  if (action.payload.reset) {
    const data = checkOnPrevioslyDraggableData(results, state.compareIds)

    return {
      ...state,
      currentId: '',
      data,
      count,
      next,
      previous
    }
  }

  if (action.payload) {
    const data = checkOnPrevioslyDraggableData([...results], state.compareIds)

    return {
      ...state,
      currentId: '',
      data,
      count,
      next,
      previous
    }
  }

  return {
    ...state,
    data: []
  }
}

export const resetDraggablePolicy = (state = INITIAL_STATE) => {
  return {
    ...state,
    data: setDraggableState(state.data),
    compareIds: []
  }
}

export const createPolicySuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.CreatePolicyActionSuccess
) => {
  if (action.payload) {
    const policy = action.payload
    const policyWithDraggableState: PolicyWithProperties = {
      ...policy,
      draggable: true
    }

    return {
      ...state,
      isCreateLoaded: true,
      currentId: policy.id,
      data: [policyWithDraggableState, ...state.data],
      count: state.count + 1,
      createError: ''
    }
  }

  return {
    ...state,
    isCreateLoaded: true,
    createError: ''
  }
}

export const createPolicyFailure = (
  state = INITIAL_STATE,
  action: PolicyTypes.CreatePolicyActionFailure
) => {
  if (action.payload) {
    const { error } = action.payload

    return {
      ...state,
      isCreateLoaded: true,
      currentId: '',
      createError: error || 'Error'
    }
  }

  return {
    ...state,
    isCreateLoaded: true,
    createError: ''
  }
}

// UPDATE POLICY

export const updatePolicyRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isUpdateLoaded: false
  }
}

export const updatePolicySuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.UpdatePolicyActionSuccess
) => {
  if (action.payload) {
    const policy = action.payload
    const policyWithDraggableState: PolicyWithProperties = {
      ...policy,
      draggable: true
    }
    // remove previous/old policy
    const cleanedData = state.data.filter(
      (item: Policy) => item.id !== policy.id
    )

    return {
      ...state,
      isUpdateLoaded: true,
      currentId: policy.id,
      data: [...cleanedData, policyWithDraggableState],
      updateError: ''
    }
  }

  return state
}

export const updatePolicyFailure = (
  state = INITIAL_STATE,
  action: PolicyTypes.UpdatePolicyActionFailure
) => {
  if (action.payload) {
    const { error } = action.payload

    return {
      ...state,
      isUpdateLoaded: true,
      updateError: error || 'Error'
    }
  }

  return {
    ...state,
    isUpdateLoaded: true,
    updateError: ''
  }
}

// eof UPDATE POLICY

export const getPolicySuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetPolicyActionSuccess
) => {
  if (action.payload) {
    const policy = action.payload
    const policyWithDraggableState: PolicyWithProperties = {
      ...policy,
      draggable: true
    }

    return {
      ...state,
      currentId: policy ? policy.id : '',
      data: [policyWithDraggableState, ...state.data]
    }
  }

  return state
}

export const getDefaultPolicySuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetDefaultPolicyActionSuccess
) => {
  if (action.payload) {
    const defaultPolicy = action.payload

    return {
      ...state,
      defaultPolicy
    }
  }

  return state
}

export const deletePolicySuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.DeletePolicyActionSuccess
) => {
  if (action.payload) {
    const id = action.payload
    const newState = state.data.filter((item: Policy) => item.id !== id)
    const count = state.count ? state.count - 1 : 0

    return {
      ...state,
      currentId: '',
      data: newState,
      count
    }
  }

  return state
}

export const getPolicyReportRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isReportLoaded: false
  }
}

export const getPolicyReportSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetPolicyReportActionSuccess
) => {
  if (action.payload) {
    const pdfReport = action.payload

    return {
      ...state,
      isReportLoaded: true,
      pdfReport,
      reportError: ''
    }
  }

  return {
    ...state,
    isReportLoaded: true,
    reportError: 'Error'
  }
}

export const getPolicyReportFailure = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetPolicyReportActionFailure
) => {
  if (action.payload) {
    const { error } = action.payload

    return {
      ...state,
      isReportLoaded: true,
      pdfReport: '',
      reportError: error || 'Error'
    }
  }

  return {
    ...state,
    isReportLoaded: true,
    pdfReport: '',
    reportError: ''
  }
}

export const getPolicyPlotSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetPolicyPlotActionSuccess
) => {
  if (action.payload) {
    const plot = action.payload

    return {
      ...state,
      plot
    }
  }

  return state
}

export const getCatalogSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetCatalogActionSuccess
) => {
  if (action.payload) {
    const catalog = action.payload

    return {
      ...state,
      catalog
    }
  }

  return state
}

export const getRegionsSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetRegionsActionSuccess
) => {
  if (action.payload) {
    const regions = action.payload

    return {
      ...state,
      regions
    }
  }

  return state
}

export const getBauDefaultsSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetBauDefaultsActionSuccess
) => {
  if (action.payload) {
    const bauDefaults = action.payload

    return {
      ...state,
      bauDefaults
    }
  }

  return state
}

export const updateAffordabilitySuccess = (
  state = AFFORDABILITY_INITIAL_STATE,
  action: PolicyTypes.UpdateAffordabilityActionSuccess
) => {
  if (action.payload) {
    const {
      data,
      png,
      id,
      newOrUsed,
      purchaseOrLease,
      evTypeBev,
      evTypePhev,
      hhsize
    } = action.payload

    return {
      ...state,
      isLoaded: true,
      id,
      plot: data,
      png,
      newOrUsed,
      purchaseOrLease,
      evTypeBev,
      evTypePhev,
      hhsize
    }
  }

  return state
}

export const updateAdditionalGraphSuccess = (
  state = ADDITIONAL_GRAPH_INITIAL_STATE,
  action: PolicyTypes.UpdateAdditionalGraphActionSuccess
) => {
  if (action.payload) {
    const { graph } = action.payload

    return {
      ...state,
      isLoaded: true,
      plot: graph
    }
  }

  return state
}

export const getRestrictionsSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetRestrictionsActionSuccess
) => {
  if (action.payload) {
    const restrictions = action.payload

    return {
      ...state,
      restrictions
    }
  }

  return state
}

export const setCompareIds = (
  state = INITIAL_STATE,
  action: PolicyTypes.SetPoliciesCompareListAction
) => {
  if (action.payload) {
    return {
      ...state,
      compareIds: action.payload.ids
    }
  }

  return state
}

export const getComparePolicyListSuccess = (
  state = INITIAL_STATE,
  action: PolicyTypes.GetComparePoliciesByIdsActionSuccess
) => {
  if (action.payload) {
    return {
      ...state,
      comparePolicies: action.payload
    }
  }

  return state
}

export const getComparePolicyListRequestOrFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    comparePolicies: []
  }
}

export const createPolicyRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isCreateLoaded: false
  }
}

export const updateAffordabilityRequest = (
  state = AFFORDABILITY_INITIAL_STATE
) => {
  return {
    ...state,
    isLoaded: false
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const policy = createReducer(INITIAL_STATE, {
  [PolicyActionTypes.POLICY_COMPARE_ADD]: policyCompareAdd,
  [PolicyActionTypes.POLICY_COMPARE_REMOVE]: policyCompareRemove,
  [PolicyActionTypes.RESET_DRAGGABLE_POLICY]: resetDraggablePolicy,
  [PolicyActionTypes.GET_POLICIES_SUCCESS]: getPoliciesSuccess,
  [PolicyActionTypes.GET_POLICIES_FAILURE]: getPoliciesFailure,
  [PolicyActionTypes.GET_POLICIES_BY_PAGES_SUCCESS]: getPoliciesByPagesSuccess,
  [PolicyActionTypes.CREATE_POLICY_REQUEST]: createPolicyRequest,
  [PolicyActionTypes.CREATE_POLICY_SUCCESS]: createPolicySuccess,
  [PolicyActionTypes.CREATE_POLICY_FAILURE]: createPolicyFailure,
  [PolicyActionTypes.GET_POLICY_REPORT_SUCCESS]: getPolicyReportSuccess,
  [PolicyActionTypes.GET_POLICY_REPORT_FAILURE]: getPolicyReportFailure,
  [PolicyActionTypes.GET_POLICY_SUCCESS]: getPolicySuccess,
  [PolicyActionTypes.GET_DEFAULT_POLICY_SUCCESS]: getDefaultPolicySuccess,
  [PolicyActionTypes.DELETE_POLICY_SUCCESS]: deletePolicySuccess,
  [PolicyActionTypes.UPDATE_POLICY_REQUEST]: updatePolicyRequest,
  [PolicyActionTypes.UPDATE_POLICY_SUCCESS]: updatePolicySuccess,
  [PolicyActionTypes.UPDATE_POLICY_FAILURE]: updatePolicyFailure,
  [PolicyActionTypes.GET_POLICY_PLOT_SUCCESS]: getPolicyPlotSuccess,
  [PolicyActionTypes.GET_CATALOG_SUCCESS]: getCatalogSuccess,
  [PolicyActionTypes.GET_REGIONS_SUCCESS]: getRegionsSuccess,
  [PolicyActionTypes.GET_BAU_DEFAULTS_SUCCESS]: getBauDefaultsSuccess,
  [PolicyActionTypes.GET_RESTRICTIONS_SUCCESS]: getRestrictionsSuccess,
  [PolicyActionTypes.SET_POLICIES_COMPARE_LIST]: setCompareIds,
  [PolicyActionTypes.GET_COMPARE_POLICIES_BY_IDS_SUCCESS]: getComparePolicyListSuccess,
  [PolicyActionTypes.GET_COMPARE_POLICIES_BY_IDS_FAILURE]: getComparePolicyListRequestOrFailure,
  [PolicyActionTypes.GET_COMPARE_POLICIES_BY_IDS_REQUEST]: getComparePolicyListRequestOrFailure
})

export const affordability = createReducer(AFFORDABILITY_INITIAL_STATE, {
  [PolicyActionTypes.UPDATE_AFFORDABILITY_REQUEST]: updateAffordabilityRequest,
  [PolicyActionTypes.UPDATE_AFFORDABILITY_SUCCESS]: updateAffordabilitySuccess
})

export const additionalGraph = createReducer(ADDITIONAL_GRAPH_INITIAL_STATE, {
  [PolicyActionTypes.UPDATE_ADDITIONAL_GRAPH_SUCCESS]: updateAdditionalGraphSuccess
})
