import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Column } from '../../column'
import { Row } from '../../row'
import { Text } from '../../text'
import { PolicyListHeaderProps } from './policy-list-header.types'
import { useStyle } from './policy-list-header.styles'

export const PolicyListHeader: FC<PolicyListHeaderProps> = ({ className }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Row
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="space-between">
        <Column
          className={classes.name}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text text="Policy Name" tx="policy.policyName" preset="boldHeader" />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text text="Duration" tx="policy.duration" preset="boldHeader" />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text text="Total cost" tx="policy.totalCost" preset="boldHeader" />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            text="EV Fleet Share"
            tx="policy.evFleetShare"
            preset="boldHeader"
          />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            text="GHGs Avoided"
            tx="policy.ghgAvoided"
            preset="boldHeader"
          />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            className={classes.flex}
            text="$ / MTCO{sub}e"
            tx="policy.MTCO2"
            preset="boldHeader"
            values={{
              sub: (
                <Text
                  className={classes.sub}
                  text="2"
                  preset="boldTitle"
                  color="black"
                />
              )
            }}
          />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            text="Date Modified"
            tx="policy.dateModified"
            preset="boldHeader"
          />
        </Column>
      </Row>
    </Row>
  )
}
