import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import {
  Content,
  IconName,
  Column,
  SliderMenu,
  PolicySettings,
  InputsPolicyProps,
  VALUES_BY_BLOCKS,
  SLIDER_VALUES,
  ActionTypeName
} from '../..'
import { ObjectProps } from '..'
import { SliderHeader } from '../../slider-header'
import { MSRPCap } from './msrp-cap'
import { CommercialFleetIncentive } from './commercial-fleet-incentive'
import { EVIncentives } from './ev-incentives'
import { RegulatoryPolicies } from './regulatory-policies'
import { ScrapReplaceProgram } from './scrap-replace-program'
import { EVIIncentive } from './evi-incentive'
import { useStyle } from '../inputs.styles'

const data: PolicySettings = {
  id: '1',
  groupId: 'national',
  tx: 'policy.nationalInputs',
  text: 'National Inputs',
  types: [
    {
      tx: 'policy.eligibilityForNewInc',
      text: 'Eligibility for New EV Incentives',
      sliderId: 'msrpCap',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }) => (
        <MSRPCap
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      )
    },
    {
      tx: 'policy.evIncentives',
      text: 'EV Incentives',
      sliderId: 'evIncentives',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }) => (
        <EVIncentives
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      )
    },
    {
      tx: 'policy.eviIncentives',
      text: 'EVI Incentives',
      sliderId: 'eviIncentive',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }) => (
        <EVIIncentive
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      )
    },
    {
      tx: 'policy.commercialFleetIncentive',
      text: 'Commercial Fleet Incentive',
      sliderId: 'commercialFleetIncentive',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }) => (
        <CommercialFleetIncentive
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      )
    },
    {
      tx: 'policy.scrapReplaceProgram',
      text: 'Scrap & Replace Program',
      sliderId: 'scrapReplace',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }) => (
        <ScrapReplaceProgram
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      )
    },
    {
      tx: 'policy.regulatoryPolicies',
      text: 'Regulatory Policies',
      sliderId: 'regulatoryPolicies',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }) => (
        <RegulatoryPolicies
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      )
    }
  ]
}

export const NationalInputs: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  defaultPolicy,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [isRevert, changeIsRevert] = useState(false)

  const settingsLength = data.types.length
  const getLastSliderClass = (key: number) => {
    const isLast = key === settingsLength - 1
    return isLast ? classes.slider : ''
  }
  const [isOpen, changeIsOpen] = useState(false)
  const [slidersStates, changeSlidersState] = useState<ObjectProps>({
    msrpCap: false,
    eviIncentive: false,
    evIncentives: false,
    commercialFleetIncentive: false,
    scrapReplace: false,
    regulatoryPolicies: false
  })

  const revert = (array: string[]) => {
    const idsToRevert: any = {}
    array.forEach((id: string) => {
      const isKeyExist = id in updates
      const isValueUpdated =
        defaultPolicy && Object.keys(updates).length && isKeyExist
      if (isValueUpdated) {
        idsToRevert[id] = defaultPolicy[id]
      }
    })
    const isIdsExist = Object.keys(idsToRevert).length
    if (isIdsExist) {
      onChange(idsToRevert, ActionTypeName.REVERT)
    }
  }

  const handleOnRevert = () => {
    revert(VALUES_BY_BLOCKS.nationalInputs)
  }

  const handleOnRevertPartly = (sliderId: string) => () => {
    revert(SLIDER_VALUES[sliderId])
  }

  const getIsRevertPartly = useCallback(
    (sliderId: string): boolean => {
      const isUpdated = SLIDER_VALUES[sliderId].find((id: string) =>
        Object.keys(updates).includes(id)
      )
      return Boolean(isUpdated)
    },
    [updates]
  )

  const handleOnStateChange = (state: boolean) => {
    if (!state && error) {
      const { id: errorId } = error
      onError({ errorId, reset: true })
    }
  }

  const updateSliderState = (sliderId: string, value: boolean) => {
    changeSlidersState({ ...slidersStates, [sliderId]: value })
  }

  const handleOnClick = () => {
    const state = !isOpen
    changeIsOpen(state)
    changeSlidersState({
      msrpCap: state,
      eviIncentive: state,
      evIncentives: state,
      commercialFleetIncentive: state,
      scrapReplace: state,
      regulatoryPolicies: state
    })
  }

  useEffect(() => {
    const isAllOpened = Object.values(slidersStates).every((el: boolean) => el)

    changeIsOpen(isAllOpened)
  }, [slidersStates])

  useEffect(() => {
    if (defaults) {
      const isUpdated = VALUES_BY_BLOCKS.nationalInputs.find((id: string) =>
        Object.keys(updates).includes(id)
      )

      changeIsRevert(Boolean(isUpdated))
    }
  }, [defaultPolicy, updates, defaults])

  useEffect(() => {
    const sliders: ObjectProps = {}
    data.types.forEach((type) => {
      const { sliderId } = type
      const isOpened = error
        ? error.sliderId === sliderId
        : slidersStates[sliderId]

      sliders[sliderId] = isOpened
    })
    changeSlidersState(sliders)
  }, [error])

  return (
    <Column fullHeight className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <SliderHeader
          text={data.text}
          tx={data.tx}
          iconName={IconName.STAR_RATE}
          iconFill="button"
          isButtonClicked={isOpen}
          isRevert={isRevert}
          handleRevert={handleOnRevert}
          onClick={handleOnClick}
        />

        {data.types.map((type, idx) => {
          const Component = type.comp as React.FC<InputsPolicyProps>
          const { sliderId } = type

          return (
            <SliderMenu
              key={`national_${type.text}`}
              className={`${classes.border} ${getLastSliderClass(idx)}`}
              isOpen={slidersStates[sliderId]}
              text={type.text}
              tx={type.tx}
              dropdownIconName="darkBlue"
              sliderId={type.sliderId}
              isRevert={getIsRevertPartly(sliderId)}
              onRevert={handleOnRevertPartly(type.sliderId)}
              onClick={handleOnStateChange}
              onStateChange={updateSliderState}
            >
              <Component
                defaults={defaults}
                updates={updates}
                error={error}
                defaultPolicy={defaultPolicy}
                onChange={onChange}
                onError={onError}
              />
            </SliderMenu>
          )
        })}
      </Content>
    </Column>
  )
}
