import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.tableRow,
    borderRadius: '12px 12px 0 0 ',
    width: 'calc(100% - 72px)',
    height: 46,
    padding: '0 56px 0 16px',
    borderBottom: `1px solid ${theme.colors.grey}`,
    boxShadow: '2px 4px 6px 0px #0000000f',
    '&:hover': {
      boxShadow: '2px 4px 8px #00000029'
    }
  },
  name: {
    width: 160
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: theme.cursor
    }
  },
  column: {
    width: 100
  },
  text: {
    cursor: theme.cursor,
    marginTop: 16,
    maxWidth: 123,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  sub: {
    marginTop: 1
  },
  flex: {
    display: 'flex'
  },
  '@media screen and (max-width: 800px)': {
    name: {
      width: 100
    },
    text: {
      maxWidth: 90
    },
    column: {
      width: 'auto'
    }
  }
}))
