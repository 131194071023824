import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { WidgetsProps } from './widgets.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    width: 'calc(100% - 72px)',
    padding: '18px 34px',
    flexDirection: ({ vertical }: WidgetsProps) =>
      vertical ? 'column' : 'row',
    borderRadius: 12
  },
  header: {
    height: 46
  },
  name: {
    overflow: 'hidden'
  },
  remove: {
    cursor: theme.cursor
  },
  widgetContainer: {
    padding: ({ vertical }) => (vertical ? '16px 0' : 0)
  },
  row: {
    padding: '8px 0'
  },
  separator: {
    borderLeft: ({ vertical }: WidgetsProps) =>
      vertical ? 'none' : `1px solid ${theme.colors.secondButton}`,
    width: ({ vertical }: WidgetsProps) => (vertical ? '100%' : 1),
    height: ({ vertical }: WidgetsProps) => (vertical ? 1 : '100%'),
    marginLeft: ({ vertical }: WidgetsProps) => (vertical ? 0 : 32),
    marginRight: ({ vertical }: WidgetsProps) => (vertical ? 0 : 32), // indent only for separator,
    backgroundColor: ({ vertical }: WidgetsProps) =>
      vertical ? theme.colors.secondButton : 'none'
  },
  description: {
    marginLeft: 16
  },
  breadcrumbs: {
    transform: 'rotate(180deg)',
    cursor: theme.cursor,
    '&:hover > *': {
      fill: theme.colors.lightBlue
    }
  },
  button: {
    marginTop: 24
  },
  text: {
    whiteSpace: 'break-spaces',
    textAlign: 'center'
  },
  titleSeparator: {
    marginTop: 16
  },
  mainCell: {
    minWidth: 140
  },
  cell: {
    width: 94
  },
  label: {
    marginLeft: 8
  },
  emptyRegion: {
    minHeight: 32
  },
  sub: {
    marginTop: -12
  }
}))
