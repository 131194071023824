import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import { State } from '../../../store'
import { Restrictions } from '../../../services'
import { useResponsive } from '../../../providers'
import { YearsSlider } from '../../years-slider'
import {
  Content,
  IconName,
  Column,
  SliderMenu,
  Row,
  Dropdown,
  InputsPolicyProps,
  GeneralInputsBasicValues,
  GeneralInputsBasicIds,
  DropdownItemData,
  Input,
  ActionTypeName,
  InputTypeValue
} from '../..'
import {
  changeCurrentValues,
  formatValue,
  getCurrentDropdown,
  getCustomSettings,
  getDropdownData,
  getError,
  getIsRegionalOptionsAllowed,
  getIsValid,
  getLabel,
  getSetting,
  getSliderId,
  getUpcomingSetting,
  range,
  setError
} from '../helpers'
import { VALUES_BY_BLOCKS } from '../constants'
import {
  generalInputBasicProps,
  policyStartYearLabelProps,
  regionLabelProps,
  yearIconProps
} from '../label-props'
import { useStyle } from '../inputs.styles'

export const GeneralInputsBasic: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  defaultPolicy,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [isRevert, changeIsRevert] = useState(false)

  const { isTablet } = useResponsive()
  const { user, restrictions, catalog } = useSelector((state: State) => ({
    user: state.auth.user,
    restrictions: state.policy.restrictions as Restrictions,
    catalog: state.policy.catalog
  }))
  const yearsRange = useMemo(() => range({ start: 10, end: 30, step: 5 }), [])
  const isRegionalAllowed = useMemo(() => getIsRegionalOptionsAllowed(user), [
    user
  ])
  const dropdowns = useMemo(
    () => ({
      region: getDropdownData(GeneralInputsBasicIds.REGION, catalog)
    }),
    [catalog]
  )
  const ResponsiveColRow = isTablet ? Column : Row

  const { customDefaults, settingsList, customSettings } = useMemo(
    () =>
      getCustomSettings({
        defaults,
        updates
      }),
    [defaults, updates]
  )
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: GeneralInputsBasicValues) => getUpcomingSetting(id, settingsList),
    [settingsList]
  )

  // start of manipulating with current settings
  const getGeneralInputs = useCallback(
    (): any => ({
      region: handleGetUpcomingSetting(GeneralInputsBasicIds.REGION),
      years: handleGetUpcomingSetting(GeneralInputsBasicIds.SIMULATION_YEARS),
      startYear: handleGetUpcomingSetting(GeneralInputsBasicIds.START_YEAR)
    }),
    [handleGetUpcomingSetting]
  )
  const firstInputs = getGeneralInputs()
  const [generalInput, changeGeneralInput] = useState(firstInputs)

  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeGeneralInput(getGeneralInputs())
    changeSettings(s)
  }, [defaults, updates])
  // eof of manipulating with current settings

  // METHODS
  const getIsExist = (id: GeneralInputsBasicValues) =>
    generalInput[id] !== undefined
  const getIsDefault = (id: GeneralInputsBasicValues) =>
    generalInput[id] !== getSetting(id, customDefaults)?.value

  const handleOnChangeYears = (id: GeneralInputsBasicValues) => (
    value: DropdownItemData | number
  ) => {
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeGeneralInput({
      ...generalInput,
      [id]: value
    })
    changeSettings(updSettings)
    onChange({ [id]: value })
  }

  const handleOnChange = (
    id: GeneralInputsBasicValues,
    flag: InputTypeValue
  ) => (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeGeneralInput({
      ...generalInput,
      [id]: value
    })
    changeSettings(updSettings)
    onChange({ [id]: value })
  }

  const handleOnDropdownChange = (id: GeneralInputsBasicValues) => (
    item: DropdownItemData
  ) => {
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeGeneralInput({
      ...generalInput,
      [id]: Number(item.id)
    })
    changeSettings(updSettings)
    onChange({ [id]: Number(item.id) }, ActionTypeName.SAVE)
  }

  const handleUndoClick = (id: GeneralInputsBasicValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })

      changeGeneralInput({
        ...generalInput,
        [id]: !setting ? undefined : setting.value
      })
      changeSettings(updSettings)
    }
  }

  const handleOnGetError = useCallback(
    (id: GeneralInputsBasicValues) => getError(id, error),
    [error]
  )

  const handleOnBlur = (id: GeneralInputsBasicValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnGetCurrentDropdown = useCallback(
    (value: number, id: string) =>
      getCurrentDropdown({
        dropdowns,
        value,
        id
      }),
    [dropdowns]
  )
  // eof METHODS

  const isRegionDisabled = isRegionalAllowed ? !generalInput.region : true

  const revert = (array: string[]) => {
    const idsToRevert: any = {}
    array.forEach((id: string) => {
      const isValueUpdated =
        defaultPolicy && Object.keys(updates).length && updates[id]
      if (isValueUpdated) {
        idsToRevert[id] = defaultPolicy[id]
      }
    })
    changeGeneralInput({
      ...generalInput,
      ...idsToRevert
    })
    onChange(idsToRevert, ActionTypeName.REVERT)
    changeSettings(idsToRevert)
  }

  const handleOnRevert = () => {
    revert(VALUES_BY_BLOCKS.general)
  }

  useEffect(() => {
    if (defaults && defaultPolicy) {
      const isUpdated = VALUES_BY_BLOCKS.general.find((id: string) =>
        Object.keys(updates).includes(id)
      )

      changeIsRevert(Boolean(isUpdated))
    }
  }, [defaultPolicy, updates, defaults])

  return (
    <Column className={classes.container}>
      <Content className={classes.noPadding}>
        <SliderMenu
          {...generalInputBasicProps}
          className={classes.container}
          isOpen
          preset="h2"
          iconName={IconName.EXTENSION}
          iconColor="red"
          dropdownIconName="darkBlue"
          sliderId="general"
          isRevert={isRevert}
          onRevert={handleOnRevert}
        >
          <Column className={`${classes.noPadding} ${classes.container}`}>
            <Content className={classes.noPadding}>
              <ResponsiveColRow
                className={classes.row}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <YearsSlider
                  range={yearsRange}
                  disabled={!getIsExist(GeneralInputsBasicIds.SIMULATION_YEARS)}
                  description={
                    restrictions[GeneralInputsBasicIds.SIMULATION_YEARS]
                      ?.description
                  }
                  textProps={getLabel(
                    GeneralInputsBasicIds.SIMULATION_YEARS,
                    restrictions
                  )}
                  value={Number(generalInput.years)}
                  onChange={handleOnChangeYears(
                    GeneralInputsBasicIds.SIMULATION_YEARS
                  )}
                />
              </ResponsiveColRow>
              <Row
                className={`${classes.row} ${classes.responsiveRow}`}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={GeneralInputsBasicIds.START_YEAR}
                  labelProps={policyStartYearLabelProps}
                  isYear
                  iconProps={yearIconProps}
                  defaultValue={generalInput.startYear}
                  isFocused={getIsExist(GeneralInputsBasicIds.START_YEAR)}
                  description={
                    restrictions[GeneralInputsBasicIds.START_YEAR]?.description
                  }
                  disabled={!getIsExist(GeneralInputsBasicIds.START_YEAR)}
                  isUndo={getIsDefault(GeneralInputsBasicIds.START_YEAR)}
                  errorProps={handleOnGetError(
                    GeneralInputsBasicIds.START_YEAR
                  )}
                  onBlur={handleOnBlur(GeneralInputsBasicIds.START_YEAR)}
                  onChange={handleOnChange(
                    GeneralInputsBasicIds.START_YEAR,
                    InputTypeValue.YEAR
                  )}
                  onUndoClick={handleUndoClick(
                    GeneralInputsBasicIds.START_YEAR
                  )}
                />
                <Row className={classes.cell}>
                  <Dropdown
                    className={classes.dropdown}
                    label={regionLabelProps.text}
                    active={generalInput.region}
                    preset="body"
                    data={dropdowns[GeneralInputsBasicIds.REGION]}
                    defaultValue={handleOnGetCurrentDropdown(
                      generalInput.region,
                      'region'
                    )}
                    description={
                      restrictions[GeneralInputsBasicIds.REGION]?.description
                    }
                    disabled={isRegionDisabled}
                    isUndo={getIsDefault(GeneralInputsBasicIds.REGION)}
                    onChange={handleOnDropdownChange(
                      GeneralInputsBasicIds.REGION
                    )}
                    onUndoClick={handleUndoClick(GeneralInputsBasicIds.REGION)}
                  />
                </Row>
              </Row>
            </Content>
          </Column>
        </SliderMenu>
      </Content>
    </Column>
  )
}
