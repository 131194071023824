import React, { FC, useEffect, useState, useCallback, MouseEvent } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import {
  DropdownItemData,
  DropdownItems,
  PositionDropdown
} from '../dropdown-items'
import { Icon, IconName } from '../icon'
import { RightCaret } from './right-caret'
import { InfoModal } from '../info-modal'
import { DropdownProps } from './dropdown.types'
import { useStyle } from './dropdown.styles'

export const Dropdown: FC<DropdownProps> = ({
  className = '',
  containerClassName = '',
  custom,
  label,
  labelTx,
  active,
  preset,
  rightIconName,
  iconProps,
  data,
  defaultValue,
  disabled = false,
  isUndo = false,
  errorProps,
  description = '',
  onChange,
  onUndoClick
}) => {
  const [value, changeValue] = useState<DropdownItemData | undefined>(
    defaultValue
  )
  const [open, changeOpen] = useState<boolean>(false)
  const [openModal, changeOpenModal] = useState(false)
  const theme: LightTheme = useTheme()
  const classes = useStyle({
    open,
    custom,
    isUndo,
    disabled,
    value,
    description,
    theme
  })

  const disabledInputClass = disabled ? classes.disabled : ''
  const disabledLabelClass = disabled ? classes.disabledLabel : ''
  const iconFill = disabled ? theme.colors.border : theme.colors.darkBlue
  const combinedClass = errorProps
    ? `${classes.container} ${classes.error}`
    : classes.container

  useEffect(() => {
    changeValue(defaultValue)
  }, [defaultValue])

  const handleOnChange = (item: DropdownItemData) => {
    changeValue(item)
    if (onChange) {
      onChange(item)
    }
  }

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    [openModal]
  )

  const handleOnGetDescription = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()

    if (description) {
      handleOnOpenModal(true)()
    }
  }

  const handleUndoClick = () => {
    if (onUndoClick) {
      onUndoClick()
    }

    changeValue(defaultValue)
  }

  const handleOnOpen = useCallback(
    (state: boolean) => {
      if (!disabled) {
        changeOpen(state)
      }
    },
    [disabled]
  )

  const handleOnCaretClick = () => {
    handleOnOpen(false)
  }

  const isUndoVisible = isUndo && defaultValue

  return (
    <Column fullWidth>
      <DropdownItems
        className={`${disabledInputClass} ${className}`}
        containerClassName={containerClassName}
        isScrollable
        disabled={!disabled}
        active={active}
        data={data}
        position={PositionDropdown.BOTTOM}
        onOpen={handleOnOpen}
        onChange={handleOnChange}
      >
        <Row className={combinedClass} fullWidth justifyContent="space-between">
          <Column alignItems="flex-start">
            {label && (
              <Text
                className={`${disabledLabelClass} ${classes.label}`}
                text={label}
                tx={labelTx}
                color="darkBlue"
                preset={preset}
                onClick={handleOnGetDescription}
              />
            )}
            {value && (
              <Text text={value.name} tx={value.nameTx} preset={preset} />
            )}
          </Column>
          {iconProps && (
            <Row className={classes.textIcon}>
              <Text preset="bodyBold" color="border" {...iconProps} />
            </Row>
          )}
          <RightCaret
            open={open}
            custom={custom}
            iconName={rightIconName}
            fill={iconFill}
            onClick={handleOnCaretClick}
          />
        </Row>
      </DropdownItems>

      {isUndoVisible && (
        <Column className={classes.undoIcon} onClick={handleUndoClick}>
          <Icon name={IconName.UNDO} fill="purple" />
          <Text tx="reset.title" text="reset" color="purple" preset="title" />
        </Column>
      )}

      {openModal && label && (
        <InfoModal
          titleProps={{
            text: label,
            tx: labelTx
          }}
          onClose={handleOnOpenModal(false)}
        >
          {description && <Text text={description} preset="body" />}
        </InfoModal>
      )}
    </Column>
  )
}
