import { createUseStyles } from 'react-jss'

import { StyleProps } from '..'

export const useStyle = createUseStyles(() => ({
  caret: {
    marginTop: ({ custom }: StyleProps) => (!custom ? 12 : custom.caret)
  },
  caretDown: {
    position: 'absolute',
    right: 0,
    transform: ({ open }: StyleProps) =>
      open ? 'rotate(180deg) translateY(2px)' : 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out'
  }
}))
