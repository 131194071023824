import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const StarRate: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 16.067l4.947 3.6-1.894-5.814L20 10.333h-6.067l-1.933-6-1.933 6H4l4.947 3.52-1.894 5.814 4.947-3.6z"
        fill={props.fill || theme.colors.lightBlue}
      />
    </svg>
  )
}
