import React, { FC, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import { useResponsive } from '../../providers'
import { LightTheme } from '../../theme'
import { Column, Content, Row, Text } from '..'
import { Icon } from '../icon'
import { IconName } from '../icon/types'
import { FooterProps } from './footer.types'
import { useStyle } from './footer.styles'

export const Footer: FC<FooterProps> = ({
  className = '',
  isLoggedIn,
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const history = useHistory()
  const { isTablet } = useResponsive()

  const linkPreset = isTablet ? 'caption' : 'bodyBold'

  const handleOnConditionsAndPrivacyClick = useCallback(() => {
    history.push('/conditions-and-privacy')
  }, [])

  const handleOnAboutUsClick = useCallback(() => {
    window.open('https://energycenter.org/about-us', '_blank')
  }, [])

  return (
    <Column className={classes.content} fullWidth>
      <Content {...props} className={`${className} ${classes.footer}`}>
        <Row
          fullWidth
          justifyContent="space-between"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Column alignItems="flex-start" className={classes.col}>
            <Icon name={IconName.LOGO_CSE} width={223} height={81} />
            <Row>
              <Text
                className={classes.navItem}
                text="About Us"
                tx="navlink.aboutUs"
                color="darkBlue"
                preset={linkPreset}
                onClick={handleOnAboutUsClick}
              />
              <Text
                className={classes.navItem}
                text="Conditions of Use and Privacy Policy"
                tx="navlink.conditionsPrivacy"
                color="darkBlue"
                preset={linkPreset}
                onClick={handleOnConditionsAndPrivacyClick}
              />
            </Row>
          </Column>
          <Column alignItems="flex-start" className={classes.col}>
            <Text
              text="Contact Information"
              tx="footer.contactInfo.title"
              preset="h3"
            />
            <Text
              text="3980 Sherman Street, Suite 170, San Diego, CA 92110\n
              Email: caret@energycenter.org"
              tx="footer.contactInfo"
              className={classes.contactInfo}
              color="inactive"
              preset="body"
            />
            <Text
              className={classes.copyright}
              text="© Copyright 2022  Center for Sustainable Energy. All Rights Reserved."
              tx="footer.copyright"
              preset="smallSubTitleSetting"
            />
          </Column>
        </Row>
      </Content>
    </Column>
  )
}
