import { call, put } from 'redux-saga/effects'

import {
  Api,
  CreatePolicyResults,
  DeletePolicyResults,
  GetCatalogResults,
  GetPoliciesResults,
  GetPolicyPlotResults,
  GetPolicyResults,
  GetRegionsResults,
  GetBauDefaultsResults,
  UpdateAffordabilityResults,
  GetRestrictionsResults,
  GetComparePoliciesByIdsResults,
  UpdateAdditionalGraphResults,
  GetDefaultPolicyResults,
  GetPolicyReportResults
} from '../../../services'
import { PolicyCreators, PolicyTypes } from '../../actions'

export function* GetPolicies(
  api: Api,
  action: PolicyTypes.GetPoliciesActionRequest
) {
  const response: GetPoliciesResults = yield call(
    api.policy.getPolicies,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getPoliciesFailure({
        error: 'Failure get list of policies'
      })
    )
  } else {
    const { reset } = response
    yield put(
      PolicyCreators.getPoliciesSuccess({
        ...response.policies,
        reset
      })
    )
  }
}

export function* GetPoliciesByPages(
  api: Api,
  action: PolicyTypes.GetPoliciesActionRequest
) {
  const response: GetPoliciesResults = yield call(
    api.policy.getPoliciesByPages,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getPoliciesByPagesFailure({
        error: 'Failure get list of policies'
      })
    )
  } else {
    const { reset } = response
    yield put(
      PolicyCreators.getPoliciesByPagesSuccess({
        ...response.policies,
        reset
      })
    )
  }
}

export function* GetComparePoliciesByIdPolicies(
  api: Api,
  action: PolicyTypes.GetComparePoliciesByIdsActionRequest
) {
  const response: GetComparePoliciesByIdsResults = yield call(
    api.policy.getComparePoliciesByIds,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getComparePoliciesByIdsFailure({
        error: 'Failure get compare list of policies'
      })
    )
  } else {
    yield put(PolicyCreators.getComparePoliciesByIdsSuccess(response.policies))
  }
}

export function* CreatePolicy(
  api: Api,
  action: PolicyTypes.CreatePolicyActionRequest
) {
  const response: CreatePolicyResults = yield call(
    api.policy.createPolicy,
    action.payload
  )

  if (response.kind !== 'ok') {
    if (response.kind === 'unknown') {
      yield put(PolicyCreators.createPolicyFailure())
    } else if (response.kind === 'bad-data') {
      yield put(
        PolicyCreators.createPolicyFailure({
          error: `Failure create policy ${action.payload.name}`
        })
      )
    } else {
      yield put(
        PolicyCreators.createPolicyFailure({
          error: response.kind || 'server'
        })
      )
    }
  } else {
    yield put(PolicyCreators.createPolicySuccess(response.policy))
    window.open(`/policy/${response.policy.id}`, '_self')
  }
}

export function* UpdatePolicy(
  api: Api,
  action: PolicyTypes.UpdatePolicyActionRequest
) {
  const response: CreatePolicyResults = yield call(
    api.policy.updatePolicy,
    action.payload
  )

  if (response.kind !== 'ok') {
    if (response.kind === 'unknown') {
      yield put(PolicyCreators.updatePolicyFailure())
    } else {
      yield put(
        PolicyCreators.updatePolicyFailure({
          error: `Failure update policy ${action.payload.name}`
        })
      )
    }
  } else {
    yield put(PolicyCreators.updatePolicySuccess(response.policy))

    if (response.reset) {
      window.open(`/policy/${response.policy.id}/summary`, '_self')
    }
  }
}

export function* GetPolicy(
  api: Api,
  action: PolicyTypes.GetPolicyActionRequest
) {
  const response: GetPolicyResults = yield call(
    api.policy.getPolicy,
    action.payload.id
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getPolicyFailure({
        error: 'Failure get list of policies'
      })
    )
  } else {
    yield put(PolicyCreators.getPolicySuccess(response.policy))
  }
}

export function* GetPolicyReport(
  api: Api,
  action: PolicyTypes.GetPolicyReportActionRequest
) {
  const response: GetPolicyReportResults = yield call(
    api.policy.getPolicyReport,
    action.payload
  )

  if (response.kind !== 'ok') {
    if (response.kind === 'unknown') {
      yield put(PolicyCreators.getPolicyReportFailure())
    } else if (response.kind === 'bad-data') {
      yield put(
        PolicyCreators.getPolicyReportFailure({
          error: 'Failure get pdf report'
        })
      )
    } else {
      yield put(
        PolicyCreators.getPolicyReportFailure({
          error: response.kind || 'server'
        })
      )
    }
  } else {
    yield put(PolicyCreators.getPolicyReportSuccess(response.pdfReport))
  }
}

export function* GetDefaultPolicy(api: Api) {
  const response: GetDefaultPolicyResults = yield call(
    api.policy.getDefaultPolicy
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getDefaultPolicyFailure({
        error: 'Failure get default policy'
      })
    )
  } else {
    yield put(PolicyCreators.getDefaultPolicySuccess(response.policy))
  }
}

export function* DeletePolicy(
  api: Api,
  action: PolicyTypes.DeletePolicyActionRequest
) {
  const response: DeletePolicyResults = yield call(
    api.policy.deletePolicy,
    action.payload.id
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.deletePolicyFailure({
        error: 'Failure delete policy'
      })
    )
  } else {
    yield put(PolicyCreators.deletePolicySuccess(response.id))
  }
}

export function* GetPolicyPlot(
  api: Api,
  action: PolicyTypes.GetPolicyPlotActionRequest
) {
  const response: GetPolicyPlotResults = yield call(
    api.policy.getPolicyPlot,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getPolicyPlotFailure({
        error: 'Failure get plot'
      })
    )
  } else {
    yield put(PolicyCreators.getPolicyPlotSuccess(response.plot))
  }
}

export function* GetCatalog(api: Api) {
  const response: GetCatalogResults = yield call(api.policy.getCatalog)

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getCatalogFailure({
        error: 'Failure get catalog'
      })
    )
  } else {
    yield put(PolicyCreators.getCatalogSuccess(response.catalog))
  }
}

export function* GetRegions(api: Api) {
  const response: GetRegionsResults = yield call(api.policy.getRegions)

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getCatalogFailure({
        error: 'Failure get regions'
      })
    )
  } else {
    yield put(PolicyCreators.getRegionsSuccess(response.regions))
  }
}

export function* GetBauDefaults(api: Api) {
  const response: GetBauDefaultsResults = yield call(api.policy.getBauDefaults)

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getBauDefaultsFailure({
        error: 'Failure get bau defaults'
      })
    )
  } else {
    yield put(PolicyCreators.getBauDefaultsSuccess(response.bauDefaults))
  }
}

export function* UpdateAffordability(
  api: Api,
  action: PolicyTypes.UpdateAffordabilityActionRequest
) {
  const response: UpdateAffordabilityResults = yield call(
    api.policy.updateAffordability,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.updateAffordabilityFailure({
        error: 'Failure get affordability plot'
      })
    )
  } else {
    yield put(PolicyCreators.updateAffordabilitySuccess(response.affordability))
  }
}

export function* UpdateAdditionalGraph(
  api: Api,
  action: PolicyTypes.UpdateAdditionalGraphActionRequest
) {
  const response: UpdateAdditionalGraphResults = yield call(
    api.policy.updateAdditionalGraph,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.updateAdditionalGraphFailure({
        error: 'Failure get additional graph plot'
      })
    )
  } else {
    yield put(
      PolicyCreators.updateAdditionalGraphSuccess(response.additionalGraph)
    )
  }
}

export function* GetRestrictions(api: Api) {
  const response: GetRestrictionsResults = yield call(
    api.policy.getRestrictions
  )

  if (response.kind !== 'ok') {
    yield put(
      PolicyCreators.getRestrictionsFailure({
        error: 'Failure get restrictions'
      })
    )
  } else {
    yield put(PolicyCreators.getRestrictionsSuccess(response.restrictions))
  }
}
