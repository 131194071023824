import { createUseStyles } from 'react-jss'
import { DropdownItemsStyleProps } from '.'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: ({ disabled }: DropdownItemsStyleProps) =>
      disabled ? 'default' : theme.cursor
  },
  listContainer: {
    position: 'absolute',
    top: 50,
    minHeight: 52,
    maxHeight: 200,
    height: ({ listHeight }: DropdownItemsStyleProps) => listHeight,
    overflow: 'auto',
    width: '100%',
    zIndex: 2,
    boxShadow: '2px 4px 8px #00000014',
    border: `2px solid ${theme.colors.background}`,
    borderTop: 'none',
    borderRadius: '0 0 12px 12px',
    scrollbarWidth: 'none',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 10
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.grey
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb &:hover': {
      background: theme.colors.hoverGrey
    }
  }
}))
