import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import { State } from '../../../store'
import { Policy, Restrictions } from '../../../services'
import { Row } from '../../row'
import { Content } from '../../content'
import { Column } from '../../column'
import { Input } from '../../input'
import { RadioButtonGroup } from '../../radio-button-group'
import {
  getCustomSettings,
  getUpcomingSetting,
  getSetting,
  changeCurrentValues,
  getIsValid,
  getError,
  setError,
  handleOnCheckAllErrors,
  getSliderId,
  getLabel,
  formatValue
} from '../helpers'
import {
  dollarIconProps,
  percentIconProps,
  yearIconProps,
  yearsIconProps
} from '../label-props'
import {
  COMMERCIAL_FLEET_BOOLEAN_RADIO_IDS,
  EXCLUDE_COMMERCIAL_BOOLEAN_RADIO_IDS,
  FLEET_EVI_INCENTIVE_RADIO_IDS,
  RADIO_ID_DEPENDENCIES
} from '../constants'
import {
  CommercialFleetIncentiveIds,
  CommercialFleetIncentiveValues,
  InputsPolicyProps,
  InputTypeValue
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const CommercialFleetIncentive: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions, bauDefaults } = useSelector(
    (state: State) => ({
      restrictions: state.policy.restrictions as Restrictions,
      bauDefaults: state.policy.bauDefaults,
      catalog: state.policy.catalog
    })
  )

  const values = {
    ...defaults,
    ...updates
  }
  const isUSRegion = values && values.region === 1
  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: CommercialFleetIncentiveValues) =>
      getUpcomingSetting(id, settingsList),
    [settingsList]
  )

  // start of manipulating with current settings
  const getCommercialFleetIncentive = useCallback(
    (): any => ({
      fleetEviBoolean: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE
      ),
      fleetYears: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.DURATION
      ),
      fleetExcludedProp: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.VEHICLES_EXCLUDED
      ),
      fleetEviIncentive: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.EVI_INCENTIVE
      ),
      commercialFleetBoolean: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN
      ),
      commercialFleetStartYear: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR
      ),
      commercialFleetEndYear: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR
      ),
      commercialFleetIncPercent: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS
      ),
      excludeLeaseMsrp: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.EXCLUDE_LEASED_VEHICLES
      ),
      fleetSalesPercent: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS
      ),
      usCapType: handleGetUpcomingSetting(CommercialFleetIncentiveIds.CAP_TYPE),
      regionExcludeCommercialBoolean: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN
      ),
      regionCommercialEvIncentive: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.COMMERCIAL_EV_INC
      ),
      usMsrpCapType: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.US_MSRP_CAP_TYPE
      ),
      regionMsrpCapType: handleGetUpcomingSetting(
        CommercialFleetIncentiveIds.REGION_MSRPCAP_TYPE
      )
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getCommercialFleetIncentive()
  const [commercialFleetIncentive, changeCommercialFleetIncentive] = useState(
    fields
  )

  // when we press 'save' we get updated data from main 'update-policy' component
  // so we need to update our defaults for 'undo' fields state
  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeCommercialFleetIncentive(getCommercialFleetIncentive())
    changeSettings(s)
  }, [defaults, updates])
  // eof of manipulating with current settings

  // METHODS
  const getIsExist = (id: CommercialFleetIncentiveValues) =>
    commercialFleetIncentive[id] !== undefined
  const getIsDefault = (id: CommercialFleetIncentiveValues) =>
    commercialFleetIncentive[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (
    id: CommercialFleetIncentiveValues,
    flag: InputTypeValue
  ) => (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeCommercialFleetIncentive({
      ...commercialFleetIncentive,
      [id]: value
    })
    changeSettings(updSettings)
    onChange({ [id]: value })
  }

  const handleOnBlur = (id: CommercialFleetIncentiveValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnRadioChange = (id: string) => (status: boolean) => {
    changeCommercialFleetIncentive({
      ...commercialFleetIncentive,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleUndoClick = (id: CommercialFleetIncentiveValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value

      changeCommercialFleetIncentive({
        ...commercialFleetIncentive,
        [id]: !setting ? undefined : setting.value
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnGetError = (id: CommercialFleetIncentiveValues) =>
    getError(id, error)

  const getIsUpdateInBauValues = (key: keyof Policy) => {
    const isUndefined = typeof updates[key] === 'undefined'
    const isEqual = updates[key] === bauDefaults[key]

    return !isUndefined && isEqual
  }

  const getAttentionIconState = (id: keyof Policy): object => {
    const isRadioOff = !commercialFleetIncentive[id]
    const bauKeys = Object.keys(bauDefaults)
    const newKeys = Object.keys(updates)
    const dependencyKeys = RADIO_ID_DEPENDENCIES[id]
    const isBauSetted = dependencyKeys.find(getIsUpdateInBauValues)
    const isBauValuesFromDependency = dependencyKeys.find((depId: string) =>
      bauKeys.includes(depId)
    )
    let isIconVisible = Boolean(
      isRadioOff && isBauSetted && isBauValuesFromDependency
    )

    if (isBauSetted) {
      return {
        statusIcon: isIconVisible,
        attentionText:
          'Underlying inputs were changed to BAU.\nOpen the section to review \nand/or discard changes.',
        attentionTx: 'policy.radio.warning'
      }
    }

    const isNewValuesFromDependency = dependencyKeys.find((depId: string) =>
      newKeys.includes(depId)
    )
    isIconVisible = Boolean(isRadioOff && isNewValuesFromDependency)

    return {
      statusIcon: isIconVisible,
      attentionText:
        'Underlying inputs were changed,\nthis may affect policy output.\nOpen the section to review \nand/or discard changes.',
      attentionTx: 'policy.radio.warning2'
    }
  }
  // eof METHODS

  const isMsrpSelected = commercialFleetIncentive.usMsrpCapType === 2
  const isTieredMsrpSelected = commercialFleetIncentive.usMsrpCapType === 3

  const isRegionMsrpSelected = commercialFleetIncentive.regionMsrpCapType === 2
  const isRegionTieredMsrpSelected = commercialFleetIncentive.regionMsrpCapType === 3
  const isExcludeLeaseMsrp =
    isMsrpSelected ||
    isTieredMsrpSelected ||
    isRegionMsrpSelected ||
    isRegionTieredMsrpSelected

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(
                CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE,
                restrictions
              )}
              value={commercialFleetIncentive.fleetEviBoolean}
              disabled={
                !getIsExist(CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE)
              }
              isUndo={getIsDefault(
                CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE
              )}
              description={
                restrictions[CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE]
                  ?.description
              }
              errorProps={handleOnCheckAllErrors({
                values: commercialFleetIncentive,
                radioId: CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE,
                ids: FLEET_EVI_INCENTIVE_RADIO_IDS,
                error
              })}
              {...getAttentionIconState(
                CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE
              )}
              onChange={handleOnRadioChange(
                CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE
              )}
              onUndoClick={handleUndoClick(
                CommercialFleetIncentiveIds.FLEET_EVI_INCENTIVE
              )}
            />
          </Column>
          {commercialFleetIncentive.fleetEviBoolean && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={CommercialFleetIncentiveIds.DURATION}
                labelProps={getLabel(
                  CommercialFleetIncentiveIds.DURATION,
                  restrictions
                )}
                isYears
                defaultValue={commercialFleetIncentive.fleetYears}
                iconProps={yearsIconProps}
                isFocused={getIsExist(CommercialFleetIncentiveIds.DURATION)}
                description={
                  restrictions[CommercialFleetIncentiveIds.DURATION]
                    ?.description
                }
                disabled={!commercialFleetIncentive.fleetEviBoolean}
                isUndo={getIsDefault(CommercialFleetIncentiveIds.DURATION)}
                errorProps={handleOnGetError(
                  CommercialFleetIncentiveIds.DURATION
                )}
                onBlur={handleOnBlur(CommercialFleetIncentiveIds.DURATION)}
                onChange={handleOnChange(
                  CommercialFleetIncentiveIds.DURATION,
                  InputTypeValue.YEARS
                )}
                onUndoClick={handleUndoClick(
                  CommercialFleetIncentiveIds.DURATION
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={CommercialFleetIncentiveIds.VEHICLES_EXCLUDED}
                labelProps={getLabel(
                  CommercialFleetIncentiveIds.VEHICLES_EXCLUDED,
                  restrictions
                )}
                isPercent
                defaultValue={commercialFleetIncentive.fleetExcludedProp}
                iconProps={percentIconProps}
                isFocused={getIsExist(
                  CommercialFleetIncentiveIds.VEHICLES_EXCLUDED
                )}
                description={
                  restrictions[CommercialFleetIncentiveIds.VEHICLES_EXCLUDED]
                    ?.description
                }
                disabled={!commercialFleetIncentive.fleetEviBoolean}
                isUndo={getIsDefault(
                  CommercialFleetIncentiveIds.VEHICLES_EXCLUDED
                )}
                errorProps={handleOnGetError(
                  CommercialFleetIncentiveIds.VEHICLES_EXCLUDED
                )}
                onBlur={handleOnBlur(
                  CommercialFleetIncentiveIds.VEHICLES_EXCLUDED
                )}
                onChange={handleOnChange(
                  CommercialFleetIncentiveIds.VEHICLES_EXCLUDED,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(
                  CommercialFleetIncentiveIds.VEHICLES_EXCLUDED
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={CommercialFleetIncentiveIds.EVI_INCENTIVE}
                labelProps={getLabel(
                  CommercialFleetIncentiveIds.EVI_INCENTIVE,
                  restrictions
                )}
                defaultValue={commercialFleetIncentive.fleetEviIncentive}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(
                  CommercialFleetIncentiveIds.EVI_INCENTIVE
                )}
                description={
                  restrictions[CommercialFleetIncentiveIds.EVI_INCENTIVE]
                    ?.description
                }
                disabled={!commercialFleetIncentive.fleetEviBoolean}
                isUndo={getIsDefault(CommercialFleetIncentiveIds.EVI_INCENTIVE)}
                errorProps={handleOnGetError(
                  CommercialFleetIncentiveIds.EVI_INCENTIVE
                )}
                onBlur={handleOnBlur(CommercialFleetIncentiveIds.EVI_INCENTIVE)}
                onChange={handleOnChange(
                  CommercialFleetIncentiveIds.EVI_INCENTIVE,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(
                  CommercialFleetIncentiveIds.EVI_INCENTIVE
                )}
              />
            </>
          )}
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(
                CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN,
                restrictions
              )}
              value={commercialFleetIncentive.commercialFleetBoolean}
              disabled={
                !getIsExist(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN
                )
              }
              isUndo={getIsDefault(
                CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN
              )}
              description={
                restrictions[
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN
                ]?.description
              }
              errorProps={handleOnCheckAllErrors({
                values: commercialFleetIncentive,
                radioId: CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN,
                ids: COMMERCIAL_FLEET_BOOLEAN_RADIO_IDS,
                error
              })}
              {...getAttentionIconState(
                CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN
              )}
              onChange={handleOnRadioChange(
                CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN
              )}
              onUndoClick={handleUndoClick(
                CommercialFleetIncentiveIds.COMMERCIAL_FLEET_BOOLEAN
              )}
            />
          </Column>
          {commercialFleetIncentive.commercialFleetBoolean && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR}
                labelProps={getLabel(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR,
                  restrictions
                )}
                isYear
                defaultValue={commercialFleetIncentive.commercialFleetStartYear}
                iconProps={yearIconProps}
                isFocused={getIsExist(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR
                )}
                description={
                  restrictions[
                    CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR
                  ]?.description
                }
                disabled={!commercialFleetIncentive.commercialFleetBoolean}
                isUndo={getIsDefault(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR
                )}
                errorProps={handleOnGetError(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR
                )}
                onBlur={handleOnBlur(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR
                )}
                onChange={handleOnChange(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR,
                  InputTypeValue.YEAR
                )}
                onUndoClick={handleUndoClick(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR}
                labelProps={getLabel(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR,
                  restrictions
                )}
                isYears
                defaultValue={commercialFleetIncentive.commercialFleetEndYear}
                iconProps={yearsIconProps}
                isFocused={getIsExist(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR
                )}
                description={
                  restrictions[
                    CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR
                  ]?.description
                }
                disabled={!commercialFleetIncentive.commercialFleetBoolean}
                isUndo={getIsDefault(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR
                )}
                errorProps={handleOnGetError(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR
                )}
                onBlur={handleOnBlur(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR
                )}
                onChange={handleOnChange(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR,
                  InputTypeValue.YEARS
                )}
                onUndoClick={handleUndoClick(
                  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS}
                labelProps={getLabel(
                  CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS,
                  restrictions
                )}
                isPercent
                defaultValue={
                  commercialFleetIncentive.commercialFleetIncPercent
                }
                iconProps={percentIconProps}
                isFocused={getIsExist(
                  CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS
                )}
                description={
                  restrictions[
                    CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS
                  ]?.description
                }
                disabled={!commercialFleetIncentive.commercialFleetBoolean}
                isUndo={getIsDefault(
                  CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS
                )}
                errorProps={handleOnGetError(
                  CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS
                )}
                onBlur={handleOnBlur(
                  CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS
                )}
                onChange={handleOnChange(
                  CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(
                  CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS
                )}
              />
              {!isUSRegion && (
                <>
                  <Column
                    className={`${classes.cell} ${classes.selectionControlsContainer}`}
                    alignItems="flex-start"
                  >
                    <RadioButtonGroup
                      {...getLabel(
                        CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN,
                        restrictions
                      )}
                      value={
                        commercialFleetIncentive.regionExcludeCommercialBoolean
                      }
                      disabled={
                        !getIsExist(
                          CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN
                        )
                      }
                      isUndo={getIsDefault(
                        CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN
                      )}
                      description={
                        restrictions[
                          CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN
                        ]?.description
                      }
                      errorProps={handleOnCheckAllErrors({
                        values: commercialFleetIncentive,
                        radioId:
                          CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN,
                        ids: EXCLUDE_COMMERCIAL_BOOLEAN_RADIO_IDS,
                        error
                      })}
                      {...getAttentionIconState(
                        CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN
                      )}
                      onChange={handleOnRadioChange(
                        CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN
                      )}
                      onUndoClick={handleUndoClick(
                        CommercialFleetIncentiveIds.EXCLUDE_COMMERCIAL_BOOLEAN
                      )}
                    />
                  </Column>
                  {commercialFleetIncentive.regionExcludeCommercialBoolean && (
                    <Input
                      alignItems="flex-start"
                      className={classes.cell}
                      id={CommercialFleetIncentiveIds.COMMERCIAL_EV_INC}
                      labelProps={getLabel(
                        CommercialFleetIncentiveIds.COMMERCIAL_EV_INC,
                        restrictions
                      )}
                      isDollar
                      defaultValue={
                        commercialFleetIncentive.regionCommercialEvIncentive
                      }
                      iconProps={percentIconProps}
                      isFocused={getIsExist(
                        CommercialFleetIncentiveIds.COMMERCIAL_EV_INC
                      )}
                      description={
                        restrictions[
                          CommercialFleetIncentiveIds.COMMERCIAL_EV_INC
                        ]?.description
                      }
                      isUndo={getIsDefault(
                        CommercialFleetIncentiveIds.COMMERCIAL_EV_INC
                      )}
                      errorProps={handleOnGetError(
                        CommercialFleetIncentiveIds.COMMERCIAL_EV_INC
                      )}
                      onBlur={handleOnBlur(
                        CommercialFleetIncentiveIds.COMMERCIAL_EV_INC
                      )}
                      onChange={handleOnChange(
                        CommercialFleetIncentiveIds.COMMERCIAL_EV_INC,
                        InputTypeValue.DOLLAR
                      )}
                      onUndoClick={handleUndoClick(
                        CommercialFleetIncentiveIds.COMMERCIAL_EV_INC
                      )}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {commercialFleetIncentive.commercialFleetBoolean && (
            <>
              {isExcludeLeaseMsrp && (
                <Column
                  className={`${classes.cell} ${classes.selectionControlsContainer}`}
                  alignItems="flex-start"
                >
                  <RadioButtonGroup
                    {...getLabel(
                      CommercialFleetIncentiveIds.EXCLUDE_LEASED_VEHICLES,
                      restrictions
                    )}
                    value={commercialFleetIncentive.excludeLeaseMsrp}
                    disabled={
                      !getIsExist(
                        CommercialFleetIncentiveIds.EXCLUDE_LEASED_VEHICLES
                      )
                    }
                    description={
                      restrictions[
                        CommercialFleetIncentiveIds.EXCLUDE_LEASED_VEHICLES
                      ]?.description
                    }
                    isUndo={getIsDefault(
                      CommercialFleetIncentiveIds.EXCLUDE_LEASED_VEHICLES
                    )}
                    onChange={handleOnRadioChange(
                      CommercialFleetIncentiveIds.EXCLUDE_LEASED_VEHICLES
                    )}
                    onUndoClick={handleUndoClick(
                      CommercialFleetIncentiveIds.EXCLUDE_LEASED_VEHICLES
                    )}
                  />
                </Column>
              )}
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS}
                labelProps={getLabel(
                  CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS,
                  restrictions
                )}
                isPercent
                defaultValue={commercialFleetIncentive.fleetSalesPercent}
                iconProps={percentIconProps}
                isFocused={getIsExist(
                  CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS
                )}
                description={
                  restrictions[CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS]
                    ?.description
                }
                disabled={!commercialFleetIncentive.commercialFleetBoolean}
                isUndo={getIsDefault(
                  CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS
                )}
                errorProps={handleOnGetError(
                  CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS
                )}
                onBlur={handleOnBlur(
                  CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS
                )}
                onChange={handleOnChange(
                  CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(
                  CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS
                )}
              />
            </>
          )}
        </Row>
      </Content>
    </Column>
  )
}
