import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { PolicyListItemStyleProps } from '.'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    height: 56,
    borderBottom: `1px solid ${theme.colors.grey}`,
    opacity: ({ disabled }: PolicyListItemStyleProps) => (disabled ? 0.6 : 1),
    width: 'calc(100% - 32px)',
    cursor: theme.cursor,
    '&:hover': {
      backgroundColor: `${theme.colors.arctic}`,
      boxShadow: '2px 4px 16px rgb(3 82 127 / 12%)',
      zIndex: 9,
      padding: '0 16px',
      '&:last-child': {
        borderRadius: '0 0 12px 12px'
      }
    }
  },
  name: {
    width: 160
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: theme.cursor
    }
  },
  column: {
    width: 100
  },
  text: {
    cursor: theme.cursor,
    maxWidth: 123,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  '@media screen and (max-width: 800px)': {
    name: {
      width: 100
    },
    text: {
      maxWidth: 90
    }
  }
}))
