import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.tableRow,
    borderRadius: 12,
    padding: 16,
    width: 'calc(100% - 32px)',
    border: `1px solid ${theme.colors.grey}`
  },
  text: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'break-spaces'
  },
  close: {
    marginLeft: 16,
    cursor: theme.cursor
  },
  name: {
    height: 42,
    marginTop: 8
  }
}))
