import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { CardList } from '../../../components'
import { EmptyList } from '../empty-list'
import { CardViewProps } from './card-view.types'
import { useStyle } from './card-view.styles'

export const CardView = ({
  count,
  openCompare,
  orderBy,
  current,
  part,
  handleOnAddToCompare
}: CardViewProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  if (!count) {
    return <EmptyList />
  }

  return (
    <CardList
      key={count}
      className={classes.cardsList}
      draggable={openCompare}
      orderBy={orderBy}
      current={current}
      valuesAmount={{
        count,
        part
      }}
      onAddToCompare={handleOnAddToCompare}
    />
  )
}
