import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Column } from '../../column'
import { PolicyListItem } from '../policy-list-item'
import { ItemsListProps } from './items-list.types'
import { useStyle } from './items-list.styles'

export const ItemsList = ({
  data,
  draggable,
  onAddToCompare
}: ItemsListProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Column fullWidth className={classes.container}>
      {data.map((policy) => {
        const isDraggable = Boolean(draggable && policy.draggable)
        const disabled = draggable && !policy.draggable

        return (
          <PolicyListItem
            key={policy.id}
            {...policy}
            disabled={disabled}
            draggable={isDraggable}
            onAddToCompare={onAddToCompare}
          />
        )
      })}
    </Column>
  )
}
