import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  top: {
    marginTop: 32
  },
  title: {
    padding: '16px 0'
  },
  input: {
    marginTop: 72,
    width: '100%'
  }
})
