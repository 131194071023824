import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  noPadding: {
    padding: 0
  },
  row: {
    position: 'relative',
    width: 'calc(100% - 64px)',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  cell: {
    maxWidth: 276,
    minWidth: 276,
    padding: '12px 0',
    '&:not(:first-child)': {
      marginLeft: 92
    },
    '&:nth-child(3n + 3) + &': {
      marginLeft: 0
    }
  },
  dropdown: {
    top: 0,
    width: '100%',
    overflow: 'auto',
    borderRadius: 0,
    borderBottom: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
})
