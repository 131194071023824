import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const User: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6665 20.0001C1.6665 30.1253 9.87462 38.3334 19.9998 38.3334C30.1251 38.3334 38.3332 30.1253 38.3332 20.0001C38.3332 9.87486 30.1251 1.66675 19.9998 1.66675C9.87462 1.66675 1.6665 9.87486 1.6665 20.0001ZM7.66735 28.5412C9.54309 26.0635 13.9508 25.0001 19.9998 25.0001C26.0488 25.0001 30.4566 26.0635 32.3323 28.5412C34.0142 26.1173 34.9998 23.1738 34.9998 20.0001C34.9998 11.7158 28.2841 5.00008 19.9998 5.00008C11.7156 5.00008 4.99984 11.7158 4.99984 20.0001C4.99984 23.1738 5.98548 26.1173 7.66735 28.5412ZM13.3332 16.6667C13.3332 12.9263 15.965 10.0001 19.9998 10.0001C24.0235 10.0001 26.6665 13.2027 26.6665 17.0001C26.6665 22.4661 23.6966 25.0001 19.9998 25.0001C16.2679 25.0001 13.3332 22.379 13.3332 16.6667Z"
        fill={props.fill || theme.colors.text}
      />
    </svg>
  )
}
