import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Column, Row, Text } from '../..'
import { WidgetProps } from '../widgets/widgets.types'
import { useStyle } from './progress-bar.styles'

export const ProgressBar: FC<WidgetProps> = ({ value = 0 }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, value })

  const circleSize = {
    cx: '55',
    cy: '55',
    r: '55'
  }

  return (
    <Column fullWidth>
      <Row className={`${classes.size} ${classes.content}`}>
        <svg className={`${classes.size} ${classes.progressBar}`}>
          <circle
            className={`${classes.size} ${classes.circle} ${classes.mainCircle}`}
            {...circleSize}
          ></circle>
          <circle
            className={`${classes.container} ${classes.circle} ${classes.innerCircle}`}
            {...circleSize}
          ></circle>
        </svg>
        <Row className={`${classes.container} ${classes.number}`}>
          <Text text={`${value}%`} preset="h2" />
        </Row>
      </Row>
    </Column>
  )
}
