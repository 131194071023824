import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    color: theme.colors.grey,
    padding: '0 16px',
    marginTop: '-48px',
    minHeight: 660
  },
  icon: {
    padding: '0 6px'
  },
  vector: {
    marginTop: 16,
    padding: '0 78px',
    width: 'calc(100% - 156px)'
  },
  description: {
    marginTop: 10
  },
  suggestion: {
    marginTop: 16
  },
  emptyGroup: {
    marginTop: 34
  }
}))
