import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const RadioButtonActive: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8.00004 1.45454C4.38509 1.45454 1.45459 4.38504 1.45459 8C1.45459 11.615 4.38509 14.5455 8.00004 14.5455C11.615 14.5455 14.5455 11.615 14.5455 8C14.5455 4.38504 11.615 1.45454 8.00004 1.45454ZM8.00004 4.36362C10.0083 4.36362 11.6364 5.99168 11.6364 7.99999C11.6364 10.0083 10.0083 11.6363 8.00004 11.6363C5.99173 11.6363 4.36367 10.0083 4.36367 7.99999C4.36367 5.99168 5.99173 4.36362 8.00004 4.36362Z"
        stroke={props.fill || theme.colors.button}
      />
    </svg>
  )
}
