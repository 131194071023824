import React, { FC } from 'react'

import { PolicySummaryList, Column } from '../../components'

export const PolicySummary: FC = () => {
  return (
    <Column fullWidth justifyContent="flex-start">
      <PolicySummaryList />
    </Column>
  )
}
