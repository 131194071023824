import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { PolicyList } from '../../../components'
import { EmptyList } from '../empty-list'
import { ListViewProps } from './list-view.types'
import { useStyle } from './list-view.styles'

export const ListView = ({
  count,
  openCompare,
  orderBy,
  current,
  part,
  handleOnAddToCompare
}: ListViewProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  if (!count) {
    return <EmptyList />
  }

  return (
    <PolicyList
      key={count}
      className={classes.policiesList}
      draggable={openCompare}
      orderBy={orderBy}
      current={current}
      valuesAmount={{
        count,
        part
      }}
      onAddToCompare={handleOnAddToCompare}
    />
  )
}
