import React, { FC, useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import { State } from '../../../store'
import { Policy, Restrictions } from '../../../services'
import { Row } from '../../row'
import { Text } from '../../text'
import { Checkbox } from '../../checkbox'
import {
  Content,
  Column,
  Input,
  RadioButtonGroup,
  INCENTIVES_RADIO_IDS
} from '../..'
import {
  getUpcomingSetting,
  getCustomSettings,
  getIsValid,
  changeCurrentValues,
  getSetting,
  getError,
  setError,
  getIsTaxCreditSar,
  handleOnCheckAllErrors,
  getSliderId,
  getCurrentDropdown,
  getDropdownData,
  hasCoincidence,
  getLabel,
  formatValue,
  getIsTaxCreditRegionSar
} from '../helpers'
import { applyDiscountRateToProps, percentIconProps } from '../label-props'
import {
  EvIncentiveIds,
  InputsPolicyProps,
  ModelCharacteristicsIds,
  ModelCharacteristicsValues,
  InputTypeValue
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const ModelCharacteristics: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions, catalog } = useSelector((state: State) => ({
    restrictions: state.policy.restrictions as Restrictions,
    catalog: state.policy.catalog
  }))

  const dropdowns: any = {
    scenario: getDropdownData('scenario', catalog)
  }
  // start of manipulating with default settings
  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: ModelCharacteristicsValues) => getUpcomingSetting(id, settingsList),
    [settingsList]
  )
  // eof manipulating with default settings

  // start of manipulating with current settings
  const getModelCharacteristics = useCallback(
    (): any => ({
      diffusionCurveScalar: handleGetUpcomingSetting(
        ModelCharacteristicsIds.ADOPTION_RATE
      ),
      discountRate: handleGetUpcomingSetting(
        ModelCharacteristicsIds.DISCOUNT_RATE
      ),
      discountCosts: handleGetUpcomingSetting(ModelCharacteristicsIds.COSTS),
      taxCreditBoolean: handleGetUpcomingSetting(
        ModelCharacteristicsIds.INCENTIVES
      ),
      taxCreditNewEvInc: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_NEW_EV_INC
      ),
      taxCreditSoftCap: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_SOFT_CAP
      ),
      taxCreditDomestic: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_DOMESTIC
      ),
      taxCreditUsedEvInc: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_USED_EV_INC
      ),
      taxCreditLeadingManufacturer: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_LEADING_MANUFACTURER
      ),
      taxCreditSar: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_SAR
      ),
      taxCreditRegionSar: handleGetUpcomingSetting(
        ModelCharacteristicsIds.REGION_TAX_CREDIT_SAR
      ),
      taxCreditRegionSoftCap: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_REGION_SOFT_CAP
      ),
      taxCreditRegionNewEvInc: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_REGION_NEW_EV_INC
      ),
      taxCreditRegionUsedEvInc: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_REGION_USED_EV_INC
      ),
      taxCreditFplBonus: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_FPL_BONUS
      ),
      taxCreditAssembledInUs: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_ASSEMBLED_IN_US
      ),
      // values for dependencies to show actual checkboxes on values changes in other blocks
      region: handleGetUpcomingSetting(ModelCharacteristicsIds.REGION),
      lmBoolean: handleGetUpcomingSetting(ModelCharacteristicsIds.LM_BOOLEAN),
      nationalSaRIncentiveFpl: handleGetUpcomingSetting(
        ModelCharacteristicsIds.NATIONAL_SAR_INC_FPL
      ),
      nationalSaRIncentiveNonfpl: handleGetUpcomingSetting(
        ModelCharacteristicsIds.NATIONAL_SAR_INC_NON_FPL
      ),
      regionSaRIncentiveFpl: handleGetUpcomingSetting(
        ModelCharacteristicsIds.REGIONAL_SAR_INC_FPL
      ),
      regionSaRIncentiveNonfpl: handleGetUpcomingSetting(
        ModelCharacteristicsIds.REGIONAL_SAR_INC_NON_FPL
      ),
      fplBonusNewBoolean: handleGetUpcomingSetting(
        ModelCharacteristicsIds.FPL_BONUS_NEW_BOOLEAN
      ),
      assUsBoolean: handleGetUpcomingSetting(
        ModelCharacteristicsIds.ASS_US_BOOLEAN
      ),
      taxCreditRegionFplBonus: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_REGION_FPL_BONUS
      ),
      regionFplBonusNewBoolean: handleGetUpcomingSetting(
        ModelCharacteristicsIds.REGION_FPL_BONUS_NEW_BOOLEAN
      ),
      consumerDiscountRate: handleGetUpcomingSetting(
        ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE
      ),
      batteryImprovementRate: handleGetUpcomingSetting(
        ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE
      ),
      firstYearDiscount: handleGetUpcomingSetting(
        ModelCharacteristicsIds.FIRST_YEAR_DISCOUNT
      ),
      taxCreditTieredIncentiveP2: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P2
      ),
      taxCreditTieredIncentiveP3: handleGetUpcomingSetting(
        ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P3
      ),
      scenario: handleGetUpcomingSetting(ModelCharacteristicsIds.DECLINE_RATE)
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getModelCharacteristics()
  const [modelCharacteristics, changeModelCharacteristics] = useState(fields)

  // when we press 'save' we get updated data from main 'update-policy' component
  // so we need to update our defaults for 'undo' fields state
  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeModelCharacteristics(getModelCharacteristics())
    changeSettings(s)
  }, [defaults, updates])
  // eof of manipulating with current settings

  // METHODS
  const getIsExist = (id: ModelCharacteristicsValues) =>
    modelCharacteristics[id] !== undefined
  const getIsDefault = (id: ModelCharacteristicsValues) =>
    modelCharacteristics[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (
    id: ModelCharacteristicsValues,
    flag: InputTypeValue
  ) => (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    const reg = new RegExp('^[0-9]$') // only digits
    const stabValue = reg.test(String(value)) ? Number(value) : value

    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeModelCharacteristics({
      ...modelCharacteristics,
      [id]: stabValue
    })
    changeSettings(updSettings)
    onChange({ [id]: stabValue })
  }

  const handleOnBlur = (id: ModelCharacteristicsValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnRadioChange = (id: string) => (status: boolean) => {
    changeModelCharacteristics({
      ...modelCharacteristics,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleCheckboxChange = (id: string) => (status: boolean) => {
    changeModelCharacteristics({
      ...modelCharacteristics,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleUndoClick = (id: ModelCharacteristicsValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value
      changeModelCharacteristics({
        ...modelCharacteristics,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnGetError = (id: ModelCharacteristicsValues) =>
    getError(id, error)

  const handleOnGetCurrentDropdown = (value: number, id: string) =>
    getCurrentDropdown({
      dropdowns,
      value,
      id
    })
  // eof METHODS

  const UPDATES = updates as Partial<Policy>
  const isUSRegion = modelCharacteristics.region === 1

  const isTaxCreditSar = getIsTaxCreditSar({
    id1: 'nationalSaRIncentiveFpl',
    id2: 'nationalSaRIncentiveNonfpl',
    currentValues: modelCharacteristics,
    updates: UPDATES
  })
  const isTaxCreditRegionSar = getIsTaxCreditRegionSar({
    id1: 'regionSaRIncentiveFpl',
    id2: 'regionSaRIncentiveNonfpl',
    currentValues: modelCharacteristics,
    updates: UPDATES
  })

  const isTieredWithShift = hasCoincidence({
    option: 'tiered_with_shift',
    value: Number(modelCharacteristics.scenario),
    dropdownId: EvIncentiveIds.DECLINE_RATE,
    getDropdown: handleOnGetCurrentDropdown
  })
  const isTieredWithShiftAlt = hasCoincidence({
    option: 'tiered_with_shift_alt',
    value: Number(modelCharacteristics.scenario),
    dropdownId: EvIncentiveIds.DECLINE_RATE,
    getDropdown: handleOnGetCurrentDropdown
  })
  const isTieredWithShiftMarketShare = hasCoincidence({
    option: 'tiered_with_shift_market_share',
    value: Number(modelCharacteristics.scenario),
    dropdownId: EvIncentiveIds.DECLINE_RATE,
    getDropdown: handleOnGetCurrentDropdown
  })
  const isTieredWithShiftAltMarketShare = hasCoincidence({
    option: 'tiered_with_shift_alt_market_share',
    value: Number(modelCharacteristics.scenario),
    dropdownId: EvIncentiveIds.DECLINE_RATE,
    getDropdown: handleOnGetCurrentDropdown
  })

  const isCreditIncP2 = isTieredWithShift || isTieredWithShiftMarketShare
  const isCreditIncP3 =
    isTieredWithShift ||
    isTieredWithShiftMarketShare ||
    isTieredWithShiftAlt ||
    isTieredWithShiftAltMarketShare
  const isLeadingManufacturer =
    modelCharacteristics.lmBoolean || UPDATES.lmBoolean
  const isFplBonusNewBoolean =
    modelCharacteristics.fplBonusNewBoolean || UPDATES.fplBonusNewBoolean
  const isAssUsBoolean =
    modelCharacteristics.assUsBoolean || UPDATES.assUsBoolean
  const isTaxCreditRegionFplBonus =
    modelCharacteristics.regionFplBonusNewBoolean ||
    UPDATES.regionFplBonusNewBoolean

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={ModelCharacteristicsIds.ADOPTION_RATE}
            labelProps={getLabel(
              ModelCharacteristicsIds.ADOPTION_RATE,
              restrictions
            )}
            statusIcon
            attentionText="Be careful about changing this value.\nRecommended value 1.0"
            attentionTx="policy.attention"
            isFocused={getIsExist(ModelCharacteristicsIds.ADOPTION_RATE)}
            isUndo={getIsDefault(ModelCharacteristicsIds.ADOPTION_RATE)}
            description={
              restrictions[ModelCharacteristicsIds.ADOPTION_RATE]?.description
            }
            disabled={!getIsExist(ModelCharacteristicsIds.ADOPTION_RATE)}
            defaultValue={modelCharacteristics.diffusionCurveScalar}
            errorProps={handleOnGetError(ModelCharacteristicsIds.ADOPTION_RATE)}
            onBlur={handleOnBlur(ModelCharacteristicsIds.ADOPTION_RATE)}
            onChange={handleOnChange(
              ModelCharacteristicsIds.ADOPTION_RATE,
              InputTypeValue.PERCENT
            )}
            onUndoClick={handleUndoClick(ModelCharacteristicsIds.ADOPTION_RATE)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE}
            labelProps={getLabel(
              ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE,
              restrictions
            )}
            isFocused={getIsExist(
              ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE
            )}
            isUndo={getIsDefault(
              ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE
            )}
            description={
              restrictions[ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE]
                ?.description
            }
            isPercent
            defaultValue={modelCharacteristics.batteryImprovementRate}
            iconProps={percentIconProps}
            disabled={
              !getIsExist(ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE)
            }
            errorProps={handleOnGetError(
              ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE
            )}
            onBlur={handleOnBlur(
              ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE
            )}
            onChange={handleOnChange(
              ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE,
              InputTypeValue.PERCENT
            )}
            onUndoClick={handleUndoClick(
              ModelCharacteristicsIds.BATTERY_IMPROVEMENT_RATE
            )}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={ModelCharacteristicsIds.DISCOUNT_RATE}
            labelProps={getLabel(
              ModelCharacteristicsIds.DISCOUNT_RATE,
              restrictions
            )}
            isPercent
            defaultValue={modelCharacteristics.discountRate}
            iconProps={percentIconProps}
            disabled={!getIsExist(ModelCharacteristicsIds.DISCOUNT_RATE)}
            isFocused={getIsExist(ModelCharacteristicsIds.DISCOUNT_RATE)}
            isUndo={getIsDefault(ModelCharacteristicsIds.DISCOUNT_RATE)}
            description={
              restrictions[ModelCharacteristicsIds.DISCOUNT_RATE]?.description
            }
            errorProps={handleOnGetError(ModelCharacteristicsIds.DISCOUNT_RATE)}
            onBlur={handleOnBlur(ModelCharacteristicsIds.DISCOUNT_RATE)}
            onChange={handleOnChange(
              ModelCharacteristicsIds.DISCOUNT_RATE,
              InputTypeValue.PERCENT
            )}
            onUndoClick={handleUndoClick(ModelCharacteristicsIds.DISCOUNT_RATE)}
          />
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(
                ModelCharacteristicsIds.FIRST_YEAR_DISCOUNT,
                restrictions
              )}
              value={modelCharacteristics.firstYearDiscount}
              disabled={
                !getIsExist(ModelCharacteristicsIds.FIRST_YEAR_DISCOUNT)
              }
              description={
                restrictions[ModelCharacteristicsIds.FIRST_YEAR_DISCOUNT]
                  ?.description
              }
              isUndo={getIsDefault(ModelCharacteristicsIds.FIRST_YEAR_DISCOUNT)}
              onChange={handleOnRadioChange(
                ModelCharacteristicsIds.FIRST_YEAR_DISCOUNT
              )}
              onUndoClick={handleUndoClick(
                ModelCharacteristicsIds.FIRST_YEAR_DISCOUNT
              )}
            />
          </Column>
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <Text text={applyDiscountRateToProps.text} preset="caption" />
            <Row
              className={classes.selectionControls}
              fullWidth
              justifyContent="space-between"
            >
              <Checkbox
                text={
                  getLabel(ModelCharacteristicsIds.COSTS, restrictions).text
                }
                defaultChecked={modelCharacteristics.discountCosts}
                disabled={!getIsExist(ModelCharacteristicsIds.COSTS)}
                description={
                  restrictions[ModelCharacteristicsIds.COSTS]?.description
                }
                isUndo={getIsDefault(ModelCharacteristicsIds.COSTS)}
                onChange={handleCheckboxChange(ModelCharacteristicsIds.COSTS)}
                onUndoClick={handleUndoClick(ModelCharacteristicsIds.COSTS)}
              />
            </Row>
          </Column>
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(ModelCharacteristicsIds.INCENTIVES, restrictions)}
              value={modelCharacteristics.taxCreditBoolean}
              disabled={!getIsExist(ModelCharacteristicsIds.INCENTIVES)}
              description={
                restrictions[ModelCharacteristicsIds.INCENTIVES]?.description
              }
              isUndo={getIsDefault(ModelCharacteristicsIds.INCENTIVES)}
              errorProps={handleOnCheckAllErrors({
                values: modelCharacteristics,
                radioId: ModelCharacteristicsIds.INCENTIVES,
                ids: INCENTIVES_RADIO_IDS,
                error
              })}
              onChange={handleOnRadioChange(ModelCharacteristicsIds.INCENTIVES)}
              onUndoClick={handleUndoClick(ModelCharacteristicsIds.INCENTIVES)}
            />
          </Column>
          {modelCharacteristics.taxCreditBoolean && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE}
              labelProps={getLabel(
                ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE,
                restrictions
              )}
              isPercent
              defaultValue={modelCharacteristics.consumerDiscountRate}
              iconProps={percentIconProps}
              isFocused={getIsExist(
                ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE
              )}
              isUndo={getIsDefault(
                ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE
              )}
              description={
                restrictions[ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE]
                  ?.description
              }
              disabled={
                !getIsExist(ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE)
              }
              errorProps={handleOnGetError(
                ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE
              )}
              onBlur={handleOnBlur(
                ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE
              )}
              onChange={handleOnChange(
                ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE,
                InputTypeValue.PERCENT
              )}
              onUndoClick={handleUndoClick(
                ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE
              )}
            />
          )}
        </Row>
        {modelCharacteristics.taxCreditBoolean && (
          <Row
            className={`${classes.row} ${classes.responsiveRow}`}
            justifyContent="flex-start"
          >
            <Row
              className={`${classes.cell}`}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Checkbox
                text={
                  getLabel(
                    ModelCharacteristicsIds.TAX_CREDIT_NEW_EV_INC,
                    restrictions
                  ).text
                }
                defaultChecked={modelCharacteristics.taxCreditNewEvInc}
                disabled={!modelCharacteristics.taxCreditBoolean}
                description={
                  restrictions[ModelCharacteristicsIds.TAX_CREDIT_NEW_EV_INC]
                    ?.description
                }
                isUndo={getIsDefault(
                  ModelCharacteristicsIds.TAX_CREDIT_NEW_EV_INC
                )}
                onChange={handleCheckboxChange(
                  ModelCharacteristicsIds.TAX_CREDIT_NEW_EV_INC
                )}
                onUndoClick={handleUndoClick(
                  ModelCharacteristicsIds.TAX_CREDIT_NEW_EV_INC
                )}
              />
            </Row>
            <Row
              className={`${classes.cell}`}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Checkbox
                text={
                  getLabel(
                    ModelCharacteristicsIds.TAX_CREDIT_DOMESTIC,
                    restrictions
                  ).text
                }
                defaultChecked={modelCharacteristics.taxCreditDomestic}
                disabled={!modelCharacteristics.taxCreditBoolean}
                description={
                  restrictions[ModelCharacteristicsIds.TAX_CREDIT_DOMESTIC]
                    ?.description
                }
                isUndo={getIsDefault(
                  ModelCharacteristicsIds.TAX_CREDIT_DOMESTIC
                )}
                onChange={handleCheckboxChange(
                  ModelCharacteristicsIds.TAX_CREDIT_DOMESTIC
                )}
                onUndoClick={handleUndoClick(
                  ModelCharacteristicsIds.TAX_CREDIT_DOMESTIC
                )}
              />
            </Row>
            {isLeadingManufacturer && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_LEADING_MANUFACTURER,
                      restrictions
                    ).text
                  }
                  defaultChecked={
                    modelCharacteristics.taxCreditLeadingManufacturer
                  }
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[
                      ModelCharacteristicsIds.TAX_CREDIT_LEADING_MANUFACTURER
                    ]?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_LEADING_MANUFACTURER
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_LEADING_MANUFACTURER
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_LEADING_MANUFACTURER
                  )}
                />
              </Row>
            )}
            <Row
              className={`${classes.cell}`}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Checkbox
                text={
                  getLabel(
                    ModelCharacteristicsIds.TAX_CREDIT_SOFT_CAP,
                    restrictions
                  ).text
                }
                defaultChecked={modelCharacteristics.taxCreditSoftCap}
                disabled={!modelCharacteristics.taxCreditBoolean}
                description={
                  restrictions[ModelCharacteristicsIds.TAX_CREDIT_SOFT_CAP]
                    ?.description
                }
                isUndo={getIsDefault(
                  ModelCharacteristicsIds.TAX_CREDIT_SOFT_CAP
                )}
                onChange={handleCheckboxChange(
                  ModelCharacteristicsIds.TAX_CREDIT_SOFT_CAP
                )}
                onUndoClick={handleUndoClick(
                  ModelCharacteristicsIds.TAX_CREDIT_SOFT_CAP
                )}
              />
            </Row>
            <Row
              className={`${classes.cell}`}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Checkbox
                text={
                  getLabel(
                    ModelCharacteristicsIds.TAX_CREDIT_USED_EV_INC,
                    restrictions
                  ).text
                }
                defaultChecked={modelCharacteristics.taxCreditUsedEvInc}
                disabled={!modelCharacteristics.taxCreditBoolean}
                description={
                  restrictions[ModelCharacteristicsIds.TAX_CREDIT_USED_EV_INC]
                    ?.description
                }
                isUndo={getIsDefault(
                  ModelCharacteristicsIds.TAX_CREDIT_USED_EV_INC
                )}
                onChange={handleCheckboxChange(
                  ModelCharacteristicsIds.TAX_CREDIT_USED_EV_INC
                )}
                onUndoClick={handleUndoClick(
                  ModelCharacteristicsIds.TAX_CREDIT_USED_EV_INC
                )}
              />
            </Row>
            {isTaxCreditSar && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_SAR,
                      restrictions
                    ).text
                  }
                  defaultChecked={modelCharacteristics.taxCreditSar}
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[ModelCharacteristicsIds.TAX_CREDIT_SAR]
                      ?.description
                  }
                  isUndo={getIsDefault(ModelCharacteristicsIds.TAX_CREDIT_SAR)}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_SAR
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_SAR
                  )}
                />
              </Row>
            )}
            {!isUSRegion && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_REGION_NEW_EV_INC,
                      restrictions
                    ).text
                  }
                  defaultChecked={modelCharacteristics.taxCreditRegionNewEvInc}
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[
                      ModelCharacteristicsIds.TAX_CREDIT_REGION_NEW_EV_INC
                    ]?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_NEW_EV_INC
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_NEW_EV_INC
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_NEW_EV_INC
                  )}
                />
              </Row>
            )}
            {isCreditIncP2 && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P2,
                      restrictions
                    ).text
                  }
                  defaultChecked={
                    modelCharacteristics.taxCreditTieredIncentiveP2
                  }
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[
                      ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P2
                    ]?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P2
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P2
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P2
                  )}
                />
              </Row>
            )}
            {isCreditIncP3 && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P3,
                      restrictions
                    ).text
                  }
                  defaultChecked={
                    modelCharacteristics.taxCreditTieredIncentiveP3
                  }
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[
                      ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P3
                    ]?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P3
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P3
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_TIERED_INCENTIVE_P3
                  )}
                />
              </Row>
            )}
            {!isUSRegion && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_REGION_USED_EV_INC,
                      restrictions
                    ).text
                  }
                  defaultChecked={modelCharacteristics.taxCreditRegionUsedEvInc}
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[
                      ModelCharacteristicsIds.TAX_CREDIT_REGION_USED_EV_INC
                    ]?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_USED_EV_INC
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_USED_EV_INC
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_USED_EV_INC
                  )}
                />
              </Row>
            )}
            {isTaxCreditRegionSar && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.REGION_TAX_CREDIT_SAR,
                      restrictions
                    ).text
                  }
                  defaultChecked={modelCharacteristics.taxCreditRegionSar}
                  description={
                    restrictions[ModelCharacteristicsIds.REGION_TAX_CREDIT_SAR]
                      ?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.REGION_TAX_CREDIT_SAR
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.REGION_TAX_CREDIT_SAR
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.REGION_TAX_CREDIT_SAR
                  )}
                />
              </Row>
            )}
            {!isUSRegion && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_REGION_SOFT_CAP,
                      restrictions
                    ).text
                  }
                  defaultChecked={modelCharacteristics.taxCreditRegionSoftCap}
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[
                      ModelCharacteristicsIds.TAX_CREDIT_REGION_SOFT_CAP
                    ]?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_SOFT_CAP
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_SOFT_CAP
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_SOFT_CAP
                  )}
                />
              </Row>
            )}
            {isFplBonusNewBoolean && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_FPL_BONUS,
                      restrictions
                    ).text
                  }
                  defaultChecked={modelCharacteristics.taxCreditFplBonus}
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[ModelCharacteristicsIds.TAX_CREDIT_FPL_BONUS]
                      ?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_FPL_BONUS
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_FPL_BONUS
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_FPL_BONUS
                  )}
                />
              </Row>
            )}
            {isAssUsBoolean && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_ASSEMBLED_IN_US,
                      restrictions
                    ).text
                  }
                  defaultChecked={modelCharacteristics.taxCreditAssembledInUs}
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[
                      ModelCharacteristicsIds.TAX_CREDIT_ASSEMBLED_IN_US
                    ]?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_ASSEMBLED_IN_US
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_ASSEMBLED_IN_US
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_ASSEMBLED_IN_US
                  )}
                />
              </Row>
            )}
            {isTaxCreditRegionFplBonus && (
              <Row
                className={`${classes.cell}`}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Checkbox
                  text={
                    getLabel(
                      ModelCharacteristicsIds.TAX_CREDIT_REGION_FPL_BONUS,
                      restrictions
                    ).text
                  }
                  defaultChecked={modelCharacteristics.taxCreditRegionFplBonus}
                  disabled={!modelCharacteristics.taxCreditBoolean}
                  description={
                    restrictions[
                      ModelCharacteristicsIds.TAX_CREDIT_REGION_FPL_BONUS
                    ]?.description
                  }
                  isUndo={getIsDefault(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_FPL_BONUS
                  )}
                  onChange={handleCheckboxChange(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_FPL_BONUS
                  )}
                  onUndoClick={handleUndoClick(
                    ModelCharacteristicsIds.TAX_CREDIT_REGION_FPL_BONUS
                  )}
                />
              </Row>
            )}
          </Row>
        )}
      </Content>
    </Column>
  )
}
