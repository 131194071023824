import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { SliderMenuStyleProps } from './slider-menu.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: ({ disabled }: SliderMenuStyleProps) =>
      disabled ? theme.colors.disableRadioBackground : theme.colors.white
  },
  row: {
    padding: '16px 0',
    width: 'calc(100% - 64px)',
    cursor: ({ disabled }: SliderMenuStyleProps) =>
      disabled ? 'default' : theme.cursor
  },
  title: {
    marginLeft: 24
  },
  pureTitle: {
    marginLeft: 48
  },
  arrow: {
    marginLeft: 24,
    transform: ({ open }: SliderMenuStyleProps) =>
      open ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out'
  },
  itemsContainer: {
    padding: ({ open }: SliderMenuStyleProps) => (open ? '16px 0' : ''),
    height: ({ open, height }: SliderMenuStyleProps) => (open ? height : 0),
    visibility: ({ open }: SliderMenuStyleProps) =>
      open ? 'visible' : 'hidden',
    background: theme.colors.white,
    transition: ({ open }) =>
      open
        ? 'visibility 0.1s ease-in-out 0.25s, height 0.25s ease-in-out'
        : 'visibility 0.1s ease-in-out, height 0.25s ease-in-out',
    borderRadius: '0 0 12px 12px',
    overflow: ({ open }) => (open ? 'visible' : 'hidden')
  },
  warning: {
    right: 124
  },
  replyAll: {
    cursor: theme.cursor,
    '& > *': {
      cursor: theme.cursor
    }
  },
  reset: {
    marginLeft: 8
  }
}))
