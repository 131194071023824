import React, { FC, useRef } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { useOutsideAlerter } from '../../../hooks'
import { Column } from '../../column'
import { Row } from '../../row'
import { Text } from '../../text'
import { ErrorNotification } from '../../error-notification'
import { FieldNotificationProps } from './field-notification.types'
import { useStyle } from './field-notification.styles'

export const FieldNotification: FC<FieldNotificationProps> = ({
  isOpen = false,
  onClose
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  useOutsideAlerter(ref, isOpen, onClose)

  return (
    <ErrorNotification onClose={onClose}>
      <Column ref={ref} fullWidth alignItems="flex-start">
        <Text
          text="Invalid Value"
          tx="policy.error.title"
          preset="h2"
          color="danger"
        />
        <Row className={classes.errorDesc}>
          <Text
            text="Please check, there is at least one invalid value"
            tx="policy.error.description"
            preset="body"
            color="red"
          />
        </Row>
      </Column>
    </ErrorNotification>
  )
}
