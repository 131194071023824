import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { useResponsive } from '../../../providers'
import { Column, Icon, Row, Text } from '../..'
import { ComponentTextProps } from '../../text'
import { IconName } from '../../icon'
import { ProgressBar } from '../progress-bar/progress-bar'
import { PlotIds } from '../policy-summary-list.types'
import { EvSalesIncCostProps } from './widgets.types'
import { useStyle } from './widgets.styles'

export const EvSalesIncCost: FC<EvSalesIncCostProps> = ({
  fleetSize,
  finalMarketShare,
  evAdoptionYear,
  navigate,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { isTablet } = useResponsive()
  const ResponsiveColRow = isTablet ? Row : Column

  const evAdoptionLabelProps: ComponentTextProps = {
    tx: 'policy.salesFleetPenetration',
    text: 'EV Sales and Fleet Penetration',
    color: 'text',
    preset: 'bodyBold'
  }

  const fleetShareLabelProps: ComponentTextProps = {
    tx: 'policy.summary.fleetShare',
    text: 'U.S. Fleet Share \nin {year}',
    color: 'inactive',
    preset: 'body',
    values: {
      year: evAdoptionYear
    }
  }

  const marketShareLabelProps: ComponentTextProps = {
    tx: 'policy.summary.marketShare',
    text: 'U.S. Market Share \nin {year}',
    color: 'inactive',
    preset: 'body',
    values: {
      year: evAdoptionYear
    }
  }

  const handleOnEvSales = () => {
    onClick(PlotIds.EV_ADOPTION)
  }

  return (
    <Column fullWidth>
      <Row className={classes.row} fullWidth justifyContent="space-between">
        <Row>
          <Icon name={IconName.EV_STATION} fill="red" />
          <Text className={classes.description} {...evAdoptionLabelProps} />
        </Row>
        {navigate && (
          <Icon
            className={classes.breadcrumbs}
            name={IconName.BREAD_CRUMBS}
            fill="darkBlue"
            onClick={handleOnEvSales}
          />
        )}
      </Row>
      <ResponsiveColRow fullWidth>
        <Row className={classes.row} fullWidth justifyContent="space-between">
          <Row>
            <ProgressBar value={fleetSize} />
          </Row>
          <Row fullWidth>
            <Text className={classes.text} {...fleetShareLabelProps} />
          </Row>
        </Row>
        <Row className={classes.row} fullWidth justifyContent="space-between">
          <Row>
            <ProgressBar value={finalMarketShare} />
          </Row>
          <Row fullWidth>
            <Text className={classes.text} {...marketShareLabelProps} />
          </Row>
        </Row>
      </ResponsiveColRow>
    </Column>
  )
}
