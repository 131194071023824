import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 12
  },
  noPadding: {
    padding: 0
  },
  plotSlider: {
    padding: '8px 0'
  },
  row: {
    width: 'calc(100% - 64px)',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  frame: {
    width: 'calc(100% - 64px)',
    height: 492
  },
  content: {
    borderTop: `1px solid ${theme.colors.secondButton}`
  },
  tabs: {
    width: 'calc(100% - 64px)'
  },
  tab: {
    padding: '0 48px 16px 48px'
  },
  selected: {
    borderBottom: `1px solid ${theme.colors.blue}`,
    '& > *': {
      color: theme.colors.blue
    }
  },
  '@media screen and (max-width: 1170px)': {
    frame: {
      width: '100%'
    },
    row: {
      alignItems: 'flex-start'
    }
  },
  '@media screen and (max-width: 800px)': {
    frame: {
      width: '130%',
      transform: 'scale(0.7)'
    }
  }
}))
