import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from 'react-jss'

import { IconName, Column, Row, Text, Icon } from '..'
import { LightTheme } from '../../theme'
import { InfoModal } from '../info-modal'
import { YearsSliderProps } from './years-slider.types'
import { useStyle } from './years-slider.styles'

export const YearsSlider: FC<YearsSliderProps> = ({
  className = '',
  isSmall = false,
  disabled = false,
  range,
  value,
  textProps,
  description = '',
  onChange
}) => {
  const theme: LightTheme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const [openModal, changeOpenModal] = useState(false)
  const [width, changeWidth] = useState(0)
  const [val, changeVal] = useState(Number(value))
  const mainColor = isSmall ? theme.colors.green : theme.colors.darkBlue
  const classes = useStyle({
    width,
    disabled,
    isSmall,
    mainColor,
    theme,
    description
  })

  const mainTextColor = isSmall ? 'green' : 'darkBlue'
  const textColor = disabled ? 'inactive' : mainTextColor
  const getInactiveFill = (item: number) =>
    item > val ? theme.colors.grey : mainColor

  const updateSlider = useCallback(
    (year: number, idx: number) => {
      const min = range[0]
      const max = range[range.length - 1]
      const parts = (max - min) / range.length
      const sliderWidth = ref.current ? ref.current.offsetWidth : 0
      const partWidth = sliderWidth / parts
      const x = idx * partWidth

      changeWidth(x)
      changeVal(year)

      if (!x && idx) {
        setTimeout(() => updateSlider(year, idx), 2000)
      }
    },
    [range]
  )

  const handleOnChange = (year: number, idx: number) => () => {
    if (!disabled) {
      updateSlider(year, idx)
    }

    if (onChange) {
      onChange(year)
    }
  }

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    [openModal]
  )

  const handleOnClick = () =>
    description ? handleOnOpenModal(true)() : undefined

  useEffect(() => {
    changeVal(Number(value))
  }, [value])

  useEffect(() => {
    if (ref.current) {
      const currentIdx = range.indexOf(val)
      updateSlider(val, currentIdx)
    }
  }, [ref, range, val, updateSlider])

  return (
    <Column
      className={classes.cell}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Row alignItems="flex-start">
        <Text
          className={classes.title}
          color="inactive"
          preset="caption"
          {...textProps}
          onClick={handleOnClick}
        />
      </Row>

      <Row
        ref={ref}
        className={classes.container}
        fullWidth
        justifyContent="flex-start"
      >
        <Row
          className={`${className} ${classes.slider}`}
          justifyContent="flex-start"
          fullWidth
        >
          <Row className={`${classes.container} ${classes.basic}`} fullWidth />
          <Row className={classes.filled} />
          <Row
            fullWidth
            alignItems="flex-end"
            justifyContent="space-between"
            className={`${classes.container} ${classes.pointers}`}
          >
            {range.map((item: number, idx: number) => (
              <Column
                key={`year_${item}`}
                className={classes.values}
                onClick={handleOnChange(item, idx)}
              >
                {item === val ? (
                  <Icon
                    name={IconName.ELLIPSE12}
                    className={classes.selected}
                    fill={getInactiveFill(item)}
                  />
                ) : (
                  <Icon name={IconName.ELLIPSE9} fill={getInactiveFill(item)} />
                )}
                <Text
                  className={classes.text}
                  text={String(item)}
                  color={textColor}
                  preset="subTitle"
                />
              </Column>
            ))}
          </Row>
        </Row>
      </Row>

      {openModal && (
        <InfoModal titleProps={textProps} onClose={handleOnOpenModal(false)}>
          {description && <Text text={description} preset="body" />}
        </InfoModal>
      )}
    </Column>
  )
}
