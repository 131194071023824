import snakecaseKeys from 'snakecase-keys'

import { CreatePolicyRequestParams } from '../../store/actions/policy/policy.types'

export const alignPolicyToCorrectValues = (
  policy: CreatePolicyRequestParams
) => {
  const body = snakecaseKeys(policy, {
    deep: true
  })

  const alignedBody = {
    ...body,
    MiA_inc: body.mi_a_inc,
    MiA_prop: body.mi_a_prop,
    maximum_SaR_age: body.maximum_sa_r_age,
    region_SaR_end_years: body.region_sa_r_end_years,
    region_SaR_incentive_fpl: body.region_sa_r_incentive_fpl,
    region_SaR_incentive_nonfpl: body.region_sa_r_incentive_nonfpl,
    region_SaR_start_year: body.region_sa_r_start_year,
    region_fpl_SaR_new: body.region_fpl_sa_r_new,
    region_fpl_SaR_used: body.region_fpl_sa_r_used,
    region_nonfpl_SaR_new: body.region_nonfpl_sa_r_new,
    region_nonfpl_SaR_used: body.region_nonfpl_sa_r_used,
    national_fpl_SaR_new: body.national_fpl_sa_r_new,
    national_fpl_SaR_used: body.national_fpl_sa_r_used,
    national_nonfpl_SaR_new: body.national_nonfpl_sa_r_new,
    national_nonfpl_SaR_used: body.national_nonfpl_sa_r_used,
    national_SaR_incentive_fpl: body.national_sa_r_incentive_fpl,
    national_SaR_incentive_nonfpl: body.national_sa_r_incentive_nonfpl,
    oem_100_threshold: body.oem100_threshold,
    input_2050_mpg: body.input2050_mpg,
    SaR_end_years: body.sa_r_end_years,
    SaR_start_year: body.sa_r_start_year,
    min_tier_inc_kWh: body.min_tier_inc_k_wh,
    min_tier_kWh: body.min_tier_k_wh,
    region_min_tier_inc_kWh: body.region_min_tier_inc_k_wh,
    region_min_tier_kWh: body.region_min_tier_k_wh,
    region_step_tier_inc_kWh: body.region_step_tier_inc_k_wh,
    region_step_tier_kWh: body.region_step_tier_k_wh,
    step_tier_inc_kWh: body.step_tier_inc_k_wh,
    step_tier_kWh: body.step_tier_k_wh,

    p2_min_tier_kWh: body.p2_min_tier_k_wh,
    p2_min_tier_inc_kWh: body.p2_min_tier_inc_k_wh,
    p2_step_tier_kWh: body.p2_step_tier_k_wh,
    p2_step_tier_inc_kWh: body.p2_step_tier_inc_k_wh,
    p3_min_tier_kWh: body.p3_min_tier_k_wh,
    p3_min_tier_inc_kWh: body.p3_min_tier_inc_k_wh,
    p3_step_tier_kWh: body.p3_step_tier_k_wh,
    p3_step_tier_inc_kWh: body.p3_step_tier_inc_k_wh,

    HoH_cap: body.ho_h_cap,
    HoH_cap_none: body.ho_h_cap_none,
    HoH_cap_phase2: body.ho_h_cap_phase2,
    HoH_cap_phase3: body.ho_h_cap_phase3,
    region_HoH_cap: body.region_ho_h_cap,
    region_HoH_cap_phase2: body.region_ho_h_cap_phase2,
    region_HoH_cap_phase3: body.region_ho_h_cap_phase3
  }

  return alignedBody
}
