import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Row } from '../../row'
import { Text } from '../../text'
import { LightTheme } from '../../../theme'
import { DropdownItemProps } from './dropdown-item.types'
import { useStyle } from './dropdown-item.styles'

export const DropdownItem: FC<DropdownItemProps> = ({
  isScrollable = false,
  disabled = false,
  item,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ isScrollable, theme })
  const { name, nameTx } = item
  const disabledClass = disabled && classes.disabled
  const textColor = disabled ? 'grey' : 'inactive'

  const handleOnClick = () => {
    onClick(item)
  }

  return (
    <Row
      justifyContent="flex-start"
      className={`${disabledClass} ${classes.container}`}
      onClick={handleOnClick}
    >
      <Text text={name} tx={nameTx} preset="h5" color={textColor} />
    </Row>
  )
}
