import React, { FC, useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import {
  Content,
  Column,
  Input,
  Text,
  RegulatoryPoliciesValues,
  RadioButtonGroup,
  getError,
  getUpcomingSetting,
  getCustomSettings,
  getSetting,
  changeCurrentValues,
  getIsValid,
  setError,
  handleOnCheckAllErrors,
  ICEV_BAN_BOOLEAN_RADIO_IDS,
  getSliderId,
  RADIO_ID_DEPENDENCIES,
  getLabel,
  formatValue
} from '../..'
import { Policy, Restrictions } from '../../../services'
import { Row } from '../../row'
import { State } from '../../../store'
import { dollarIconProps, yearIconProps, zevMandateProps } from '../label-props'
import {
  InputsPolicyProps,
  InputTypeValue,
  RegulatoryPoliciesIds
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const RegulatoryPolicies: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions, bauDefaults } = useSelector((state: State) => ({
    restrictions: state.policy.restrictions as Restrictions,
    bauDefaults: state.policy.bauDefaults
  }))

  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: RegulatoryPoliciesValues) => getUpcomingSetting(id, settingsList),
    [settingsList]
  )

  // start of manipulating with current settings
  const getRegulatoryPolicies = useCallback(
    (): Partial<Policy> => ({
      zevMandateGoal: handleGetUpcomingSetting(
        RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES
      ),
      zevMandateYear: handleGetUpcomingSetting(
        RegulatoryPoliciesIds.ZEV_GOAL_YEAR
      ),
      noncomplianceCost: handleGetUpcomingSetting(
        RegulatoryPoliciesIds.NON_COMPLIANCE_FEE
      ),
      additionalOemIncentive: handleGetUpcomingSetting(
        RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT
      ),
      oem100Threshold: handleGetUpcomingSetting(
        RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD
      ),
      icevBanBoolean: handleGetUpcomingSetting(
        RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN
      ),
      icevBanYear: handleGetUpcomingSetting(RegulatoryPoliciesIds.ICEV_BAN_YEAR)
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getRegulatoryPolicies()
  const [regulatoryPolicies, changeRegulatoryPolicies] = useState(fields)

  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeRegulatoryPolicies(getRegulatoryPolicies())
    changeSettings(s)
  }, [defaults, updates])
  // eof of manipulating with current settings

  // METHODS
  const getIsExist = (id: RegulatoryPoliciesValues) =>
    regulatoryPolicies[id] !== undefined
  const getIsDefault = (id: RegulatoryPoliciesValues) =>
    regulatoryPolicies[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (
    id: RegulatoryPoliciesValues,
    flag: InputTypeValue
  ) => (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeRegulatoryPolicies({
      ...regulatoryPolicies,
      [id]: value
    })
    changeSettings(updSettings)
    onChange({ [id]: value })
  }

  const handleOnBlur = (id: RegulatoryPoliciesValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnRadioChange = (id: string) => (status: boolean) => {
    changeRegulatoryPolicies({
      ...regulatoryPolicies,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleUndoClick = (id: RegulatoryPoliciesValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value

      changeRegulatoryPolicies({
        ...regulatoryPolicies,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnGetError = (id: RegulatoryPoliciesValues) => getError(id, error)

  const getIsUpdateInBauValues = (key: keyof Policy) => {
    const isUndefined = typeof updates[key] === 'undefined'
    const isEqual = updates[key] === bauDefaults[key]

    return !isUndefined && isEqual
  }

  const getAttentionIconState = (id: keyof Policy): object => {
    const isRadioOff = !regulatoryPolicies[id]
    const bauKeys = Object.keys(bauDefaults)
    const newKeys = Object.keys(updates)
    const dependencyKeys = RADIO_ID_DEPENDENCIES[id]
    const isBauSetted = dependencyKeys.find(getIsUpdateInBauValues)
    const isBauValuesFromDependency = dependencyKeys.find((depId: string) =>
      bauKeys.includes(depId)
    )
    let isIconVisible = Boolean(
      isRadioOff && isBauSetted && isBauValuesFromDependency
    )

    if (isBauSetted) {
      return {
        statusIcon: isIconVisible,
        attentionText:
          'Underlying inputs were changed to BAU.\nOpen the section to review \nand/or discard changes.',
        attentionTx: 'policy.radio.warning'
      }
    }

    const isNewValuesFromDependency = dependencyKeys.find((depId: string) =>
      newKeys.includes(depId)
    )
    isIconVisible = Boolean(isRadioOff && isNewValuesFromDependency)

    return {
      statusIcon: isIconVisible,
      attentionText:
        'Underlying inputs were changed,\nthis may affect policy output.\nOpen the section to review \nand/or discard changes.',
      attentionTx: 'policy.radio.warning2'
    }
  }
  // eof METHODS

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text {...zevMandateProps} className={classes.subTitle} preset="h3" />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES}
            labelProps={getLabel(
              RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES,
              restrictions
            )}
            defaultValue={regulatoryPolicies.zevMandateGoal}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES)}
            description={
              restrictions[RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES]?.description
            }
            disabled={!getIsExist(RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES)}
            isUndo={getIsDefault(RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES)}
            errorProps={handleOnGetError(
              RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES
            )}
            onBlur={handleOnBlur(RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES)}
            onChange={handleOnChange(
              RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(
              RegulatoryPoliciesIds.ZEV_GOAL_EV_SALES
            )}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={RegulatoryPoliciesIds.ZEV_GOAL_YEAR}
            labelProps={getLabel(
              RegulatoryPoliciesIds.ZEV_GOAL_YEAR,
              restrictions
            )}
            isYear
            iconProps={yearIconProps}
            defaultValue={regulatoryPolicies.zevMandateYear}
            isFocused={getIsExist(RegulatoryPoliciesIds.ZEV_GOAL_YEAR)}
            description={
              restrictions[RegulatoryPoliciesIds.ZEV_GOAL_YEAR]?.description
            }
            disabled={!getIsExist(RegulatoryPoliciesIds.ZEV_GOAL_YEAR)}
            isUndo={getIsDefault(RegulatoryPoliciesIds.ZEV_GOAL_YEAR)}
            errorProps={handleOnGetError(RegulatoryPoliciesIds.ZEV_GOAL_YEAR)}
            onBlur={handleOnBlur(RegulatoryPoliciesIds.ZEV_GOAL_YEAR)}
            onChange={handleOnChange(
              RegulatoryPoliciesIds.ZEV_GOAL_YEAR,
              InputTypeValue.YEAR
            )}
            onUndoClick={handleUndoClick(RegulatoryPoliciesIds.ZEV_GOAL_YEAR)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={RegulatoryPoliciesIds.NON_COMPLIANCE_FEE}
            labelProps={getLabel(
              RegulatoryPoliciesIds.NON_COMPLIANCE_FEE,
              restrictions
            )}
            defaultValue={regulatoryPolicies.noncomplianceCost}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(RegulatoryPoliciesIds.NON_COMPLIANCE_FEE)}
            description={
              restrictions[RegulatoryPoliciesIds.NON_COMPLIANCE_FEE]
                ?.description
            }
            disabled={!getIsExist(RegulatoryPoliciesIds.NON_COMPLIANCE_FEE)}
            isUndo={getIsDefault(RegulatoryPoliciesIds.NON_COMPLIANCE_FEE)}
            errorProps={handleOnGetError(
              RegulatoryPoliciesIds.NON_COMPLIANCE_FEE
            )}
            onBlur={handleOnBlur(RegulatoryPoliciesIds.NON_COMPLIANCE_FEE)}
            onChange={handleOnChange(
              RegulatoryPoliciesIds.NON_COMPLIANCE_FEE,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(
              RegulatoryPoliciesIds.NON_COMPLIANCE_FEE
            )}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT}
            labelProps={getLabel(
              RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT,
              restrictions
            )}
            defaultValue={regulatoryPolicies.additionalOemIncentive}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(
              RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT
            )}
            description={
              restrictions[RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT]
                ?.description
            }
            disabled={
              !getIsExist(RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT)
            }
            isUndo={getIsDefault(RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT)}
            errorProps={handleOnGetError(
              RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT
            )}
            onBlur={handleOnBlur(RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT)}
            onChange={handleOnChange(
              RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(
              RegulatoryPoliciesIds.VOLUNTARY_ZEV_PRICE_CUT
            )}
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(
                RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN,
                restrictions
              )}
              value={regulatoryPolicies.icevBanBoolean}
              disabled={!getIsExist(RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN)}
              isUndo={getIsDefault(RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN)}
              errorProps={handleOnCheckAllErrors({
                values: regulatoryPolicies,
                radioId: RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN,
                ids: ICEV_BAN_BOOLEAN_RADIO_IDS,
                error
              })}
              description={
                restrictions[RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN]
                  ?.description
              }
              {...getAttentionIconState(RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN)}
              onChange={handleOnRadioChange(
                RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN
              )}
              onUndoClick={handleUndoClick(
                RegulatoryPoliciesIds.ICEV_BAN_BOOLEAN
              )}
            />
          </Column>
          {regulatoryPolicies.icevBanBoolean && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={RegulatoryPoliciesIds.ICEV_BAN_YEAR}
                labelProps={getLabel(
                  RegulatoryPoliciesIds.ICEV_BAN_YEAR,
                  restrictions
                )}
                isYear
                iconProps={yearIconProps}
                defaultValue={regulatoryPolicies.icevBanYear}
                isFocused={getIsExist(RegulatoryPoliciesIds.ICEV_BAN_YEAR)}
                description={
                  restrictions[RegulatoryPoliciesIds.ICEV_BAN_YEAR]?.description
                }
                disabled={!regulatoryPolicies.icevBanBoolean}
                isUndo={getIsDefault(RegulatoryPoliciesIds.ICEV_BAN_YEAR)}
                errorProps={handleOnGetError(
                  RegulatoryPoliciesIds.ICEV_BAN_YEAR
                )}
                onBlur={handleOnBlur(RegulatoryPoliciesIds.ICEV_BAN_YEAR)}
                onChange={handleOnChange(
                  RegulatoryPoliciesIds.ICEV_BAN_YEAR,
                  InputTypeValue.YEAR
                )}
                onUndoClick={handleUndoClick(
                  RegulatoryPoliciesIds.ICEV_BAN_YEAR
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD}
                labelProps={getLabel(
                  RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD,
                  restrictions
                )}
                defaultValue={regulatoryPolicies.oem100Threshold}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(
                  RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD
                )}
                description={
                  restrictions[RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD]
                    ?.description
                }
                disabled={
                  !getIsExist(RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD)
                }
                isUndo={getIsDefault(
                  RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD
                )}
                errorProps={handleOnGetError(
                  RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD
                )}
                onBlur={handleOnBlur(
                  RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD
                )}
                onChange={handleOnChange(
                  RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(
                  RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD
                )}
              />
            </>
          )}
        </Row>
      </Content>
    </Column>
  )
}
