import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  title: {
    padding: '32px 0'
  },
  container: {
    padding: '16px 0',
    height: '80%'
  }
})
