import { createUseStyles } from 'react-jss'
import { InputProps } from '.'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    borderBottom: ({ isUndo }: InputProps) =>
      isUndo
        ? `1px solid ${theme.colors.blue}`
        : `1px solid ${theme.colors.grey}`,
    '&:hover, &:focus': {
      border: 'none',
      borderBottom: ({ isUndo }: InputProps) =>
        isUndo
          ? `1px solid ${theme.colors.blue}`
          : `1px solid ${theme.colors.grey}`
    }
  },
  focused: {
    borderBottom: ({ isUndo }: InputProps) =>
      isUndo ? '' : `1px solid ${theme.colors.blue}`,
    '&:hover, &:focus': {
      borderBottom: ({ isUndo }: InputProps) =>
        isUndo ? '' : `1px solid ${theme.colors.blue}`
    }
  },
  error: {
    borderBottom: `1px solid ${theme.colors.red}!important`
  },
  floatingLabel: {
    width: 'max-content',
    maxHeight: '18.4px', // equals to input text height
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: ({ isUndo }: InputProps) =>
      isUndo ? theme.colors.blue : theme.colors.darkBlue,
    position: 'absolute',
    transition: '0.2s ease all',
    zIndex: 1,
    pointerEvents: ({ isRegular }: InputProps) => (isRegular ? 'none' : 'auto'),
    '&:hover': {
      color: ({ isRegular }: InputProps) =>
        isRegular ? theme.colors.darkBlue : theme.colors.orange,
      cursor: ({ isRegular }: InputProps) => (isRegular ? 'default' : 'help')
    }
  },
  disabled: {
    color: `${theme.colors.border} !important`
  },
  disabledInput: {
    color: `${theme.colors.inactive} !important`
  },
  labelUp: {
    top: 0
  },
  labelDown: {
    top: 22,
    left: 0,
    right: 14
  },
  fullWidth: {
    width: '100%'
  },
  statusIcon: {},
  input: {
    padding: '8px 16px 8px 0px',
    marginTop: 12,
    outline: 0,
    border: 'none',
    color: theme.colors.text,
    fontSize: theme.fonts.defaultSize,
    borderBottom: 'none',
    '&::placeholder': {
      color: theme.colors.darkBlue
    },
    '&:-internal-autofill-selected, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus,': {
      backgroundColor: `${theme.colors.white}!important`,
      '-webkit-box-shadow': '0 0 0px 1000px white inset',
      color: `${theme.colors.text}`
    },
    '&:focus': {
      '& + span': {
        color: theme.colors.blue
      }
    },
    '&::-ms-reveal': {
      display: 'none'
    }
  },
  iconName: {
    position: 'absolute',
    padding: 0,
    right: 0,
    bottom: 10,
    '& > *': {
      cursor: 'pointer'
    }
  },
  errorContainer: {
    position: 'absolute',
    color: theme.colors.red,
    top: '100%'
  },
  undoIcon: {
    position: 'absolute',
    right: -40,
    bottom: 10,
    cursor: theme.cursor,
    '& > *:not(:first-child)': {
      marginTop: 6
    }
  },
  textIcon: {
    position: 'absolute',
    color: theme.colors.border,
    padding: 0,
    right: 0,
    bottom: 10,
    cursor: theme.cursor,
    '&:hover > *': {
      visibility: 'visible'
    }
  },
  hint: {
    maxWidth: 180,
    padding: '6px 8px',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    position: 'absolute',
    left: '-55px',
    bottom: '130%',
    visibility: 'hidden',
    zIndex: 2
  }
}))
