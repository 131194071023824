import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Column, Icon, Row, Text } from '../..'
import { ComponentTextProps } from '../../text'
import { IconName } from '../../icon'
import { PlotIds } from '../policy-summary-list.types'
import { GHGsEmissionsProps } from './widgets.types'
import { useStyle } from './widgets.styles'

export const GhgsEmissions: FC<GHGsEmissionsProps> = ({
  totalEmissions,
  ghgPlotMtco2,
  navigate,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const ghgsEmissionsLabelProps: ComponentTextProps = {
    tx: 'policy.ghgPlot',
    text: 'GHG Emissions',
    color: 'text',
    preset: 'bodyBold'
  }

  const totalAvoidedEmissionsLabelProps: ComponentTextProps = {
    tx: 'policy.summary.totalAvoidedEmissions',
    text: 'Total Avoided Emissions',
    color: 'border',
    preset: 'caption'
  }

  const totalEmissionsValues = {
    ghgPlotMtco2,
    sub: <Text className={classes.sub} preset="textSetting" text="2" />
  }

  const handleOnGhgsEmissions = () => {
    onClick(PlotIds.GHG_PLOT)
  }

  return (
    <Column fullWidth>
      <Row className={classes.row} fullWidth justifyContent="space-between">
        <Row>
          <Icon name={IconName.FILTER_DRAMA} fill="blue" />
          <Text className={classes.description} {...ghgsEmissionsLabelProps} />
        </Row>
        {navigate && (
          <Icon
            className={classes.breadcrumbs}
            name={IconName.BREAD_CRUMBS}
            fill="darkBlue"
            onClick={handleOnGhgsEmissions}
          />
        )}
      </Row>
      <Row fullWidth className={classes.row} justifyContent="space-between">
        <Text {...totalAvoidedEmissionsLabelProps} />
      </Row>
      <Row className={classes.row} fullWidth justifyContent="space-between">
        <Text text={String(totalEmissions)} color="blue" preset="h1" />
        <Text
          tx="policy.totalEmissions"
          text="{ghgPlotMtco2}/MTCO{sub}e"
          values={totalEmissionsValues}
          preset="h1"
        />
      </Row>
    </Column>
  )
}
