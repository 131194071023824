import {
  CreatePolicyRequestParams,
  GetPoliciesRequestParams,
  PolicyCompareListParams
} from '../../store/actions/policy/policy.types'
import { GeneralApiProblem } from '../api-problem'
import { List } from '../api.types'

export type Policy = {
  id: string
  name: string
  years: number
  startYear: number
  msrpCap: number
  msrpSoftCap: number
  createdAt: string
  updatedAt: string

  region: number
  scenario: number
  regionScenario: number
  newVehGrowth: number
  diffusionCurveScalar: number
  discountRate: number
  consumerDiscountRate: number
  discountCosts: boolean
  electricityDecarbRate: number
  newEvStartingAmt: number
  maxMarketShareValue: number
  usedEvPerc: number
  tieredIncentive: boolean
  minTierRange: number
  minTierInc: number
  stepTierRange: number
  stepTierInc: number
  maxTierInc: number
  minTierKWh: string
  minTierIncKWh: string
  stepTierKWh: string
  stepTierIncKWh: string

  tieredP1Years: number
  p2MinTierKWh: number
  p2MinTierIncKWh: number
  p2StepTierKWh: number
  p2StepTierIncKWh: number
  p2MaxTierInc: number
  tieredP2Years: number
  p3MinTierKWh: number
  p3MinTierIncKWh: number
  p3StepTierKWh: number
  p3StepTierIncKWh: number
  p3MaxTierInc: number

  fplBonusNewBoolean: boolean
  fplBonusNewInc: string
  fplBonusEndYears: string
  fplRate: number

  includeFplCap: boolean
  fplIncentiveCap: number
  usedEvType: number
  usedEvDollar: number

  oemStartingAmt: number
  dealerInc: number
  miAInc: number
  miAProp: number
  saRStartYear: number
  maximumSaRAge: number
  saREndYears: number
  SaRIncentive: number
  zevMandateGoal: number
  zevMandateYear: number
  noncomplianceCost: number
  additionalOemIncentive: number
  oem100Threshold: number
  regionMsrpCap: number
  regionSoftCap: number
  regionNewEvInc: number
  regionUsedEvInc: number
  regionMaxMarketShare: number
  regionFplRate: number
  eviOverbuildRate: number
  startingWl2: number
  workl2Rate: number
  startingPl2: number
  publ2Rate: number
  startingDc: number
  pubdcRate: number
  regionSaRStartYear: number
  regionAgeVehicle: number
  fuelSavings: boolean
  health: boolean
  hovLaneAccess: boolean
  designatedParking: boolean
  freeTolls: boolean
  freeFerries: boolean
  other: boolean
  newEvYears: number
  oemYears: number
  dealerYears: number
  regionNewEvYears: number
  regionUsedEvYears: number
  nonFinancialYears: number
  otherNonfinancialValue: number
  ghgBoolean: number
  vmtGrowth: number
  input2050Mpg: number
  cafeStandardOption: number
  tCO2: number
  totalCostsTotal: number
  evAdoptionShare: number
  finalMarketShare: number
  ghgPlotAvoided: string
  ghgPlotMtco2: string
  // TODO: replace with correct ids from BE
  // no such values, need to be listed for emptieness check up
  coBenefits: boolean
  taxCreditBoolean: boolean
  totalCosts?: string
  ghgPlot?: string
  evAdoption?: string
  evAffordability?: string

  totalCostsUs?: string
  totalCostsRegion?: string
  evAdoptionUs?: string
  evAdoptionRegion?: string

  fleetExcludedProp: number
  fleetEviIncentive: number
  fleetYears: string | number | undefined

  nationalSaRIncentiveFpl: number
  nationalFplSaRNew: boolean
  nationalFplSaRUsed: boolean
  nationalSaRIncentiveNonfpl: number
  nationalNonfplSaRNew: boolean
  nationalNonfplSaRUsed: boolean

  regionFleetSalesPercent: number
  regionFleetExcludedProp: number
  regionFleetEviIncentive: number
  regionDuration: string | number | undefined
  regionExcludeCommercialBoolean: boolean
  regionCommercialEvIncentive: number

  regionZevMandateGoal: number
  regionZevMandateYear: number
  regionNoncomplianceCost: number
  regionAdditionalOemIncentive: number
  regionOem100Threshold: number

  regionSaRIncentiveFpl: number
  regionFplSaRNew: boolean
  regionFplSaRUsed: boolean
  regionSaRIncentiveNonfpl: number
  regionNonfplSaRNew: boolean
  regionNonfplSaRUsed: boolean

  regionEviOverbuildRate: number
  regionStartingWl2: number
  regionWorkl2Rate: number
  regionStartingPl2: number
  regionPubl2Rate: number
  regionStartingDc: number
  regionPubdcRate: number

  lmBoolean: boolean
  lmIncPerc: number
  secondLmIncDollar: number
  secondLmIncYear: number

  y1Lm: number
  y2Lm: number
  y3Lm: number
  y4Lm: number
  y5Lm: number
  y6Lm: number
  lmEndYears: string
  programUsedBoolean: boolean
  cutoffModelYear: number
  programUsedEvIncentive: number
  usedProgramExpansionYear: number
  secondFplUsed: number

  fplBonusNewExpansionBoolean: boolean
  assUsType: number
  assUsReqYear: number

  regionDurationToRemainConstant: number
  regionTieredIncentive: boolean
  regionMinTierRange: number
  regionMinTierInc: number
  regionStepTierRange: number
  regionStepTierInc: number
  regionMaxTierInc: number

  purchaseOrLease: number
  newOrUsed: number
  evTypeBev: boolean
  evTypePhev: boolean
  hhsize: number

  taxCreditNewEvInc: boolean
  taxCreditSoftCap: boolean
  taxCreditDomestic: boolean
  taxCreditUsedEvInc: boolean
  taxCreditLeadingManufacturer: boolean
  taxCreditSar: boolean
  taxCreditRegionFplBonus: boolean

  taxCreditRegionSoftCap: boolean
  taxCreditRegionNewEvInc: boolean
  taxCreditRegionUsedEvInc: boolean
  taxCreditRegionSar: boolean
  taxCreditAssembledInUs: boolean
  taxCreditFplBonus: boolean

  usCapType: number
  incomeCap: number
  incomeType: number
  softCapEndYears: number
  newEvEndYears: number
  usedEvEndYears: number
  domesticContentEndYears: number
  eviScenario: number
  eviIncentiveYears: number
  fleetEviBoolean: boolean
  SaREndYears: number
  icevBanBoolean: boolean
  icevBanYear: number
  regionIncomeCap: number
  regionIncCapSoftCap: number
  regionSoftCapEndYears: number
  regionNewEvEndYears: number
  regionUsedEvEndYears: number
  regionSaREndYears: number

  addGraphString: number
  sccCalc: number
  userSccInput: number
  userSccRate: number
  fleetAgeYear: string
  benefitsCarbon: boolean
  benefitsFuel: boolean
  benefitsHealth: boolean
  benefitsOm: boolean

  totalCostsPhase1: string
  totalCostsPhase2: string
  totalCostsPhase1Region: string
  totalCostsPhase2Region: string
  totalCostsTotalRegion: string

  additionalGraph?: string

  kwhConsumption?: string
  eviInstalled?: string
  eviCostByType?: string
  cobenefits?: string
  jobsCreated?: string
  fleetShare?: string
  evAdoptionYear: number

  bonusFplDiffusion?: string
  fleetAge?: string
  roiOverTime?: string
  tieredIncentiveType: number

  tieredMsrpCap: number
  tieredMsrpSoftCap: string

  regionCapType: number
  regionTieredMsrpCap: number
  regionTieredMsrpSoftCap: string
  fleetAgeYearAll: boolean

  fplAndNewVehicles?: string
  fplParticipation?: string
  usedPlusNew?: string
  zevMandate?: string
  totalCostsPng?: string
  ghgPlotPng?: string
  evAdoptionPng?: string
  evAffordabilityPng?: string
  kwhConsumptionPng?: string
  eviInstalledPng?: string
  eviCostByTypePng?: string
  cobenefitsPng?: string
  jobsCreatedPng?: string
  fleetSharePng?: string
  fleetAgePng?: string
  roiOverTimePng?: string
  bonusFplDiffusionPng?: string
  fplAndNewVehiclesPng?: string
  fplParticipationPng?: string
  usedPlusNewPng?: string
  zevMandatePng?: string
  ghgComparison?: string
  ghgComparisonPng?: string
  gasolineConsumption?: string
  gasolineConsumptionPng?: string
  ghgReductions?: string
  ghgReductionsPng?: string

  pdfReport?: string

  fleetNewList: boolean
  includeEv: boolean
  includeIcev: boolean
  includeFleet: boolean
  includeNew: boolean
  fplParticipationType: number
  fplAnnualCumulative: number

  regionSalesTaxBoolean: boolean
  regionSalesTaxEndYears: string
  regionTieredIncentiveType: number
  regionMinTierKWh: string
  regionMinTierIncKWh: string
  regionStepTierKWh: string
  regionStepTierIncKWh: string
  regionFplBonusNewBoolean: boolean
  regionFplBonusNewInc: string
  regionFplBonusEndYears: string

  assUsBoolean: boolean
  assUsInc: string
  y1AssUs: string
  y2AssUs: string
  y3AssUs: string
  y4AssUs: string
  y5AssUs: string
  y6AssUs: string
  assUsEndYears: string

  lmBonusType: number
  lmIncDollar: string
  usePriceElasticity: boolean
  declineWtp: boolean
  avgUsedEvCost: string

  excludeEvCafe: boolean
  phevLastYear: string
  overrideRetirement: boolean
  fleetGrowthRate: boolean
  batteryImprovementRate: string
  firstYearDiscount: string
  taxCreditTieredIncentiveP2: boolean
  taxCreditTieredIncentiveP3: boolean
  singleCap: string
  hoHCap: string
  jointCap: string
  magiSoftCap: string
  softCapStartYear: string

  usMsrpCapType: number
  usSoftCap: number
  usIncomeCapType: number
  magiPhaseOption: number
  magiPhase1Years: number
  singleCapPhase2: number
  hoHCapPhase2: number
  jointCapPhase2: number
  magiPhase2Years: number
  singleCapPhase3: number
  hoHCapPhase3: number
  jointCapPhase3: number

  applyCapsToUsed: boolean
  magiCapUsedOption: number
  bonusUsedBatteryIncentive: string
  batteryDegradationRate: string
  excludeNondealersUsed: boolean
  percentExcludedUsed: string
  excludeSecondTimeSales: boolean
  secondSalesType: number
  fiftyPercYear: string
  assUsApplyUsed: boolean
  newEvStartYear: string
  fplBonusStartYear: string
  usedEvStartYear: string
  minUsedEvAge: string
  lmStartYear: string
  assUsStartYear: string
  oemStartYear: string
  oemEndYears: string
  domesticContentStartYear: string
  includeEviCosts: boolean
  fleetSalesPercent: string
  commercialFleetBoolean: boolean
  commercialFleetStartYear: string
  commercialFleetEndYear: string
  commercialFleetIncPercent: string
  excludeLeaseMsrp: string
  regionMsrpCapNone: boolean
  regionIncomeCapNone: boolean
  regionTieredMsrpCapNone: boolean
  regionSoftCapStartYear: string
  regionNewEvStartYear: string
  regionFplBonusStartYear: string
  regionUsedEvStartYear: string

  regionMsrpCapType: number
  regionTieredMsrpSoftCapBoolean: boolean
  regionMinTierRangeSoftCap: number
  regionMinTierIncSoftCap: number
  regionStepTierRangeSoftCap: number
  regionStepTierIncSoftCap: number
  regionMaxTierIncSoftCap: number
  regionIncomeCapType: number
  regionMagiPhaseOption: number
  regionSingleCap: number
  regionHoHCap: number
  regionJointCap: number
  regionMagiPhase1Years: number
  regionSingleCapPhase2: number
  regionHoHCapPhase2: number
  regionJointCapPhase2: number
  regionMagiPhase2Years: number
  regionSingleCapPhase3: number
  regionHoHCapPhase3: number
  regionJointCapPhase3: number

  regionSalesTaxIncludeBev: boolean
  regionSalesTaxIncludePhev: boolean
  regionSalesTaxIncludeNew: boolean
  regionSalesTaxIncludeUsed: boolean
  njFilterEligibilityBoolean: boolean
}

export interface PolicyPlotsPng {
  totalCostsPng?: string
  ghgPlotPng?: string
  evAdoptionPng?: string
  evAffordabilityPng?: string
  kwhConsumptionPng?: string
  eviInstalledPng?: string
  eviCostByTypePng?: string
  cobenefitsPng?: string
  jobsCreatedPng?: string
  fleetSharePng?: string
  fleetAgePng?: string
  roiOverTimePng?: string
  bonusFplDiffusionPng?: string
  fplAndNewVehiclesPng?: string
  fplParticipationPng?: string
  usedPlusNewPng?: string
  zevMandatePng?: string
}

export type PolicyPlotsPngKeys = keyof PolicyPlotsPng

export interface ExpandedSettings extends Policy {
  reset?: boolean
}

export type AffordabilitySettings = {
  purchaseOrLease: number
  newOrUsed: number
  evTypeBev: boolean
  evTypePhev: boolean
  hhsize: number
}

export interface Affordability extends AffordabilitySettings {
  policy: string
  updatePng?: boolean
}

export type AffordabilityPlot = {
  id: string
  data: string
  png: string
}

export type AffordabilityPayload = AffordabilityPlot & AffordabilitySettings

export type AdditionalGraph = {
  policy: string
  graph: number
}

export type AdditionalGraphPlot = {
  id: string
  graph: string
}

export type PolicyKeys = keyof Policy
export type PolicyEntries = [string, any]

export type CatalogItem = [number, string]
export type CatalogList = Array<CatalogItem>

export type CatalogProperties = {
  additionalGraph: CatalogList
  cafeStandardOption: CatalogList
  usCapType: CatalogList
  eviScenario: CatalogList
  ghgBoolean: CatalogList
  incomeCap: CatalogList
  msrpcap: CatalogList
  newOrUsed: CatalogList
  purchaseOrLease: CatalogList
  region: CatalogList
  sccCalc: CatalogList
  scenario: CatalogList
  regionsWithSalesTax: CatalogList
}

export type RegionProperties = {
  abbreviation: string
  name: string
  value: number
}

export type InputRule = {
  min: number
  max: number
  step?: number
  default: number
  required: boolean
  title: string
  description?: string
}

export type RegionsProperties = RegionProperties[]

export type Restrictions = {
  [K in PolicyKeys]: InputRule
}

export enum PlotNames {
  TOTAL_COSTS = 'total_costs',
  GHG_PLOT = 'ghg_plot',
  EV_ADOPTION = 'ev_adoption',
  EV_AFFORDABILITY = 'ev_affordability'
}

export type PolicyPlot = {
  id: string
  name: string
}

export type FullPolicyBE = {}

export type ResetParams = {
  reset?: boolean
}
export type GetPoliciesResponse = List<Policy>

export type GetComparePoliciesByIdsResponse = Policy[]

export type Report = {
  pdfReport: string
}

export type CreatePolicyResponse = Policy
export type UpdatePolicyResponse = Policy
export type GetPolicyResponse = Policy
export type GetPolicyReportResponse = Report
export type GetDefaultPolicyResponse = Policy
export type GetPolicyPlotResponse = string
export type GetCatalogResponse = CatalogProperties
export type GetRegionsResponse = RegionsProperties
export type GetBauDefaultsResponse = Partial<Policy>
export type UpdateAffordabilityResponse = AffordabilityPlot
export type UpdateAdditionalGraphResponse = AdditionalGraphPlot
export type GetRestrictionsResponse = Restrictions
export type UpdateFullPolicyBEPasswordResponse = FullPolicyBE

export type CreatePolicyResults =
  | {
      kind?: string
      policy: CreatePolicyResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type UpdatePolicyResults =
  | {
      kind?: string
      policy: UpdatePolicyResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type GetPolicyResults =
  | {
      kind?: string
      policy: GetPolicyResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type GetPolicyReportResults =
  | {
      kind?: string
      pdfReport: string
      reset?: boolean
    }
  | GeneralApiProblem

export type GetDefaultPolicyResults =
  | {
      kind?: string
      policy: GetDefaultPolicyResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type GetComparePoliciesByIdsResults =
  | {
      kind?: string
      policies: GetComparePoliciesByIdsResponse
    }
  | GeneralApiProblem

export type GetPoliciesResults =
  | {
      kind?: string
      policies: GetPoliciesResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type DeletePolicyResults =
  | {
      kind?: string
      id: string
      reset?: boolean
    }
  | GeneralApiProblem

export type GetPolicyPlotResults =
  | {
      kind?: string
      plot: GetPolicyPlotResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type GetCatalogResults =
  | {
      kind?: string
      catalog: GetCatalogResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type GetRegionsResults =
  | {
      kind?: string
      regions: GetRegionsResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type GetBauDefaultsResults =
  | {
      kind?: string
      bauDefaults: GetBauDefaultsResponse
    }
  | GeneralApiProblem

export type UpdateAffordabilityResults =
  | {
      kind?: string
      affordability: AffordabilityPayload
    }
  | GeneralApiProblem

export type UpdateAdditionalGraphResults =
  | {
      kind?: string
      additionalGraph: UpdateAdditionalGraphResponse
    }
  | GeneralApiProblem

export type GetRestrictionsResults =
  | {
      kind?: string
      restrictions: GetRestrictionsResponse
    }
  | GeneralApiProblem

export type PolicyApi = {
  getPolicies: (params: GetPoliciesRequestParams) => Promise<GetPoliciesResults>
  getPoliciesByPages: (
    params: GetPoliciesRequestParams
  ) => Promise<GetPoliciesResults>
  createPolicy: (
    policy: CreatePolicyRequestParams
  ) => Promise<CreatePolicyResults>
  getPolicyReport: (id: string) => Promise<GetPolicyReportResults>
  getPolicy: (id: string) => Promise<GetPolicyResults>
  getDefaultPolicy: () => Promise<GetDefaultPolicyResults>
  updatePolicy: (
    policy: Policy,
    reset?: boolean
  ) => Promise<UpdatePolicyResults>
  deletePolicy: (id: string) => Promise<DeletePolicyResults>
  getPolicyPlot: (plot: PolicyPlot) => Promise<GetPolicyPlotResults>
  getCatalog: () => Promise<GetCatalogResults>
  getRegions: () => Promise<GetRegionsResults>
  getBauDefaults: () => Promise<GetBauDefaultsResults>
  updateAffordability: (
    data: Affordability
  ) => Promise<UpdateAffordabilityResults>
  updateAdditionalGraph: (
    data: AdditionalGraph
  ) => Promise<UpdateAdditionalGraphResults>
  getRestrictions: () => Promise<GetRestrictionsResults>
  getComparePoliciesByIds: (
    data: PolicyCompareListParams
  ) => Promise<GetComparePoliciesByIdsResults>
}
