import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderLeft: `1px solid ${theme.colors.grey}`,
    minWidth: 371,
    marginTop: 16,
    padding: '0 16px',
    minHeight: 588,
    marginLeft: 16
  },
  title: {
    marginLeft: 8
  },
  back: {
    paddingRight: 32
  },
  nextTitle: {
    marginTop: 32,
    marginLeft: 16
  },
  itemsContainer: {
    marginTop: 12
  },
  item: {
    marginTop: 8
  },
  icon: {
    cursor: theme.cursor
  }
}))
