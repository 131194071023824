import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { CheckboxProps } from './checkbox.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  value: {
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    verticalAlign: 'center'
  },
  undoIcon: {
    position: 'absolute',
    right: -40,
    bottom: 0,
    cursor: theme.cursor,
    '& > *:not(:first-child)': {
      marginTop: 6
    }
  },
  text: {
    marginLeft: 16,
    '&:hover': {
      color: ({ description }: CheckboxProps) =>
        !description ? theme.colors.text : theme.colors.orange,
      cursor: ({ description }: CheckboxProps) =>
        !description ? 'default' : 'help'
    }
  },
  disabled: {
    color: `${theme.colors.border} !important`
  },
  textIcon: {
    position: 'absolute',
    color: theme.colors.border,
    padding: 0,
    right: -70,
    bottom: -4,
    cursor: theme.cursor,
    '&:hover > *': {
      visibility: 'visible'
    }
  },
  hint: {
    maxWidth: 190,
    padding: '6px 8px',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    position: 'absolute',
    left: '-51px',
    bottom: '130%',
    visibility: 'hidden',
    zIndex: 2
  },
  errorContainer: {
    position: 'absolute',
    color: theme.colors.red,
    top: '100%'
  }
}))
