import { ComponentTextProps } from '..'
import {
  getColorBorder,
  getColorDarkBlue,
  getColorInactive,
  getColorText
} from './helpers'

export const forecastedMPGLabelProps = getColorDarkBlue({
  tx: 'policy.forecastedMPG',
  text: 'Forecasted MPG'
})

export const mpgLabelProps = getColorDarkBlue({
  tx: 'policy.mpgby2050',
  text: 'mpg by 2050'
})

export const growthRateLabelProps = getColorDarkBlue({
  tx: 'policy.vmtGrowthRate',
  text: 'VMT Growth Rate'
})

export const fleetSizeLabelProps = getColorDarkBlue({
  tx: 'policy.forecastedFleetSize',
  text: 'Forecasted Change in Fleet Size'
})

export const percentIconProps = getColorBorder({
  text: '%',
  tx: 'policy.percent'
})

export const avoidedGHGClassLabelProps = getColorDarkBlue({
  tx: 'policy.avoidedGHGClass',
  text: 'Avoided GHG Calculation'
})

export const decarbonizationRateLabelProps = getColorDarkBlue({
  tx: 'policy.electricityDecarbonizationRate',
  text: 'Electricity Decarbonization Rate'
})

export const coBenefitsIncludedLabelProps = getColorDarkBlue({
  tx: 'policy.coBenefitsIncluded',
  text: 'Co-benefits Included in $/MTCO2 Calculation'
})

export const coBenefitsLabelProps = getColorDarkBlue({
  tx: 'policy.coBenefits',
  text: 'Co-benefits'
})

export const adoptionRateLabelProps = getColorDarkBlue({
  tx: 'policy.evAdoptionRate',
  text: 'EV Adoption Rate Scale Factor'
})

export const discountRateLabelProps = getColorDarkBlue({
  tx: 'policy.discountRate',
  text: 'Discount Rate'
})

export const coBenefitsCalculationLabelProps = getColorDarkBlue({
  tx: 'policy.coBenefitsIncluded',
  text: 'Co-benefits Included in $/MTCO2 Calculation'
})

export const incentivesLabelProps = getColorText({
  tx: 'policy.incentivesFutureCredits',
  text: 'Incentives are future credits'
})

export const consumerDiscountRateLabelProps = getColorText({
  tx: 'policy.consumerDiscountRate',
  text: 'Future credit discount rate'
})

export const costsLabelProps = getColorDarkBlue({
  tx: 'policy.costs',
  text: 'Costs'
})

export const regionLabelProps = getColorDarkBlue({
  text: 'Region',
  tx: 'policy.region'
})

export const coBenefitsRollOverTimeProps: ComponentTextProps = {
  text: 'Co-benefits and Return on Investment',
  tx: 'policy.coBenefitsRollOverTime'
}

export const generalInputBasicProps: ComponentTextProps = {
  text: 'General Inputs (Basic)',
  tx: 'policy.generalInputs.basic'
}

export const generalInputsAdvancedProps: ComponentTextProps = {
  text: 'General Inputs (Advanced)',
  tx: 'policy.generalInputs.advanced'
}

export const vehicleFleetCharactProps: ComponentTextProps = {
  text: 'Vehicle Fleet Characteristics',
  tx: 'policy.vehicleFleetCharacteristics'
}

export const ghgReductionCharacteristicsProps: ComponentTextProps = {
  text: 'GHG Reduction Characteristics',
  tx: 'policy.ghgReductionCharacteristics'
}

export const modelCharacteristicsProps: ComponentTextProps = {
  text: 'Model Characteristics',
  tx: 'policy.modelCharacteristics'
}

export const additionalGraphParametersProps: ComponentTextProps = {
  text: 'Additional Graph Parameters',
  tx: 'policy.additionalGraphParameters'
}

export const fleetAgeGraphProps: ComponentTextProps = {
  text: 'Fleet Age Graph',
  tx: 'policy.fleetAgeGraph'
}

export const fleetNewVehiclesGraphProps: ComponentTextProps = {
  text: 'Fleet + New Vehicles Graph',
  tx: 'policy.fleetNewVehiclesGraph'
}

export const evIncentivesProps: ComponentTextProps = {
  text: 'EV Incentives',
  tx: 'policy.evIncentives'
}

export const nationalInputsProps: ComponentTextProps = {
  text: 'National Inputs',
  tx: 'policy.nationalInputs'
}

export const fplParticipationProps: ComponentTextProps = {
  text: 'FPL Participation',
  tx: 'policy.fplParticipation'
}

export const newEVIncentiveProps: ComponentTextProps = {
  text: 'New EV Incentive',
  tx: 'policy.newEVIncentive'
}

export const usedEVIncentiveProps: ComponentTextProps = {
  text: 'Used EV Incentive',
  tx: 'policy.usedEVIncentive'
}

export const leadingManufacturersIncProps: ComponentTextProps = {
  text: 'Labor & Assembly Incentive',
  tx: 'policy.leadingManufacturersInc'
}

export const oemIncentiveProps: ComponentTextProps = {
  text: 'OEM Incentive',
  tx: 'policy.oemIncentive'
}

export const dealerIncentiveProps: ComponentTextProps = {
  text: 'Dealer Incentive',
  tx: 'policy.dealerIncentive'
}

export const madeInAmericaIncProps: ComponentTextProps = {
  text: 'Domestic Content Incentive',
  tx: 'policy.madeInAmericaInc'
}

export const eviIncentivesProps: ComponentTextProps = {
  text: 'EVI Incentives',
  tx: 'policy.eviIncentives'
}

export const commercialFleetIncentiveProps: ComponentTextProps = {
  text: 'Commercial Fleet Incentive',
  tx: 'policy.commercialFleetIncentive'
}

export const regionExcludeCommercialBooleanProps: ComponentTextProps = {
  text: 'Exclude Commercial EVs from State Incentive',
  tx: 'policy.regionExcludeCommercialBoolean'
}

export const regionCommercialEvIncentiveProps: ComponentTextProps = {
  text: 'State Commercial EV Incentive',
  tx: 'policy.regionCommercialEvIncentive'
}

export const scrapReplaceProgramProps: ComponentTextProps = {
  text: 'Scrap & Replace Program',
  tx: 'policy.scrapReplaceProgram'
}

export const scrapReplaceLabelProps: ComponentTextProps = {
  text: 'Scrap & Replace',
  tx: 'policy.scrapReplace'
}

export const overviewProps: ComponentTextProps = {
  text: 'Overview',
  tx: 'policy.overview'
}

export const regulatoryPoliciesProps: ComponentTextProps = {
  text: 'Regulatory Policies',
  tx: 'policy.regulatoryPolicies'
}

export const regionalInputsProps: ComponentTextProps = {
  text: 'Regional Inputs',
  tx: 'policy.regionalInputs'
}

export const nonFinancialIncentivesProps: ComponentTextProps = {
  text: 'Non-Financial Incentives',
  tx: 'policy.nonFinancialIncentives'
}

export const durationAndEmissionsLabelProps: ComponentTextProps = {
  tx: 'policy.durationAndEmissions',
  text: 'Policy Duration & Emissions'
}

export const simulationYearsLabelProps = getColorDarkBlue({
  text: 'Simulation Years',
  tx: 'policy.simulationYears'
})

export const salesToFleetsLabelProps = getColorDarkBlue({
  tx: 'policy.evSalesToFleets',
  text: 'EV Sales to Fleets'
})

export const vehiclesExcludedLabelProps = getColorDarkBlue({
  tx: 'policy.fleetVehiclesExcluded',
  text: 'Fleet Vehicles Excluded from EVI Incentive'
})

export const eviIncentiveLabelProps = getColorDarkBlue({
  tx: 'policy.fleetEVIIncentive',
  text: 'Fleet EVI Incentive (per EV)'
})

export const durationLabelProps = getColorDarkBlue({
  tx: 'policy.duration',
  text: 'Duration'
})

export const yearsIconProps = getColorBorder({
  text: 'years',
  tx: 'policy.years'
})

export const yearIconProps = getColorBorder({
  text: 'year',
  tx: 'policy.year'
})

export const dollarPerYearIconProps = getColorBorder({
  text: '$ per year',
  tx: 'policy.dollarPerYear'
})

export const mpgIconProps = getColorBorder({
  text: 'mpg',
  tx: 'policy.mpg'
})

export const hhsizeLabelProps = getColorText({
  tx: 'policy.hhsize',
  text: 'Household Size',
  preset: 'h2'
})

export const financeTypeLabelProps = getColorText({
  tx: 'policy.financeType',
  text: 'Finance Type',
  preset: 'h2'
})

export const vehicleTypeLabelProps = getColorText({
  tx: 'policy.vehicleType',
  text: 'Vehicle Type',
  preset: 'h2'
})

export const evTypeBevLabelProps = getColorText({
  tx: 'policy.evTypeBev',
  text: 'BEV',
  preset: 'subTitleSetting'
})

export const evTypePhevLabelProps = getColorText({
  tx: 'policy.evTypePhev',
  text: 'PHEV',
  preset: 'subTitleSetting'
})

export const newOrUsedLabelProps = getColorText({
  tx: 'policy.newOrUsed',
  text: 'New or Used',
  preset: 'h2'
})

export const incentiveValueLabelProps = getColorDarkBlue({
  tx: 'policy.incentiveValue',
  text: 'Incentive Value'
})

export const durationToRemainLabelProps = getColorDarkBlue({
  tx: 'policy.durationToRemainConstant',
  text: 'Duration to remain constant'
})

export const declineRateLabelProps = getColorDarkBlue({
  tx: 'policy.incentiveDeclineRate',
  text: 'Incentive Decline Rate'
})

export const maxShareLabelProps = getColorDarkBlue({
  tx: 'policy.maxShare',
  text: 'max share'
})

export const tieredIncentiveLabelProps = getColorText({
  tx: 'policy.useTieredIncentive',
  text: 'Use Tiered Incentive?'
})

export const minimumRangeLabelProps = getColorDarkBlue({
  tx: 'policy.minimumRangeToQualify',
  text: 'Minimum Range to Qualify'
})

export const minimumIncentiveLabelProps = getColorDarkBlue({
  tx: 'policy.minimumIncentive',
  text: 'Minimum Incentive'
})

export const rangeIncrementLabelProps = getColorDarkBlue({
  tx: 'policy.rangeIncrement',
  text: 'Range Increment'
})

export const incentiveIncrementLabelProps = getColorDarkBlue({
  tx: 'policy.incentiveIncrement',
  text: 'Incentive Increment'
})

export const maximumIncentiveLabelProps = getColorDarkBlue({
  tx: 'policy.maximumIncentive',
  text: 'Maximum Incentive'
})

export const incentiveValueNewEVLabelProps = getColorDarkBlue({
  tx: 'policy.incentiveValueOfNewEVIncentive',
  text: 'Incentive Value (of New EV Incentive)'
})

export const federalPovertyLabelProps = getColorDarkBlue({
  tx: 'policy.federalPovertyLevel',
  text: 'Federal Poverty Level Eligibility Threshold'
})

export const manufacturersIncentiveLabelProps = getColorText({
  tx: 'policy.leadingManufacturersIncentive',
  text: 'Labor & Assembly Incentive?'
})

export const bonusIncentiveLabelProps = getColorDarkBlue({
  tx: 'policy.bonusIncentive',
  text: 'Incentive Amount (of New EV Incentive)'
})

export const qualifiedVehiclesLabelProps = getColorDarkBlue({
  tx: 'policy.qualifiedVehicles',
  text: 'Qualified Percentage of Vehicles:'
})

export const firstYearLabelProps = getColorDarkBlue({
  tx: 'policy.year1',
  text: 'Year 1'
})

export const secondYearLabelProps = getColorDarkBlue({
  tx: 'policy.year2',
  text: 'Year 2'
})

export const thirdYearLabelProps = getColorDarkBlue({
  tx: 'policy.year3',
  text: 'Year 3'
})

export const fourthYearLabelProps = getColorDarkBlue({
  tx: 'policy.year4',
  text: 'Year 4'
})

export const fifthYearLabelProps = getColorDarkBlue({
  tx: 'policy.year5',
  text: 'Year 5'
})

export const sixthYearLabelProps = getColorDarkBlue({
  tx: 'policy.year6',
  text: 'Year 6+'
})

export const leadingProgramMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.leadingProgramMaxDuration',
  text: 'Labor & Assembly Program Max Duration'
})

export const splitProgramLabelProps = getColorDarkBlue({
  tx: 'policy.modelYearSplitProgram',
  text: 'Model Year Split Program for Used EVs?'
})

export const useConsumerValuationLabelProps = getColorDarkBlue({
  tx: 'policy.useConsumerValuation',
  text: 'Use Consumer Valuation for Used EV?'
})

export const declineWTPLabelProps = getColorDarkBlue({
  tx: 'policy.declineWTP',
  text: 'Decline WTP?'
})

export const avgUsedEvCostLabelProps = getColorDarkBlue({
  tx: 'policy.avgUsedEvCost',
  text: 'Average Used EV Cost'
})

export const exemptEvsFromSalesTaxLabelProps = getColorDarkBlue({
  tx: 'policy.exemptEvsFromSalesTax',
  text: 'Exempt EVs from Sales Tax?'
})

export const lastingForLabelProps = getColorDarkBlue({
  tx: 'policy.lastingFor',
  text: 'Lasting for'
})

export const leadingManufacturersLabelProps = getColorDarkBlue({
  tx: 'policy.leadingManufacturersRequired',
  text: 'Labor & Assembly Required for Eligibility'
})

export const laborAssemblyLabelProps = getColorDarkBlue({
  tx: 'policy.laborAssembly',
  text: 'Labor & Assembly'
})

export const eligibleUsedEVsLabelProps = getColorDarkBlue({
  tx: 'policy.eligibleUsed',
  text: 'Eligible Used EVs Receive'
})

export const eligibilityExpandsLabelProps = getColorDarkBlue({
  tx: 'policy.eligibilityExpands',
  text: 'Year in which FPL Eligibility Expands'
})

export const expandedEligibilityLabelProps = getColorDarkBlue({
  tx: 'policy.expandedEligibility',
  text: 'Expanded FPL Eligibility'
})

export const vehiclesEligibleLabelProps = getColorDarkBlue({
  tx: 'policy.vehiclesEligible',
  text: 'Percentage of Vehicles Eligible'
})

export const dollarIconProps = getColorBorder({
  text: '$',
  tx: 'policy.dollar'
})

export const milesIconProps = getColorBorder({
  text: 'miles',
  tx: 'policy.miles'
})

export const kwhIconProps = getColorBorder({
  text: 'kWh',
  tx: 'policy.kwh'
})

export const overBuildRateLabelProps = getColorDarkBlue({
  tx: 'policy.overBuildRate',
  text: 'Over-build Rate'
})

export const policyStartYearLabelProps = getColorDarkBlue({
  tx: 'policy.policyStartYear',
  text: 'Policy Start Year'
})

export const incentiveLabelProps = getColorDarkBlue({
  tx: 'policy.incentive',
  text: 'Incentive'
})

export const rateLabelProps = getColorDarkBlue({
  tx: 'policy.rate1Per',
  text: 'Rate: 1 per'
})

export const evsIconProps = getColorBorder({
  text: 'EVs',
  tx: 'policy.EVs'
})

export const workLevelProps: ComponentTextProps = {
  tx: 'policy.workLevel2',
  text: 'Work Level 2'
}

export const publicLevelProps: ComponentTextProps = {
  tx: 'policy.publicLevel2',
  text: 'Public Level 2'
}

export const publicDCFCProps: ComponentTextProps = {
  tx: 'policy.publicDCFC',
  text: 'Public DCFC'
}

export const IncentiveCapLabelProps: ComponentTextProps = {
  tx: 'policy.incentiveCap',
  text: 'Incentive Cap'
}

export const msrpLabelProps = getColorDarkBlue({
  tx: 'policy.msrpCap',
  text: 'MSRP Cap'
})

export const softCapIncentiveLabelProps = getColorDarkBlue({
  tx: 'policy.softCapIncentive',
  text: 'Soft Cap Incentive'
})

export const zevGoalEVSalesLabelProps = getColorDarkBlue({
  tx: 'policy.zevGoal',
  text: 'ZEV Goal (EV Sales)'
})

export const zevGoalYearLabelProps = getColorDarkBlue({
  tx: 'policy.zevGoalYear',
  text: 'ZEV Goal Year'
})

export const nonComplianceFeeLabelProps = getColorDarkBlue({
  tx: 'policy.nonComplianceFee',
  text: 'Non-Compliance Fee'
})

export const voluntaryZevPriceCutLabelProps = getColorDarkBlue({
  tx: 'policy.voluntaryOEM',
  text: 'Voluntary OEM ZEV Price Cut'
})

export const oemEffectiveThresholdLabelProps = getColorDarkBlue({
  tx: 'policy.oemEffective',
  text: 'OEM Effective 100% Threshold'
})

export const zevMandateProps: ComponentTextProps = {
  tx: 'policy.zevMandate',
  text: 'ZEV Mandate'
}

export const startYearLabelProps = getColorDarkBlue({
  tx: 'policy.startYear',
  text: 'Start Year'
})

export const maximumAgeOfVehicleLabelProps = getColorDarkBlue({
  tx: 'policy.maximumAgeOfVehicle',
  text: 'Maximum Age of Vehicle'
})

export const srMxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.srMaxDuration',
  text: 'S&R Maximum Duration'
})

export const regionSrMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.regionSarMaxDuration',
  text: 'Region S&R Maximum Duration'
})

export const newEvDurationLabelProps = getColorDarkBlue({
  tx: 'policy.newEvMaxDuration',
  text: 'New EV Maximum Duration'
})

export const usedProps = getColorInactive({
  tx: 'policy.used',
  text: 'Used',
  preset: 'body'
})

export const newProps = getColorInactive({
  tx: 'policy.new',
  text: 'New',
  preset: 'body'
})

export const fleetOrNewProps = getColorInactive({
  tx: 'policy.fleetOrNew',
  text: 'Fleet and/or New?',
  preset: 'caption'
})

export const vehicleProps = getColorInactive({
  tx: 'policy.vehicle',
  text: 'Vehicle Type:',
  preset: 'caption'
})

export const fleetProps = getColorInactive({
  tx: 'policy.fleet',
  text: 'Fleet',
  preset: 'body'
})

export const fplPartMetricProps = getColorInactive({
  tx: 'policy.fplPartMetric',
  text: 'FPL Participation Metric:',
  preset: 'body'
})

export const vehiclesProps = getColorInactive({
  tx: 'policy.vehicles',
  text: 'Vehicles',
  preset: 'body'
})

export const dollarsProps: ComponentTextProps = {
  tx: 'policy.dollars',
  text: 'Dollars'
}

export const excludeProps: ComponentTextProps = {
  tx: 'policy.exclude',
  text: 'Exclude'
}

export const includeProps: ComponentTextProps = {
  tx: 'policy.include',
  text: 'Include'
}

export const evProps = getColorInactive({
  tx: 'policy.ev',
  text: 'EV',
  preset: 'body'
})

export const icevProps = getColorInactive({
  tx: 'policy.icev',
  text: 'ICEV',
  preset: 'body'
})

export const purchaseProps = getColorText({
  tx: 'policy.purchase',
  text: 'Purchase',
  preset: 'subTitleSetting'
})

export const leaseProps = getColorText({
  tx: 'policy.lease',
  text: 'Lease',
  preset: 'subTitleSetting'
})

export const fuelSavingsProps: ComponentTextProps = {
  tx: 'policy.fuelSavings',
  text: 'Fuel Savings',
  color: 'inactive'
}

export const healthSavingsProps: ComponentTextProps = {
  tx: 'policy.healthSavings',
  text: 'Health Savings',
  color: 'inactive'
}

export const omSavingsProps: ComponentTextProps = {
  tx: 'policy.omSavings',
  text: 'O&M Savings (fuel excluded)',
  color: 'inactive'
}

export const flpProps: ComponentTextProps = {
  tx: 'policy.fpl',
  text: 'FPL'
}

export const nonFLPProps: ComponentTextProps = {
  tx: 'policy.nonFpl',
  text: 'Non-FPL'
}

export const otherIncentiveValueLabelProps = getColorDarkBlue({
  tx: 'policy.otherIncentiveValue',
  text: 'Other Incentive Value'
})

export const additionalGraphLabelProps = getColorDarkBlue({
  tx: 'policy.additionalGraph',
  text: 'Additional Graph'
})

export const sccCalcLabelProps = getColorDarkBlue({
  tx: 'policy.sccCalc',
  text: 'Social Cost of Carbon Calculation'
})

export const scc20LabelProps = getColorDarkBlue({
  tx: 'policy.scc20',
  text: '2021 SCC'
})

export const sccIncrRateLabelProps = getColorDarkBlue({
  tx: 'policy.sccIncrRate',
  text: 'Annual SCC increase rate'
})

export const newEvMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.newEvMaxDuration',
  text: 'New EV Maximum Duration'
})

export const regionNewEvMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.regionNewEvMaxDuration',
  text: 'Region New EV Maximum Duration'
})

export const usedMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.usedMaxDuration',
  text: 'Used EV Maximum Duration'
})

export const regionUsedMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.regionUsedEvMaxDuration',
  text: 'Region Used EV Maximum Duration'
})

export const icevBanLabelProps = getColorDarkBlue({
  tx: 'policy.icevBan',
  text: 'ICEV ban?'
})

export const icevBanYearLabelProps = getColorDarkBlue({
  tx: 'policy.icevBanStartYear',
  text: 'ICEV ban start year'
})

export const eviIncDecRateLabelProps = getColorDarkBlue({
  tx: 'policy.eviIncDecRate',
  text: 'EVI incentive decline rate'
})

export const eviIncDurationLabelProps = getColorDarkBlue({
  tx: 'policy.eviIncDuration',
  text: 'EVI Incentive duration'
})

export const domesticMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.domesticMaxDuration',
  text: 'Domestic Content Maximum Duration'
})

export const fleetEviIncLabelProps = getColorDarkBlue({
  tx: 'policy.fleetEviInc',
  text: 'Fleet EVI Incentive?'
})

export const msrpOrIncomeLabelProps = getColorDarkBlue({
  tx: 'policy.msrpOrIncome',
  text: 'Cap Type'
})

export const incomeCapLabelProps = getColorDarkBlue({
  tx: 'policy.incomeCap',
  text: 'Income Cap'
})

export const regionIncomeCapLabelProps = getColorDarkBlue({
  tx: 'policy.regionIncomeCap',
  text: 'Region Income Cap'
})

export const incomeSoftCapLabelProps = getColorDarkBlue({
  tx: 'policy.incomeSoftCap',
  text: 'Income Soft Cap'
})

export const regionIncomeSoftCapLabelProps = getColorDarkBlue({
  tx: 'policy.regionIncomeSoftCap',
  text: 'Region Income Soft Cap'
})

export const softMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.softMaxDuration',
  text: 'Soft Cap Maximum Duration'
})

export const regionSoftMaxDurationLabelProps = getColorDarkBlue({
  tx: 'policy.softMaxDuration',
  text: 'Region Soft Cap Maximum Duration'
})

export const aditionalGraphTypeLabelProps = getColorDarkBlue({
  tx: 'policy.aditionalGraphType',
  text: 'Aditional Graph Type'
})

export const householdSizeLabelProps = getColorText({
  tx: 'policy.householdSize',
  text: 'Household Size',
  preset: 'h2'
})

export const setToBauSubtitleLabelProps = getColorText({
  text:
    "At pressing 'Set to BAU' button the following \ninputs will be set to their defaults:",
  tx: 'policy.setToBau.subTitle',
  preset: 'h3'
})

export const forecastedMpgLabelProps = getColorText({
  text: 'Forecasted MPG,',
  tx: 'policy.setToBau.mpg',
  preset: 'body'
})

export const vmtGrowthRateLabelProps = getColorText({
  text: 'VMT Growth Rate,',
  tx: 'policy.setToBau.growthRate',
  preset: 'body'
})

export const forecastedFleetSizeLabelProps = getColorText({
  text: 'Forecasted Change in Fleet Size.',
  tx: 'policy.setToBau.fleetSize',
  preset: 'body'
})

export const descriptionLabelProps = getColorText({
  text: 'All other incentives inputs will be set to zero.',
  tx: 'policy.setToBau.description',
  preset: 'h3'
})

export const tieredMsrpCapLabelProps = getColorDarkBlue({
  text: 'Second Tier MSRP Cap',
  tx: 'policy.tieredMsrpCap'
})

export const tieredMsrpSoftCapLabelProps = getColorDarkBlue({
  text: 'Second Tier MSRP Soft Cap',
  tx: 'policy.tieredMsrpSoftCap'
})

export const fleetAgeGraphLabelProps = getColorText({
  text: 'Fleet Age Graph',
  tx: 'policy.fleetAgeGraph'
})

export const avoidedSocialCostLabelProps = getColorText({
  text: 'Avoided Social Cost of Carbon',
  tx: 'policy.avoidedSocialCost',
  color: 'inactive'
})

export const bonusFplNewEvIncLabelProps = getColorDarkBlue({
  text: 'Bonus FPL New EV Incentive?',
  tx: 'policy.bonusFplNewEvInc'
})

export const additionalNewEvIncFplLabelProps = getColorDarkBlue({
  text: 'Additional New EV Incentive FPL HH',
  tx: 'policy.additionalNewEvIncFpl'
})

export const fplNewEvBonusDurationLabelProps = getColorDarkBlue({
  text: 'FPL New EV Bonus Duration',
  tx: 'policy.fplNewEvBonusDuration'
})

export const minBatteryCapacityLabelProps = getColorDarkBlue({
  tx: 'policy.minBatteryCapacity',
  text: 'Minimum Battery Capacity to Qualify'
})

export const batteryCapacityIncLabelProps = getColorDarkBlue({
  tx: 'policy.batteryCapacityInc',
  text: 'Battery Capacity Increment'
})

export const byMilesOrKwhLabelProps = getColorDarkBlue({
  tx: 'policy.byMilesOrKwh',
  text: 'By miles or kWh?'
})

export const milesOptionLabelProps = getColorText({
  tx: 'policy.miles',
  text: 'miles'
})

export const kwhLabelProps = getColorText({
  tx: 'policy.kwh',
  text: 'kWh'
})

export const allFleetAgeYearsLabelProps = getColorDarkBlue({
  tx: 'policy.allFleetAgeYears',
  text: 'All fleet age years?'
})

export const usAssemblyIncLabelProps: ComponentTextProps = {
  tx: 'policy.usAssemblyInc',
  text: 'U.S. Assembly Incentive'
}

export const updatePolicyNameLabelProps: ComponentTextProps = {
  tx: 'policy.updatePolicyName',
  text: 'Updating Policy Name'
}

export const updateLabelProps: ComponentTextProps = {
  tx: 'policy.update',
  text: 'Update'
}

export const yesLabelProps: ComponentTextProps = {
  text: 'Yes',
  tx: 'policy.yes',
  color: 'inactive'
}

export const noLabelProps: ComponentTextProps = {
  text: 'No',
  tx: 'policy.no',
  color: 'inactive'
}

export const bonusPercentOrDollarLabelProps = getColorText({
  text: 'Incentive Type',
  tx: 'policy.bonusPercentOrDollar'
})

export const bonusIncLabelProps = getColorInactive({
  text: 'Incentive Amount',
  tx: 'policy.bonusInc'
})

export const percentLabelProps = getColorInactive({
  text: 'Percent',
  tx: 'policy.percentText'
})

export const assembledInUsIncLabelProps = getColorInactive({
  tx: 'policy.assembledInUsInc',
  text: 'U.S. Assembly Incentive?',
  preset: 'body'
})

export const percentageInY1LabelProps = getColorInactive({
  tx: 'policy.percentageInY1',
  text: 'Percentage Qualify in Year 1',
  preset: 'body'
})

export const percentageInY2LabelProps = getColorInactive({
  tx: 'policy.percentageInY2',
  text: 'Percentage Qualify in Year 2',
  preset: 'body'
})

export const percentageInY3LabelProps = getColorInactive({
  tx: 'policy.percentageInY3',
  text: 'Percentage Qualify in Year 3',
  preset: 'body'
})

export const percentageInY4LabelProps = getColorInactive({
  tx: 'policy.percentageInY4',
  text: 'Percentage Qualify in Year 4',
  preset: 'body'
})

export const percentageInY5LabelProps = getColorInactive({
  tx: 'policy.percentageInY5',
  text: 'Percentage Qualify in Year 5',
  preset: 'body'
})

export const percentageInY6LabelProps = getColorInactive({
  tx: 'policy.percentageInY6',
  text: 'Percentage Qualify in Year 6+',
  preset: 'body'
})

export const assembledInUsProgramMaxDurationLabelProps = getColorInactive({
  tx: 'policy.assembledInUsProgramMaxDuration',
  text: 'U.S. Assembly Program Maximum Duration',
  preset: 'body'
})

export const tieredP1YearsLabelProps = getColorDarkBlue({
  tx: 'policy.tieredP1Years',
  text: 'Ending after Year'
})

export const includeFplCapLabelProps = getColorDarkBlue({
  tx: 'policy.includeFplCap',
  text: 'Include FPL Bonus in Cap?'
})

export const incentiveCapLabelProps = getColorDarkBlue({
  tx: 'policy.incentiveCap',
  text: 'Incentive Cap'
})

export const usedEvTypeLabelProps = getColorDarkBlue({
  tx: 'policy.usedEvType',
  text: 'Used EV Incentive Type'
})

export const changesToLabelProps = getColorDarkBlue({
  tx: 'policy.changesTo',
  text: 'Changes to'
})

export const afterLabelProps = getColorDarkBlue({
  tx: 'policy.after',
  text: '...after'
})

export const fplBonusNewExpansionLabelProps = getColorDarkBlue({
  tx: 'policy.fplBonusNewExpansion',
  text: 'Apply Expansion to Bonus FPL New EV Incentive?'
})

export const incentiveOrRequirementLabelProps = getColorDarkBlue({
  tx: 'policy.incentiveOrRequirement',
  text: 'Incentive or Requirement?'
})

export const requirementBeginsInYearLabelProps = getColorDarkBlue({
  tx: 'policy.requirementBeginsInYear',
  text: 'Requirement Begins in Year'
})

export const annualOrCumulativeProps = getColorDarkBlue({
  tx: 'policy.annualOrCumulative',
  text: 'Annual or Cumulative?'
})

export const annualProps = getColorInactive({
  tx: 'policy.annual',
  text: 'Annual'
})

export const cumulativeProps = getColorInactive({
  tx: 'policy.cumulative',
  text: 'Cumulative'
})

export const incentiveGrayLabelProps = getColorInactive({
  tx: 'policy.incentive',
  text: 'Incentive'
})

export const requirementLabelProps = getColorInactive({
  tx: 'policy.requirement',
  text: 'Requirement'
})

export const setToBauLabelProps: ComponentTextProps = {
  tx: 'policy.setToBau.title',
  text: 'Set to BAU'
}

export const scrapAndReplaceWarningLabelProps: ComponentTextProps = {
  tx: 'policy.scrapAndReplace.warning',
  text:
    'The following Scrap and Replace values are too low to encourage any participation, given current maximum age: {values}'
}

export const wishToContinueLabelProps: ComponentTextProps = {
  tx: 'policy.wishToContinue',
  text: 'Do you wish to continue?'
}

export const warningLabelProps: ComponentTextProps = {
  tx: 'policy.warning',
  text: 'WARNING'
}

export const applyDiscountRateToProps: ComponentTextProps = {
  tx: 'policy.applyDiscountRateTo',
  text: 'Apply discount rate to:'
}

export const includeBenefitsLabelProps: ComponentTextProps = {
  text: 'Include Which Benefits?',
  tx: 'policy.includeBenefits',
  values: {
    attention: ''
  }
}

export const eligibilityForNewIncLabelProps: ComponentTextProps = {
  tx: 'policy.eligibilityForNewInc',
  text: 'Eligibility for New EV Incentives'
}

// NEW LABELS from 04.02.22
export const excludeEvsFromCafeLabelProps: ComponentTextProps = {
  tx: 'policy.excludeEvsFromCafe',
  text: 'Exclude EVs from CAFE?'
}

export const lastYearOfPhevsLabelProps: ComponentTextProps = {
  tx: 'policy.lastYearOfPhevs',
  text: 'Last Year of PHEVs'
}

export const overrideRetirementLabelProps: ComponentTextProps = {
  tx: 'policy.overrideRetirementRate',
  text: 'Override Retirement Rate?'
}

export const fleetGrowthRateLabelProps: ComponentTextProps = {
  tx: 'policy.annualFleetGrowthRate',
  text: 'Annual Fleet Growth Rate'
}

export const annualBatteryCapacityImpRateLabelProps: ComponentTextProps = {
  tx: 'policy.annualBatteryCapacityImpRate',
  text: 'Annual Battery Capacity Improvement Rate'
}

export const firstYearDiscountLabelProps: ComponentTextProps = {
  tx: 'policy.applyConsumerDiscountInYear1',
  text: 'Apply Consumer Discount in Year 1?'
}

export const phase2OfTieredIncLabelProps: ComponentTextProps = {
  tx: 'policy.phase2OfTieredInc',
  text: 'Phase 2 of Tiered Incentive'
}

export const phase3OfTieredIncLabelProps: ComponentTextProps = {
  tx: 'policy.phase3OfTieredInc',
  text: 'Phase 3 of Tiered Incentive'
}

export const disableMsrpCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableMsrpCap',
  text: 'Disable MSRP Cap'
}

export const disableIncomeCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableIncomeCap',
  text: 'Disable Income Cap'
}

export const disableSecondTierMsrpCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableSecondTierMsrpCap',
  text: 'Disable Second Tier MSRP Cap'
}

export const disableSingleMagiCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableSingleMagiCap',
  text: 'Disable Single MAGI Cap'
}

export const magiCapSingleLabelProps: ComponentTextProps = {
  tx: 'policy.magiCapSingle',
  text: 'MAGI Cap Single'
}

export const disableHeadOfHouseholdMagiCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableHeadOfHouseholdMagiCap',
  text: 'Disable Head Of Household Magi MAGI Cap'
}

export const magiCapHeadOfHouseholdLabelProps: ComponentTextProps = {
  tx: 'policy.magiCapHeadOfHousehold',
  text: 'MAGI Cap Head of Household'
}

export const disableJointMagiCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableJointMagiCap',
  text: 'Disable Joint MAGI Cap'
}

export const magiCapJointLabelProps: ComponentTextProps = {
  tx: 'policy.magiCapJoint',
  text: 'MAGI Cap Joint'
}

export const magiSoftCapLabelProps: ComponentTextProps = {
  tx: 'policy.magiSoftCap',
  text: 'MAGI Soft Cap'
}

export const softCapLabelProps: ComponentTextProps = {
  tx: 'policy.softCap',
  text: 'Soft Cap'
}

export const softCapStartingYearLabelProps: ComponentTextProps = {
  tx: 'policy.softCapStartingYear',
  text: 'Soft Cap Starting Year'
}

export const applyCapsToUsedEvIncLabelProps: ComponentTextProps = {
  tx: 'policy.applyCapsToUsedEvInc',
  text: 'Apply Caps To Used EV Incentive'
}

export const magiCapOptionLabelProps: ComponentTextProps = {
  tx: 'policy.magiCapOption',
  text: 'MAGI Cap Option'
}

export const bonusForBatteryCapacityQualLabelProps: ComponentTextProps = {
  tx: 'policy.bonusForBatteryCapacityQual',
  text: 'Bonus For Battery Capacity Qualified EVs'
}

export const averageAnnualBatteryDegLabelProps: ComponentTextProps = {
  tx: 'policy.averageAnnualBatteryDeg',
  text: 'Average Annual Battery Degradation'
}

export const excludeNonqualifiedSalesLabelProps: ComponentTextProps = {
  tx: 'policy.excludeNonqualifiedSales',
  text: 'Exclude Non-Quilified Sellers?'
}

export const percentageExcludedLabelProps: ComponentTextProps = {
  tx: 'policy.percentageExcluded',
  text: 'Percentage Excluded'
}

export const excludeSecondTimeSalesLabelProps: ComponentTextProps = {
  tx: 'policy.excludeSecondTimeSales',
  text: 'Exclude Second-Time Sales?'
}

export const secondTimeSalesTypeLabelProps: ComponentTextProps = {
  tx: 'policy.secondTimeSalesType',
  text: 'Second Time Sales Type'
}

export const yearWhenRateReachesLabelProps: ComponentTextProps = {
  tx: 'policy.yearWhenRateReaches',
  text: 'Year When Rate Reaches 50%'
}

export const applyUSAssemReqToUsedEvsLabelProps: ComponentTextProps = {
  tx: 'policy.applyUSAssemReqToUsedEvs',
  text: 'Apply U.S.Assembly Requirement to Used EVs?'
}

export const newEvIncStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.newEvIncStartYear',
  text: 'New EV Incentive Starting Year'
}

export const fplNewBonusIncStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.fplNewBonusIncStartYear',
  text: 'FPL New EV Bonus Incentive Starting Year'
}

export const minAgeForUsedEvsLabelProps: ComponentTextProps = {
  tx: 'policy.minAgeForUsedEvs',
  text: 'Minimum Age for Used EVs'
}

export const laProgramStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.laProgramStartYear',
  text: 'L&A Program Starting Year'
}

export const usAssemProgramStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.usAssemProgramStartYear',
  text: 'U.S.Assembly Program Starting Year'
}

export const oemIncStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.oemIncStartYear',
  text: 'OEM Incentive Starting Year'
}

export const oemIncMaxDurationLabelProps: ComponentTextProps = {
  tx: 'policy.oemIncMaxDuration',
  text: 'OEM Incentive Maximum Duration'
}

export const domesticContentIncentiveStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.domesticContentIncentiveStartYear',
  text: 'Domestic Content Incentive Starting Year'
}

export const domesticContentLabelProps: ComponentTextProps = {
  tx: 'policy.domesticContent',
  text: 'Domestic Content'
}

export const includeEviInTotalCostsChartLabelProps: ComponentTextProps = {
  tx: 'policy.includeEviInTotalCostsChart',
  text: 'Include EVI in Total Costs Chart?'
}

export const commercialFleetIncentiveQLabelProps: ComponentTextProps = {
  tx: 'policy.commercialFleetIncentiveQ',
  text: 'Commercial Fleet Incentive?'
}

export const commercialFleetIncStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.commercialFleetIncStartYear',
  text: 'Commercial Fleet Incentive Starting Year'
}

export const commercialFleetIncMaxDurLabelProps: ComponentTextProps = {
  tx: 'policy.commercialFleetIncMaxDur',
  text: 'Commercial Fleet Incentive Maximum Duration'
}

export const assumedEvSalesToFleetsLabelProps: ComponentTextProps = {
  tx: 'policy.assumedEvSalesToFleets',
  text: 'Assumed EV Sales to Fleets'
}

export const excludeLeasedVehiclesLabelProps: ComponentTextProps = {
  tx: 'policy.excludeLeasedVehicles',
  text: 'Exclude Leased Vehicles from MSRP Cap?'
}

export const evSalesToFleetsLabelProps: ComponentTextProps = {
  tx: 'policy.evSalesToFleets',
  text: 'EV Sales to Fleets'
}

export const scrapReplaceProgStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.scrapReplaceProgStartYear',
  text: 'Scrap and Replace Program Starting Year'
}

export const disableRegionMsrpCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableRegionMsrpCap',
  text: 'Disable Region MSRP Cap'
}

export const disableRegionIncomeCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableRegionIncomeCap',
  text: 'Disable Region Income Cap'
}

export const disableRegionSecondTierMsrpCapLabelProps: ComponentTextProps = {
  tx: 'policy.disableRegionSecondTierMsrpCap',
  text: 'Disable Region Second Tier MSRP Cap'
}

export const regionSoftCapStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.regionSoftCapStartYear',
  text: 'Region Soft Cap Starting Year'
}

export const regionSoftCapLabelProps: ComponentTextProps = {
  tx: 'policy.regionSoftCap',
  text: 'Region Soft Cap'
}

export const regionNewEVIncStartingYearLabelProps: ComponentTextProps = {
  tx: 'policy.regionNewEVIncStartingYear',
  text: 'Region New EV Incentive Starting Year'
}

export const regionNewEvIncLabelProps: ComponentTextProps = {
  tx: 'policy.regionNewEvInc',
  text: 'Region New EV Incentive'
}

export const fplNewEvBonusIncStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.fplNewEvBonusIncStartYear',
  text: 'FPL New EV Bonus Incentive Starting Year'
}

export const regionUsedEVIncStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.regionUsedEVIncStartYear',
  text: 'Region Used EV Incentive Starting Year'
}

export const regionUsedEvIncLabelProps: ComponentTextProps = {
  tx: 'policy.regionUsedEvInc',
  text: 'Region Used EV Incentive'
}

export const regionScrapReplaceProgramStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.regionScrapReplaceProgramStartYear',
  text: 'Region Scrap and Replace Program Starting Year'
}

export const usedEvIncStartYearLabelProps: ComponentTextProps = {
  tx: 'policy.usedEvIncStartYear',
  text: 'Used EV Incentive Starting Year'
}

export const scalarProps: ComponentTextProps = {
  tx: 'policy.scalar',
  text: 'Scalar'
}

export const arrayProps: ComponentTextProps = {
  tx: 'policy.array',
  text: 'Array'
}

export const option1Props: ComponentTextProps = {
  tx: 'policy.option1',
  text: 'Option 1'
}

export const option2Props: ComponentTextProps = {
  tx: 'policy.option2',
  text: 'Option 2'
}

export const newEVMaxDurLabelProps: ComponentTextProps = {
  tx: 'policy.newEvMaxDuration',
  text: 'New EV Maximum Duration'
}

export const regionScrapReplaceLabelProps: ComponentTextProps = {
  tx: 'policy.regionScrapReplace',
  text: 'Region Scrap & Replace'
}

export const fplBonusNewEvIncLabelProps: ComponentTextProps = {
  tx: 'policy.fplBonusNewEvInc',
  text: 'FPL Bonus New EV Incentive'
}

export const usAssemblyLabelProps: ComponentTextProps = {
  tx: 'policy.usAssembly',
  text: 'U.S. Assembly'
}

export const regionFplBonusNewEvIncLabelProps: ComponentTextProps = {
  tx: 'policy.regionFplBonusNewEvInc',
  text: 'Region FPL Bonus New EV Incentive'
}

export const healthLabelProps: ComponentTextProps = {
  tx: 'policy.health',
  text: 'Health'
}

export const hovLaneAccessLabelProps: ComponentTextProps = {
  tx: 'policy.hovLaneAccess',
  text: 'HOV Lane Access'
}

export const designatedParkingLabelProps: ComponentTextProps = {
  tx: 'policy.designatedParking',
  text: 'Designated Parking'
}

export const freeTollsLabelProps: ComponentTextProps = {
  tx: 'policy.freeTolls',
  text: 'Free Tolls'
}

export const freeFerriesLabelProps: ComponentTextProps = {
  tx: 'policy.freeFerries',
  text: 'Free Ferries'
}

export const otherLabelProps: ComponentTextProps = {
  tx: 'policy.other',
  text: 'Other'
}

export const msrpCapOptionProps: ComponentTextProps = {
  tx: 'policy.msrpCapOption',
  text: 'MSRP Cap Option'
}

export const firstTierSoftIncProps: ComponentTextProps = {
  tx: 'policy.firstTierSoftInc',
  text: 'First Tier Soft Cap Incentive'
}

export const useTieredSoftCapProps: ComponentTextProps = {
  tx: 'policy.useTieredSoftCap',
  text: 'Use Tiered Soft Cap'
}

export const incomeCapOptionProps: ComponentTextProps = {
  tx: 'policy.incomeCapOption',
  text: 'Income Cap Option'
}

export const quantityMagiPhasesProps: ComponentTextProps = {
  tx: 'policy.quantityMagiPhases',
  text: 'Quantity of MAGI Phase'
}

export const singleMagiCapProps: ComponentTextProps = {
  tx: 'policy.singleMagiCap',
  text: 'Single MAGI Cap'
}

export const headHouseMagiCapProps: ComponentTextProps = {
  tx: 'policy.headHouseMagiCap',
  text: 'Head of Household MAGI Cap'
}

export const jointMagiCapProps: ComponentTextProps = {
  tx: 'policy.jointMagiCap',
  text: 'Joint MAGI Cap'
}

export const endsAfterProps: ComponentTextProps = {
  tx: 'policy.endsAfter',
  text: 'Ends after'
}

export const phase2SingleMagiCapProps: ComponentTextProps = {
  tx: 'policy.phase2SingleMagiCap',
  text: 'Phase 2 Single MAGI Cap'
}

export const phase2HeadHouseMagiProps: ComponentTextProps = {
  tx: 'policy.phase2HeadHouseMagi',
  text: 'Phase 2 Head of Household MAGI Cap'
}

export const phase2JointMagiProps: ComponentTextProps = {
  tx: 'policy.phase2JointMagi',
  text: 'Phase 2 Joint MAGI Cap'
}

export const phase3SingleMagiCapProps: ComponentTextProps = {
  tx: 'policy.phase3SingleMagiCap',
  text: 'Phase 3 Single MAGI Cap'
}

export const phase3HeadHouseMagiProps: ComponentTextProps = {
  tx: 'policy.phase3HeadHouseMagi',
  text: 'Phase 3 Head of Household MAGI Cap'
}

export const phase3JointMagiProps: ComponentTextProps = {
  tx: 'policy.phase3JointMagi',
  text: 'Phase 3 Joint MAGI Cap'
}

export const maximumIncentiveProps: ComponentTextProps = {
  tx: 'policy.maximumIncentive',
  text: 'Maximum Incentive'
}

export const incentiveIncrementProps: ComponentTextProps = {
  tx: 'policy.incentiveIncrement',
  text: 'Incentive Increment'
}

export const rangeIncrementProps: ComponentTextProps = {
  tx: 'policy.rangeIncrement',
  text: 'Range Increment'
}

export const minimumIncentiveProps = {
  tx: 'policy.minimumIncentive',
  text: 'Minimum Incentive'
}

export const minimumRangeProps = {
  tx: 'policy.minimumRangeToQualify',
  text: 'Minimum Range to Qualify'
}

export const includeBevsProps: ComponentTextProps = {
  tx: 'policy.includeBevs',
  text: 'Include BEVs'
}

export const includePhevsProps: ComponentTextProps = {
  tx: 'policy.includePhevs',
  text: 'Include PHEVs'
}

export const includeNewEvsProps = {
  tx: 'policy.includeNewEvs',
  text: 'Include New EVs'
}

export const includeUsedEvsProps = {
  tx: 'policy.includeUsedEvs',
  text: 'Include Used EVs'
}

export const njFilterEligibiltyProps = {
  tx: 'policy.njFilterEligibilty',
  text: 'Filter by NJ Eligibility'
}

export const LABELS: any = {
  // additional-graph-params
  benefitsCarbon: avoidedSocialCostLabelProps,
  benefitsFuel: fuelSavingsProps,
  benefitsHealth: healthSavingsProps,
  benefitsOm: omSavingsProps,
  sccCalc: sccCalcLabelProps,
  userSccInput: scc20LabelProps,
  userSccRate: sccIncrRateLabelProps,
  fleetAgeYearAll: allFleetAgeYearsLabelProps,
  fleetAgeYear: fleetAgeGraphLabelProps,
  includeEv: evProps,
  includeIcev: icevProps,
  includeFleet: fleetProps,
  includeNew: newProps,
  fplParticipationType: fplPartMetricProps,
  fplAnnualCumulative: annualOrCumulativeProps,

  // model-characteristics
  diffusionCurveScalar: adoptionRateLabelProps,
  batteryImprovementRate: annualBatteryCapacityImpRateLabelProps,
  discountRate: discountRateLabelProps,
  firstYearDiscount: firstYearDiscountLabelProps,
  discountCosts: costsLabelProps,
  taxCreditBoolean: incentivesLabelProps,
  consumerDiscountRate: consumerDiscountRateLabelProps,
  taxCreditNewEvInc: newEVIncentiveProps,
  taxCreditDomestic: domesticContentLabelProps,
  taxCreditLeadingManufacturer: laborAssemblyLabelProps,
  taxCreditSoftCap: softCapLabelProps,
  taxCreditUsedEvInc: usedEVIncentiveProps,
  taxCreditSar: scrapReplaceLabelProps,
  taxCreditRegionNewEvInc: regionNewEvIncLabelProps,
  taxCreditTieredIncentiveP2: phase2OfTieredIncLabelProps,
  taxCreditTieredIncentiveP3: phase3OfTieredIncLabelProps,
  taxCreditRegionUsedEvInc: regionUsedEvIncLabelProps,
  taxCreditRegionSar: regionScrapReplaceLabelProps,
  taxCreditRegionSoftCap: regionSoftCapLabelProps,
  taxCreditFplBonus: fplBonusNewEvIncLabelProps,
  taxCreditAssembledInUs: usAssemblyLabelProps,
  taxCreditRegionFplBonus: regionFplBonusNewEvIncLabelProps,

  // reduction-characteristics
  ghgBoolean: avoidedGHGClassLabelProps,
  electricityDecarbRate: decarbonizationRateLabelProps,
  fuelSavings: fuelSavingsProps,
  health: healthLabelProps,

  // vehicle-fleet-characteristics
  cafeStandardOption: forecastedMPGLabelProps,
  input2050Mpg: mpgLabelProps,
  excludeEvCafe: excludeEvsFromCafeLabelProps,
  phevLastYear: lastYearOfPhevsLabelProps,
  vmtGrowth: growthRateLabelProps,
  newVehGrowth: fleetSizeLabelProps,
  overrideRetirement: overrideRetirementLabelProps,
  fleetGrowthRate: fleetGrowthRateLabelProps,

  // general-input-basic
  years: simulationYearsLabelProps,

  // commercial-fleet-inc
  fleetEviBoolean: fleetEviIncLabelProps,
  fleetYears: durationLabelProps,
  fleetExcludedProp: vehiclesExcludedLabelProps,
  fleetEviIncentive: eviIncentiveLabelProps,
  commercialFleetBoolean: commercialFleetIncentiveQLabelProps,
  commercialFleetStartYear: commercialFleetIncStartYearLabelProps,
  commercialFleetEndYear: commercialFleetIncMaxDurLabelProps,
  commercialFleetIncPercent: assumedEvSalesToFleetsLabelProps,
  regionExcludeCommercialBoolean: regionExcludeCommercialBooleanProps,
  regionCommercialEvIncentive: regionCommercialEvIncentiveProps,
  excludeLeaseMsrp: excludeLeasedVehiclesLabelProps,
  fleetSalesPercent: evSalesToFleetsLabelProps,

  // evi-inc
  includeEviCosts: includeEviInTotalCostsChartLabelProps,
  eviOverbuildRate: overBuildRateLabelProps,
  eviScenario: eviIncDecRateLabelProps,
  eviIncentiveYears: eviIncDurationLabelProps,
  startingWl2: incentiveLabelProps,
  workl2Rate: rateLabelProps,
  startingPl2: incentiveLabelProps,
  publ2Rate: rateLabelProps,
  startingDc: incentiveLabelProps,
  pubdcRate: rateLabelProps,

  // msrp-cap
  usCapType: msrpOrIncomeLabelProps,
  msrpCap: msrpLabelProps,
  msrpSoftCap: softCapIncentiveLabelProps,
  incomeCap: incomeCapLabelProps,
  tieredMsrpCap: tieredMsrpCapLabelProps,
  tieredMsrpSoftCap: tieredMsrpSoftCapLabelProps,
  softCapStartYear: softCapStartingYearLabelProps,
  softCapEndYears: softMaxDurationLabelProps,
  singleCap: magiCapSingleLabelProps,
  hoHCap: magiCapHeadOfHouseholdLabelProps,
  jointCap: magiCapJointLabelProps,

  usMsrpCapType: msrpCapOptionProps,
  usSoftCap: softCapIncentiveLabelProps,
  usIncomeCapType: incomeCapOptionProps,
  magiPhaseOption: quantityMagiPhasesProps,
  magiPhase1Years: endsAfterProps,
  singleCapPhase2: phase2SingleMagiCapProps,
  hoHCapPhase2: phase2HeadHouseMagiProps,
  jointCapPhase2: phase2JointMagiProps,
  magiPhase2Years: endsAfterProps,
  singleCapPhase3: phase3SingleMagiCapProps,
  hoHCapPhase3: phase3HeadHouseMagiProps,
  jointCapPhase3: phase3JointMagiProps,

  applyCapsToUsed: applyCapsToUsedEvIncLabelProps,
  magiCapUsedOption: magiCapOptionLabelProps,
  bonusUsedBatteryIncentive: bonusForBatteryCapacityQualLabelProps,
  batteryDegradationRate: averageAnnualBatteryDegLabelProps,
  excludeNondealersUsed: excludeNonqualifiedSalesLabelProps,
  percentExcludedUsed: percentageExcludedLabelProps,
  excludeSecondTimeSales: excludeSecondTimeSalesLabelProps,
  secondSalesType: secondTimeSalesTypeLabelProps,
  fiftyPercYear: yearWhenRateReachesLabelProps,
  assUsApplyUsed: applyUSAssemReqToUsedEvsLabelProps,

  // regulatory-policies
  zevMandateGoal: zevGoalEVSalesLabelProps,
  zevMandateYear: zevGoalYearLabelProps,
  noncomplianceCost: nonComplianceFeeLabelProps,
  additionalOemIncentive: voluntaryZevPriceCutLabelProps,
  icevBanBoolean: icevBanLabelProps,
  icevBanYear: icevBanYearLabelProps,
  oem100Threshold: oemEffectiveThresholdLabelProps,

  // scrap-replace
  maximumSaRAge: maximumAgeOfVehicleLabelProps,
  saRStartYear: scrapReplaceProgStartYearLabelProps,
  saREndYears: srMxDurationLabelProps,
  nationalSaRIncentiveFpl: incentiveValueLabelProps,
  nationalFplSaRNew: newProps,
  nationalFplSaRUsed: usedProps,
  nationalSaRIncentiveNonfpl: incentiveValueLabelProps,
  nationalNonfplSaRNew: newProps,
  nationalNonfplSaRUsed: usedProps,

  // ev-incentives
  newEvStartingAmt: incentiveValueLabelProps,
  newEvYears: durationToRemainLabelProps,
  newEvStartYear: newEvIncStartYearLabelProps,
  newEvEndYears: newEVMaxDurLabelProps,
  scenario: declineRateLabelProps,
  maxMarketShareValue: maxShareLabelProps,
  tieredIncentive: tieredIncentiveLabelProps,
  tieredIncentiveType: byMilesOrKwhLabelProps,
  minTierRange: minimumRangeLabelProps,
  minTierInc: minimumIncentiveLabelProps,
  stepTierRange: rangeIncrementLabelProps,
  stepTierInc: incentiveIncrementLabelProps,
  minTierKWh: minBatteryCapacityLabelProps,
  minTierIncKWh: minimumIncentiveLabelProps,
  stepTierKWh: batteryCapacityIncLabelProps,
  stepTierIncKWh: incentiveIncrementLabelProps,
  maxTierInc: maximumIncentiveLabelProps,
  tieredP1Years: tieredP1YearsLabelProps,
  p2MinTierKWh: minBatteryCapacityLabelProps,
  p2MinTierIncKWh: minimumIncentiveLabelProps,
  p2StepTierKWh: batteryCapacityIncLabelProps,
  p2StepTierIncKWh: incentiveIncrementLabelProps,
  p2MaxTierInc: maximumIncentiveLabelProps,
  tieredP2Years: tieredP1YearsLabelProps,
  p3MinTierKWh: minBatteryCapacityLabelProps,
  p3MinTierIncKWh: minimumIncentiveLabelProps,
  p3StepTierKWh: batteryCapacityIncLabelProps,
  p3StepTierIncKWh: incentiveIncrementLabelProps,
  p3MaxTierInc: maximumIncentiveLabelProps,
  fplBonusNewBoolean: bonusFplNewEvIncLabelProps,
  fplBonusNewInc: additionalNewEvIncFplLabelProps,
  fplBonusStartYear: fplNewBonusIncStartYearLabelProps,
  fplBonusEndYears: fplNewEvBonusDurationLabelProps,
  includeFplCap: includeFplCapLabelProps,
  fplIncentiveCap: incentiveCapLabelProps,
  usedEvType: usedEvTypeLabelProps,
  usedEvPerc: incentiveValueNewEVLabelProps,
  usedEvDollar: incentiveValueLabelProps,
  fplRate: federalPovertyLabelProps,
  usedEvStartYear: usedEvIncStartYearLabelProps,
  usedEvEndYears: usedMaxDurationLabelProps,
  usePriceElasticity: useConsumerValuationLabelProps,
  declineWtp: declineWTPLabelProps,
  avgUsedEvCost: avgUsedEvCostLabelProps,
  minUsedEvAge: minAgeForUsedEvsLabelProps,
  lmBoolean: manufacturersIncentiveLabelProps,
  lmBonusType: bonusPercentOrDollarLabelProps,
  lmIncPerc: bonusIncentiveLabelProps,
  lmIncDollar: bonusIncLabelProps,
  secondLmIncDollar: changesToLabelProps,
  secondLmIncYear: afterLabelProps,
  y1Lm: firstYearLabelProps,
  y2Lm: secondYearLabelProps,
  y3Lm: thirdYearLabelProps,
  y4Lm: fourthYearLabelProps,
  y5Lm: fifthYearLabelProps,
  y6Lm: sixthYearLabelProps,
  lmStartYear: laProgramStartYearLabelProps,
  lmEndYears: leadingProgramMaxDurationLabelProps,
  programUsedBoolean: splitProgramLabelProps,
  cutoffModelYear: leadingManufacturersLabelProps,
  programUsedEvIncentive: eligibleUsedEVsLabelProps,
  usedProgramExpansionYear: eligibilityExpandsLabelProps,
  secondFederalPUsed: expandedEligibilityLabelProps,
  fplBonusNewExpansionBoolean: fplBonusNewExpansionLabelProps,
  assUsBoolean: assembledInUsIncLabelProps,
  assUsType: incentiveOrRequirementLabelProps,
  assUsInc: bonusIncLabelProps,
  assUsReqYear: requirementBeginsInYearLabelProps,
  y1AssUs: percentageInY1LabelProps,
  y2AssUs: percentageInY2LabelProps,
  y3AssUs: percentageInY3LabelProps,
  y4AssUs: percentageInY4LabelProps,
  y5AssUs: percentageInY5LabelProps,
  y6AssUs: percentageInY6LabelProps,
  assUsStartYear: usAssemProgramStartYearLabelProps,
  assUsEndYears: assembledInUsProgramMaxDurationLabelProps,
  oemStartingAmt: incentiveValueLabelProps,
  oemYears: durationLabelProps,
  oemStartYear: oemIncStartYearLabelProps,
  oemEndYears: oemIncMaxDurationLabelProps,
  dealerInc: incentiveValueLabelProps,
  dealerYears: durationLabelProps,
  miAInc: incentiveValueLabelProps,
  miAProp: vehiclesEligibleLabelProps,
  domesticContentStartYear: domesticContentIncentiveStartYearLabelProps,
  domesticContentEndYears: domesticMaxDurationLabelProps,

  // regional msrp cap
  regionCapType: msrpOrIncomeLabelProps,
  regionMsrpCapNone: disableRegionMsrpCapLabelProps,
  regionMsrpCap: msrpLabelProps,
  regionSoftCap: softCapIncentiveLabelProps,
  regionIncomeCapNone: disableRegionIncomeCapLabelProps,
  regionIncomeCap: regionIncomeCapLabelProps,
  regionIncCapSoftCap: regionIncomeSoftCapLabelProps,
  regionTieredMsrpCapNone: disableRegionSecondTierMsrpCapLabelProps,
  regionTieredMsrpCap: tieredMsrpCapLabelProps,
  regionTieredMsrpSoftCap: firstTierSoftIncProps,
  regionSoftCapStartYear: regionSoftCapStartYearLabelProps,
  regionSoftCapEndYears: regionSoftMaxDurationLabelProps,

  // regional ev-inc
  regionNewEvInc: incentiveValueLabelProps,
  regionNewEvYears: durationToRemainLabelProps,
  regionNewEvStartYear: regionNewEVIncStartingYearLabelProps,
  regionNewEvEndYears: regionNewEvMaxDurationLabelProps,
  regionScenario: declineRateLabelProps,
  regionMaxMarketShare: maxShareLabelProps,
  regionSalesTaxBoolean: exemptEvsFromSalesTaxLabelProps,
  regionSalesTaxEndYears: lastingForLabelProps,
  regionTieredIncentive: tieredIncentiveLabelProps,
  regionTieredIncentiveType: byMilesOrKwhLabelProps,
  regionMaxTierInc: maximumIncentiveLabelProps,
  regionMinTierRange: minimumRangeLabelProps,
  regionMinTierInc: minimumIncentiveLabelProps,
  regionStepTierRange: rangeIncrementLabelProps,
  regionStepTierInc: incentiveIncrementLabelProps,
  regionMinTierKWh: minBatteryCapacityLabelProps,
  regionMinTierIncKWh: minimumIncentiveLabelProps,
  regionStepTierKWh: batteryCapacityIncLabelProps,
  regionStepTierIncKWh: incentiveIncrementLabelProps,
  regionFplBonusNewBoolean: bonusFplNewEvIncLabelProps,
  regionFplBonusNewInc: additionalNewEvIncFplLabelProps,
  regionFplBonusStartYear: fplNewEvBonusIncStartYearLabelProps,
  regionFplBonusEndYears: fplNewEvBonusDurationLabelProps,
  regionUsedEvInc: incentiveValueLabelProps,
  oneHundredStepValues: federalPovertyLabelProps,
  regionUsedEvYears: durationToRemainLabelProps,
  regionUsedEvStartYear: regionUsedEVIncStartYearLabelProps,
  regionUsedEvEndYears: regionUsedMaxDurationLabelProps,
  nonFinancialYears: durationLabelProps,
  hovLaneAccess: hovLaneAccessLabelProps,
  designatedParking: designatedParkingLabelProps,
  freeTolls: freeTollsLabelProps,
  freeFerries: freeFerriesLabelProps,
  other: otherLabelProps,
  otherNonfinancialValue: otherIncentiveValueLabelProps,

  // regional scrap-and-replace
  regionAgeVehicle: maximumAgeOfVehicleLabelProps,
  regionSaRStartYear: scrapReplaceProgStartYearLabelProps,
  regionSaREndYears: regionSrMaxDurationLabelProps,
  regionSaRIncentiveFpl: incentiveValueLabelProps,
  regionFplRate: federalPovertyLabelProps,
  regionFplSaRNew: newProps,
  regionFplSaRUsed: usedProps,
  regionSaRIncentiveNonfpl: incentiveValueLabelProps,
  regionNonfplSaRNew: newProps,
  regionNonfplSaRUsed: usedProps,

  regionMsrpCapType: msrpCapOptionProps,
  regionTieredMsrpSoftCapBoolean: useTieredSoftCapProps,
  regionMinTierRangeSoftCap: minimumRangeProps,
  regionMinTierIncSoftCap: minimumIncentiveProps,
  regionStepTierRangeSoftCap: rangeIncrementProps,
  regionStepTierIncSoftCap: incentiveIncrementProps,
  regionMaxTierIncSoftCap: maximumIncentiveProps,
  regionIncomeCapType: incomeCapOptionProps,
  regionMagiPhaseOption: quantityMagiPhasesProps,
  regionSingleCap: singleMagiCapProps,
  regionHoHCap: headHouseMagiCapProps,
  regionJointCap: jointMagiCapProps,
  regionMagiPhase1Years: endsAfterProps,
  regionSingleCapPhase2: phase2SingleMagiCapProps,
  regionHoHCapPhase2: phase2HeadHouseMagiProps,
  regionJointCapPhase2: phase2JointMagiProps,
  regionMagiPhase2Years: endsAfterProps,
  regionSingleCapPhase3: phase3SingleMagiCapProps,
  regionHoHCapPhase3: phase3HeadHouseMagiProps,
  regionJointCapPhase3: phase3JointMagiProps,

  regionSalesTaxIncludeBev: includeBevsProps,
  regionSalesTaxIncludePhev: includePhevsProps,
  regionSalesTaxIncludeNew: includeNewEvsProps,
  regionSalesTaxIncludeUsed: includeUsedEvsProps,
  njFilterEligibilityBoolean: njFilterEligibiltyProps
}
