import { DropdownItemsData } from '../../dropdown-items'
import { GetSubMenuProps } from './policy-list-item.types'

export const getSubMenu = ({
  handleOnResultsClick,
  handleOnOpenModal,
  handleOnEditClick,
  handleOnCreateCopy,
  onAddToCompare,
  props
}: GetSubMenuProps): DropdownItemsData => ([
    {
      id: '1',
      value: 'open',
      name: 'Open Results',
      nameTx: 'policy.settings.open',
      onClick: handleOnResultsClick
    },
    {
      id: '2',
      value: 'edit',
      name: 'Edit',
      nameTx: 'policy.settings.edit',
      onClick: handleOnEditClick
    },
    {
      id: '3',
      value: 'create',
      name: 'Create Copy',
      nameTx: 'policy.settings.createCopy',
      onClick: handleOnCreateCopy(true)
    },
    {
      id: '4',
      value: 'addToCompare',
      name: 'Add To Compare',
      nameTx: 'policy.settings.addToCompare',
      onClick: () => onAddToCompare(props)
    },
    {
      id: '5',
      value: 'delete',
      name: 'Delete',
      nameTx: 'policy.settings.delete',
      onClick: handleOnOpenModal(true)
    }
  ]
)
