import React, { FC, useCallback, useMemo, useEffect, useState } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { Policy } from '../../../services'
import { State } from '../../../store'
import { LightTheme } from '../../../theme'
import { Column } from '../../column'
import { Content } from '../../content'
import { Dropdown } from '../../dropdown'
import { DropdownItemData } from '../../dropdown-items'
import { Row } from '../../row'
import {
  AdditionalGraphParametersIds,
  AdditionalGraphParametersValues,
  getCurrentDropdown,
  getCustomSettings,
  getDropdownData,
  getUpcomingSetting,
  aditionalGraphTypeLabelProps
} from '../../update-policy'
import { PlotIds, PlotIdsValues } from '../policy-summary-list.types'
import {
  AdditionalGraphSettingsProps,
  AdditionalGraphsIdsList
} from './additional-graph-settings.types'
import { useStyle } from './additional-graph-settings.styles'

// get order from BE, BE sends array
export const AdditionalGraphsIds: AdditionalGraphsIdsList = {
  1: PlotIds.COBENEFITS,
  2: PlotIds.JOBS_CREATED,
  3: PlotIds.FLEET_SHARE,
  4: PlotIds.FLEET_AGE,
  5: PlotIds.ROI_OVER_TIME,
  6: PlotIds.BONUS_FPL_DIFFUSION,
  7: PlotIds.FPL_AND_NEW_VEHICLES,
  8: PlotIds.FPL_PARTICIPATION,
  9: PlotIds.USED_PLUS_NEW,
  10: PlotIds.ZEV_MANDATE,
  11: PlotIds.GHG_COMPARISON,
  12: PlotIds.GASOLINE_CONSUMPTION,
  13: PlotIds.GHG_REDUCTIONS
}

export const AdditionalGraphSettings: FC<AdditionalGraphSettingsProps> = ({
  onChange
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { catalog } = useSelector((state: State) => ({
    catalog: state.policy.catalog
  }))
  const [defaults, changeDefaults] = useState<Policy | {}>({
    // default value, better to get from BE
    addGraphString: 1
  })
  const dropdowns: any = useMemo(
    () => ({
      additionalGraph: getDropdownData('displayedAdditionalGraphs', catalog)
    }),
    [catalog]
  )
  const { settingsList } = useMemo(
    () =>
      getCustomSettings({
        defaults,
        updates: {}
      }),
    [defaults]
  )
  const handleGetUpcomingSetting = useCallback(
    (settingId: AdditionalGraphParametersValues) =>
      getUpcomingSetting(settingId, settingsList),
    [settingsList]
  )

  const getAdditionalGraphParameters = useCallback(
    (): any => ({
      addGraphString: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.ADD_GRAPH_STRING
      )
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getAdditionalGraphParameters()
  const [additionalGraphParameters, changeAdditionalGraphParameters] = useState(
    fields
  )

  // METHODS
  const changePlot = useCallback(
    (plotKey: number) => {
      const selectedPlot = dropdowns.additionalGraph.find(
        (item: DropdownItemData) => Number(item.id) === plotKey
      )
      if (selectedPlot) {
        const plotId = Number(selectedPlot.id)
        const plotName: PlotIdsValues = AdditionalGraphsIds[plotId]
        onChange(plotName)
      }
    },
    [dropdowns]
  )

  const handleOnDropdownChange = useCallback(
    (value: DropdownItemData) => {
      const plotKey = Number(value.id)
      localStorage.setItem('addGraphString', String(plotKey))

      changeAdditionalGraphParameters({
        ...additionalGraphParameters,
        addGraphString: plotKey
      })
      changeDefaults({
        addGraphString: plotKey
      })
      changePlot(plotKey)
    },
    [changePlot]
  )

  const handleOnGetCurrentDropdown = (value: number, settingId: string) =>
    getCurrentDropdown({
      dropdowns,
      value,
      id: settingId
    })
  // eof METHODS

  useEffect(() => {
    const selectedGraph = localStorage.getItem('addGraphString')
    if (selectedGraph) {
      const data: DropdownItemData = {
        id: selectedGraph,
        value: selectedGraph,
        name: ''
      }
      handleOnDropdownChange(data)
    }
  }, [handleOnDropdownChange])

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row
          className={classes.row}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row className={classes.cell}>
            <Dropdown
              className={classes.dropdown}
              label={aditionalGraphTypeLabelProps.text}
              data={dropdowns.additionalGraph}
              preset="body"
              defaultValue={handleOnGetCurrentDropdown(
                additionalGraphParameters.addGraphString,
                'additionalGraph'
              )}
              onChange={handleOnDropdownChange}
            />
          </Row>
        </Row>
      </Content>
    </Column>
  )
}
