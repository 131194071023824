import { ComponentTextProps, TextProps } from '../text'

export const totalCostsLabeltalCosts: ComponentTextProps = {
  tx: 'policy.annualCostPlot',
  text: 'Annual cost of the policy'
}
export const ghgPlotLabelProps: ComponentTextProps = {
  tx: 'policy.ghgPlot',
  text: 'GHG Emissions'
}
export const evAdoptionLabelProps: ComponentTextProps = {
  tx: 'policy.evAdoption',
  text: 'EV sales and Incentive cost'
}
export const evAffordabilityLabelProps: ComponentTextProps = {
  tx: 'policy.evAffordability',
  text: 'EV Affordability'
}
export const additionalGraphLabelProps: ComponentTextProps = {
  tx: 'policy.additionalGraph',
  text: 'Additional Graph'
}
export const warningTextProps: TextProps = {
  text: 'Be careful! EV Adoption Rate Scale Factor has a value other than 1.0',
  tx: 'policy.overview.warning'
}
export const kwhConsumptionLabelProps: ComponentTextProps = {
  tx: 'policy.kwhConsumption',
  text: 'KWh Consumption'
}
export const eviInstalledLabelProps: ComponentTextProps = {
  tx: 'policy.eviInstalled',
  text: 'EVI Installed'
}
export const eviCostByTypeLabelProps: ComponentTextProps = {
  tx: 'policy.eviCostByType',
  text: 'EVI Cost By Type'
}
