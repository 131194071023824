import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Button } from '../../button'
import { Column } from '../../column'
import { Modal } from '../../modal'
import { Text } from '../../text'
import { Row } from '../../row'
import { AgreeModalProps } from './agree-modal.types'
import { useStyle } from './agree-modal.styles'

export const AgreeModal: FC<AgreeModalProps> = ({
  className = '',
  children,
  titleProps,
  data = [],
  onSubmit,
  onClose,
  onAutoClose = onClose
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }
    onClose()
  }

  return (
    <Modal onClose={onAutoClose}>
      <Column fullWidth fullHeight justifyContent="space-between">
        <Column className={classes.title} fullWidth>
          {titleProps && <Text {...titleProps} preset="h1" />}
        </Column>
        <Column
          className={`${className} ${classes.content}`}
          justifyContent="flex-start"
          fullHeight
          fullWidth
        >
          {data.map((item) => (
            <Text key={item.id} text={item.message} />
          ))}
          {children}
        </Column>
        <Row justifyContent="space-around" fullWidth>
          <Button preset="secondary" onClick={onAutoClose}>
            <Text text="Cancel" tx="btn.cancel" preset="body" />
          </Button>
          <Button onClick={handleOnSubmit}>
            <Text text="Ok" tx="btn.ok" preset="body" />
          </Button>
        </Row>
      </Column>
    </Modal>
  )
}
