import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'
import { StyleProps } from './view-filter-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: ({ active }: StyleProps) => ({
    backgroundColor: active ? theme.colors.white : theme.colors.transparent,
    border: `1px solid ${active ? theme.colors.white : theme.colors.disabled}`,
    cursor: theme.cursor,
    minWidth: 125,
    padding: '6px 0',
    borderRadius: 32
  }),
  text: {
    marginLeft: 8,
    cursor: theme.cursor
  }
}))
