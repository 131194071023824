import React, { FC, useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import {
  Content,
  Column,
  Input,
  Text,
  getCustomSettings,
  getUpcomingSetting,
  getSetting,
  changeCurrentValues,
  getIsValid,
  getError,
  setError,
  isNumeric,
  getSliderId,
  getLabel,
  formatValue
} from '../..'
import { Policy, Restrictions } from '../../../services'
import { State } from '../../../store'
import { Checkbox } from '../../checkbox'
import { Row } from '../../row'
import {
  dollarIconProps,
  flpProps,
  nonFLPProps,
  yearsIconProps,
  yearIconProps
} from '../label-props'
import {
  InputsPolicyProps,
  InputTypeValue,
  ScrapReplaceProgramIds,
  ScrapReplaceProgramValues
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const ScrapReplaceProgram: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions } = useSelector((state: State) => ({
    restrictions: state.policy.restrictions as Restrictions
  }))

  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: ScrapReplaceProgramValues) => getUpcomingSetting(id, settingsList),
    [settingsList]
  )

  // start of manipulating with current settings
  const getScrapReplaceProgram = useCallback(
    (): Partial<Policy> => ({
      saRStartYear: handleGetUpcomingSetting(ScrapReplaceProgramIds.START_YEAR),
      maximumSaRAge: handleGetUpcomingSetting(
        ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE
      ),
      saREndYears: handleGetUpcomingSetting(ScrapReplaceProgramIds.END_YEAR),
      nationalSaRIncentiveFpl: handleGetUpcomingSetting(
        ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL
      ),
      nationalFplSaRNew: handleGetUpcomingSetting(
        ScrapReplaceProgramIds.FPL_INCENTIVE_NEW
      ),
      nationalFplSaRUsed: handleGetUpcomingSetting(
        ScrapReplaceProgramIds.FPL_INCENTIVE_USED
      ),
      nationalSaRIncentiveNonfpl: handleGetUpcomingSetting(
        ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP
      ),
      nationalNonfplSaRNew: handleGetUpcomingSetting(
        ScrapReplaceProgramIds.NON_FPL_INCENTIVE_NEW
      ),
      nationalNonfplSaRUsed: handleGetUpcomingSetting(
        ScrapReplaceProgramIds.NON_FPL_INCENTIVE_USED
      )
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getScrapReplaceProgram()
  const [scrapReplaceProgram, changeScrapReplaceProgram] = useState(fields)

  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeScrapReplaceProgram(getScrapReplaceProgram())
    changeSettings(s)
  }, [defaults, updates])
  // eof of manipulating with current settings

  // METHODS
  const getIsExist = (id: ScrapReplaceProgramValues) =>
    scrapReplaceProgram[id] !== undefined
  const getIsDefault = (id: ScrapReplaceProgramValues) =>
    scrapReplaceProgram[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (
    id: ScrapReplaceProgramValues,
    flag: InputTypeValue
  ) => (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)

    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    const processedValue = isNumeric(String(value)) ? Number(value) : value
    changeScrapReplaceProgram({
      ...scrapReplaceProgram,
      [id]: processedValue
    })
    changeSettings(updSettings)
    onChange({ [id]: processedValue })
  }

  const handleOnBlur = (id: ScrapReplaceProgramValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnCheckboxChange = (id: string) => (status: boolean) => {
    changeScrapReplaceProgram({
      ...scrapReplaceProgram,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleUndoClick = (id: ScrapReplaceProgramValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value

      changeScrapReplaceProgram({
        ...scrapReplaceProgram,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnGetError = (id: ScrapReplaceProgramValues) =>
    getError(id, error)
  // eof METHODS

  const isFPLDisabled =
    scrapReplaceProgram.nationalFplSaRNew === false &&
    scrapReplaceProgram.nationalFplSaRUsed === false
  const isNonFPLDisabled =
    scrapReplaceProgram.nationalNonfplSaRNew === false &&
    scrapReplaceProgram.nationalNonfplSaRUsed === false

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE}
            labelProps={getLabel(
              ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE,
              restrictions
            )}
            isYears
            defaultValue={scrapReplaceProgram.maximumSaRAge}
            iconProps={yearsIconProps}
            isFocused={getIsExist(
              ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE
            )}
            description={
              restrictions[ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE]
                ?.description
            }
            disabled={
              !getIsExist(ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE)
            }
            isUndo={getIsDefault(ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE)}
            errorProps={handleOnGetError(
              ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE
            )}
            onBlur={handleOnBlur(ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE)}
            onChange={handleOnChange(
              ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(
              ScrapReplaceProgramIds.MAXIMUM_AGE_OF_VEHICLE
            )}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={ScrapReplaceProgramIds.START_YEAR}
            labelProps={getLabel(
              ScrapReplaceProgramIds.START_YEAR,
              restrictions
            )}
            isYear
            iconProps={yearIconProps}
            defaultValue={scrapReplaceProgram.saRStartYear}
            isFocused={getIsExist(ScrapReplaceProgramIds.START_YEAR)}
            description={
              restrictions[ScrapReplaceProgramIds.START_YEAR]?.description
            }
            disabled={!getIsExist(ScrapReplaceProgramIds.START_YEAR)}
            isUndo={getIsDefault(ScrapReplaceProgramIds.START_YEAR)}
            errorProps={handleOnGetError(ScrapReplaceProgramIds.START_YEAR)}
            onBlur={handleOnBlur(ScrapReplaceProgramIds.START_YEAR)}
            onChange={handleOnChange(
              ScrapReplaceProgramIds.START_YEAR,
              InputTypeValue.YEAR
            )}
            onUndoClick={handleUndoClick(ScrapReplaceProgramIds.START_YEAR)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={ScrapReplaceProgramIds.END_YEAR}
            labelProps={getLabel(ScrapReplaceProgramIds.END_YEAR, restrictions)}
            isYears
            defaultValue={scrapReplaceProgram.saREndYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(ScrapReplaceProgramIds.END_YEAR)}
            description={
              restrictions[ScrapReplaceProgramIds.END_YEAR]?.description
            }
            disabled={!getIsExist(ScrapReplaceProgramIds.END_YEAR)}
            isUndo={getIsDefault(ScrapReplaceProgramIds.END_YEAR)}
            errorProps={handleOnGetError(ScrapReplaceProgramIds.END_YEAR)}
            onBlur={handleOnBlur(ScrapReplaceProgramIds.END_YEAR)}
            onChange={handleOnChange(
              ScrapReplaceProgramIds.END_YEAR,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(ScrapReplaceProgramIds.END_YEAR)}
          />
        </Row>

        {/* FLP */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text {...flpProps} className={classes.subTitle} preset="h3" />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {!isFPLDisabled && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL}
              labelProps={getLabel(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL,
                restrictions
              )}
              defaultValue={scrapReplaceProgram.nationalSaRIncentiveFpl}
              isDollar
              iconProps={dollarIconProps}
              isFocused={getIsExist(ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL)}
              description={
                restrictions[ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL]
                  ?.description
              }
              disabled={!getIsExist(ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL)}
              isUndo={getIsDefault(ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL)}
              errorProps={handleOnGetError(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL
              )}
              onBlur={handleOnBlur(ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL)}
              onChange={handleOnChange(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_FPL
              )}
            />
          )}
          <Row
            className={`${classes.cell} ${classes.selectionControls}`}
            justifyContent="space-between"
          >
            <Checkbox
              {...getLabel(
                ScrapReplaceProgramIds.FPL_INCENTIVE_NEW,
                restrictions
              )}
              defaultChecked={scrapReplaceProgram.nationalFplSaRNew}
              description={
                restrictions[ScrapReplaceProgramIds.FPL_INCENTIVE_NEW]
                  ?.description
              }
              disabled={!getIsExist(ScrapReplaceProgramIds.FPL_INCENTIVE_NEW)}
              isUndo={getIsDefault(ScrapReplaceProgramIds.FPL_INCENTIVE_NEW)}
              onChange={handleOnCheckboxChange(
                ScrapReplaceProgramIds.FPL_INCENTIVE_NEW
              )}
              onUndoClick={handleUndoClick(
                ScrapReplaceProgramIds.FPL_INCENTIVE_NEW
              )}
            />
            <Checkbox
              {...getLabel(
                ScrapReplaceProgramIds.FPL_INCENTIVE_USED,
                restrictions
              )}
              defaultChecked={scrapReplaceProgram.nationalFplSaRUsed}
              description={
                restrictions[ScrapReplaceProgramIds.FPL_INCENTIVE_USED]
                  ?.description
              }
              disabled={!getIsExist(ScrapReplaceProgramIds.FPL_INCENTIVE_USED)}
              isUndo={getIsDefault(ScrapReplaceProgramIds.FPL_INCENTIVE_USED)}
              onChange={handleOnCheckboxChange(
                ScrapReplaceProgramIds.FPL_INCENTIVE_USED
              )}
              onUndoClick={handleUndoClick(
                ScrapReplaceProgramIds.FPL_INCENTIVE_USED
              )}
            />
          </Row>
        </Row>

        {/* Non FLP */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text {...nonFLPProps} className={classes.subTitle} preset="h3" />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {!isNonFPLDisabled && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP}
              labelProps={getLabel(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP,
                restrictions
              )}
              defaultValue={scrapReplaceProgram.nationalSaRIncentiveNonfpl}
              isDollar
              iconProps={dollarIconProps}
              isFocused={getIsExist(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP
              )}
              description={
                restrictions[ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP]
                  ?.description
              }
              disabled={
                !getIsExist(ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP)
              }
              isUndo={getIsDefault(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP
              )}
              errorProps={handleOnGetError(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP
              )}
              onBlur={handleOnBlur(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP
              )}
              onChange={handleOnChange(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(
                ScrapReplaceProgramIds.INCENTIVE_VALUE_NON_FLP
              )}
            />
          )}
          <Row
            className={`${classes.cell} ${classes.selectionControls}`}
            justifyContent="space-between"
          >
            <Checkbox
              {...getLabel(
                ScrapReplaceProgramIds.NON_FPL_INCENTIVE_NEW,
                restrictions
              )}
              defaultChecked={scrapReplaceProgram.nationalNonfplSaRNew}
              description={
                restrictions[ScrapReplaceProgramIds.NON_FPL_INCENTIVE_NEW]
                  ?.description
              }
              disabled={
                !getIsExist(ScrapReplaceProgramIds.NON_FPL_INCENTIVE_NEW)
              }
              isUndo={getIsDefault(
                ScrapReplaceProgramIds.NON_FPL_INCENTIVE_NEW
              )}
              onChange={handleOnCheckboxChange(
                ScrapReplaceProgramIds.NON_FPL_INCENTIVE_NEW
              )}
              onUndoClick={handleUndoClick(
                ScrapReplaceProgramIds.NON_FPL_INCENTIVE_NEW
              )}
            />
            <Checkbox
              {...getLabel(
                ScrapReplaceProgramIds.NON_FPL_INCENTIVE_USED,
                restrictions
              )}
              defaultChecked={scrapReplaceProgram.nationalNonfplSaRUsed}
              description={
                restrictions[ScrapReplaceProgramIds.NON_FPL_INCENTIVE_USED]
                  ?.description
              }
              disabled={
                !getIsExist(ScrapReplaceProgramIds.NON_FPL_INCENTIVE_USED)
              }
              isUndo={getIsDefault(
                ScrapReplaceProgramIds.NON_FPL_INCENTIVE_USED
              )}
              onChange={handleOnCheckboxChange(
                ScrapReplaceProgramIds.NON_FPL_INCENTIVE_USED
              )}
              onUndoClick={handleUndoClick(
                ScrapReplaceProgramIds.NON_FPL_INCENTIVE_USED
              )}
            />
          </Row>
        </Row>
      </Content>
    </Column>
  )
}
