import React, { ReactNode } from 'react'
import { ErrorResponse } from '../../services'
import {
  CatalogProperties,
  InputRule,
  Policy,
  PolicyKeys,
  Restrictions
} from '../../services/policy/policy.types'
import { ComponentTextProps, TextProps, TextTranslate } from '../text'

export interface PolicySettingsProps {
  className?: string
}

export interface PolicySettings extends ComponentTextProps {
  id: string
  groupId: string
  types: TypeSetting[]
}

export interface TypeSetting {
  tx?: TextTranslate
  text: string
  sliderId: string
  comp: (values: InputsPolicyProps) => React.ReactElement | React.FC
  warningTextProps?: TextProps
}

export interface PolicyProps {
  id: string
}

export type PolicySetting = string | number | boolean | undefined

export type CustomSettingType = {
  id: string
  value: number | string | boolean | undefined
  isOverrided: boolean
}

export type CustomSettingsType = CustomSettingType[]

export type ErrorType = {
  id: PolicyKeys
  sliderId?: string
  textProps: ComponentTextProps
}

export type ErrorProps = {
  errorId: PolicyKeys
  values?: InputRule
  sliderId?: string
  reset?: boolean
}

export type SettingsType = Policy | undefined

export enum GeneralInputsBasicIds {
  SIMULATION_YEARS = 'years',
  REGION = 'region',
  START_YEAR = 'startYear'
}

export enum VehicleFleetCharacteristicsIds {
  FORECASTED_MPG = 'cafeStandardOption',
  MPG = 'input2050Mpg',
  GROWTH_RATE = 'vmtGrowth',
  EXCLUDE_EV_CAFE = 'excludeEvCafe',
  PHEV_LAST_YEAR = 'phevLastYear',
  FLEET_SIZE = 'newVehGrowth',
  OVERRIDE_RETIREMENT = 'overrideRetirement',
  FLEET_GROWTH_RATE = 'fleetGrowthRate'
}

export enum ReductionCharacteristicsIds {
  AVOIDED_GHG_CLASS = 'ghgBoolean',
  DECARBONIZATION_RATE = 'electricityDecarbRate',
  FUEL_SAVINGS = 'fuelSavings',
  HEALTH = 'health'
}

export enum ModelCharacteristicsIds {
  ADOPTION_RATE = 'diffusionCurveScalar',
  BATTERY_IMPROVEMENT_RATE = 'batteryImprovementRate',
  DISCOUNT_RATE = 'discountRate',
  FIRST_YEAR_DISCOUNT = 'firstYearDiscount',
  COSTS = 'discountCosts',
  INCENTIVES = 'taxCreditBoolean',
  TAX_CREDIT_TIERED_INCENTIVE_P2 = 'taxCreditTieredIncentiveP2',
  TAX_CREDIT_TIERED_INCENTIVE_P3 = 'taxCreditTieredIncentiveP3',
  CONSUMER_DISCOUNT_RATE = 'consumerDiscountRate',
  TAX_CREDIT_NEW_EV_INC = 'taxCreditNewEvInc',
  TAX_CREDIT_SOFT_CAP = 'taxCreditSoftCap',
  TAX_CREDIT_DOMESTIC = 'taxCreditDomestic',
  TAX_CREDIT_USED_EV_INC = 'taxCreditUsedEvInc',
  TAX_CREDIT_LEADING_MANUFACTURER = 'taxCreditLeadingManufacturer',
  TAX_CREDIT_SAR = 'taxCreditSar',
  REGION_TAX_CREDIT_SAR = 'taxCreditRegionSar',

  TAX_CREDIT_REGION_SOFT_CAP = 'taxCreditRegionSoftCap',
  TAX_CREDIT_REGION_NEW_EV_INC = 'taxCreditRegionNewEvInc',
  TAX_CREDIT_REGION_USED_EV_INC = 'taxCreditRegionUsedEvInc',
  TAX_CREDIT_FPL_BONUS = 'taxCreditFplBonus',
  TAX_CREDIT_ASSEMBLED_IN_US = 'taxCreditAssembledInUs',

  REGION = 'region',
  LM_BOOLEAN = 'lmBoolean',
  NATIONAL_SAR_INC_FPL = 'nationalSaRIncentiveFpl',
  NATIONAL_SAR_INC_NON_FPL = 'nationalSaRIncentiveNonfpl',
  REGIONAL_SAR_INC_FPL = 'regionSaRIncentiveFpl',
  REGIONAL_SAR_INC_NON_FPL = 'regionSaRIncentiveNonfpl',
  FPL_BONUS_NEW_BOOLEAN = 'fplBonusNewBoolean',
  ASS_US_BOOLEAN = 'assUsBoolean',
  TAX_CREDIT_REGION_FPL_BONUS = 'taxCreditRegionFplBonus',
  REGION_FPL_BONUS_NEW_BOOLEAN = 'regionFplBonusNewBoolean',

  DECLINE_RATE = 'scenario'
}

export enum AffordabilityIds {
  PURCHASE_OR_LEASE = 'purchaseOrLease',
  NEW_OR_USED = 'newOrUsed',
  EV_TYPE_BEV = 'evTypeBev',
  EV_TYPE_PHEV = 'evTypePhev',
  HOUSEHOLD_SIZE = 'hhsize'
}

export enum AdditionalGraphParametersIds {
  SIMULATION_YEARS = 'years',
  ADD_GRAPH_STRING = 'addGraphString',
  SCC_CALC = 'sccCalc',
  USER_SCC_INPUT = 'userSccInput',
  USER_SCC_RATE = 'userSccRate',
  FLEET_AGE_YEAR = 'fleetAgeYear',
  FLEET_AGE_YEAR_ALL = 'fleetAgeYearAll',
  BENEFITS_CARBON = 'benefitsCarbon',
  BENEFITS_FUEL = 'benefitsFuel',
  BENEFITS_HEALTH = 'benefitsHealth',
  BENEFITS_OM = 'benefitsOm',
  EV = 'includeEv',
  ICEV = 'includeIcev',
  FLEET = 'includeFleet',
  NEW = 'includeNew',
  FPL_PART_DIV = 'fplParticipationType',
  FPL_ANNUAL_CUMULATIVE = 'fplAnnualCumulative'
}

export type GeneralInputsBasicValues = keyof Record<
  GeneralInputsBasicIds,
  string
>

export type VehicleFleetCharacteristicsValues = keyof Record<
  VehicleFleetCharacteristicsIds,
  string
>

export type ReductionCharacteristicsValues = keyof Record<
  ReductionCharacteristicsIds,
  string
>

export type ModelCharacteristicsValues = keyof Record<
  ModelCharacteristicsIds,
  string
>

export type AffordabilityValues = keyof Record<AffordabilityIds, string>

export type AdditionalGraphParametersValues = keyof Record<
  AdditionalGraphParametersIds,
  string
>

export enum CommercialFleetIncentiveIds {
  FLEET_EVI_INCENTIVE = 'fleetEviBoolean',
  VEHICLES_EXCLUDED = 'fleetExcludedProp',
  EVI_INCENTIVE = 'fleetEviIncentive',
  DURATION = 'fleetYears',
  COMMERCIAL_FLEET_BOOLEAN = 'commercialFleetBoolean',
  COMMERCIAL_FLEET_START_YEAR = 'commercialFleetStartYear',
  COMMERCIAL_FLEET_END_YEAR = 'commercialFleetEndYear',
  ASSUMED_EV_SALES_TO_FLEETS = 'commercialFleetIncPercent',
  EXCLUDE_LEASED_VEHICLES = 'excludeLeaseMsrp',
  EV_SALES_TO_FLEETS = 'fleetSalesPercent',
  CAP_TYPE = 'usCapType',
  EXCLUDE_COMMERCIAL_BOOLEAN = 'regionExcludeCommercialBoolean',
  COMMERCIAL_EV_INC = 'regionCommercialEvIncentive',
  US_MSRP_CAP_TYPE = 'usMsrpCapType',
  REGION_MSRPCAP_TYPE = 'regionMsrpCapType'
}

export enum RegionalCommercialFleetIncentiveIds {
  SALES_TO_FLEETS = 'regionFleetSalesPercent',
  EVI_INCENTIVE = 'regionFleetEviIncentive',
  VEHICLES_EXCLUDED = 'regionFleetExcludedProp',
  DURATION = 'regionDuration'
}

export enum EvIncentiveIds {
  INCENTIVE_VALUE = 'newEvStartingAmt',
  DURATION_TO_REMAIN_CONSTANT = 'newEvYears',
  NEW_EV_START_YEAR = 'newEvStartYear',
  END_YEAR = 'newEvEndYears',
  DECLINE_RATE = 'scenario',
  MAX_SHARE = 'maxMarketShareValue',

  TIERED_INCENTIVE = 'tieredIncentive',
  TIERED_INCENTIVE_TYPE = 'tieredIncentiveType',
  MINIMUM_RANGE_TO_QUALIFY = 'minTierRange',
  MINIMUM_INCENTIVE = 'minTierInc',
  RANGE_INCREMENT = 'stepTierRange',
  INCENTIVE_INCREMENT = 'stepTierInc',
  MAXIMUM_INCENTIVE = 'maxTierInc',

  MIN_TIER_KWH = 'minTierKWh',
  MIN_TIER_INC_KWH = 'minTierIncKWh',
  STEP_TIER_KWH = 'stepTierKWh',
  STEP_TIER_INC_KWH = 'stepTierIncKWh',

  TIERED_P1_YEARS = 'tieredP1Years',
  P2_MIN_TIER_KWH = 'p2MinTierKWh',
  P2_MIN_TIER_INC_KWH = 'p2MinTierIncKWh',
  P2_STEP_TIER_KWH = 'p2StepTierKWh',
  P2_STEP_TIER_INC_KWH = 'p2StepTierIncKWh',
  P2_MAX_TIER_INC = 'p2MaxTierInc',
  TIERED_P2_YEARS = 'tieredP2Years',
  P3_MIN_TIER_KWH = 'p3MinTierKWh',
  P3_MIN_TIER_INC_KWH = 'p3MinTierIncKWh',
  P3_STEP_TIER_KWH = 'p3StepTierKWh',
  P3_STEP_TIER_INC_KWH = 'p3StepTierIncKWh',
  P3_MAX_TIER_INC = 'p3MaxTierInc',

  FPL_BONUS_NEW_BOOLEAN = 'fplBonusNewBoolean',
  FPL_BONUS_NEW_INC = 'fplBonusNewInc',
  FPL_BONUS_START_YEAR = 'fplBonusStartYear',
  FPL_BONUS_END_YEARS = 'fplBonusEndYears',

  INCLUDE_FPL_BONUS_IN_CAP = 'includeFplCap',
  FPL_INCENTIVE_CAP = 'fplIncentiveCap',

  USED_EV_TYPE = 'usedEvType',
  INCENTIVE_VALUE_NEW_EV = 'usedEvPerc',
  USED_EV_DOLLAR = 'usedEvDollar',

  FEDERAL_POVERTY = 'fplRate',
  USED_EV_INC_START = 'usedEvStartYear',
  USED_MAX_DURATION = 'usedEvEndYears',
  USE_PRICE_ELASTICITY = 'usePriceElasticity',
  DECLINE_WTP = 'declineWtp',
  AVG_USED_EV_COST = 'avgUsedEvCost',
  MANUFACTURERS_INCENTIVE = 'lmBoolean',
  BONUS_PERCENT_OR_DOLLAR = 'lmBonusType',
  BONUS_INCENTIVE_DOLLAR = 'lmIncDollar',
  BONUS_INCENTIVE = 'lmIncPerc',

  SECOND_LM_INC_DOLLAR = 'secondLmIncDollar',
  SECOND_LM_INC_YEAR = 'secondLmIncYear',

  FIRST_YEAR = 'y1Lm',
  SECOND_YEAR = 'y2Lm',
  THIRD_YEAR = 'y3Lm',
  FOURTH_YEAR = 'y4Lm',
  FIFTH_YEAR = 'y5Lm',
  SIXTH_YEAR = 'y6Lm',
  LEADING_PROGRAM_START_YEAR = 'lmStartYear',
  LEADING_PROGRAM_MAX_DURATION = 'lmEndYears',
  SPLIT_PROGRAM = 'programUsedBoolean',
  LEADING_MANUFACTURERS = 'cutoffModelYear',
  ELIGIBLE_USED_EVS = 'programUsedEvIncentive',
  ELIGIBILITY_EXPANDS = 'usedProgramExpansionYear',
  EXPANDED_ELIGIBILITY = 'secondFplUsed',
  FPL_BONUS_EXPANSION = 'fplBonusNewExpansionBoolean',

  ASSEMBLED_IN_US_INCENTIVE = 'assUsBoolean',
  ASSEMBLED_US_INCENTIVE = 'assUsInc',
  ASS_US_TYPE = 'assUsType',
  ASS_US_REQ_YEAR = 'assUsReqYear',

  US_FIRST_YEAR = 'y1AssUs',
  US_SECOND_YEAR = 'y2AssUs',
  US_THIRD_YEAR = 'y3AssUs',
  US_FOURTH_YEAR = 'y4AssUs',
  US_FIFTH_YEAR = 'y5AssUs',
  US_SIXTH_YEAR = 'y6AssUs',
  ASSEMBLED_PROGRAM_START_YEAR = 'assUsStartYear',
  ASSEMBLED_PROGRAM_MAX_DURATION = 'assUsEndYears',

  INCENTIVE_VALUE_OEM = 'oemStartingAmt',
  DURATION_OEM = 'oemYears',
  OEM_START_YEAR = 'oemStartYear',
  OEM_END_YEAR = 'oemEndYears',
  INCENTIVE_VALUE_DEALER = 'dealerInc',
  DURATION_DEALER = 'dealerYears',
  INCENTIVE_VALUE_AMERICA = 'miAInc',
  VEHICLES_ELIGIBLE = 'miAProp',
  DOMESTIC_START_YEAR = 'domesticContentStartYear',
  DOMESTIC_MAX_DURATION = 'domesticContentEndYears',
  USED_EV_START_YEAR = 'usedEvStartYear',
  MIN_USED_EV_AGE = 'minUsedEvAge'
}

export enum EvIncentiveRegularIds {
  INCENTIVE_VALUE = 'regionNewEvInc',
  DURATION_TO_REMAIN_CONSTANT = 'regionNewEvYears',
  NEW_EV_START_YEAR = 'regionNewEvStartYear',
  NEW_EV_MAX_DURATION = 'regionNewEvEndYears',
  DECLINE_RATE = 'regionScenario',
  MAX_SHARE = 'regionMaxMarketShare',

  REGION_SALES_TAX_BOOLEAN = 'regionSalesTaxBoolean',
  REGION_SALES_TAX_END_YEARS = 'regionSalesTaxEndYears',

  REGION_TIERED_INC_TYPE = 'regionTieredIncentiveType',
  REGION_MIN_TIER_KWH = 'regionMinTierKWh',
  REGION_MIN_TIER_INC_KWH = 'regionMinTierIncKWh',
  REGION_STEP_TIER_KWH = 'regionStepTierKWh',
  REGION_STEP_TIER_INC_KWH = 'regionStepTierIncKWh',

  REGION_FPL_BONUS_NEW_BOOLEAN = 'regionFplBonusNewBoolean',
  REGION_FPL_BONUS_NEW_INC = 'regionFplBonusNewInc',
  REGION_FPL_BONUS_START_YEAR = 'regionFplBonusStartYear',
  REGION_FPL_BONUS_END_YEARS = 'regionFplBonusEndYears',

  TIERED_INCENTIVE = 'regionTieredIncentive',
  REGION_MIN_TIER_RANGE = 'regionMinTierRange',
  REGION_MIN_TIER_INC = 'regionMinTierInc',
  REGION_STEP_TIER_RANGE = 'regionStepTierRange',
  REGION_STEP_TIER_INC = 'regionStepTierInc',

  MAXIMUM_INCENTIVE = 'regionMaxTierInc',

  INCENTIVE_VALUE_USED_EV = 'regionUsedEvInc',
  FEDERAL_POVERTY = 'regionFplRate',
  USED_EV_DURATION = 'regionUsedEvYears',
  USED_EV_START_YEAR = 'regionUsedEvStartYear',
  USED_EV_MAX_DURATION = 'regionUsedEvEndYears',

  OTHER_INCENTIVE_VALUE = 'otherNonfinancialValue',
  DURATION = 'nonFinancialYears',

  LANE_ACCESS = 'hovLaneAccess',
  DESIGNATED_PARKING = 'designatedParking',
  FREE_TOLLS = 'freeTolls',
  FREE_FERRIES = 'freeFerries',
  OTHER = 'other',
  REGION_NEW_EV_START_YEAR = 'regionNewEvStartYear',
  REGION_USED_EV_START_YEAR = 'regionUsedEvStartYear',

  REGION_SALES_INCLUDE_BEV = 'regionSalesTaxIncludeBev',
  REGION_SALES_INCLUDE_PHEV = 'regionSalesTaxIncludePhev',
  REGION_SALES_INCLUDE_NEW = 'regionSalesTaxIncludeNew',
  REGION_SALES_INCLUDE_USED = 'regionSalesTaxIncludeUsed',
  NJ_FILTER_ELIGIBILITY = 'njFilterEligibilityBoolean'
}

export enum EviIncentiveIds {
  INCLUDE_EVI_COSTS = 'includeEviCosts',
  OVER_BUILD_RATE = 'eviOverbuildRate',
  EVI_DECLINE_RATE = 'eviScenario',
  EVI_INCENTIVE_DURATION = 'eviIncentiveYears',
  INCENTIVE_WORK_LEVEL = 'startingWl2',
  RATE_WORK_LEVEL = 'workl2Rate',
  INCENTIVE_PUBLIC_LEVEL = 'startingPl2',
  RATE_PUBLIC_LEVEL = 'publ2Rate',
  INCENTIVE_PUBLIC = 'startingDc',
  RATE_PUBLIC = 'pubdcRate'
}

export enum RegionalEviIncentiveIds {
  OVER_BUILD_RATE = 'regionEviOverbuildRate',
  INCENTIVE_WORK_LEVEL = 'regionStartingWl2',
  RATE_WORK_LEVEL = 'regionWorkl2Rate',
  INCENTIVE_PUBLIC_LEVEL = 'regionStartingPl2',
  RATE_PUBLIC_LEVEL = 'regionPubl2Rate',
  INCENTIVE_PUBLIC = 'regionStartingDc',
  RATE_PUBLIC = 'regionPubdcRate'
}

export enum MsrpCapIds {
  CAP_TYPE = 'usCapType',
  MSRP = 'msrpCap',
  INCOME_CAP = 'incomeCap',
  TIERED_MSRP_CAP = 'tieredMsrpCap',
  TIERED_MSRP_SOFT_CAP = 'tieredMsrpSoftCap',
  MAGI_CAP_SINGLE = 'singleCap',
  MAGI_CAP_HEADHOUSE = 'hoHCap',
  MAGI_CAP_JOINT = 'jointCap',
  MAGI_SOFT_CAP = 'magiSoftCap',
  SOFT_CAP_STARTING_YEAR = 'newEvStartYear',
  SOFT_CAP_MAX_DURATION = 'softCapEndYears',
  APPLY_CAPS_TO_USED = 'applyCapsToUsed',
  MAGI_CAP_USED_OPTION = 'magiCapUsedOption',
  BONUS_USED_BATTERY_INCENTIVE = 'bonusUsedBatteryIncentive',
  AVERAGE_ANNUAL_BATTERY_DEG = 'batteryDegradationRate',
  EXCLUDE_NON_QUALIFIED_SELLERS = 'excludeNondealersUsed',
  PERCENTAGE_EXCLUDED = 'percentExcludedUsed',
  EXCLUDED_SECOND_TIME_SALES = 'excludeSecondTimeSales',
  SECOND_TIME_SALES_TYPE = 'secondSalesType',
  YEAR_RATE_RICH_50 = 'fiftyPercYear',
  APPLY_US_ASSEMBLY_REQ_TO_USED_EVS = 'assUsApplyUsed',
  SOFT_CAP_START_YEAR = 'softCapStartYear',
  ASS_US_TYPE = 'assUsType',

  US_MSRP_CAP_TYPE = 'usMsrpCapType',
  US_SOFT_CAP = 'usSoftCap',
  US_INCOME_CAP_TYPE = 'usIncomeCapType',
  MAGI_PHASE_OPTION = 'magiPhaseOption',
  MAGI_PHASE1_YEARS = 'magiPhase1Years',
  SINGLE_CAP_PHASE2 = 'singleCapPhase2',
  HOH_CAP_PHASE2 = 'hoHCapPhase2',
  JOINT_CAP_PHASE2 = 'jointCapPhase2',
  MAGI_PHASE2_YEARS = 'magiPhase2Years',
  SINGLE_CAP_PHASE3 = 'singleCapPhase3',
  HOH_CAP_PHASE3 = 'hoHCapPhase3',
  JOINT_CAP_PHASE3 = 'jointCapPhase3'
}

export enum RegionalMsrpCapIds {
  MSRP = 'regionMsrpCap',
  INCOME_CAP = 'regionIncomeCap',
  SOFT_CAP_MAX_START_YEAR = 'regionSoftCapStartYear',
  TIERED_MSRP_CAP = 'regionTieredMsrpCap',
  SOFT_CAP_START_YEAR = 'regionSoftCapStartYear',
  SOFT_CAP_MAX_DURATION = 'regionSoftCapEndYears',
  TIERED_MSRP_SOFT_CAP = 'regionTieredMsrpSoftCap',

  REGION_TIERED_MSRP_CAP_BOOL = 'regionTieredMsrpSoftCapBoolean',
  REGION_MIN_TIER_RANGE_CAP = 'regionMinTierRangeSoftCap',
  REGION_MIN_TIER_INC_SOFT_CAP = 'regionMinTierIncSoftCap',
  REGION_STEP_TIER_INC_SOFT_CAP = 'regionStepTierIncSoftCap',
  REGION_MAX_TIER_INC_SOFT_CAP = 'regionMaxTierIncSoftCap',
  REGION_STEP_TIER_RANGE_SOFT_CAP = 'regionStepTierRangeSoftCap',

  REGION_MSRP_CAP_TYPE = 'regionMsrpCapType',
  REGION_SOFT_CAP = 'regionSoftCap',
  REGION_SINGLE_CAP = 'regionSingleCap',
  REGION_HOH_CAP = 'regionHoHCap',
  REGION_JOINT_CAP = 'regionJointCap',
  REGION_INCOME_CAP_TYPE = 'regionIncomeCapType',
  MAGI_PHASE_OPTION = 'regionMagiPhaseOption',
  MAGI_PHASE1_YEARS = 'regionMagiPhase1Years',
  SINGLE_CAP_PHASE2 = 'regionSingleCapPhase2',
  HOH_CAP_PHASE2 = 'regionHoHCapPhase2',
  JOINT_CAP_PHASE2 = 'regionJointCapPhase2',
  MAGI_PHASE2_YEARS = 'regionMagiPhase2Years',
  SINGLE_CAP_PHASE3 = 'regionSingleCapPhase3',
  HOH_CAP_PHASE3 = 'regionHoHCapPhase3',
  JOINT_CAP_PHASE3 = 'regionJointCapPhase3',
  TIERED_INC = 'regionTieredIncentive'
}

export enum RegulatoryPoliciesIds {
  ZEV_GOAL_EV_SALES = 'zevMandateGoal',
  ZEV_GOAL_YEAR = 'zevMandateYear',
  NON_COMPLIANCE_FEE = 'noncomplianceCost',
  VOLUNTARY_ZEV_PRICE_CUT = 'additionalOemIncentive',
  OEM_EFFECTIVE_THRESHOLD = 'oem100Threshold',
  ICEV_BAN_BOOLEAN = 'icevBanBoolean',
  ICEV_BAN_YEAR = 'icevBanYear'
}

export enum RegionalRegulatoryPoliciesIds {
  ZEV_GOAL_EV_SALES = 'regionZevMandateGoal',
  ZEV_GOAL_YEAR = 'regionZevMandateYear',
  NON_COMPLIANCE_FEE = 'regionNoncomplianceCost',
  VOLUNTARY_ZEV_PRICE_CUT = 'regionAdditionalOemIncentive',
  OEM_EFFECTIVE_THRESHOLD = 'regionOem100Threshold'
}

export enum ScrapReplaceProgramIds {
  START_YEAR = 'saRStartYear',
  MAXIMUM_AGE_OF_VEHICLE = 'maximumSaRAge',
  SAR_START_YEAR = 'saRStartYear',
  END_YEAR = 'saREndYears',
  INCENTIVE_VALUE_FPL = 'nationalSaRIncentiveFpl',
  FPL_INCENTIVE_NEW = 'nationalFplSaRNew',
  FPL_INCENTIVE_USED = 'nationalFplSaRUsed',
  INCENTIVE_VALUE_NON_FLP = 'nationalSaRIncentiveNonfpl',
  NON_FPL_INCENTIVE_NEW = 'nationalNonfplSaRNew',
  NON_FPL_INCENTIVE_USED = 'nationalNonfplSaRUsed'
}

export enum RegionalScrapReplaceProgramIds {
  START_YEAR = 'regionSaRStartYear',
  END_YEAR = 'regionSaREndYears',
  MAXIMUM_AGE_OF_VEHICLE = 'regionAgeVehicle',
  INCENTIVE_VALUE_FPL = 'regionSaRIncentiveFpl',
  FPL_INCENTIVE_NEW = 'regionFplSaRNew',
  FPL_INCENTIVE_USED = 'regionFplSaRUsed',
  INCENTIVE_VALUE_NON_FLP = 'regionSaRIncentiveNonfpl',
  NON_FPL_INCENTIVE_NEW = 'regionNonfplSaRNew',
  NON_FPL_INCENTIVE_USED = 'regionNonfplSaRUsed'
}

export type CommercialFleetIncentiveValues = keyof Record<
  CommercialFleetIncentiveIds,
  string
>

export type RegionalCommercialFleetIncentiveValues = keyof Record<
  RegionalCommercialFleetIncentiveIds,
  string
>

export type EvIncentiveValues = keyof Record<EvIncentiveIds, string>
export type EvIncentiveRegularValues = keyof Record<
  EvIncentiveRegularIds,
  string
>
export type EviIncentiveValues = keyof Record<EviIncentiveIds, string>
export type RegionalEviIncentiveValues = keyof Record<
  RegionalEviIncentiveIds,
  string
>

export type MsrpCapValues = keyof Record<MsrpCapIds, string>
export type RegionalMsrpCapValues = keyof Record<RegionalMsrpCapIds, string>

export type RegulatoryPoliciesValues = keyof Record<
  RegulatoryPoliciesIds,
  string
>

export type RegionalRegulatoryPoliciesValues = keyof Record<
  RegionalRegulatoryPoliciesIds,
  string
>

export type ScrapReplaceProgramValues = keyof Record<
  ScrapReplaceProgramIds,
  string
>
export type RegionalScrapReplaceProgramValues = keyof Record<
  RegionalScrapReplaceProgramIds,
  string
>

export interface ObjectProps {
  [key: string]: any
}

export enum ActionTypeName {
  SAVE = 'save',
  REVERT = 'revert'
}

export type InputsPolicyProps = {
  children?: ReactNode
  defaults: Policy | ObjectProps
  updates: ObjectProps
  defaultPolicy: ObjectProps
  isRegionalDisabled?: boolean | undefined
  error: ErrorType | undefined
  onSetToBAU?: () => void
  onChange: (values: Partial<Policy>, action?: ActionTypeName) => void
  onError: ({ errorId, values }: ErrorProps) => void
}

export type RangeProps = {
  start: number
  end: number
  step: number
}

export interface CheckValidProps {
  id: PolicyKeys
  value: any
  restrictions: Restrictions | {}
}

export interface CustomSettingsProps {
  defaults: Policy | {}
  updates: Partial<Policy> | undefined
}

export interface ChangeValuesProps {
  id: PolicyKeys
  isOverrided: boolean
  settings: CustomSettingType[]
}

export interface CurrentDropdownProps {
  dropdowns: any
  value: number
  id: string
}

export interface DropdownValue {
  id: string
  value: string
  name: string
  onClick: () => void
}

export interface CoincidenceProps {
  option: string
  value: number
  dropdownId: string
  getDropdown: (value: number, id: string) => DropdownValue
}

export interface SetErrorProps {
  id: any
  isValid: boolean
  rule: any
  sliderId: string
  onError: ({ errorId, values }: ErrorProps) => void
}

export interface FleetAgeYearValidateProps {
  id: AdditionalGraphParametersValues
  value: string
  years: number
  restrictions: Restrictions | {}
}

export interface NewEvEndYearsVisibilityProps {
  value: number
  id: string
  options: string[]
  getDropdown: (value: number, id: string) => DropdownValue
}

export interface TaxCreditSarProps {
  id1: PolicyKeys
  id2: PolicyKeys
  currentValues: Policy
  updates: Partial<Policy>
}

export type InvalidValuesProps = [string, ErrorResponse]

export interface GetPolicyNameProps {
  updates: Partial<Policy>
  defaults: Policy
}

export interface GetHasRegionTaxesProps {
  updates: Partial<Policy>
  defaults: Policy | {}
  catalog: CatalogProperties | {}
}

export type PolicyKeysProps = Partial<PolicyKeys>
export interface CheckAllErrorsProps {
  values: Partial<Policy>
  radioId: PolicyKeysProps
  ids: PolicyKeysProps[]
  error?: ErrorType | undefined
}

export interface CheckDropdownErrorsProps {
  ids: PolicyKeysProps[]
  error?: ErrorType | undefined
}

export interface SliderValuesProps {
  [key: string]: string[]
}

export enum InputTypeValue {
  DOLLAR = 'dollar',
  YEAR = 'year',
  YEARS = 'years',
  EVS = 'evs',
  PERCENT = 'percent'
}

export interface GetMsrpValuesFlagsProps {
  msrpCap: Partial<Policy>
  dropdowns: any
}

export interface GetEvIncValuesFlagsProps {
  evIncentive: Partial<Policy>
  dropdowns: any
  handleOnGetCurrentDropdown: (value: number, id: string) => any
}
