import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    outline: `2px dashed ${theme.colors.border}`,
    height: 98,
    width: '100%',
    borderRadius: 16
  }
}))
