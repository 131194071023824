import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const ViewAgenda: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.667 2H2.33366C1.96699 2 1.66699 2.3 1.66699 2.66667V6.66667C1.66699 7.03333 1.96699 7.33333 2.33366 7.33333H13.667C14.0337 7.33333 14.3337 7.03333 14.3337 6.66667V2.66667C14.3337 2.3 14.0337 2 13.667 2ZM13.0003 6V3.33333H3.00033V6H13.0003ZM13.0003 12.6667V10H3.00033V12.6667H13.0003ZM2.33366 8.66667H13.667C14.0337 8.66667 14.3337 8.96667 14.3337 9.33333V13.3333C14.3337 13.7 14.0337 14 13.667 14H2.33366C1.96699 14 1.66699 13.7 1.66699 13.3333V9.33333C1.66699 8.96667 1.96699 8.66667 2.33366 8.66667Z"
        fill={props.fill || theme.colors.darkBlue}
      />
    </svg>
  )
}
