import React, { FC, useState, useEffect, useRef, useCallback } from 'react'
import { useTheme } from 'react-jss'

import { Column, Hint, Icon, IconName, RadioButton } from '..'
import { LightTheme } from '../../theme'
import { Row } from '../row'
import { Text, ComponentTextProps } from '../text'
import { InfoModal } from '../info-modal'
import { RadioButtonGroupProps } from './radio-button-group.types'
import { useStyle } from './radio-button-group.styles'

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  className = '',
  value,
  text = '',
  tx,
  disabled = false,
  isAutoHeight = false,
  isUndo = false,
  firstLabelProps,
  secondLabelProps,
  errorProps,
  statusIcon = false,
  attentionTx,
  attentionText = '',
  description = '',
  onChange,
  onUndoClick,
  onCustomTextClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, description, isAutoHeight })
  const ref = useRef<HTMLDivElement>(null)
  const [isFocused, changeIsFocused] = useState(false)
  const [openModal, changeOpenModal] = useState(false)

  const defDisabled = disabled ? 'disabled' : undefined
  const [isDisabled] = useState(defDisabled)
  const iconColor = isUndo ? 'path' : 'darkBlue'
  const disabledIconColor = isDisabled ? 'inactive' : iconColor
  const radioColor = errorProps ? 'red' : disabledIconColor

  const disabledTextColor = isDisabled ? 'border' : 'text'
  const buttonsStyle = className || classes.radioBtns
  const containerStyle = className || classes.cell
  const radioStyle = className ? classes.colRadio : ''

  const yesLabelProps: ComponentTextProps = {
    text: 'Yes',
    tx: 'policy.yes',
    color: 'inactive',
    ...firstLabelProps
  }

  const noLabelProps: ComponentTextProps = {
    text: 'No',
    tx: 'policy.no',
    color: 'inactive',
    ...secondLabelProps
  }

  useEffect(() => {
    if (errorProps && ref.current && !isFocused) {
      ref.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      })
    }
  }, [errorProps])

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    [openModal]
  )

  const onGetDescription = () => {
    if (description) {
      handleOnOpenModal(true)()

      if (onCustomTextClick) {
        onCustomTextClick()
      }
    }
  }

  const handleOnRadioChange = (param: string) => (status: boolean) => {
    if (!isDisabled) {
      const isChecked = param === 'yes' && status
      if (onChange) {
        onChange(isChecked)
      }
    }
  }

  const handleOnFocusUpdate = (state: boolean): any => {
    changeIsFocused(state)
  }

  return (
    <Column
      ref={ref}
      className={`${containerStyle} ${classes.radioContainer}`}
      alignItems="flex-start"
      justifyContent="space-between"
      onBlur={() => handleOnFocusUpdate(false)}
      onFocus={() => handleOnFocusUpdate(true)}
    >
      {text && (
        <Text
          className={classes.title}
          text={text}
          tx={tx}
          preset="caption"
          color={disabledTextColor}
          onClick={onGetDescription}
        />
      )}
      <Row className={buttonsStyle}>
        <RadioButton
          className={`${classes.radio} ${radioStyle}`}
          defaultChecked={value}
          iconColor={radioColor}
          textProps={yesLabelProps}
          onChange={handleOnRadioChange('yes')}
        />
        <RadioButton
          className={`${classes.radio} ${radioStyle}`}
          defaultChecked={!value}
          iconColor={radioColor}
          textProps={noLabelProps}
          onChange={handleOnRadioChange('no')}
        />
      </Row>

      {statusIcon && (
        <Row className={classes.warning}>
          <Icon name={IconName.ALERT_CIRCLE} />
          {attentionTx && (
            <Hint
              className={classes.hint}
              tx={attentionTx}
              text={attentionText}
            />
          )}
        </Row>
      )}

      {isUndo && (
        <Column className={classes.undoIcon} onClick={onUndoClick}>
          <Icon name={IconName.UNDO} fill="purple" />
          <Text tx="reset.title" text="reset" color="purple" preset="title" />
        </Column>
      )}

      {openModal && (
        <InfoModal
          titleProps={{
            text,
            tx
          }}
          onClose={handleOnOpenModal(false)}
        >
          {description && <Text text={description} preset="body" />}
        </InfoModal>
      )}
    </Column>
  )
}
