import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles(() => ({
  container: {
    width: 800,
    height: 950
  },
  image: {
    position: 'absolute',
    top: '25%',
    left: '-10%',
    transform: 'rotate(-90deg)',
    width: 810,
    height: 455
  }
}))
