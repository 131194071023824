import React, { FC, useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import { State } from '../../../store'
import { Policy, Restrictions } from '../../../services'
import { Row } from '../../row'
import { Button } from '../../button'
import {
  Content,
  Column,
  Input,
  Dropdown,
  Text,
  DropdownItemData,
  RadioButtonGroup,
  CustomRadioButtonGroup
} from '../..'
import {
  getDropdownData,
  getUpcomingSetting,
  getCustomSettings,
  getSetting,
  changeCurrentValues,
  getIsValid,
  getCurrentDropdown,
  getError,
  hasCoincidence,
  setError,
  getSliderId,
  handleOnCheckDropdownErrors,
  handleOnCheckAllErrors,
  getBooleanValue,
  getSettingValue,
  getLabel,
  formatValue
} from '../helpers'
import {
  percentIconProps,
  mpgIconProps,
  excludeProps,
  includeProps
} from '../label-props'
import {
  customStyles,
  EXCLUDE_EV_CAFE_RADIO_IDS,
  FORECASTED_MPG_DROPDOWN_IDS,
  NUMBER_VALUES_IDS,
  OVERRIDE_RETIREMENT_RADIO_IDS,
  RADIO_ID_DEPENDENCIES
} from '../constants'
import {
  InputsPolicyProps,
  InputTypeValue,
  VehicleFleetCharacteristicsIds,
  VehicleFleetCharacteristicsValues
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const VehicleFleetCharacteristics: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onSetToBAU,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions, catalog, bauDefaults } = useSelector(
    (state: State) => ({
      restrictions: state.policy.restrictions as Restrictions,
      catalog: state.policy.catalog,
      bauDefaults: state.policy.bauDefaults
    })
  )

  const dropdowns = {
    cafeStandardOption: getDropdownData(
      VehicleFleetCharacteristicsIds.FORECASTED_MPG,
      catalog
    )
  }

  // start of manipulating with default settings
  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: VehicleFleetCharacteristicsValues) =>
      getUpcomingSetting(id, settingsList),
    [settingsList]
  )
  // eof manipulating with default settings

  const getVehicleFleet = useCallback(
    (): any => ({
      cafeStandardOption: handleGetUpcomingSetting(
        VehicleFleetCharacteristicsIds.FORECASTED_MPG
      ),
      input2050Mpg: handleGetUpcomingSetting(
        VehicleFleetCharacteristicsIds.MPG
      ),
      vmtGrowth: handleGetUpcomingSetting(
        VehicleFleetCharacteristicsIds.GROWTH_RATE
      ),
      newVehGrowth: handleGetUpcomingSetting(
        VehicleFleetCharacteristicsIds.FLEET_SIZE
      ),
      excludeEvCafe: handleGetUpcomingSetting(
        VehicleFleetCharacteristicsIds.EXCLUDE_EV_CAFE
      ),
      phevLastYear: handleGetUpcomingSetting(
        VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR
      ),
      overrideRetirement: handleGetUpcomingSetting(
        VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT
      ),
      fleetGrowthRate: handleGetUpcomingSetting(
        VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE
      )
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getVehicleFleet()
  const [
    vehicleFleetCharacteristics,
    changeVehicleFleetCharacteristics
  ] = useState(fields)

  // when we press 'save' we get updated data from main 'update-policy' component
  // so we need to update our defaults for 'undo' fields state
  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeVehicleFleetCharacteristics(getVehicleFleet())
    changeSettings(s)
  }, [defaults, updates])

  // METHODS
  const getIsExist = (id: VehicleFleetCharacteristicsValues) =>
    vehicleFleetCharacteristics[id] !== undefined
  const getIsDefault = (id: VehicleFleetCharacteristicsValues) =>
    vehicleFleetCharacteristics[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (
    id: VehicleFleetCharacteristicsValues,
    flag: InputTypeValue
  ) => (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    // TODO: check do we need this regexp
    const reg = new RegExp('^[0-9]+$') // only digits
    const stabValue = reg.test(String(value)) ? Number(value) : value

    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeVehicleFleetCharacteristics({
      ...vehicleFleetCharacteristics,
      [id]: stabValue
    })
    changeSettings(updSettings)
    onChange({ [id]: stabValue })
  }

  const handleOnBlur = (id: VehicleFleetCharacteristicsValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnDropdownChange = (id: VehicleFleetCharacteristicsValues) => (
    value: DropdownItemData
  ) => {
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeVehicleFleetCharacteristics({
      ...vehicleFleetCharacteristics,
      [id]: Number(value.id)
    })
    changeSettings(updSettings)
    onChange({ [id]: Number(value.id) })
  }

  const handleOnRadioChange = (id: string) => (status: boolean) => {
    changeVehicleFleetCharacteristics({
      ...vehicleFleetCharacteristics,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const getIsUpdateInBauValues = (key: keyof Policy) => {
    const isUndefined = typeof updates[key] === 'undefined'
    const isEqual = updates[key] === bauDefaults[key]

    return !isUndefined && isEqual
  }

  const getAttentionIconState = (id: keyof Policy): object => {
    const isRadioOff = !vehicleFleetCharacteristics[id]
    const bauKeys = Object.keys(bauDefaults)
    const newKeys = Object.keys(updates)
    const dependencyKeys = RADIO_ID_DEPENDENCIES[id]
    const isBauSetted = dependencyKeys.find(getIsUpdateInBauValues)
    const isBauValuesFromDependency = dependencyKeys.find((depId: string) =>
      bauKeys.includes(depId)
    )
    let isIconVisible = Boolean(
      isRadioOff && isBauSetted && isBauValuesFromDependency
    )

    if (isBauSetted) {
      return {
        statusIcon: isIconVisible,
        attentionText:
          'Underlying inputs were changed to BAU.\nOpen the section to review \nand/or discard changes.',
        attentionTx: 'policy.radio.warning'
      }
    }

    const isNewValuesFromDependency = dependencyKeys.find((depId: string) =>
      newKeys.includes(depId)
    )
    isIconVisible = Boolean(isRadioOff && isNewValuesFromDependency)

    return {
      statusIcon: isIconVisible,
      attentionText:
        'Underlying inputs were changed,\nthis may affect policy output.\nOpen the section to review \nand/or discard changes.',
      attentionTx: 'policy.radio.warning2'
    }
  }

  const handleUndoClick = (id: VehicleFleetCharacteristicsValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value
      changeVehicleFleetCharacteristics({
        ...vehicleFleetCharacteristics,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const getCorrectValueById = ({ settingId = '', value = false }) => {
    const hasSetting = String(value) && settingId

    if (!hasSetting) {
      return {}
    }

    const settingValue =
      hasSetting && NUMBER_VALUES_IDS.includes(settingId)
        ? getSettingValue(value)
        : value

    if (settingId && settings) {
      changeSettings({
        ...settings,
        [settingId]: settingValue
      })
    }
    return settingValue
  }

  const getSwitcherValue = ({ settingId = '', value = false }) => {
    const settingValue = getCorrectValueById({ settingId, value })
    const settingUpdate = settingId && { [settingId]: settingValue }
    return settingUpdate
  }

  const handleOnCustomRadioChange = (id: VehicleFleetCharacteristicsIds) => (
    value: boolean
  ) => {
    const settingUpdate = getSwitcherValue({
      settingId: id,
      value
    }) as Partial<Policy>
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeVehicleFleetCharacteristics({
      ...vehicleFleetCharacteristics,
      ...settingUpdate
    })
    changeSettings(updSettings)
    onChange(settingUpdate)
  }

  const handleOnSetToBau = () => {
    if (onSetToBAU) {
      onSetToBAU()
    }
  }

  const handleOnGetCurrentDropdown = (value: number, id: string) =>
    getCurrentDropdown({
      dropdowns,
      value,
      id
    })
  const handleOnGetError = (id: VehicleFleetCharacteristicsValues) =>
    getError(id, error)
  const getEmptyDropdownStyle = (id: VehicleFleetCharacteristicsIds) =>
    getIsExist(id) ? undefined : customStyles
  // eof METHODS

  const isUserInputSelected = hasCoincidence({
    option: 'user',
    value: vehicleFleetCharacteristics.cafeStandardOption,
    dropdownId: VehicleFleetCharacteristicsIds.FORECASTED_MPG,
    getDropdown: handleOnGetCurrentDropdown
  })
  const isCafeSelected = hasCoincidence({
    option: 'cafe',
    value: vehicleFleetCharacteristics.cafeStandardOption,
    dropdownId: VehicleFleetCharacteristicsIds.FORECASTED_MPG,
    getDropdown: handleOnGetCurrentDropdown
  })
  const combinedClass = isUserInputSelected ? classes.combinedField : ''

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={`${classes.cell} ${combinedClass}`}
            alignItems="flex-end"
          >
            <Dropdown
              className={classes.dropdown}
              custom={getEmptyDropdownStyle(
                VehicleFleetCharacteristicsIds.FORECASTED_MPG
              )}
              label={
                getLabel(
                  VehicleFleetCharacteristicsIds.FORECASTED_MPG,
                  restrictions
                ).text
              }
              preset="body"
              data={dropdowns.cafeStandardOption}
              defaultValue={handleOnGetCurrentDropdown(
                vehicleFleetCharacteristics.cafeStandardOption,
                VehicleFleetCharacteristicsIds.FORECASTED_MPG
              )}
              description={
                restrictions[VehicleFleetCharacteristicsIds.FORECASTED_MPG]
                  ?.description
              }
              disabled={!vehicleFleetCharacteristics.cafeStandardOption}
              errorProps={handleOnCheckDropdownErrors({
                ids: FORECASTED_MPG_DROPDOWN_IDS,
                error
              })}
              isUndo={getIsDefault(
                VehicleFleetCharacteristicsIds.FORECASTED_MPG
              )}
              onChange={handleOnDropdownChange(
                VehicleFleetCharacteristicsIds.FORECASTED_MPG
              )}
              onUndoClick={handleUndoClick(
                VehicleFleetCharacteristicsIds.FORECASTED_MPG
              )}
            />
            {isUserInputSelected && (
              <Input
                alignItems="flex-start"
                id={VehicleFleetCharacteristicsIds.MPG}
                labelProps={getLabel(
                  VehicleFleetCharacteristicsIds.MPG,
                  restrictions
                )}
                iconProps={mpgIconProps}
                defaultValue={vehicleFleetCharacteristics.input2050Mpg}
                isFocused={getIsExist(VehicleFleetCharacteristicsIds.MPG)}
                description={
                  restrictions[VehicleFleetCharacteristicsIds.MPG]?.description
                }
                disabled={!isUserInputSelected}
                isUndo={getIsDefault(VehicleFleetCharacteristicsIds.MPG)}
                errorProps={handleOnGetError(
                  VehicleFleetCharacteristicsIds.MPG
                )}
                onBlur={handleOnBlur(VehicleFleetCharacteristicsIds.MPG)}
                onChange={handleOnChange(
                  VehicleFleetCharacteristicsIds.MPG,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(
                  VehicleFleetCharacteristicsIds.MPG
                )}
              />
            )}
          </Row>
          {isCafeSelected && (
            <>
              <Column
                className={`${classes.cell} ${classes.selectionControlsContainer}`}
                alignItems="flex-start"
              >
                <CustomRadioButtonGroup
                  className={classes.customRadio}
                  labelProps={getLabel(
                    VehicleFleetCharacteristicsIds.EXCLUDE_EV_CAFE,
                    restrictions
                  )}
                  firstLabelProps={excludeProps}
                  secondLabelProps={includeProps}
                  value={getBooleanValue(
                    vehicleFleetCharacteristics.excludeEvCafe
                  )}
                  description={
                    restrictions[VehicleFleetCharacteristicsIds.EXCLUDE_EV_CAFE]
                      ?.description
                  }
                  errorProps={handleOnCheckAllErrors({
                    values: vehicleFleetCharacteristics,
                    radioId: VehicleFleetCharacteristicsIds.EXCLUDE_EV_CAFE,
                    ids: EXCLUDE_EV_CAFE_RADIO_IDS
                  })}
                  {...getAttentionIconState(
                    VehicleFleetCharacteristicsIds.EXCLUDE_EV_CAFE
                  )}
                  isUndo={getIsDefault(
                    VehicleFleetCharacteristicsIds.EXCLUDE_EV_CAFE
                  )}
                  onChange={handleOnCustomRadioChange(
                    VehicleFleetCharacteristicsIds.EXCLUDE_EV_CAFE
                  )}
                  onUndoClick={handleUndoClick(
                    VehicleFleetCharacteristicsIds.EXCLUDE_EV_CAFE
                  )}
                />
              </Column>
              {vehicleFleetCharacteristics.excludeEvCafe === 1 && (
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR}
                  labelProps={getLabel(
                    VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR,
                    restrictions
                  )}
                  isPercent
                  defaultValue={vehicleFleetCharacteristics.phevLastYear}
                  iconProps={percentIconProps}
                  isFocused={getIsExist(
                    VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR
                  )}
                  isUndo={getIsDefault(
                    VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR
                  )}
                  description={
                    restrictions[VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR]
                      ?.description
                  }
                  disabled={
                    !getIsExist(VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR)
                  }
                  errorProps={handleOnGetError(
                    VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR
                  )}
                  onBlur={handleOnBlur(
                    VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR
                  )}
                  onChange={handleOnChange(
                    VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR,
                    InputTypeValue.PERCENT
                  )}
                  onUndoClick={handleUndoClick(
                    VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR
                  )}
                />
              )}
            </>
          )}
          <Row
            className={`${classes.row} ${classes.responsiveRow}`}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={VehicleFleetCharacteristicsIds.GROWTH_RATE}
              labelProps={getLabel(
                VehicleFleetCharacteristicsIds.GROWTH_RATE,
                restrictions
              )}
              isPercent
              defaultValue={vehicleFleetCharacteristics.vmtGrowth}
              iconProps={percentIconProps}
              isFocused={getIsExist(VehicleFleetCharacteristicsIds.GROWTH_RATE)}
              isUndo={getIsDefault(VehicleFleetCharacteristicsIds.GROWTH_RATE)}
              description={
                restrictions[VehicleFleetCharacteristicsIds.GROWTH_RATE]
                  ?.description
              }
              disabled={!getIsExist(VehicleFleetCharacteristicsIds.GROWTH_RATE)}
              errorProps={handleOnGetError(
                VehicleFleetCharacteristicsIds.GROWTH_RATE
              )}
              onBlur={handleOnBlur(VehicleFleetCharacteristicsIds.GROWTH_RATE)}
              onChange={handleOnChange(
                VehicleFleetCharacteristicsIds.GROWTH_RATE,
                InputTypeValue.PERCENT
              )}
              onUndoClick={handleUndoClick(
                VehicleFleetCharacteristicsIds.GROWTH_RATE
              )}
            />
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={VehicleFleetCharacteristicsIds.FLEET_SIZE}
              labelProps={getLabel(
                VehicleFleetCharacteristicsIds.FLEET_SIZE,
                restrictions
              )}
              isPercent
              defaultValue={vehicleFleetCharacteristics.newVehGrowth}
              iconProps={percentIconProps}
              isFocused={getIsExist(VehicleFleetCharacteristicsIds.FLEET_SIZE)}
              isUndo={getIsDefault(VehicleFleetCharacteristicsIds.FLEET_SIZE)}
              description={
                restrictions[VehicleFleetCharacteristicsIds.FLEET_SIZE]
                  ?.description
              }
              disabled={!getIsExist(VehicleFleetCharacteristicsIds.FLEET_SIZE)}
              errorProps={handleOnGetError(
                VehicleFleetCharacteristicsIds.FLEET_SIZE
              )}
              onBlur={handleOnBlur(VehicleFleetCharacteristicsIds.FLEET_SIZE)}
              onChange={handleOnChange(
                VehicleFleetCharacteristicsIds.FLEET_SIZE,
                InputTypeValue.PERCENT
              )}
              onUndoClick={handleUndoClick(
                VehicleFleetCharacteristicsIds.FLEET_SIZE
              )}
            />
          </Row>
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(
                VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT,
                restrictions
              )}
              value={vehicleFleetCharacteristics.overrideRetirement}
              disabled={
                !getIsExist(VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT)
              }
              description={
                restrictions[VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT]
                  ?.description
              }
              isUndo={getIsDefault(
                VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT
              )}
              errorProps={handleOnCheckAllErrors({
                values: vehicleFleetCharacteristics,
                radioId: VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT,
                ids: OVERRIDE_RETIREMENT_RADIO_IDS
              })}
              {...getAttentionIconState(
                VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT
              )}
              onChange={handleOnRadioChange(
                VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT
              )}
              onUndoClick={handleUndoClick(
                VehicleFleetCharacteristicsIds.OVERRIDE_RETIREMENT
              )}
            />
          </Column>
          {vehicleFleetCharacteristics.overrideRetirement && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE}
              labelProps={getLabel(
                VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE,
                restrictions
              )}
              isPercent
              defaultValue={vehicleFleetCharacteristics.fleetGrowthRate}
              iconProps={percentIconProps}
              isFocused={getIsExist(
                VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE
              )}
              isUndo={getIsDefault(
                VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE
              )}
              description={
                restrictions[VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE]
                  ?.description
              }
              disabled={
                !getIsExist(VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE)
              }
              errorProps={handleOnGetError(
                VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE
              )}
              onBlur={handleOnBlur(
                VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE
              )}
              onChange={handleOnChange(
                VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE,
                InputTypeValue.PERCENT
              )}
              onUndoClick={handleUndoClick(
                VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE
              )}
            />
          )}
        </Row>
        <Row className={classes.row} fullWidth justifyContent="flex-end">
          <Row className={classes.cell} alignItems="flex-end">
            <Button preset="secondary" onClick={handleOnSetToBau}>
              <Text
                tx="btn.setToBau"
                text="Set to BAU"
                color="darkBlue"
                preset="body"
              />
            </Button>
          </Row>
        </Row>
      </Content>
    </Column>
  )
}
