import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import moment from 'moment'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { useStyle } from './policy-mini-item.styles'
import { PolicyMiniItemProps } from './policy-mini-item.types'

export const PolicyMiniItem: FC<PolicyMiniItemProps> = ({
  className = '',
  updatedAt,
  name,
  onRemove
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const dateModified = moment(updatedAt).format('MMM D, YYYY')
  return (
    <Column
      className={`${className} ${classes.container}`}
      fullWidth
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="space-between">
        <Text
          className={classes.text}
          text={dateModified}
          preset="caption"
          color="border"
        />
        <Column className={classes.close} onClick={onRemove}>
          <Icon name={IconName.CLEAR} fill={theme.colors.darkBlue} />
        </Column>
      </Row>
      <Column
        fullWidth
        alignItems="flex-start"
        className={classes.name}
      >
        <Text className={classes.text} text={name} preset="h3" />
      </Column>
    </Column>
  )
}
