import React, { FC, useState, FocusEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  Column,
  IconName,
  Input,
  Row,
  Text
} from '../../../../components'
import { AuthCreators, ForgotPasswordCreators, State } from '../../../../store'

import {
  oldPasswordLabelProps,
  errorProps,
  parametersErrorProps,
  passDifferProps,
  passMatchProps,
  passwordLabelProps,
  reEnterPasswordLabelProps
} from '../../labels'

import { FieldIds, FieldType, TypesValue } from '../forgot-password'

import {
  ChangePasswordProps,
  RecoveryPasswordData,
  RecoveryPasswordIds,
  RecoveryPasswordErrorsProps
} from './change-password.types'
import { useStyle } from '../forgot-password/forgot-password.styles'

const DEFAULT_FIELD_TYPES: TypesValue = {
  oldPassword: FieldType.PASSWORD,
  password: FieldType.PASSWORD,
  repeatPassword: FieldType.PASSWORD
}

export const ChangePassword: FC<ChangePasswordProps> = ({
  className = '',
  ...props
}) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const [isSent, changeIsSent] = useState<boolean>(false)
  const [error, changeError] = useState<RecoveryPasswordErrorsProps>({
    oldPassword: undefined,
    password: undefined,
    repeatPassword: undefined
  })
  const [recoveryData, changeRecoveryData] = useState<RecoveryPasswordData>({
    oldPassword: '',
    password: '',
    repeatPassword: ''
  })
  const [types, changeTypes] = useState<TypesValue>(DEFAULT_FIELD_TYPES)
  const { changed, invalidValues } = useSelector((state: State) => ({
    changed: state.changeCurrentPassword.changed,
    invalidValues: state.changeCurrentPassword.invalidValues
  }))

  const isResetDisabled =
    !recoveryData.oldPassword ||
    recoveryData.password !== recoveryData.repeatPassword ||
    !recoveryData.password ||
    !recoveryData.repeatPassword

  const goToLogin = () => {
    dispatch(AuthCreators.resetLogout())
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = e.target

    changeRecoveryData({
      ...recoveryData,
      [id]: value
    })
  }

  const onIconClick = (id: FieldIds) => {
    const isPassword = types[id] !== FieldType.INPUT
    const type = isPassword ? FieldType.INPUT : FieldType.PASSWORD

    changeTypes({
      ...types,
      [id]: type
    })
  }

  const getIconName = (id: FieldIds): IconName =>
    types[id] === FieldType.INPUT
      ? IconName.VISIBILITY
      : IconName.VISIBILITY_OFF

  const onOldPassIconClick = () => onIconClick('oldPassword')
  const onPassIconClick = () => onIconClick('password')
  const onRepeatPassIconClick = () => onIconClick('repeatPassword')

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const id = (event.target as Element).id as RecoveryPasswordIds
    const { value } = event.target
    // Minimum eight characters, at least one letter and one number
    const regexp = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$')
    const isPasswordCorrect = regexp.test(value)

    const isOldPassword = id !== 'oldPassword'
    const isSameAsOld = recoveryData.oldPassword === recoveryData[id]
    const isNewPassword = isOldPassword && isSameAsOld && recoveryData[id]

    const isPassMatch = recoveryData.password !== recoveryData.repeatPassword
    const isTypedData =
      Boolean(recoveryData.password) && Boolean(recoveryData.repeatPassword)

    if (isPassMatch && isTypedData) {
      changeError({
        ...error,
        repeatPassword: passMatchProps
      })
      return
    }

    if (isNewPassword) {
      changeError({
        ...error,
        [id]: passDifferProps
      })
      return
    }

    if (!isPasswordCorrect) {
      changeError({
        ...error,
        [id]: parametersErrorProps
      })
      return
    }

    changeError({
      ...error,
      [id]: ''
    })
  }

  const onSubmit = () => {
    if (!isResetDisabled) {
      dispatch(
        ForgotPasswordCreators.postChangeCurrentPasswordRequest({
          oldPassword: recoveryData.oldPassword,
          newPassword1: recoveryData.password,
          newPassword2: recoveryData.repeatPassword
        })
      )
    } else {
      const passwordErrors = !recoveryData.password ? errorProps : undefined
      const repeatPasswordErrors = !recoveryData.repeatPassword
        ? errorProps
        : undefined

      changeError({
        oldPassword: undefined,
        password: passwordErrors,
        repeatPassword: repeatPasswordErrors
      })
    }
  }

  useEffect(() => {
    if (changed) {
      changeIsSent(true)
      setTimeout(() => {
        dispatch(AuthCreators.resetLogout())
      }, 6000)
    }

    return () => {
      changeIsSent(false)
    }
  }, [changed])

  useEffect(() => {
    if (Object.values(invalidValues).length) {
      const key = Object.keys(invalidValues)[0]
      const value: any = Object.values(invalidValues)[0]

      changeError({
        ...error,
        [key]: {
          text: value[0],
          color: 'red'
        }
      })
    }
  }, [invalidValues])

  useEffect(() => {
    return () => {
      dispatch(ForgotPasswordCreators.postChangeCurrentPasswordRequest())
    }
  }, [])

  return (
    <Column {...props} className={`${className} ${classes.signIn}`}>
      <Column fullHeight fullWidth justifyContent="space-between">
        {isSent ? (
          <>
            <Row fullWidth justifyContent="flex-start">
              <Text
                text="Successful password change!"
                tx="password.change.success.title"
                preset="h1"
                color="text"
                className={classes.title}
              />
            </Row>
            <Column fullHeight fullWidth justifyContent="space-between">
              <Row
                fullWidth
                justifyContent="flex-start"
                className={classes.container}
              >
                <Text
                  tx="password.reset.success"
                  text="You can now use your new password to login in to your account!"
                  color="text"
                  preset="body"
                />
              </Row>
              <Row>
                <Button className={classes.button} onClick={goToLogin}>
                  <Text text="Login" tx="login.title" preset="body" />
                </Button>
              </Row>
            </Column>
          </>
        ) : (
          <>
            <Row fullWidth justifyContent="flex-start">
              <Text
                text="Change your password"
                tx="password.change.title"
                preset="h1"
                color="text"
                className={classes.title}
              />
            </Row>
            <Row
              fullWidth
              justifyContent="flex-start"
              className={classes.container}
            >
              <Text
                text="Your new password must be different from the previously used password."
                tx="password.reset.desc"
                preset="body"
                color="text"
              />
            </Row>
            <Column fullWidth fullHeight justifyContent="space-between">
              <Column fullWidth>
                <Row fullWidth className={classes.changeInput}>
                  <Input
                    id="oldPassword"
                    className={classes.input}
                    alignItems="flex-start"
                    isRegular
                    labelProps={oldPasswordLabelProps}
                    defaultValue={recoveryData.oldPassword}
                    type={types.oldPassword}
                    iconName={getIconName('oldPassword')}
                    isFocused={Boolean(recoveryData.oldPassword)}
                    errorProps={error.oldPassword}
                    onChange={onChange}
                    onBlur={onBlur}
                    onIconClick={onOldPassIconClick}
                  />
                </Row>
                <Row fullWidth className={classes.changeInput}>
                  <Input
                    id="password"
                    className={classes.input}
                    alignItems="flex-start"
                    isRegular
                    labelProps={passwordLabelProps}
                    defaultValue={recoveryData.password}
                    type={types.password}
                    iconName={getIconName('password')}
                    isFocused={Boolean(recoveryData.password)}
                    errorProps={error.password}
                    onChange={onChange}
                    onBlur={onBlur}
                    onIconClick={onPassIconClick}
                  />
                </Row>
                <Row fullWidth className={classes.changeInput}>
                  <Input
                    id="repeatPassword"
                    className={classes.input}
                    alignItems="flex-start"
                    isRegular
                    labelProps={reEnterPasswordLabelProps}
                    defaultValue={recoveryData.repeatPassword}
                    type={types.repeatPassword}
                    iconName={getIconName('repeatPassword')}
                    isFocused={Boolean(recoveryData.repeatPassword)}
                    errorProps={error.repeatPassword}
                    onChange={onChange}
                    onBlur={onBlur}
                    onIconClick={onRepeatPassIconClick}
                  />
                </Row>
              </Column>
              <Row>
                <Button
                  className={classes.button}
                  preset="accent"
                  disabled={isResetDisabled}
                  onClick={onSubmit}
                >
                  <Text
                    text="Change Password"
                    tx="btn.changePassword"
                    preset="body"
                  />
                </Button>
              </Row>
            </Column>
          </>
        )}
      </Column>
    </Column>
  )
}
