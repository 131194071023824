import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { useResponsive } from '../../../providers'
import { Column } from '../../column'
import { Content } from '../../content'
import { Row } from '../../row'
import { SliderMenu } from '../../slider-menu'
import { IconName } from '../../icon'
import { PlotSlider } from '../plot-slider'
import { AffordabilitySlider } from '../affordability-slider'
import { Widgets } from '../widgets'
import { StateFilters } from '../state-filters'
import {
  ADDITIONAL_GRAPH,
  EV_AFFORDABILITY,
  generalInputBasicLabelProps,
  GENERAL_PLOTS
} from '../constants'
import { getFilterPreset } from '../helpers'
import { warningTextProps } from '../labels'
import { PlotIdsValues, SliderListProps } from '../policy-summary-list.types'
import { useStyle } from '../../update-policy/inputs.styles'

export const SlidersList: FC<SliderListProps> = ({
  isLoaded,
  policy,
  region,
  affordabilityPlot,
  changeSelectedAddGraph
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { isTablet } = useResponsive()
  const [openedPlot, changeOpenedPlot] = useState('')
  const [stateFilter, changeStateFilter] = useState(0)
  const isUSRegion = policy && policy.region === 1

  const handleOnOpenedPlotChange = (id: PlotIdsValues) => {
    changeOpenedPlot(id)
  }

  const reset = () => {
    changeOpenedPlot('')
  }

  const handleOnClick = (id: number) => () => changeStateFilter(id)

  const getBtnPreset = useCallback(
    (id: number) => getFilterPreset({ id, stateFilter }),
    [stateFilter]
  )

  const scaleFactorWarning = useMemo(
    () =>
      policy && policy.diffusionCurveScalar > 1 ? warningTextProps : undefined,
    []
  )

  useEffect(() => {
    if (!isUSRegion) {
      changeStateFilter(1)
    }
  }, [isUSRegion])

  return (
    <Column className={classes.container}>
      <Content className={classes.noPadding}>
        <StateFilters
          isUSRegion={isUSRegion}
          getPreset={getBtnPreset}
          handleOnClick={handleOnClick}
        />
        <Row className={`${classes.slider} ${classes.plotSlider}`}>
          <SliderMenu
            tx={generalInputBasicLabelProps.tx}
            text={generalInputBasicLabelProps.text}
            className={classes.container}
            isOpen
            containerClassName={classes.content}
            preset="h2"
            iconName={IconName.CATEGORY}
            iconColor="green"
            dropdownIconName="darkBlue"
            warningTextProps={scaleFactorWarning}
          >
            <Column className={classes.noPadding}>
              <Content className={classes.noPadding}>
                <Widgets
                  isSummary
                  vertical={isTablet}
                  policy={policy}
                  stateFilter={stateFilter}
                  region={region}
                  onClick={handleOnOpenedPlotChange}
                />
              </Content>
            </Column>
          </SliderMenu>
        </Row>
        {GENERAL_PLOTS.map((props) => (
          <PlotSlider
            key={props.plotId}
            {...props}
            stateFilter={stateFilter}
            openedPlot={openedPlot}
            policy={policy}
            changeSelectedAddGraph={changeSelectedAddGraph}
            reset={reset}
          />
        ))}
        <AffordabilitySlider
          key={EV_AFFORDABILITY.plotId}
          {...EV_AFFORDABILITY}
          isLoaded={isLoaded}
          stateFilter={stateFilter}
          openedPlot={openedPlot}
          policy={policy}
          affordabilityPlot={affordabilityPlot}
          changeSelectedAddGraph={changeSelectedAddGraph}
          reset={reset}
        />
        {ADDITIONAL_GRAPH.map((props) => (
          <PlotSlider
            key={props.plotId}
            {...props}
            stateFilter={stateFilter}
            openedPlot={openedPlot}
            policy={policy}
            changeSelectedAddGraph={changeSelectedAddGraph}
            reset={reset}
          />
        ))}
      </Content>
    </Column>
  )
}
