import { createActions, createTypes } from 'reduxsauce'

import { PolicyActions, PolicyActionsTypes } from './policy.types'

export const policyStringTypes = createTypes<PolicyActionsTypes>(`
    POLICY_COMPARE_ADD
    POLICY_COMPARE_REMOVE

    SET_POLICIES_COMPARE_LIST
    
    GET_POLICIES_REQUEST
    GET_POLICIES_SUCCESS
    GET_POLICIES_FAILURE

    GET_POLICIES_BY_PAGES_REQUEST
    GET_POLICIES_BY_PAGES_SUCCESS
    GET_POLICIES_BY_PAGES_FAILURE

    RESET_DRAGGABLE_POLICY

    CREATE_POLICY_REQUEST
    CREATE_POLICY_SUCCESS
    CREATE_POLICY_FAILURE

    GET_POLICY_REPORT_REQUEST
    GET_POLICY_REPORT_SUCCESS
    GET_POLICY_REPORT_FAILURE

    GET_POLICY_REQUEST
    GET_POLICY_SUCCESS
    GET_POLICY_FAILURE

    GET_DEFAULT_POLICY_REQUEST
    GET_DEFAULT_POLICY_SUCCESS
    GET_DEFAULT_POLICY_FAILURE

    UPDATE_POLICY_REQUEST
    UPDATE_POLICY_SUCCESS
    UPDATE_POLICY_FAILURE

    DELETE_POLICY_REQUEST
    DELETE_POLICY_SUCCESS
    DELETE_POLICY_FAILURE

    GET_POLICY_PLOT_REQUEST
    GET_POLICY_PLOT_SUCCESS
    GET_POLICY_PLOT_FAILURE

    GET_CATALOG_REQUEST
    GET_CATALOG_SUCCESS
    GET_CATALOG_FAILURE

    GET_REGIONS_REQUEST
    GET_REGIONS_SUCCESS
    GET_REGIONS_FAILURE

    GET_BAU_DEFAULTS_REQUEST
    GET_BAU_DEFAULTS_SUCCESS
    GET_BAU_DEFAULTS_FAILURE

    UPDATE_AFFORDABILITY_REQUEST
    UPDATE_AFFORDABILITY_SUCCESS
    UPDATE_AFFORDABILITY_FAILURE

    UPDATE_ADDITIONAL_GRAPH_REQUEST
    UPDATE_ADDITIONAL_GRAPH_SUCCESS
    UPDATE_ADDITIONAL_GRAPH_FAILURE

    GET_RESTRICTIONS_REQUEST
    GET_RESTRICTIONS_SUCCESS
    GET_RESTRICTIONS_FAILURE

    GET_COMPARE_POLICIES_BY_IDS_REQUEST
    GET_COMPARE_POLICIES_BY_IDS_SUCCESS
    GET_COMPARE_POLICIES_BY_IDS_FAILURE
`)

const { Types: PolicyActionTypes, Creators: PolicyCreators } = createActions<
  PolicyActionsTypes,
  PolicyActions
>({
  setPoliciesCompareList: ['payload'],

  policyCompareAdd: ['payload'],
  policyCompareRemove: ['payload'],

  getPoliciesRequest: ['payload'],
  getPoliciesSuccess: ['payload'],
  getPoliciesFailure: ['payload'],

  getPoliciesByPagesRequest: ['payload'],
  getPoliciesByPagesSuccess: ['payload'],
  getPoliciesByPagesFailure: ['payload'],

  resetDraggablePolicy: ['payload'],

  createPolicyRequest: ['payload'],
  createPolicySuccess: ['payload'],
  createPolicyFailure: ['payload'],

  getPolicyReportRequest: ['payload'],
  getPolicyReportSuccess: ['payload'],
  getPolicyReportFailure: ['payload'],

  getPolicyRequest: ['payload'],
  getPolicySuccess: ['payload'],
  getPolicyFailure: ['payload'],

  getDefaultPolicyRequest: ['payload'],
  getDefaultPolicySuccess: ['payload'],
  getDefaultPolicyFailure: ['payload'],

  updatePolicyRequest: ['payload'],
  updatePolicySuccess: ['payload'],
  updatePolicyFailure: ['payload'],

  deletePolicyRequest: ['payload'],
  deletePolicySuccess: ['payload'],
  deletePolicyFailure: ['payload'],

  getPolicyPlotRequest: ['payload'],
  getPolicyPlotSuccess: ['payload'],
  getPolicyPlotFailure: ['payload'],

  getCatalogRequest: ['payload'],
  getCatalogSuccess: ['payload'],
  getCatalogFailure: ['payload'],

  getRegionsRequest: ['payload'],
  getRegionsSuccess: ['payload'],
  getRegionsFailure: ['payload'],

  getBauDefaultsRequest: ['payload'],
  getBauDefaultsSuccess: ['payload'],
  getBauDefaultsFailure: ['payload'],

  updateAffordabilityRequest: ['payload'],
  updateAffordabilitySuccess: ['payload'],
  updateAffordabilityFailure: ['payload'],

  updateAdditionalGraphRequest: ['payload'],
  updateAdditionalGraphSuccess: ['payload'],
  updateAdditionalGraphFailure: ['payload'],

  getRestrictionsRequest: ['payload'],
  getRestrictionsSuccess: ['payload'],
  getRestrictionsFailure: ['payload'],

  getComparePoliciesByIdsRequest: ['payload'],
  getComparePoliciesByIdsSuccess: ['payload'],
  getComparePoliciesByIdsFailure: ['payload']
})

export { PolicyCreators, PolicyActionTypes }
