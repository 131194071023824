import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Ellipse9: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={6}
      height={6}
      viewBox="0 0 6 6"
      fill="none"
      {...props}
    >
      <circle cx="3" cy="3" r="3" fill={props.fill || theme.colors.darkBlue} />
    </svg>
  )
}
