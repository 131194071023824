import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import Background from '../../../components/icon/pdf-background.svg'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    backgroundColor: theme.colors.header,
    padding: '14px 48px 14px 60px'
  },
  filledBackground: {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundColor: theme.colors.white
  },
  logo: {
    cursor: theme.cursor
  },
  subTitle: {
    marginTop: 8
  },
  info: {
    marginTop: 24
  },
  text: {
    flexShrink: 0
  },
  freeSpace: {
    flexGrow: 1
  }
}))
