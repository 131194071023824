import React, { DragEvent, FC, useState, useEffect, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Column,
  DropCart,
  Icon,
  IconName,
  PolicyMiniItem,
  Row,
  Text
} from '../../../components'
import { LightTheme } from '../../../theme'
import { PolicyCreators } from '../../../store/actions'
import { PolicyWithProperties } from '../../../store/reducers/policy/policy.types'
import {
  AddItemListProps,
  UpdateItemsToCompareProps
} from './add-item-list.types'
import { useStyle } from './add-item-list.styles'

export const AddItemList: FC<AddItemListProps> = ({ item, onBack }) => {
  const history = useHistory()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [items, changeItems] = useState<PolicyWithProperties[]>([])
  const [compareItem, changeCompareItem] = useState(item)
  const dispatch = useDispatch()

  const compareDisabled = items.length < 2

  const updateItemsToCompare = useCallback(
    ({ id, nextState, nextIds }: UpdateItemsToCompareProps) => {
      changeItems(nextState)

      dispatch(PolicyCreators.policyCompareAdd({ id }))
      dispatch(PolicyCreators.setPoliciesCompareList({ ids: nextIds }))
    },
    []
  )

  useEffect(() => {
    // logic for Add to Compare button
    const isExcludesItem = !items.includes(compareItem)
    if (compareItem && isExcludesItem) {
      try {
        const nextState = [...items, compareItem]
        const nextIds = nextState.map((listItem) => listItem.id)

        updateItemsToCompare({
          id: compareItem.id,
          nextState,
          nextIds
        })
        changeCompareItem(undefined)
      } catch (e) {
        // If the text data isn't parsable we'll just ignore it.
        // @ts-ignore
        console.error(e)
      }
    }
  }, [compareItem, items, updateItemsToCompare])

  useEffect(() => {
    // need to keep this to remove item that was added via button
    // sometimes we can't delete last item (from AddToComapre btn)
    changeCompareItem(item)
  }, [item])

  const handleOnDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleOnDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    const dataString = event.dataTransfer.getData('data')
    try {
      const data: PolicyWithProperties = JSON.parse(dataString)
      const nextState = [...items, data]
      const nextIds = nextState.map((listItem) => listItem.id)

      updateItemsToCompare({
        id: data.id,
        nextState,
        nextIds
      })
    } catch (e) {
      // If the text data isn't parsable we'll just ignore it.
      // @ts-ignore
      console.error(e)
    }
  }, [items, updateItemsToCompare])

  const handleOnRemove = useCallback((id: string) => () => {
    const filterItems = items.filter((policy) => policy.id !== id)
    const nextIds = filterItems.map((listItem) => listItem.id)

    changeItems(filterItems)

    dispatch(PolicyCreators.policyCompareRemove({ id }))
    dispatch(PolicyCreators.setPoliciesCompareList({ ids: nextIds }))
  }, [items])

  const handleOnClick = () => {
    const ids = items.map((listItem) => listItem.id)
    history.push(`/policy/compare/${ids}`)
  }

  return (
    <Column className={classes.container} justifyContent="space-between">
      <Column fullWidth alignItems="flex-start">
        <Row fullWidth justifyContent="space-between">
          <Row justifyContent="flex-start">
            <Icon name={IconName.COMPARE} fill={theme.colors.text} />
            <Text
              className={classes.title}
              text="Comparison List"
              tx="comparisonList.add.title"
              preset="h1"
            />
          </Row>
          <Row className={classes.icon} onClick={onBack}>
            <Icon name={IconName.ARROW_BACK} fill={theme.colors.orange} />
          </Row>
        </Row>
        <Text
          className={classes.nextTitle}
          color="inactive"
          text="Drag to Compare"
          tx="comparisonList.drag.title"
        />
        <Column
          fullWidth
          className={classes.itemsContainer}
          justifyContent="flex-start"
        >
          {items.map((policyItem) => (
            <PolicyMiniItem
              key={`policy_mini_item_${policyItem.id}`}
              className={classes.item}
              {...policyItem}
              onRemove={handleOnRemove(policyItem.id)}
            />
          ))}
          {items.length < 3 && (
            <DropCart
              className={classes.item}
              onDrop={handleOnDrop}
              onDragOver={handleOnDragOver}
            />
          )}
        </Column>
      </Column>
      <Button
        disabled={compareDisabled}
        preset="accent"
        onClick={handleOnClick}
      >
        <Text text="Compare" tx="comparisonList.compare" color="white" />
      </Button>
    </Column>
  )
}
