import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  top: {
    marginTop: 32
  },
  input: {
    marginTop: 72,
    width: '100%'
  },
  container: {
    padding: '32px 0'
  },
  regionsContainer: {
    marginTop: 16,
    overflow: 'auto',
    maxHeight: 260,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  regions: {
    position: 'absolute',
    top: 0,
    width: 'calc(100% - 48px)'
  },
  region: {
    padding: '6px 0'
  },
  abbr: {
    width: 48
  },
  name: {
    width: 164
  }
})
