import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../column'
import { Text } from '../text'
import { NavTextProps } from './nav-text.types'
import { useStyle } from './nav-text.styles'
import { LightTheme } from '../../theme'

export const NavText: FC<NavTextProps> = ({
  className = '',
  active,
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const textColor = active ? 'blue' : 'darkBlue'

  return (
    <Column className={`${className} ${classes.container}`}>
      <Text color={textColor} {...props} />
      {active && <div className={classes.bottomDivider} />}
    </Column>
  )
}
