import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Column, Icon, Row, Text } from '../..'
import { durationAndEmissionsLabelProps } from '../../update-policy'
import { ComponentTextProps } from '../../text'
import { IconName } from '../../icon'
import { YearsSlider } from '../../years-slider'
import { range } from '../../update-policy/helpers'
import { GeneralSettingsProps } from './widgets.types'
import { useStyle } from './widgets.styles'

export const GeneralSettings: FC<GeneralSettingsProps> = ({
  stateFilter,
  years,
  region
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const regionLabelProps: ComponentTextProps = {
    tx: 'policy.summary.region',
    text: 'Region:',
    color: 'border',
    preset: 'caption',
    values: {
      region: <Text text={region.name} color="text" />
    }
  }
  const simulationYearsLabelProps: ComponentTextProps = {
    tx: 'policy.summary.years',
    text: 'Simulation Years: {years}',
    color: 'border',
    preset: 'caption',
    values: {
      years: <Text text={`${String(years)} years`} color="text" />
    }
  }

  const isUSRegion = region.value === 1
  const defaultFilter = isUSRegion ? 3 : 1
  const filter = stateFilter || defaultFilter
  const isFilterRegion = filter === 1 || filter === 2

  return (
    <Column fullWidth alignItems="flex-start">
      <Row className={classes.row} fullWidth justifyContent="flex-start">
        <Icon name={IconName.EXTENSION} fill="red" />
        <Text
          className={classes.description}
          color="text"
          preset="bodyBold"
          {...durationAndEmissionsLabelProps}
        />
      </Row>
      <Row
        className={`${classes.row} ${classes.emptyRegion}`}
        fullWidth
        justifyContent="flex-start"
      >
        {isFilterRegion && <Text {...regionLabelProps} />}
      </Row>
      <Row className={classes.row}>
        <YearsSlider
          isSmall
          range={range({ start: 10, end: 30, step: 5 })}
          disabled={true}
          value={Number(years)}
          textProps={simulationYearsLabelProps}
        />
      </Row>
    </Column>
  )
}
