import React, { useEffect } from 'react'
import { useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import {
  Breadcrumbs,
  Column,
  Content,
  Row,
  Text,
  ComponentTextProps,
  Widgets,
  Button
} from '../../components'
import { Policy } from '../../services'
import { State, PolicyCreators } from '../../store'
import { LightTheme } from '../../theme'
import { getRegionByValue } from './helpers'
import { useStyle } from './compare-policies.styles'

export const ComparePoliciesPage = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const history = useHistory()
  const { ids } = useParams<{ ids: string }>()
  const compareIds = ids.split(',')
  const dispatch = useDispatch()
  const { comparePolicies, regions } = useSelector((state: State) => ({
    ...state.policy
  }))

  const breadcrumbsTextProps: ComponentTextProps = {
    text: 'Back',
    tx: 'back.title'
  }

  const handleOnRemove = (id: string) => {
    const list = ['']
    comparePolicies.forEach((policy: Policy) => {
      if (policy.id !== id) {
        list.push(policy.id)
      }
    })

    const filteredIds = list.filter((el) => el)
    dispatch(
      PolicyCreators.getComparePoliciesByIdsRequest({ ids: filteredIds })
    )

    const urlIds = filteredIds.join(',')
    history.push(`/policy/compare/${urlIds}`)
  }

  const onOpenCompareCharts = () => {
    history.push(`/policy/charts/${ids}`)
  }

  useEffect(() => {
    dispatch(PolicyCreators.getRegionsRequest())
    dispatch(PolicyCreators.getComparePoliciesByIdsRequest({ ids: compareIds }))

    return () => {
      dispatch(PolicyCreators.resetDraggablePolicy())
    }
  }, [])

  return (
    <Column>
      <Content className={classes.content}>
        <Column
          className={classes.innerContent}
          fullWidth
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Breadcrumbs textProps={breadcrumbsTextProps} />
          <Row fullWidth justifyContent="space-between">
            <Text
              className={classes.title}
              preset="h1"
              text="Compare Policies"
              tx="compare.policy.title"
            />
            <Button className={classes.viewCharts} onClick={onOpenCompareCharts}>
              <Text
                tx="policy.viewCharts.compare"
                text="View Charts’ Compare"
              />
            </Button>
          </Row>
          <Row fullWidth justifyContent="space-between" alignItems="flex-start">
            {comparePolicies.map((policy) => (
              <Widgets
                vertical
                key={`widget_${policy.id}`}
                className={classes.widgetContainer}
                policy={policy}
                region={getRegionByValue(policy.region, regions)}
                isRemoveAllowed={comparePolicies.length > 1}
                onRemove={handleOnRemove}
              />
            ))}
          </Row>
        </Column>
      </Content>
    </Column>
  )
}
