import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  Content,
  Column,
  IconName,
  Row,
  Text,
  IconButton,
  PolicyProps,
  SettingsType
} from '..'
import { PolicyCreators, State } from '../../store'
import { Spinner } from '../spinner'
import { RegionProperties } from '../../services'
import { Breadcrumbs } from '../breadcrumbs'
import { SlidersList } from './sliders-list'
import { PlotIds, PlotIdsValues } from './policy-summary-list.types'
import { useStyle } from './policy-summary-list.styles'

export const PolicySummaryList = () => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { id } = useParams<PolicyProps>()
  const {
    policy,
    affordabilityPlot,
    isAffordabilityPlotLoaded,
    regions,
    report,
    reportError
  } = useSelector((state: State) => ({
    ...state.app,
    policy: state.policy.data.find((item) => item.id === id),
    regions: state.policy.regions,
    affordabilityPlot: state.affordability.plot,
    isAffordabilityPlotLoaded: state.affordability.isLoaded,
    report: state.policy.pdfReport,
    reportError: state.policy.reportError
  }))
  const [settings, changeSettings] = useState<SettingsType>(policy)
  const [isReportRequested, changeIsReportRequested] = useState(true)
  const [, changeSelectedAddGraph] = useState<PlotIdsValues>(PlotIds.COBENEFITS)
  const region = useMemo(
    () =>
      regions && settings
        ? regions.find(
            (item: RegionProperties) => item.value === settings.region
          )
        : undefined,
    [regions, settings]
  )
  const handleOnSelectedGraphChange = useCallback((graph: PlotIdsValues) => {
    changeSelectedAddGraph(graph)
  }, [])

  const handleOnPrint = () => {
    changeIsReportRequested(false)
    dispatch(PolicyCreators.getPolicyReportRequest(id))
  }

  useEffect(() => {
    if (!settings) {
      dispatch(PolicyCreators.getPolicyRequest({ id }))
    }

    dispatch(PolicyCreators.getRegionsRequest())
    dispatch(PolicyCreators.getCatalogRequest())

    return () => {
      dispatch(PolicyCreators.getPoliciesRequest())
      dispatch(PolicyCreators.getPolicyReportRequest())
    }
  }, [])

  useEffect(() => {
    if (policy) {
      changeSettings(policy)
    }

    return () => {
      changeSettings(undefined)
    }
  }, [policy])

  useEffect(() => {
    if (reportError && !isReportRequested) {
      setTimeout(() => {
        dispatch(PolicyCreators.getPolicyReportRequest(id))
      }, 3000)
    }
  }, [reportError])

  useEffect(() => {
    if (report) {
      changeIsReportRequested(true)
      dispatch(PolicyCreators.getPolicyReportRequest())
      window.open(report, '_blank')
    }
  }, [report])

  const name = settings ? settings.name : ''

  return (
    <Column>
      <Breadcrumbs className={classes.back} />
      <Content>
        <Row fullWidth alignItems="flex-start">
          <Row className={classes.info} justifyContent="flex-start" fullWidth>
            {!!name && <Text text={name} preset="h1" />}
            <Text text="Policy Impact Review (Custom)" preset="h3" />
          </Row>
          <Row className={classes.info} justifyContent="flex-end">
            {isReportRequested ? (
              <IconButton
                hint
                text="Print/Report"
                preset="primary"
                iconName={IconName.PRINT}
                onClick={handleOnPrint}
              />
            ) : (
              <Row
                className={classes.spinnerContainer}
                alignContent="center"
                alignItems="center"
              >
                <Spinner />
              </Row>
            )}
          </Row>
        </Row>
        <Column className={classes.column} fullHeight>
          {settings && (
            <SlidersList
              isLoaded={isAffordabilityPlotLoaded}
              policy={settings}
              region={region}
              affordabilityPlot={affordabilityPlot}
              changeSelectedAddGraph={handleOnSelectedGraphChange}
            />
          )}
        </Column>
      </Content>
    </Column>
  )
}
