import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  title: {
    padding: '32px 0'
  },
  content: {
    '& > *:not(:first-child)': {
      marginTop: 12
    }
  }
})
