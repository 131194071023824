import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { LightTheme } from '../../../theme'
import { State } from '../../../store'
import { Column, Row, ContentSpinner, Icon, IconName } from '../..'
import { Button } from '../../button'
import { Text } from '../../text'
import { GeneralSettings } from './general-settings'
import { AnnualCost } from './annual-cost'
import { EvSalesIncCost } from './ev-sales-inc-cost'
import { GhgsEmissions } from './ghgs-emissions'
import { PlotIdsValues } from '../policy-summary-list.types'
import { WidgetsProps } from './widgets.types'
import { useStyle } from './widgets.styles'

export const Widgets: FC<WidgetsProps> = ({
  className,
  stateFilter,
  vertical,
  isSummary = false,
  policy,
  region,
  isRemoveAllowed,
  onClick,
  onRemove
}) => {
  const history = useHistory()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ vertical, theme })
  const { isLoaded } = useSelector((state: State) => ({
    ...state.app
  }))

  const isVerticalComp = vertical && !isSummary

  if (!isLoaded || !policy || !region) {
    return <ContentSpinner />
  }

  const {
    years,
    ghgPlotAvoided,
    ghgPlotMtco2,
    totalCostsPhase1,
    totalCostsPhase2,
    totalCostsTotal,
    totalCostsPhase1Region,
    totalCostsPhase2Region,
    totalCostsTotalRegion,
    evAdoptionShare,
    finalMarketShare,
    evAdoptionYear,
    name
  } = policy

  const annualCost = {
    region,
    phase1: totalCostsPhase1,
    phase2: totalCostsPhase2,
    totalCost: totalCostsTotal,
    phase1Region: totalCostsPhase1Region,
    phase2Region: totalCostsPhase2Region,
    totalCostRegion: totalCostsTotalRegion
  }

  const handleOnClick = (id: PlotIdsValues) => {
    if (onClick) {
      onClick(id)
    }
  }

  const handleOnNavigate = () => {
    history.push(`/policy/${policy.id}/summary`)
  }

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(policy.id)
    }
  }

  return (
    <Row
      alignItems="flex-start"
      justifyContent="space-between"
      fullWidth
      className={`${className} ${classes.container}`}
    >
      {isVerticalComp && (
        <>
          <Row fullWidth>
            <Column
              className={classes.header}
              fullWidth
              alignItems="flex-start"
            >
              <Text className={classes.name} preset="h2" text={name} />
            </Column>
            {isRemoveAllowed && (
              <Icon
                className={classes.remove}
                name={IconName.CLEAR}
                fill={theme.colors.darkBlue}
                onClick={handleOnRemove}
              />
            )}
          </Row>
          <Column
            className={`${classes.titleSeparator} ${classes.separator}`}
            fullHeight
          />
        </>
      )}
      <Column
        fullWidth
        justifyContent="flex-start"
        className={classes.widgetContainer}
      >
        <GeneralSettings
          navigate={isSummary}
          stateFilter={stateFilter}
          region={region}
          years={years}
        />
        <GhgsEmissions
          navigate={isSummary}
          totalEmissions={ghgPlotAvoided}
          ghgPlotMtco2={ghgPlotMtco2}
          onClick={handleOnClick}
        />
      </Column>
      <Column className={classes.separator} fullHeight />
      <Column
        fullWidth
        justifyContent="flex-start"
        className={classes.widgetContainer}
      >
        <AnnualCost
          {...annualCost}
          navigate={isSummary}
          stateFilter={stateFilter}
          onClick={handleOnClick}
        />
      </Column>
      <Column className={classes.separator} fullHeight />
      <Column
        fullWidth
        justifyContent="flex-start"
        className={classes.widgetContainer}
      >
        <EvSalesIncCost
          navigate={isSummary}
          fleetSize={evAdoptionShare}
          finalMarketShare={finalMarketShare}
          evAdoptionYear={evAdoptionYear}
          onClick={handleOnClick}
        />
      </Column>
      {isVerticalComp && (
        <Column fullWidth>
          <Column className={classes.separator} fullHeight />
          <Button
            className={classes.button}
            preset="accent"
            onClick={handleOnNavigate}
          >
            <Text text="View Charts" tx="btn.viewCharts" preset="body" />
          </Button>
        </Column>
      )}
    </Row>
  )
}
