import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles(() => ({
  cardsList: {
    marginTop: 16,
    padding: '24px 20px 108px 20px',
    maxHeight: 600,
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0
    }
  }
}))
