import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { RegionProperties } from '../../../services'
import { State } from '../../../store'
import { Row } from '../../row'
import { RadioButton } from '../../radio-button'
import { Button } from '../../button'
import { Column } from '../../column'
import { Modal } from '../../modal'
import { Text, ComponentTextProps } from '../../text'
import { Spinner } from '../../spinner'
import { ChooseStateModalProps } from './choose-state.types'
import { useStyle } from './choose-state.styles'

export const ChooseStateModal: FC<ChooseStateModalProps> = ({
  selected,
  onSubmit,
  onClose
}) => {
  const classes = useStyle()
  const { regions } = useSelector((state: State) => ({
    regions: state.policy.regions
  }))
  const [selectedState, changeSelectedState] = useState<
    RegionProperties | undefined
  >()
  const regionsRef = useRef<HTMLInputElement[]>([])
  useEffect(() => {
    regionsRef.current = regionsRef.current.slice(0, regions.length)
  }, [regions])

  const handleOnSetRef = (element: any, idx: number) => {
    regionsRef.current[idx] = element
  }

  const titleProps: ComponentTextProps = {
    text: 'Choose State',
    tx: 'create.policy.chooseState',
    color: 'text'
  }

  const cancelProps: ComponentTextProps = {
    text: 'Cancel',
    tx: 'btn.cancel'
  }

  const confirmProps: ComponentTextProps = {
    text: 'Confirm',
    tx: 'btn.confirm'
  }

  const abbreviationProps: ComponentTextProps = {
    text: 'Abbreviation',
    preset: 'caption',
    color: 'border'
  }

  const nameProps: ComponentTextProps = {
    text: 'Name',
    preset: 'caption',
    color: 'border'
  }

  const selectedProps: ComponentTextProps = {
    text: 'Selected:',
    preset: 'caption',
    color: 'border'
  }

  const getState = (value: number | undefined) =>
    regions.length
      ? regions.find((region: RegionProperties) => region.value === value)
      : undefined

  const getRegionIdx = () => {
    const idxsList = regions.map((region: any, idx: number) => {
      if (selectedState?.value === region.value) {
        return idx
      }
      return undefined
    })
    return idxsList.find((idx: number | undefined) => Boolean(idx))
  }

  useEffect(() => {
    const state = getState(selected && selected.value)
    changeSelectedState(state)
  }, [selected])

  useEffect(() => {
    const region = getRegionIdx()
    if (regionsRef.current && region) {
      regionsRef.current[region].scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      })
    }
  }, [selectedState])

  const getIsChecked = (region: RegionProperties) => {
    const value = selectedState ? selectedState.value : undefined
    return region.value === value
  }

  const handleOnChange = (item: RegionProperties) => (status: boolean) => {
    if (status) {
      changeSelectedState(item)
    }
  }

  const handleOnSubmit = () => {
    if (selectedState) {
      onSubmit(selectedState)
      onClose()
    } else {
      console.error('choose state please')
    }
  }

  return (
    <Modal onClose={onClose}>
      <Column fullWidth fullHeight justifyContent="space-between">
        <Column fullWidth>
          <Text {...titleProps} preset="h1" />
        </Column>
        <Column
          className={classes.container}
          fullHeight
          fullWidth
          justifyContent="flex-start"
        >
          <Row fullWidth justifyContent="space-between">
            <Text {...abbreviationProps} />
            <Text {...nameProps} />
            <Text {...selectedProps} />
          </Row>
          <Column className={classes.regionsContainer} fullHeight fullWidth>
            <Column className={classes.regions} fullWidth>
              {regions.length ? (
                Array.from(regions).map(
                  (region: RegionProperties, idx: number) => (
                    <Row
                      key={region.abbreviation}
                      ref={(element) => handleOnSetRef(element, idx)}
                      className={classes.region}
                      fullWidth
                      justifyContent="space-between"
                    >
                      <Text
                        className={classes.abbr}
                        text={region.abbreviation}
                        preset="body"
                      />
                      <Text
                        className={classes.name}
                        text={region.name}
                        preset="body"
                      />
                      <RadioButton
                        iconColor="darkBlue"
                        defaultChecked={getIsChecked(region)}
                        onChange={handleOnChange(region)}
                      />
                    </Row>
                  )
                )
              ) : (
                <Spinner />
              )}
            </Column>
          </Column>
        </Column>
        <Row justifyContent="space-around" fullWidth>
          <Button preset="secondary" onClick={onClose}>
            <Text {...cancelProps} preset="body" />
          </Button>
          <Button preset="accent" onClick={handleOnSubmit}>
            <Text {...confirmProps} preset="body" />
          </Button>
        </Row>
      </Column>
    </Modal>
  )
}
