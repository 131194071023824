import React, {
  FC,
  useEffect,
  useState,
  FormEvent,
  useRef,
  useCallback
} from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import { State } from '../../../store'
import { useResponsive } from '../../../providers'
import { Policy, Restrictions } from '../../../services'
import { Row } from '../../row'
import { Warning } from '../../warning'
import { ComponentTextProps, TextProps } from '../../text'
import {
  Content,
  Column,
  Text,
  Input,
  Dropdown,
  DropdownItemData,
  Checkbox,
  RadioButtonGroup,
  CustomRadioButtonGroup
} from '../..'
import {
  getDropdownData,
  getCustomSettings,
  getUpcomingSetting,
  getSetting,
  changeCurrentValues,
  getIsValid,
  getError,
  getCurrentDropdown,
  hasCoincidence,
  fleetAgeYearValidate,
  setError,
  getSettingValue,
  getBooleanValue,
  getSliderId,
  handleOnCheckDropdownErrors,
  getLabel,
  formatValue
} from '../helpers'
import {
  annualProps,
  coBenefitsRollOverTimeProps,
  cumulativeProps,
  dollarIconProps,
  dollarPerYearIconProps,
  dollarsProps,
  fleetOrNewProps,
  vehicleProps,
  vehiclesProps,
  yearIconProps
} from '../label-props'
import {
  customStyles,
  FLEET_AGE_YEAR_ALL_RADIO_IDS,
  NUMBER_VALUES_IDS,
  RADIO_ID_DEPENDENCIES,
  SCC_CALC_DROPDOWN_IDS
} from '../constants'
import {
  AdditionalGraphParametersValues,
  AdditionalGraphParametersIds,
  InputsPolicyProps,
  CheckAllErrorsProps,
  InputTypeValue
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const AdditionalGraphParameters: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const benefitsRef = useRef<HTMLDivElement>(null)
  const { isTablet } = useResponsive()
  const [isBenefitSelected, changeIsBenefitSelected] = useState(true)
  const { restrictions, catalog, bauDefaults } = useSelector(
    (state: State) => ({
      restrictions: state.policy.restrictions as Restrictions,
      catalog: state.policy.catalog,
      bauDefaults: state.policy.bauDefaults
    })
  )

  const warningTextProps: TextProps = {
    text:
      'This input relates only to respective additional graphs. The model calculation main output will not be impacted.',
    tx: 'policy.addGraph.warning'
  }

  const dropdowns: any = {
    sccCalc: getDropdownData(AdditionalGraphParametersIds.SCC_CALC, catalog)
  }

  // start of manipulating with default settings
  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: AdditionalGraphParametersValues) =>
      getUpcomingSetting(id, settingsList),
    [settingsList]
  )
  // eof manipulating with default settings

  const getAdditionalGraphParameters = useCallback(
    (): any => ({
      years: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.SIMULATION_YEARS
      ),
      sccCalc: handleGetUpcomingSetting(AdditionalGraphParametersIds.SCC_CALC),
      userSccInput: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.USER_SCC_INPUT
      ),
      userSccRate: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.USER_SCC_RATE
      ),
      fleetAgeYear: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.FLEET_AGE_YEAR
      ),
      fleetAgeYearAll: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL
      ),
      benefitsCarbon: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.BENEFITS_CARBON
      ),
      benefitsFuel: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.BENEFITS_FUEL
      ),
      benefitsHealth: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.BENEFITS_HEALTH
      ),
      benefitsOm: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.BENEFITS_OM
      ),
      includeEv: handleGetUpcomingSetting(AdditionalGraphParametersIds.EV),
      includeIcev: handleGetUpcomingSetting(AdditionalGraphParametersIds.ICEV),
      includeFleet: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.FLEET
      ),
      includeNew: handleGetUpcomingSetting(AdditionalGraphParametersIds.NEW),
      fplParticipationType: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.FPL_PART_DIV
      ),
      fplAnnualCumulative: handleGetUpcomingSetting(
        AdditionalGraphParametersIds.FPL_ANNUAL_CUMULATIVE
      )
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getAdditionalGraphParameters()
  const [additionalGraphParameters, changeAdditionalGraphParameters] = useState(
    fields
  )

  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeAdditionalGraphParameters(getAdditionalGraphParameters())
    changeSettings(s)
  }, [defaults, updates])

  // METHODS
  const getIsExist = (id: AdditionalGraphParametersValues) =>
    additionalGraphParameters[id] !== undefined
  const getIsDefault = (id: AdditionalGraphParametersValues) =>
    additionalGraphParameters[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (
    id: AdditionalGraphParametersValues,
    flag: InputTypeValue
  ) => (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)

    const reg = new RegExp('^[0-9]$') // only digits
    const stabValue = reg.test(String(value)) ? Number(value) : value

    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeAdditionalGraphParameters({
      ...additionalGraphParameters,
      [id]: stabValue
    })
    changeSettings(updSettings)
    onChange({ [id]: stabValue })
  }

  const handleOnBlur = (id: AdditionalGraphParametersValues) => (
    e: FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    let isValid = false
    let rule

    if (id === AdditionalGraphParametersIds.FLEET_AGE_YEAR) {
      const result = fleetAgeYearValidate({
        id,
        value,
        years: additionalGraphParameters.years,
        restrictions
      })
      isValid = result.isValid
      rule = result.rule
    } else {
      const result = getIsValid({ id, value, restrictions })
      isValid = result.isValid
      rule = result.rule
    }
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnGetError = (id: AdditionalGraphParametersValues) =>
    getError(id, error)

  const handleOnBlurCheckbox = (id: AdditionalGraphParametersValues) => (
    value?: boolean
  ) => {
    const isOneBenefitSelected =
      additionalGraphParameters.benefitsCarbon ||
      additionalGraphParameters.benefitsFuel ||
      additionalGraphParameters.benefitsHealth ||
      additionalGraphParameters.benefitsOm ||
      Boolean(value)
    const sliderId: any = getSliderId(id)

    if (!isOneBenefitSelected) {
      changeIsBenefitSelected(false)
      setError({
        id,
        isValid: false,
        rule: {},
        sliderId,
        onError
      })
    } else {
      changeIsBenefitSelected(true)
      setError({
        id: AdditionalGraphParametersIds.BENEFITS_CARBON,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
      setError({
        id: AdditionalGraphParametersIds.BENEFITS_FUEL,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
      setError({
        id: AdditionalGraphParametersIds.BENEFITS_HEALTH,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnDropdownChange = (id: AdditionalGraphParametersValues) => (
    value: DropdownItemData
  ) => {
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeAdditionalGraphParameters({
      ...additionalGraphParameters,
      [id]: Number(value.id)
    })
    changeSettings(updSettings)
    onChange({ [id]: Number(value.id) })
  }

  const handleUndoClick = (id: AdditionalGraphParametersValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value
      changeAdditionalGraphParameters({
        ...additionalGraphParameters,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnCheckboxChange = (id: string) => (status: boolean) => {
    changeAdditionalGraphParameters({
      ...additionalGraphParameters,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleOnRadioChange = (id: string) => (status: boolean) => {
    changeAdditionalGraphParameters({
      ...additionalGraphParameters,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleOnGetCurrentDropdown = (value: number, id: string) =>
    getCurrentDropdown({
      dropdowns,
      value,
      id
    })

  const handleCheckboxChange = (id: string) => (status: boolean) => {
    changeAdditionalGraphParameters({
      ...additionalGraphParameters,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const getCorrectValueById = ({ settingId = '', value = false }) => {
    const hasSetting = String(value) && settingId

    if (!hasSetting) {
      return {}
    }

    const settingValue =
      hasSetting && NUMBER_VALUES_IDS.includes(settingId)
        ? getSettingValue(value)
        : value

    if (settingId && settings) {
      changeSettings({
        ...settings,
        [settingId]: settingValue
      })
    }
    return settingValue
  }

  const getSwitcherValue = ({ settingId = '', value = false }) => {
    const settingValue = getCorrectValueById({ settingId, value })
    const settingUpdate = settingId && { [settingId]: settingValue }
    return settingUpdate
  }

  const handleOnCustomRadioChange = (id: AdditionalGraphParametersIds) => (
    value: boolean
  ) => {
    const settingUpdate = getSwitcherValue({
      settingId: id,
      value
    }) as Partial<Policy>
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeAdditionalGraphParameters({
      ...additionalGraphParameters,
      ...settingUpdate
    })
    changeSettings(updSettings)
    onChange(settingUpdate)
  }

  const getEmptyDropdownStyle = (id: AdditionalGraphParametersIds) =>
    getIsExist(id) ? undefined : customStyles
  // eof METHODS

  const isUserInputSelected = hasCoincidence({
    option: 'user',
    value: Number(additionalGraphParameters.sccCalc),
    dropdownId: AdditionalGraphParametersIds.SCC_CALC,
    getDropdown: handleOnGetCurrentDropdown
  })

  // check errors in dependencies
  const handleOnCheckAllErrors = ({
    values,
    radioId,
    ids
  }: CheckAllErrorsProps): ComponentTextProps | undefined => {
    if (!values[radioId]) {
      return undefined
    }

    const errors: any = ids.map((id: string) => getError(id, error))
    return errors.find((props?: ComponentTextProps) => props)
  }
  // eof check

  const getIsUpdateInBauValues = (key: keyof Policy) => {
    const isUndefined = typeof updates[key] === 'undefined'
    const isEqual = updates[key] === bauDefaults[key]

    return !isUndefined && isEqual
  }

  const getAttentionIconState = (id: keyof Policy): object => {
    const isRadioOff = !additionalGraphParameters[id]
    const bauKeys = Object.keys(bauDefaults)
    const newKeys = Object.keys(updates)
    const dependencyKeys = RADIO_ID_DEPENDENCIES[id]
    const isBauSetted = dependencyKeys.find(getIsUpdateInBauValues)
    const isBauValuesFromDependency = dependencyKeys.find((depId: string) =>
      bauKeys.includes(depId)
    )
    let isIconVisible = Boolean(
      isRadioOff && isBauSetted && isBauValuesFromDependency
    )

    if (isBauSetted) {
      return {
        statusIcon: isIconVisible,
        attentionText:
          'Underlying inputs were changed to BAU.\nOpen the section to review \nand/or discard changes.',
        attentionTx: 'policy.radio.warning'
      }
    }

    const isNewValuesFromDependency = dependencyKeys.find((depId: string) =>
      newKeys.includes(depId)
    )
    isIconVisible = Boolean(isRadioOff && isNewValuesFromDependency)

    return {
      statusIcon: isIconVisible,
      attentionText:
        'Underlying inputs were changed,\nthis may affect policy output.\nOpen the section to review \nand/or discard changes.',
      attentionTx: 'policy.radio.warning2'
    }
  }

  useEffect(() => {
    if (!isBenefitSelected && benefitsRef.current) {
      benefitsRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      })
    }
  }, [isBenefitSelected])

  const benefitsValues = {
    attention: (
      <Text
        tx="policy.selectAtLeastOne"
        text="(Select at least one value)"
        preset="caption"
        color={isBenefitSelected ? 'grey' : 'red'}
      />
    )
  }

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        {isTablet ? (
          <Row
            className={`${classes.row} ${classes.responsiveRow}`}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {warningTextProps && (
              <Warning
                className={classes.warning}
                textProps={warningTextProps}
              />
            )}
          </Row>
        ) : null}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            {...coBenefitsRollOverTimeProps}
            preset="h5"
          />
        </Row>
        <Row
          ref={benefitsRef}
          className={`${classes.row} ${classes.responsiveRow}`}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            text="Include Which Benefits? {attention}"
            tx="policy.includeBenefits"
            values={benefitsValues}
            preset="caption"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Row className={classes.cell} justifyContent="flex-start">
            <Checkbox
              {...getLabel(
                AdditionalGraphParametersIds.BENEFITS_CARBON,
                restrictions
              )}
              defaultChecked={additionalGraphParameters.benefitsCarbon}
              description={
                restrictions[AdditionalGraphParametersIds.BENEFITS_CARBON]
                  ?.description
              }
              disabled={
                !getIsExist(AdditionalGraphParametersIds.BENEFITS_CARBON)
              }
              isUndo={getIsDefault(
                AdditionalGraphParametersIds.BENEFITS_CARBON
              )}
              onChange={handleOnCheckboxChange(
                AdditionalGraphParametersIds.BENEFITS_CARBON
              )}
              onUndoClick={handleUndoClick(
                AdditionalGraphParametersIds.BENEFITS_CARBON
              )}
              onBlur={handleOnBlurCheckbox(
                AdditionalGraphParametersIds.BENEFITS_CARBON
              )}
            />
          </Row>
          <Row className={classes.cell} justifyContent="flex-start">
            <Checkbox
              {...getLabel(
                AdditionalGraphParametersIds.BENEFITS_FUEL,
                restrictions
              )}
              defaultChecked={additionalGraphParameters.benefitsFuel}
              description={
                restrictions[AdditionalGraphParametersIds.BENEFITS_FUEL]
                  ?.description
              }
              disabled={!getIsExist(AdditionalGraphParametersIds.BENEFITS_FUEL)}
              isUndo={getIsDefault(AdditionalGraphParametersIds.BENEFITS_FUEL)}
              onChange={handleOnCheckboxChange(
                AdditionalGraphParametersIds.BENEFITS_FUEL
              )}
              onUndoClick={handleUndoClick(
                AdditionalGraphParametersIds.BENEFITS_FUEL
              )}
              onBlur={handleOnBlurCheckbox(
                AdditionalGraphParametersIds.BENEFITS_FUEL
              )}
            />
          </Row>
          <Row className={classes.cell} justifyContent="flex-start">
            <Checkbox
              {...getLabel(
                AdditionalGraphParametersIds.BENEFITS_HEALTH,
                restrictions
              )}
              defaultChecked={additionalGraphParameters.benefitsHealth}
              description={
                restrictions[AdditionalGraphParametersIds.BENEFITS_HEALTH]
                  ?.description
              }
              disabled={
                !getIsExist(AdditionalGraphParametersIds.BENEFITS_HEALTH)
              }
              isUndo={getIsDefault(
                AdditionalGraphParametersIds.BENEFITS_HEALTH
              )}
              onChange={handleOnCheckboxChange(
                AdditionalGraphParametersIds.BENEFITS_HEALTH
              )}
              onUndoClick={handleUndoClick(
                AdditionalGraphParametersIds.BENEFITS_HEALTH
              )}
              onBlur={handleOnBlurCheckbox(
                AdditionalGraphParametersIds.BENEFITS_HEALTH
              )}
            />
          </Row>
          <Row className={classes.cell} justifyContent="flex-start">
            <Checkbox
              {...getLabel(
                AdditionalGraphParametersIds.BENEFITS_OM,
                restrictions
              )}
              defaultChecked={additionalGraphParameters.benefitsOm}
              description={
                restrictions[AdditionalGraphParametersIds.BENEFITS_OM]
                  ?.description
              }
              disabled={!getIsExist(AdditionalGraphParametersIds.BENEFITS_OM)}
              isUndo={getIsDefault(AdditionalGraphParametersIds.BENEFITS_OM)}
              onChange={handleOnCheckboxChange(
                AdditionalGraphParametersIds.BENEFITS_OM
              )}
              onUndoClick={handleUndoClick(
                AdditionalGraphParametersIds.BENEFITS_OM
              )}
              onBlur={handleOnBlurCheckbox(
                AdditionalGraphParametersIds.BENEFITS_OM
              )}
            />
          </Row>
        </Row>
        {additionalGraphParameters.benefitsCarbon && (
          <Row
            className={`${classes.row} ${classes.responsiveRow}`}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Row className={classes.cell}>
              <Dropdown
                className={classes.dropdown}
                custom={getEmptyDropdownStyle(
                  AdditionalGraphParametersIds.SCC_CALC
                )}
                label={
                  getLabel(
                    AdditionalGraphParametersIds.BENEFITS_OM,
                    restrictions
                  ).text
                }
                data={dropdowns.sccCalc}
                preset="body"
                defaultValue={handleOnGetCurrentDropdown(
                  additionalGraphParameters.sccCalc,
                  AdditionalGraphParametersIds.SCC_CALC
                )}
                disabled={!additionalGraphParameters.sccCalc}
                errorProps={handleOnCheckDropdownErrors({
                  ids: SCC_CALC_DROPDOWN_IDS,
                  error
                })}
                description={
                  restrictions[AdditionalGraphParametersIds.SCC_CALC]
                    ?.description
                }
                isUndo={getIsDefault(AdditionalGraphParametersIds.SCC_CALC)}
                onChange={handleOnDropdownChange(
                  AdditionalGraphParametersIds.SCC_CALC
                )}
                onUndoClick={handleUndoClick(
                  AdditionalGraphParametersIds.SCC_CALC
                )}
              />
            </Row>
            {isUserInputSelected && (
              <>
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={AdditionalGraphParametersIds.USER_SCC_INPUT}
                  labelProps={getLabel(
                    AdditionalGraphParametersIds.USER_SCC_INPUT,
                    restrictions
                  )}
                  defaultValue={additionalGraphParameters.userSccInput}
                  isDollar
                  iconProps={dollarIconProps}
                  isFocused={getIsExist(
                    AdditionalGraphParametersIds.USER_SCC_INPUT
                  )}
                  description={
                    restrictions[AdditionalGraphParametersIds.USER_SCC_INPUT]
                      ?.description
                  }
                  disabled={
                    !getIsExist(AdditionalGraphParametersIds.USER_SCC_INPUT)
                  }
                  isUndo={getIsDefault(
                    AdditionalGraphParametersIds.USER_SCC_INPUT
                  )}
                  errorProps={handleOnGetError(
                    AdditionalGraphParametersIds.USER_SCC_INPUT
                  )}
                  onBlur={handleOnBlur(
                    AdditionalGraphParametersIds.USER_SCC_INPUT
                  )}
                  onChange={handleOnChange(
                    AdditionalGraphParametersIds.USER_SCC_INPUT,
                    InputTypeValue.DOLLAR
                  )}
                  onUndoClick={handleUndoClick(
                    AdditionalGraphParametersIds.USER_SCC_INPUT
                  )}
                />
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={AdditionalGraphParametersIds.USER_SCC_RATE}
                  labelProps={getLabel(
                    AdditionalGraphParametersIds.USER_SCC_RATE,
                    restrictions
                  )}
                  isDollar
                  defaultValue={additionalGraphParameters.userSccRate}
                  iconProps={dollarPerYearIconProps}
                  isFocused={getIsExist(
                    AdditionalGraphParametersIds.USER_SCC_RATE
                  )}
                  disabled={
                    !getIsExist(AdditionalGraphParametersIds.USER_SCC_RATE)
                  }
                  description={
                    restrictions[AdditionalGraphParametersIds.USER_SCC_RATE]
                      ?.description
                  }
                  isUndo={getIsDefault(
                    AdditionalGraphParametersIds.USER_SCC_RATE
                  )}
                  errorProps={handleOnGetError(
                    AdditionalGraphParametersIds.USER_SCC_RATE
                  )}
                  onBlur={handleOnBlur(
                    AdditionalGraphParametersIds.USER_SCC_RATE
                  )}
                  onChange={handleOnChange(
                    AdditionalGraphParametersIds.USER_SCC_RATE,
                    InputTypeValue.DOLLAR
                  )}
                  onUndoClick={handleUndoClick(
                    AdditionalGraphParametersIds.USER_SCC_RATE
                  )}
                />
              </>
            )}
          </Row>
        )}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            text="Fleet Age Graph"
            preset="h5"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL,
                restrictions
              )}
              value={additionalGraphParameters.fleetAgeYearAll}
              disabled={
                !getIsExist(AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL)
              }
              isUndo={getIsDefault(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL
              )}
              errorProps={handleOnCheckAllErrors({
                values: additionalGraphParameters,
                radioId: AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL,
                ids: FLEET_AGE_YEAR_ALL_RADIO_IDS
              })}
              description={
                restrictions[AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL]
                  ?.description
              }
              {...getAttentionIconState(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL
              )}
              onChange={handleOnRadioChange(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL
              )}
              onUndoClick={handleUndoClick(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR_ALL
              )}
            />
          </Column>
          {!additionalGraphParameters.fleetAgeYearAll && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={AdditionalGraphParametersIds.FLEET_AGE_YEAR}
              labelProps={getLabel(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR,
                restrictions
              )}
              isYear
              iconProps={yearIconProps}
              defaultValue={additionalGraphParameters.fleetAgeYear}
              isFocused={getIsExist(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR
              )}
              disabled={
                !getIsExist(AdditionalGraphParametersIds.FLEET_AGE_YEAR)
              }
              description={
                restrictions[AdditionalGraphParametersIds.FLEET_AGE_YEAR]
                  ?.description
              }
              isUndo={getIsDefault(AdditionalGraphParametersIds.FLEET_AGE_YEAR)}
              errorProps={handleOnGetError(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR
              )}
              onBlur={handleOnBlur(AdditionalGraphParametersIds.FLEET_AGE_YEAR)}
              onChange={handleOnChange(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR,
                InputTypeValue.YEAR
              )}
              onUndoClick={handleUndoClick(
                AdditionalGraphParametersIds.FLEET_AGE_YEAR
              )}
            />
          )}
        </Row>
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            text="Fleet + New Vehicles Graph"
            preset="h5"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <Text {...vehicleProps} />
            <Row
              className={classes.selectionControls}
              fullWidth
              justifyContent="space-between"
            >
              <Checkbox
                {...getLabel(AdditionalGraphParametersIds.EV, restrictions)}
                defaultChecked={additionalGraphParameters.includeEv}
                description={
                  restrictions[AdditionalGraphParametersIds.EV]?.description
                }
                disabled={!getIsExist(AdditionalGraphParametersIds.EV)}
                isUndo={getIsDefault(AdditionalGraphParametersIds.EV)}
                onChange={handleCheckboxChange(AdditionalGraphParametersIds.EV)}
                onUndoClick={handleUndoClick(AdditionalGraphParametersIds.EV)}
              />
              <Checkbox
                {...getLabel(AdditionalGraphParametersIds.ICEV, restrictions)}
                defaultChecked={additionalGraphParameters.includeIcev}
                description={
                  restrictions[AdditionalGraphParametersIds.ICEV]?.description
                }
                disabled={!getIsExist(AdditionalGraphParametersIds.ICEV)}
                isUndo={getIsDefault(AdditionalGraphParametersIds.ICEV)}
                onChange={handleCheckboxChange(
                  AdditionalGraphParametersIds.ICEV
                )}
                onUndoClick={handleUndoClick(AdditionalGraphParametersIds.ICEV)}
              />
            </Row>
          </Column>
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <Text {...fleetOrNewProps} />
            <Row
              className={classes.selectionControls}
              fullWidth
              justifyContent="space-between"
            >
              <Checkbox
                {...getLabel(AdditionalGraphParametersIds.FLEET, restrictions)}
                defaultChecked={additionalGraphParameters.includeFleet}
                description={
                  restrictions[AdditionalGraphParametersIds.FLEET]?.description
                }
                disabled={!getIsExist(AdditionalGraphParametersIds.FLEET)}
                isUndo={getIsDefault(AdditionalGraphParametersIds.FLEET)}
                onChange={handleCheckboxChange(
                  AdditionalGraphParametersIds.FLEET
                )}
                onUndoClick={handleUndoClick(
                  AdditionalGraphParametersIds.FLEET
                )}
              />
              <Checkbox
                {...getLabel(AdditionalGraphParametersIds.NEW, restrictions)}
                defaultChecked={additionalGraphParameters.includeNew}
                description={
                  restrictions[AdditionalGraphParametersIds.NEW]?.description
                }
                disabled={!getIsExist(AdditionalGraphParametersIds.NEW)}
                isUndo={getIsDefault(AdditionalGraphParametersIds.NEW)}
                onChange={handleCheckboxChange(
                  AdditionalGraphParametersIds.NEW
                )}
                onUndoClick={handleUndoClick(AdditionalGraphParametersIds.NEW)}
              />
            </Row>
          </Column>
        </Row>
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            text="FPL Participation"
            preset="h5"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <CustomRadioButtonGroup
              className={classes.customRadio}
              labelProps={getLabel(
                AdditionalGraphParametersIds.FPL_PART_DIV,
                restrictions
              )}
              firstLabelProps={vehiclesProps}
              secondLabelProps={dollarsProps}
              value={getBooleanValue(
                additionalGraphParameters.fplParticipationType
              )}
              description={
                restrictions[AdditionalGraphParametersIds.FPL_PART_DIV]
                  ?.description
              }
              isUndo={getIsDefault(AdditionalGraphParametersIds.FPL_PART_DIV)}
              onChange={handleOnCustomRadioChange(
                AdditionalGraphParametersIds.FPL_PART_DIV
              )}
              onUndoClick={handleUndoClick(
                AdditionalGraphParametersIds.FPL_PART_DIV
              )}
            />
          </Column>
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <CustomRadioButtonGroup
              className={classes.customRadio}
              labelProps={getLabel(
                AdditionalGraphParametersIds.FPL_ANNUAL_CUMULATIVE,
                restrictions
              )}
              firstLabelProps={annualProps}
              secondLabelProps={cumulativeProps}
              value={getBooleanValue(
                additionalGraphParameters.fplAnnualCumulative
              )}
              description={
                restrictions[AdditionalGraphParametersIds.FPL_ANNUAL_CUMULATIVE]
                  ?.description
              }
              isUndo={getIsDefault(
                AdditionalGraphParametersIds.FPL_ANNUAL_CUMULATIVE
              )}
              onChange={handleOnCustomRadioChange(
                AdditionalGraphParametersIds.FPL_ANNUAL_CUMULATIVE
              )}
              onUndoClick={handleUndoClick(
                AdditionalGraphParametersIds.FPL_ANNUAL_CUMULATIVE
              )}
            />
          </Column>
        </Row>
      </Content>
    </Column>
  )
}
