import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    backgroundColor: theme.colors.white,
    padding: '12px 48px 12px 60px'
  },
  contactInfo: {
    lineHeight: '16px',
    whiteSpace: 'pre'
  },
  col: {
    alignItems: 'flex-start'
  },
  contactTitle: {
    marginTop: 6
  },
  copyright: {
    marginTop: 6
  },
  freeSpace: {
    flexGrow: 1
  }
}))
