export const isNumeric = (value: string) => /^-?\d+$/.test(value)

export const formatDollars = (pureString: string) => {
  const letterSymbolRegex = new RegExp(/[A-Za-z!@#$%^&*/,()]/g)
  const thousandsRegex = new RegExp(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g)

  const fixedInt = pureString.replace(letterSymbolRegex, '')
  const formattedThousands = fixedInt.replace(thousandsRegex, '$1,')
  return formattedThousands
}

export const formatToFloat = (pureString: string) => {
  const letterSymbolRegex = new RegExp(/[A-Za-z!@#$%^&*/,()]/g)
  const alignedString = pureString.replace(letterSymbolRegex, '')
  return isNumeric(alignedString) ? Number(alignedString) : alignedString
}

export const formatToNumber = (pureString: string): string | number => {
  const letterSymbolRegex = new RegExp(/[A-Za-z!@#$%^&*/,.()]/g)
  const alignedString = pureString.replace(letterSymbolRegex, '')
  return isNumeric(alignedString) ? Number(alignedString) : alignedString
}
