import React, { useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  Breadcrumbs,
  Column,
  Content,
  Row,
  Text,
  ComponentTextProps,
  PlotSlider,
  GENERAL_PLOTS,
  PlotIdsValues,
  AffordabilitySlider,
  ADDITIONAL_GRAPH,
  EV_AFFORDABILITY,
  PlotIds
} from '../../components'
import { State, PolicyCreators } from '../../store'
import { LightTheme } from '../../theme'
import { useStyle } from './compare-charts.styles'

export const CompareChartsPage = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { ids } = useParams<{ ids: string }>()
  const compareIds = ids.split(',')
  const dispatch = useDispatch()
  const [openedPlot, changeOpenedPlot] = useState('')
  const {
    comparePolicies,
    affordabilityPlot,
    isAffordabilityPlotLoaded
  } = useSelector((state: State) => ({
    ...state.policy,
    affordabilityPlot: state.affordability.plot,
    isAffordabilityPlotLoaded: state.affordability.isLoaded
  }))
  const [, changeSelectedAddGraph] = useState<PlotIdsValues>(PlotIds.COBENEFITS)

  const breadcrumbsTextProps: ComponentTextProps = {
    text: 'Back',
    tx: 'back.title'
  }

  const reset = () => {
    changeOpenedPlot('')
  }

  const onChangeSelectedAddGraph = useCallback((graph: PlotIdsValues) => {
    changeSelectedAddGraph(graph)
  }, [])

  useEffect(() => {
    dispatch(PolicyCreators.getComparePoliciesByIdsRequest({ ids: compareIds }))
    dispatch(PolicyCreators.getCatalogRequest())
  }, [])

  return (
    <Column>
      <Content className={classes.content}>
        <Column
          className={classes.innerContent}
          fullWidth
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Breadcrumbs textProps={breadcrumbsTextProps} />
          <Row fullWidth justifyContent="space-between">
            <Text
              className={classes.title}
              preset="h1"
              text="Compare Charts"
              tx="compare.charts.title"
            />
          </Row>
          <Column
            className={classes.list}
            fullWidth
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {GENERAL_PLOTS.map((props) => (
              <PlotSlider
                key={props.plotId}
                {...props}
                stateFilter={0}
                openedPlot={openedPlot}
                policies={comparePolicies}
                changeSelectedAddGraph={onChangeSelectedAddGraph}
                reset={reset}
              />
            ))}
            <AffordabilitySlider
              key={EV_AFFORDABILITY.plotId}
              {...EV_AFFORDABILITY}
              isLoaded={isAffordabilityPlotLoaded}
              stateFilter={0}
              openedPlot={openedPlot}
              policies={comparePolicies}
              affordabilityPlot={affordabilityPlot}
              changeSelectedAddGraph={onChangeSelectedAddGraph}
              reset={reset}
            />
            {ADDITIONAL_GRAPH.map((props) => (
              <PlotSlider
                key={props.plotId}
                {...props}
                stateFilter={0}
                openedPlot={openedPlot}
                policies={comparePolicies}
                changeSelectedAddGraph={onChangeSelectedAddGraph}
                reset={reset}
              />
            ))}
          </Column>
        </Column>
      </Content>
    </Column>
  )
}
