import React, { FC, useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import { State } from '../../../store'
import { Restrictions } from '../../../services'
import { Row } from '../../row'
import { Checkbox } from '../../checkbox'
import { Content, Column, Input, Dropdown, Text, DropdownItemData } from '../..'
import {
  getUpcomingSetting,
  getDropdownData,
  getCustomSettings,
  getSetting,
  changeCurrentValues,
  getIsValid,
  getCurrentDropdown,
  getError,
  setError,
  getSliderId,
  getLabel,
  formatValue
} from '../helpers'
import { customStyles } from '../constants'
import { coBenefitsIncludedLabelProps, percentIconProps } from '../label-props'
import {
  InputsPolicyProps,
  InputTypeValue,
  ReductionCharacteristicsIds,
  ReductionCharacteristicsValues
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const ReductionCharacteristics: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions, catalog } = useSelector((state: State) => ({
    restrictions: state.policy.restrictions as Restrictions,
    catalog: state.policy.catalog
  }))

  const dropdowns = {
    ghgBoolean: getDropdownData(
      ReductionCharacteristicsIds.AVOIDED_GHG_CLASS,
      catalog
    )
  }

  // start of manipulating with default settings
  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: ReductionCharacteristicsValues) =>
      getUpcomingSetting(id, settingsList),
    [settingsList]
  )
  // eof manipulating with default settings

  const getReductionCharacteristics = useCallback(
    (): any => ({
      ghgBoolean: handleGetUpcomingSetting(
        ReductionCharacteristicsIds.AVOIDED_GHG_CLASS
      ),
      electricityDecarbRate: handleGetUpcomingSetting(
        ReductionCharacteristicsIds.DECARBONIZATION_RATE
      ),
      fuelSavings: handleGetUpcomingSetting(
        ReductionCharacteristicsIds.FUEL_SAVINGS
      ),
      health: handleGetUpcomingSetting(ReductionCharacteristicsIds.HEALTH)
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getReductionCharacteristics()
  const [reductionCharacteristics, changeReductionCharacteristics] = useState(
    fields
  )

  // when we press 'save' we get updated data from main 'update-policy' component
  // so we need to update our defaults for 'undo' fields state
  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeReductionCharacteristics(getReductionCharacteristics())
    changeSettings(s)
  }, [defaults, updates])

  // METHODS
  const getIsExist = (id: ReductionCharacteristicsValues) =>
    reductionCharacteristics[id] !== undefined
  const getIsDefault = (id: ReductionCharacteristicsValues) =>
    reductionCharacteristics[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (
    id: ReductionCharacteristicsValues,
    flag: InputTypeValue
  ) => (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    const reg = new RegExp('^[0-9]$') // only digits
    const stabValue = reg.test(String(value)) ? Number(value) : value

    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeReductionCharacteristics({
      ...reductionCharacteristics,
      [id]: stabValue
    })
    changeSettings(updSettings)
    onChange({ [id]: stabValue })
  }

  const handleOnBlur = (id: ReductionCharacteristicsValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleCheckboxChange = (id: string) => (status: boolean) => {
    changeReductionCharacteristics({
      ...reductionCharacteristics,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleOnDropdownChange = (id: ReductionCharacteristicsValues) => (
    value: DropdownItemData
  ) => {
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeReductionCharacteristics({
      ...reductionCharacteristics,
      [id]: Number(value.id)
    })
    changeSettings(updSettings)
    onChange({ [id]: Number(value.id) })
  }

  const handleUndoClick = (id: ReductionCharacteristicsValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value
      changeReductionCharacteristics({
        ...reductionCharacteristics,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnGetCurrentDropdown = (value: number, id: string) =>
    getCurrentDropdown({
      dropdowns,
      value,
      id
    })
  const handleOnGetError = (id: ReductionCharacteristicsValues) =>
    getError(id, error)
  const getEmptyDropdownStyle = (id: ReductionCharacteristicsIds) =>
    getIsExist(id) ? undefined : customStyles
  // eof METHODS

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row className={classes.cell}>
            <Dropdown
              className={classes.dropdown}
              custom={getEmptyDropdownStyle(
                ReductionCharacteristicsIds.AVOIDED_GHG_CLASS
              )}
              label={
                getLabel(
                  ReductionCharacteristicsIds.AVOIDED_GHG_CLASS,
                  restrictions
                ).text
              }
              data={dropdowns.ghgBoolean}
              preset="body"
              defaultValue={handleOnGetCurrentDropdown(
                reductionCharacteristics.ghgBoolean,
                ReductionCharacteristicsIds.AVOIDED_GHG_CLASS
              )}
              description={
                restrictions[ReductionCharacteristicsIds.AVOIDED_GHG_CLASS]
                  ?.description
              }
              disabled={!reductionCharacteristics.ghgBoolean}
              isUndo={getIsDefault(
                ReductionCharacteristicsIds.AVOIDED_GHG_CLASS
              )}
              onChange={handleOnDropdownChange(
                ReductionCharacteristicsIds.AVOIDED_GHG_CLASS
              )}
              onUndoClick={handleUndoClick(
                ReductionCharacteristicsIds.AVOIDED_GHG_CLASS
              )}
            />
          </Row>
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={ReductionCharacteristicsIds.DECARBONIZATION_RATE}
            labelProps={getLabel(
              ReductionCharacteristicsIds.DECARBONIZATION_RATE,
              restrictions
            )}
            isPercent
            defaultValue={reductionCharacteristics.electricityDecarbRate}
            iconProps={percentIconProps}
            isFocused={getIsExist(
              ReductionCharacteristicsIds.DECARBONIZATION_RATE
            )}
            disabled={
              !getIsExist(ReductionCharacteristicsIds.DECARBONIZATION_RATE)
            }
            description={
              restrictions[ReductionCharacteristicsIds.DECARBONIZATION_RATE]
                ?.description
            }
            isUndo={getIsDefault(
              ReductionCharacteristicsIds.DECARBONIZATION_RATE
            )}
            errorProps={handleOnGetError(
              ReductionCharacteristicsIds.DECARBONIZATION_RATE
            )}
            onBlur={handleOnBlur(
              ReductionCharacteristicsIds.DECARBONIZATION_RATE
            )}
            onChange={handleOnChange(
              ReductionCharacteristicsIds.DECARBONIZATION_RATE,
              InputTypeValue.PERCENT
            )}
            onUndoClick={handleUndoClick(
              ReductionCharacteristicsIds.DECARBONIZATION_RATE
            )}
          />
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <Text text={coBenefitsIncludedLabelProps.text} preset="caption" />
            <Row
              className={classes.selectionControls}
              fullWidth
              justifyContent="space-between"
            >
              <Checkbox
                {...getLabel(
                  ReductionCharacteristicsIds.FUEL_SAVINGS,
                  restrictions
                )}
                defaultChecked={reductionCharacteristics.fuelSavings}
                disabled={!getIsExist(ReductionCharacteristicsIds.FUEL_SAVINGS)}
                description={
                  restrictions[ReductionCharacteristicsIds.FUEL_SAVINGS]
                    ?.description
                }
                isUndo={getIsDefault(ReductionCharacteristicsIds.FUEL_SAVINGS)}
                onChange={handleCheckboxChange(
                  ReductionCharacteristicsIds.FUEL_SAVINGS
                )}
                onUndoClick={handleUndoClick(
                  ReductionCharacteristicsIds.FUEL_SAVINGS
                )}
              />
              <Checkbox
                {...getLabel(ReductionCharacteristicsIds.HEALTH, restrictions)}
                defaultChecked={reductionCharacteristics.health}
                disabled={!getIsExist(ReductionCharacteristicsIds.HEALTH)}
                isUndo={getIsDefault(ReductionCharacteristicsIds.HEALTH)}
                description={
                  restrictions[ReductionCharacteristicsIds.HEALTH]?.description
                }
                onChange={handleCheckboxChange(
                  ReductionCharacteristicsIds.HEALTH
                )}
                onUndoClick={handleUndoClick(
                  ReductionCharacteristicsIds.HEALTH
                )}
              />
            </Row>
          </Column>
        </Row>
      </Content>
    </Column>
  )
}
