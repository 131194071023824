import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  pdf: {
    width: 785,
    maxWidth: '100%',
    padding: '0 68px 24px 0'
  },
  content: {
    backgroundColor: theme.colors.white,
    padding: '22px 48px 22px 60px'
  },
  filledHeader: {
    backgroundColor: theme.colors.tableHeader,
    height: 24,
    marginLeft: '-60px',
    paddingLeft: 60,
    marginTop: 18
  },
  subHeader: {},
  title: {},
  subTitle: {
    color: theme.colors.green,
    borderBottom: `1px solid ${theme.colors.green}`,
    marginTop: 6
  },
  text: {
    flexShrink: 0
  },
  value: {
    flexShrink: 0
  },
  pdfRow: {
    padding: '2px 0'
  },
  containerHeader: {
    marginLeft: 24,
    marginTop: 14
  },
  disabled: {
    color: theme.colors.border
  },
  line: {
    color: theme.colors.grey
  },
  transparentContainer: {
    padding: '0 4px',
    marginTop: 12
  },
  filledContainer: {
    backgroundColor: theme.colors.footerBackground,
    padding: '0 4px',
    marginTop: 12
  },
  underline: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.colors.grey}`
  },
  freeSpace: {
    flexGrow: 1
  },
  inputs: {
    padding: '4px 0'
  },
  radioRow: {
    padding: '4px 0'
  },
  radio: {
    width: 90,
    '& > *:not(:first-child)': {
      marginLeft: 16
    }
  },
  radioGroup: {
    width: 240,
    '& > *:not(:first-child)': {
      marginLeft: 40
    }
  },
  checkboxes: {
    '& > *:not(:first-child)': {
      marginLeft: 30
    }
  },
  checkboxCell: {
    justifyContent: 'flex-start',
    width: 182,
    padding: '4px 0'
  },
  multipleCheckboxCell: {
    justifyContent: 'flex-start',
    width: 212,
    padding: '4px 0'
  },
  radioCheckIndent: {
    '& > *:not(:first-child)': {
      marginLeft: 8
    }
  },
  overview: {
    padding: '16px 0',
    '& > *:not(:first-child)': {
      marginLeft: 60
    }
  },
  newPage: {},
  sub: {
    position: 'absolute',
    marginTop: 7,
    marginLeft: -4
  },
  overviewCol: {
    minWidth: 308
  },
  valuesLine: {
    marginTop: 6,
    lineHeight: 1.7
  },
  titleLine: {
    marginTop: 12
  },
  col: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  settings: {
    '& > *:not(:first-child)': {
      marginLeft: 108
    }
  },
  setting: {
    marginTop: 8
  },
  frame: {
    height: 500,
    backgroundColor: theme.colors.white
  },
  warningContainer: {
    height: 32,
    marginTop: 12
  },
  pdfContainer: {
    width: 710,
    marginLeft: 42,
    alignContent: 'flex-start',
    alignItems: 'flex-start'
  },
  pdfWarning: {
    position: 'relative',
    right: 0
  },
  tabletPdf: {
    width: 790,
    alignContent: 'flex-start',
    alignItems: 'flex-start'
  },
  back: {
    marginTop: 12
  },
  info: {
    '& > *:not(:first-child)': {
      marginLeft: 24
    },
    '& > *': {
      textDecoration: 'none'
    }
  },
  spinnerContainer: {
    width: 40,
    height: 40
  },
  column: {
    padding: 0,
    '& > *': {
      marginTop: 12
    }
  },
  horizontalSeparator: {
    marginTop: 8
  },
  checkboxesList: {
    flexWrap: 'wrap'
  }
}))
