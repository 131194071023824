import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const WeighingMachine: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M11 5.365h2v14h-2v-14z"
        fill={props.fill || theme.colors.white}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.187 13.463c.8 1.314 2.811 1.338 3.62-.011L19 9.836l-1.813 3.626zM15 13.365c1.032 3.978 6.61 4.168 7.91.27l.09-.27-4-8-4 8zM3.187 13.463c.8 1.314 2.811 1.338 3.62-.011L5 9.836l-1.813 3.626zM1 13.365c1.032 3.978 6.61 4.168 7.91.27l.09-.27-4-8-4 8z"
        fill={props.fill || theme.colors.white}
      />
      <path
        d="M8 21.365a2 2 0 012-2h4a2 2 0 012 2H8zM4 5.365a2 2 0 012-2h12a2 2 0 012 2H4z"
        fill={props.fill || theme.colors.white}
      />
    </svg>
  )
}
