import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const ReplyAll: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4.5V7.5L3 11.5L7 15.5V18.5L0 11.5L7 4.5ZM13 4.5V8.5C20 9.5 23 14.5 24 19.5C21.5 16 18 14.4 13 14.4V18.5L6 11.5L13 4.5Z"
        fill={props.fill || theme.colors.green}
      />
    </svg>
  )
}
