import React, { DragEvent, FC, useState, useMemo, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { LightTheme } from '../../../theme'
import { PolicyWithProperties } from '../../../store/reducers/policy/policy.types'
import { PolicyCreators, State } from '../../../store'
import { Column } from '../../column'
import { DropdownItems, PositionDropdown } from '../../dropdown-items'
import { Icon, IconName } from '../../icon'
import { Row } from '../../row'
import { Text } from '../../text'
import { QuestionModal } from '../../question-modal/question-modal'
import { CreateNewPolicyModal } from '../../create-new-policy-modal'
import { cleanUpSettings } from '../../update-policy'
import { getSubMenu } from './helpers'
import { CardListItemProps } from './card-list-item.types'
import { useStyle } from './card-list-item.styles'

export const CardListItem: FC<CardListItemProps> = ({
  className,
  onAddToCompare,
  ...props
}) => {
  const {
    id,
    name,
    years,
    totalCostsTotal,
    evAdoptionShare,
    ghgPlotAvoided,
    ghgPlotMtco2,
    updatedAt,
    draggable,
    disabled
  } = props
  const dispatch = useDispatch()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, disabled })
  const { compareIds } = useSelector((state: State) => ({
    compareIds: state.policy.compareIds
  }))
  const dateModified = moment(updatedAt).format('MMM D, YYYY')
  const durationString = `${years} years`
  const totalCostsString = `${totalCostsTotal || 0}`
  const evAdoptionShareString = `${evAdoptionShare || 0}%`
  const ghgPlotAvoidedString = `${ghgPlotAvoided}`
  const ghgPlotMtco2String = `${ghgPlotMtco2 || 0}`
  const [openModal, changeOpenModal] = useState(false)
  const [createCopyModal, changeCreateCopyModal] = useState(false)

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    [openModal]
  )

  const handleOnResultsClick = useCallback(() => {
    window.open(`/policy/${id}/summary`, '_self')
  }, [id])

  const handleOnCreateCopy = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeCreateCopyModal(state)
      } else {
        changeCreateCopyModal(!createCopyModal)
      }
    },
    [createCopyModal]
  )

  const handleOnEditClick = useCallback(() => {
    window.open(`/policy/${id}`, '_self')
  }, [id])

  const handleOnDelete = () => {
    dispatch(PolicyCreators.deletePolicyRequest({ id }))
  }

  const handleOnDragStart = (event: DragEvent<HTMLDivElement>) => {
    const policyListItem: Partial<PolicyWithProperties> = {
      id,
      name,
      years,
      totalCostsTotal,
      evAdoptionShare,
      ghgPlotAvoided,
      ghgPlotMtco2,
      updatedAt,
      draggable
    }

    // Add the target element's id to the data transfer object
    event.dataTransfer.setData('data', JSON.stringify(policyListItem))

    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'copy'
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'copy'
  }

  const data = useMemo(
    () =>
      getSubMenu({
        handleOnResultsClick,
        handleOnOpenModal,
        handleOnEditClick,
        handleOnCreateCopy,
        onAddToCompare,
        props
      }),
    [
      handleOnResultsClick,
      handleOnOpenModal,
      handleOnEditClick,
      handleOnCreateCopy,
      onAddToCompare,
      props
    ]
  )

  const draggableSettings = data
  const usualSettings = data.splice(3, 1)
  const dropdowns = draggable ? usualSettings : draggableSettings
  const isAddToCompareAvailable = compareIds.length < 3

  return (
    <Column
      draggable={draggable}
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
      onDragStart={handleOnDragStart}
    >
      <Column
        fullWidth
        className={classes.header}
        justifyContent="space-between"
      >
        <Column fullWidth justifyContent="space-between">
          <Row fullWidth justifyContent="space-between">
            <Text
              className={classes.text}
              text={dateModified}
              preset="caption"
              color="border"
            />
            <Column className={classes.menu}>
              <DropdownItems
                disabled={isAddToCompareAvailable}
                position={PositionDropdown.LEFT}
                data={dropdowns}
              >
                <Icon name={IconName.MORE_HORIZON} fill="darkBlue" />
              </DropdownItems>
            </Column>
          </Row>
          <Column
            fullWidth
            alignItems="flex-start"
            className={classes.name}
            onClick={handleOnEditClick}
          >
            <Text className={classes.text} text={name} preset="h3" />
          </Column>
        </Column>
      </Column>

      <Column
        fullWidth
        className={classes.info}
        justifyContent="space-between"
        onClick={handleOnEditClick}
      >
        <Row
          className={classes.column}
          fullWidth
          justifyContent="space-between"
        >
          <Row>
            <Icon name={IconName.TIMELAPSE} fill="green" />
            <Text
              className={classes.field}
              text="Duration"
              tx="policy.duration"
              preset="subTitle"
              color="disabledRadioText"
            />
          </Row>
          <Text
            className={classes.text}
            text={durationString}
            preset="bodyBold"
          />
        </Row>
        <Row
          className={classes.column}
          fullWidth
          justifyContent="space-between"
        >
          <Row>
            <Icon name={IconName.ATTACH_MONEY} fill="green" />
            <Text
              className={classes.field}
              text="Total cost"
              tx="policy.totalCost"
              preset="subTitle"
              color="disabledRadioText"
            />
          </Row>
          <Text
            className={classes.text}
            text={totalCostsString}
            preset="bodyBold"
          />
        </Row>
        <Row
          className={classes.column}
          fullWidth
          justifyContent="space-between"
        >
          <Row>
            <Icon name={IconName.EV_STATION_SMALL} fill="green" />
            <Text
              className={classes.field}
              text="EV Fleet Share"
              tx="policy.evFleetShare"
              preset="subTitle"
              color="disabledRadioText"
            />
          </Row>
          <Text
            className={classes.text}
            text={evAdoptionShareString}
            preset="bodyBold"
          />
        </Row>
        <Row
          className={classes.column}
          fullWidth
          justifyContent="space-between"
        >
          <Row>
            <Icon name={IconName.FILTER_DRAMA_SMALL} fill="green" />
            <Text
              className={classes.field}
              text="GHGs Avoided"
              tx="policy.ghgAvoided"
              preset="subTitle"
              color="disabledRadioText"
            />
          </Row>
          <Text
            className={classes.text}
            text={ghgPlotAvoidedString}
            preset="bodyBold"
          />
        </Row>
        <Row
          className={classes.column}
          fullWidth
          justifyContent="space-between"
        >
          <Row>
            <Icon name={IconName.CLOUD_DOWNLOAD} fill="green" />
            <Text
              className={`${classes.field} ${classes.flex}`}
              text="$ / MTCO{sub}e"
              tx="policy.MTCO2"
              preset="subTitle"
              color="disabledRadioText"
              values={{
                sub: (
                  <Text
                    className={classes.sub}
                    text="2"
                    preset="subTitleMini"
                    color="disabledRadioText"
                  />
                )
              }}
            />
          </Row>
          <Text
            className={classes.text}
            text={ghgPlotMtco2String}
            preset="bodyBold"
          />
        </Row>
      </Column>
      {createCopyModal && (
        <CreateNewPolicyModal
          key={id}
          titleLabelProps={{
            tx: 'policy.settings.createCopy',
            text: 'Create Copy'
          }}
          settings={cleanUpSettings(props)}
          onClose={handleOnCreateCopy(false)}
        />
      )}
      {openModal && (
        <QuestionModal
          name={name}
          onSubmit={handleOnDelete}
          onClose={handleOnOpenModal(false)}
        >
          <Text
            text="Do you want to delete the “{name}” policy?"
            tx="delete.policy.description"
            values={{
              name: <Text text={name} preset="bodyBold" />
            }}
            preset="body"
          />
          <Text
            text="This process cannot be undone."
            tx="delete.policy.explanation"
            preset="body"
          />
        </QuestionModal>
      )}
    </Column>
  )
}
