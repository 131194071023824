import React, {
  lazy,
  Suspense,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react'
import { useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ActionTypeName, ENTIRE_MODEL_IDS, ObjectProps } from '.'
import {
  Content,
  Column,
  Row,
  IconName,
  Text,
  Button,
  PolicyProps,
  SettingsType,
  ErrorType,
  ErrorProps,
  Icon,
  ContentSpinner,
  QuestionModal
} from '..'
import { useResponsive } from '../../providers'
import { Policy } from '../../services'
import { PolicyCreators, State, ValidateCreators } from '../../store'
import { LightTheme } from '../../theme'
import { useWindowScroll } from '../../hooks'
import { CreateNewPolicyModal } from '../create-new-policy-modal'
import { Spinner } from '../spinner'
import { ErrorModal } from '../error-modal'
import {
  descriptionLabelProps,
  forecastedFleetSizeLabelProps,
  forecastedMpgLabelProps,
  setToBauLabelProps,
  setToBauSubtitleLabelProps,
  updateLabelProps,
  updatePolicyNameLabelProps,
  vmtGrowthRateLabelProps,
  warningLabelProps,
  wishToContinueLabelProps
} from './label-props'
import {
  getAlignedValuesByType,
  getIsRegionalOptionsAllowed,
  cleanUpSettings,
  getErrorsInString,
  getIsValuesValid,
  getPolicyName,
  cleanUpFromComma
} from './helpers'
import { FieldNotification } from './field-notification'
import { AgreeModal } from './agree-modal'
import { useStyle } from './update-policy.styles'

export const getComponent = (name: string) => (mod: any) => ({
  default: mod[name]
})

const GeneralInputsAdvanced = lazy(() =>
  import('./general-inputs-advanced').then(
    getComponent('GeneralInputsAdvanced')
  )
)
const GeneralInputsBasic = lazy(() =>
  import('./general-inputs-basic').then(getComponent('GeneralInputsBasic'))
)
const NationalInputs = lazy(() =>
  import('./national-inputs').then(getComponent('NationalInputs'))
)
const RegionalInputs = lazy(() =>
  import('./regional-inputs').then(getComponent('RegionalInputs'))
)

export const UpdatePolicy = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const dispatch = useDispatch()
  const { id } = useParams<PolicyProps>()
  const {
    user,
    policy,
    bauDefaults,
    isLoaded,
    invalidValues,
    invalidValuesOnFailure,
    validateError,
    policyError,
    updateError,
    isUpdateLoaded,
    defaultPolicy
  } = useSelector((state: State) => ({
    ...state.app,
    user: state.auth.user,
    isUpdateLoaded: state.policy.isUpdateLoaded,
    policy: state.policy.data.find((item) => item.id === id),
    bauDefaults: state.policy.bauDefaults,
    currentId: state.policy.currentId,
    invalidValues: state.validate.invalidValues,
    invalidValuesOnFailure: state.validate.invalidValuesOnFailure,
    validateError: state.validate.error,
    policyError: state.policy.error,
    updateError: state.policy.updateError,
    defaultPolicy: state.policy.defaultPolicy
  }))
  const [openSaveAsModal, changeOpenSaveAsModal] = useState(false)
  const [openBauModal, changeOpenBauModal] = useState(false)
  const [openEditModal, changeOpenEditModal] = useState(false)
  const [openSaRModal, changeOpenSaRModal] = useState(false)
  const [openErrorModal, changeOpenErrorModal] = useState(false)
  const [openRevertAllModal, changeOpenRevertAllModal] = useState(false)
  const [isReverted, changeIsReverted] = useState(false)
  const [isRevert, changeIsRevert] = useState(false)

  const [defaults, changeDefaults] = useState<SettingsType>(policy)
  const [updates, changeUpdates] = useState<Partial<Policy> | {}>({})
  const [error, changeError] = useState<ErrorType | undefined>()
  const [errors, changeErrors] = useState<ErrorType[] | []>([])
  const [isUpdateAllowed, changeIsUpdateAllowed] = useState(false)
  const [isNotificationVisible, changeNotificationVisible] = useState(false)
  const [isSave, changeIsSave] = useState(false)
  const [isSaveAndRun, changeIsSaveAndRun] = useState(false)
  const [isSaveAs, changeIsSaveAs] = useState(false)
  const [
    isValidateAgreedBeforeSaveAs,
    changeValidateAgreedBeforeSaveAs
  ] = useState(false)
  const isRegionalAllowed = getIsRegionalOptionsAllowed(user)
  const actionsRef = useRef<HTMLDivElement>(null)
  const [stickyClass, changeStickyClass] = useState('')
  const HEADER_OFFSET = 100

  const { isTablet } = useResponsive()
  const ResponsiveColRow = isTablet ? Column : Row

  const updateDefaults = useCallback(
    (immediateData?: Partial<Policy>) => {
      if (defaults && updates) {
        const saved = {
          ...defaults,
          ...updates,
          ...immediateData
        }
        changeDefaults(saved)
        return saved
      }
      return defaults
    },
    [defaults, updates]
  )

  const handleOnOpenSaRModal = (state?: boolean) => () => {
    if (typeof state === 'boolean') {
      changeOpenSaRModal(state)
    } else {
      changeOpenSaRModal(!openSaRModal)
    }
  }

  const handleOnCloseSaRModal = () => {
    handleOnOpenSaRModal(false)()

    changeIsSaveAndRun(false)
    changeIsSaveAs(false)
    changeValidateAgreedBeforeSaveAs(false)
  }

  const updatePolicy = useCallback(
    (reset?: boolean) => {
      const updatedPolicy = updateDefaults()
      const cleanedFromPlots = cleanUpSettings(updatedPolicy)
      const alignedByComma = cleanUpFromComma(cleanedFromPlots)
      const alignedByType = getAlignedValuesByType(alignedByComma)

      if (alignedByType) {
        dispatch(
          PolicyCreators.updatePolicyRequest({ ...alignedByType, reset })
        )
      }
    },
    [updateDefaults]
  )

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenSaveAsModal(state)
      } else {
        changeOpenSaveAsModal(!openSaveAsModal)
      }
    },
    []
  )

  const handleOnOpenRevertAllModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenRevertAllModal(state)
      } else {
        changeOpenRevertAllModal(!openRevertAllModal)
      }
    },
    []
  )

  const handleOnCloseOpenModal = useCallback(() => {
    changeIsSaveAs(false)
    changeValidateAgreedBeforeSaveAs(false)
  }, [])

  const handleOnAutoClose = useCallback(() => {
    changeIsSaveAs(false)
    changeValidateAgreedBeforeSaveAs(false)
    handleOnOpenModal(false)()
  }, [handleOnOpenModal])

  const handleOnOpenEditModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenEditModal(state)
      } else {
        changeOpenEditModal(!openEditModal)
      }
    },
    [openEditModal]
  )

  const handleOnOpenErrorModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenErrorModal(state)
      } else {
        changeOpenErrorModal(!openEditModal)
      }
    },
    [openEditModal]
  )

  const handleOnOpenBauModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenBauModal(state)
      } else {
        changeOpenBauModal(!openBauModal)
      }
    },
    [openBauModal]
  )

  const handleOnSubmitSaveAsModal = useCallback(() => {
    // inner create request is in create modal
    changeIsUpdateAllowed(false)
  }, [])

  useEffect(() => {
    if (policy) {
      changeDefaults(policy)
    }
  }, [policy])

  useEffect(() => {
    if (!policy) {
      dispatch(PolicyCreators.getPoliciesRequest())
      dispatch(PolicyCreators.getPolicyRequest({ id }))
    }

    dispatch(PolicyCreators.getDefaultPolicyRequest())
    dispatch(PolicyCreators.getCatalogRequest())
    dispatch(PolicyCreators.getRegionsRequest())
    dispatch(PolicyCreators.getBauDefaultsRequest())
    dispatch(PolicyCreators.getRestrictionsRequest())

    return () => {
      changeUpdates({})
    }
  }, [])

  const incorrectFields = useMemo(() => getIsValuesValid(invalidValues), [
    invalidValues
  ])

  useEffect(() => {
    const isUpdateExist = Object.keys(updates).length
    const isInvalidValues =
      Object.keys(invalidValues).length && incorrectFields.length
    const isValidValues =
      !Object.keys(invalidValues).length && !incorrectFields.length

    // without isSaveAs(!)
    const isOneProcessLaunched = isSave || isSaveAndRun
    const isAllowedProccessErrors = isOneProcessLaunched || isSaveAs
    const isErrors = isUpdateExist && errors.length && isAllowedProccessErrors
    const isInvalidErrors =
      isUpdateExist && isInvalidValues && isOneProcessLaunched

    if (isErrors) {
      changeError(undefined)
      changeNotificationVisible(true)
      changeIsSaveAndRun(false)
      changeIsSave(false)
      changeIsSaveAs(false)
      changeValidateAgreedBeforeSaveAs(false)
    }

    if (isInvalidErrors) {
      handleOnOpenSaRModal(true)()
    }

    if (isValidValues && isOneProcessLaunched) {
      // for 'saveAs': we can do update only after submit in modal
      changeIsUpdateAllowed(true)
    }

    const isSaveAllowed =
      (isValidValues || isValidateAgreedBeforeSaveAs) && isSaveAs

    if (isSaveAllowed) {
      // special process for saveAs modal after validation
      updateDefaults()
      handleOnOpenModal(true)()
      changeOpenErrorModal(false)
    }
  }, [
    invalidValues,
    isSaveAndRun,
    isSave,
    isSaveAs,
    isValidateAgreedBeforeSaveAs
  ])

  useEffect(() => {
    const isUpdateExist = Object.keys(updates).length
    const isNoErrors = !errors.length
    const isUpdateAvailable = isUpdateExist && isNoErrors
    const isAllowedToUpdate = isUpdateAvailable && isUpdateAllowed

    const isUpdateOnSaveAndRun = isAllowedToUpdate && isSaveAndRun
    const isUpdateOnSave = isAllowedToUpdate && isSave
    const isUpdateOnSaveAs = isAllowedToUpdate && isSaveAs
    const isUpdateOnSaveAndRunOnRevert =
      isReverted && isNoErrors && isSaveAndRun && isUpdateAllowed

    if (isUpdateOnSaveAndRunOnRevert) {
      updatePolicy(true)
      changeIsSaveAndRun(false)
      changeIsUpdateAllowed(false)
      return
    }

    if (isUpdateOnSaveAndRun) {
      updatePolicy(true)
      changeIsSaveAndRun(false)
      changeIsUpdateAllowed(false)
    }

    if (isUpdateOnSave) {
      updatePolicy()
      changeIsSave(false)
      changeIsUpdateAllowed(false)
    }

    if (isUpdateOnSaveAs) {
      changeIsSaveAs(false)
      changeIsUpdateAllowed(false)
      changeValidateAgreedBeforeSaveAs(false)
    }
  }, [isUpdateAllowed])

  useEffect(() => {
    const isErrorDetected = validateError || policyError || updateError
    const isIncorrectValues = incorrectFields.length
    if (isErrorDetected) {
      handleOnOpenErrorModal(true)()
    }
    if (isIncorrectValues) {
      handleOnOpenSaRModal(true)()
    }
  }, [validateError, policyError, updateError, invalidValues])

  const setStickyHeader = useCallback(() => {
    const header = actionsRef && actionsRef.current
    const sticky = header && header.getBoundingClientRect().top

    if (sticky && window.pageYOffset > sticky) {
      changeStickyClass(classes.sticky)
    }

    if (window.pageYOffset < HEADER_OFFSET) {
      changeStickyClass('')
    }
  }, [actionsRef])

  useEffect(() => {
    if (isNotificationVisible) {
      changeError(errors[0])
    }
  }, [isNotificationVisible, errors])

  useEffect(() => {
    const isUpdated = ENTIRE_MODEL_IDS.find((modelSetting: string) =>
      Object.keys(updates).includes(modelSetting)
    )
    changeIsRevert(Boolean(isUpdated))
  }, [updates])

  useWindowScroll(setStickyHeader)

  const getIsUpdateExist = useCallback(() => {
    const list: ObjectProps = {}
    const cleanedPolicyFromPlots = cleanUpSettings(policy)
    const alignedPolicy = getAlignedValuesByType(cleanedPolicyFromPlots)

    const cleanedDefaultsFromPlots = cleanUpSettings(defaults)
    const alignedDefaults = getAlignedValuesByType(cleanedDefaultsFromPlots)

    Object.entries(alignedPolicy).forEach(([key, value]: any) => {
      if (value !== alignedDefaults[key]) {
        list[key] = value
      }
    })

    const isUpdateExist =
      Object.keys(updates).length || Object.keys(list).length
    const isUpdateAvailable = isUpdateExist || isReverted

    return Boolean(isUpdateAvailable)
  }, [policy, defaults, updates])

  const validateBeforeUpdate = useCallback(
    (callback: () => void) => {
      const isUpdateExist = Boolean(Object.keys(updates).length)
      const isErrors = isUpdateExist && errors.length
      const cleanedFromPlots = cleanUpSettings({
        ...defaults,
        ...updates
      })
      const alignedByComma = cleanUpFromComma(cleanedFromPlots)
      const alignedByType = getAlignedValuesByType(alignedByComma)

      const isValidateAllowed = isUpdateExist && !isErrors
      const isValidateAfterRevertAllowed = isReverted && !isErrors
      const isValidateRequest =
        isValidateAfterRevertAllowed || isValidateAllowed

      if (isValidateRequest) {
        dispatch(
          ValidateCreators.postValidateRequest({
            values: alignedByType
          })
        )
      }
      callback()
    },
    [defaults, updates, errors]
  )

  const handleOnError = useCallback(
    ({ errorId, values, sliderId, reset }: ErrorProps) => {
      if (values) {
        const newError: ErrorType = {
          id: errorId,
          sliderId,
          textProps: {
            tx: 'policy.error',
            text:
              'Min {min}, max {max} {step, plural, =0 {} one {} other {, step {step}}}',
            values
          }
        }

        changeError(newError)
        changeErrors([...errors, newError])
        return
      }

      if (reset) {
        changeError(undefined)
        return
      }

      const updatedErrorsList = errors.filter(
        (item: ErrorType) => item.id !== errorId
      )

      if (updatedErrorsList.length) {
        changeError(updatedErrorsList[0])
      } else {
        changeError(undefined)
      }
      changeErrors(updatedErrorsList)
    },
    [errors]
  )

  const handleOnAffordabilityTool = useCallback(() => {
    window.open(`/policy/${id}/affordability`, '_blank')
  }, [id])

  const handleOnViewResults = useCallback(() => {
    window.open(`/policy/${id}/summary`, '_self')
  }, [id])

  const handleOnScroll = useCallback(() => {
    if (error) {
      changeError(undefined)
    }
  }, [])

  const fallback = useMemo(
    () => (
      <Column justifyContent="flex-start" onScroll={handleOnScroll}>
        <Content>
          <Column fullWidth>
            <Spinner />
          </Column>
        </Content>
      </Column>
    ),
    [handleOnScroll]
  )

  if (!isUpdateLoaded) {
    return (
      <Column fullFilledHeight>
        <Content>
          <Text
            text="Calculating your policy..."
            tx="policy.calculatingPolicy"
            preset="h2"
          />
          <Spinner className={classes.loader} preset="large" />
        </Content>
      </Column>
    )
  }

  if (!isLoaded || !defaults) {
    return <ContentSpinner />
  }

  // TODO: remove only after release
  // const handleOnSave = () => {
  //   validateBeforeUpdate(() => {
  //     changeIsSave(true)
  //   })
  // }

  const handleOnSaveAs = () => {
    validateBeforeUpdate(() => {
      changeIsSaveAs(true)
    })
  }

  const handleOnSaveRun = () => {
    validateBeforeUpdate(() => {
      changeIsSaveAndRun(true)
    })
  }

  // TODO: disable button when input values are equal to BAU list
  const handleSetToBAU = () => {
    const bauDefaultsUpdates = getAlignedValuesByType(bauDefaults)

    Object.entries(defaults).forEach(([key, value]: any) => {
      const isSimilar = bauDefaultsUpdates[key] === value
      if (isSimilar) {
        delete bauDefaultsUpdates[key]
      }
    })

    const updated = {
      ...updates,
      ...bauDefaultsUpdates
    }
    changeUpdates(updated)
  }

  const handleSaRModal = () => {
    changeIsUpdateAllowed(true)

    if (isSaveAs) {
      changeValidateAgreedBeforeSaveAs(true)
    }
  }

  const getIsSimilarValue = (values: Partial<Policy>) => {
    const [optionId, value] = Object.entries(values)[0]
    const defaultsValue = Object.entries(defaults).find(
      ([key]: any) => key === optionId
    )
    return defaultsValue && value === defaultsValue[1]
  }

  const onChange = (values: Partial<Policy>, action?: string) => {
    if (action === ActionTypeName.REVERT) {
      changeDefaults({ ...defaults, ...values })

      const newUpdates: ObjectProps = { ...updates, ...values }
      Object.keys(values).forEach((keyId: string) => {
        delete newUpdates[keyId]
      })
      changeUpdates(newUpdates)
      return
    }
    // TODO: rewrite for several inputs in values
    const isSimilar = getIsSimilarValue(values)

    let updated: any = {}
    if (isSimilar) {
      const newUpdates: ObjectProps = { ...updates, ...values }
      Object.keys(values).forEach((keyId: string) => {
        delete newUpdates[keyId]
      })
      updated = newUpdates
      changeUpdates(newUpdates)
    } else {
      updated = {
        ...updates,
        ...values
      }
      changeUpdates(updated)
    }

    // action option for Choose state popup
    // what changes region dropdown in another block
    if (action === ActionTypeName.SAVE) {
      updateDefaults(updated)
    }
  }

  const handleCloseNotification = () => {
    changeNotificationVisible(false)
  }

  const onRevertToFederal = () => {
    const cleanedFromPlots = cleanUpSettings({
      ...defaultPolicy
    })
    const alignedUpdates = getAlignedValuesByType(cleanedFromPlots)
    changeUpdates({})
    updateDefaults(alignedUpdates)
    changeIsReverted(true)
  }

  const isUSRegion = defaults && defaults.region === 1
  const sliderProps = {
    defaults,
    updates,
    defaultPolicy,
    error,
    isRegionalDisabled: isUSRegion,
    onSetToBAU: handleOnOpenBauModal(true),
    onChange,
    onError: handleOnError
  }

  const parsedErrors = getErrorsInString(invalidValuesOnFailure)

  return (
    <Column justifyContent="flex-start" onScroll={handleOnScroll}>
      <Content ref={actionsRef} className={stickyClass}>
        <ResponsiveColRow
          className={classes.controls}
          fullWidth
          alignItems="center"
        >
          <Row className={classes.info} justifyContent="flex-start" fullWidth>
            <Text text={getPolicyName({ updates, defaults })} preset="h1" />
            <Icon
              className={classes.editIcon}
              name={IconName.MODE}
              onClick={handleOnOpenEditModal(true)}
            />
          </Row>
          <Row className={classes.info} justifyContent="flex-end">
            <Button className={classes.btn} onClick={handleOnAffordabilityTool}>
              <Text text="" tx="btn.affordabilityTool" preset="body" />
            </Button>
          </Row>
          <Row
            className={`${classes.info} ${classes.processBtns}`}
            justifyContent="flex-end"
          >
            {/* TODO: remove only after release */}
            {/* <IconButton
                disabled={!isUpdateExist}
                preset="warning"
                iconName={IconName.SAVE}
                onClick={handleOnSave}
              /> */}
            <Button
              disabled={!!getIsUpdateExist()}
              preset="secondary"
              onClick={handleOnViewResults}
            >
              <Text text="View Results" tx="btn.viewResults" preset="body" />
            </Button>
            <Button
              disabled={!getIsUpdateExist()}
              preset="secondary"
              onClick={handleOnSaveAs}
            >
              <Text text="Save As" tx="btn.saveAs" preset="body" />
            </Button>
            <Button
              disabled={!getIsUpdateExist()}
              preset="accent"
              onClick={handleOnSaveRun}
            >
              <Text text="Save & Run Policy" tx="btn.saveRun" preset="body" />
            </Button>
          </Row>
        </ResponsiveColRow>
        <Row fullWidth className={classes.gradient} />
      </Content>
      <Content className={classes.scrollContainer}>
        <Column className={classes.content} fullHeight>
          <Column className={classes.inputsTitle} fullWidth>
            <Row
              className={`${classes.info}`}
              justifyContent="space-between"
              fullWidth
            >
              <Text
                text="Policy Inputs"
                tx="crete.policy.subTitle"
                preset="h2"
              />
              {isRevert && (
                <Row
                  className={classes.revert}
                  onClick={handleOnOpenRevertAllModal(true)}
                >
                  <Icon name={IconName.REPLY_ALL} fill="purple" />
                  <Text
                    className={classes.replyAll}
                    text="Revert Policy to Federal"
                    tx="policy.revertPolicyToFederal"
                    preset="h5"
                    color="purple"
                  />
                </Row>
              )}
            </Row>
          </Column>
          <Suspense fallback={fallback}>
            <GeneralInputsBasic {...sliderProps} />
            <GeneralInputsAdvanced {...sliderProps} />
            <NationalInputs {...sliderProps} />
            {isRegionalAllowed && <RegionalInputs {...sliderProps} />}
          </Suspense>
        </Column>
        {openSaveAsModal && (
          <CreateNewPolicyModal
            settings={cleanUpSettings({ ...defaults, ...updates })}
            onSubmit={handleOnSubmitSaveAsModal}
            onClose={handleOnCloseOpenModal}
            onAutoClose={handleOnAutoClose}
          />
        )}
        {openEditModal && (
          <CreateNewPolicyModal
            prevName={policy?.name}
            titleLabelProps={updatePolicyNameLabelProps}
            submitLabelProps={updateLabelProps}
            settings={{ name: policy?.name }}
            onSubmit={onChange}
            onClose={handleOnOpenEditModal(false)}
          />
        )}
        {openBauModal && (
          <AgreeModal
            titleProps={setToBauLabelProps}
            onSubmit={handleSetToBAU}
            onClose={handleOnOpenBauModal(false)}
          >
            <Column className={classes.description}>
              <Row>
                <Text {...setToBauSubtitleLabelProps} />
              </Row>
              <Column
                fullWidth
                alignItems="flex-start"
                className={classes.inputsList}
              >
                <Row>
                  <Icon name={IconName.OVAL} />
                  <Text
                    className={classes.inputItem}
                    {...forecastedMpgLabelProps}
                  />
                </Row>
                <Row>
                  <Icon name={IconName.OVAL} />
                  <Text
                    className={classes.inputItem}
                    {...vmtGrowthRateLabelProps}
                  />
                </Row>
                <Row>
                  <Icon name={IconName.OVAL} />
                  <Text
                    className={classes.inputItem}
                    {...forecastedFleetSizeLabelProps}
                  />
                </Row>
              </Column>
              <Row fullWidth justifyContent="flex-start">
                <Text {...descriptionLabelProps} />
              </Row>
            </Column>
          </AgreeModal>
        )}
        {openSaRModal && (
          <AgreeModal
            titleProps={warningLabelProps}
            data={incorrectFields}
            onSubmit={handleSaRModal}
            onAutoClose={handleOnCloseSaRModal}
            onClose={handleOnOpenSaRModal(false)}
          >
            <Column className={classes.sarModal}>
              <Row fullWidth justifyContent="flex-start">
                <Text {...wishToContinueLabelProps} />
              </Row>
            </Column>
          </AgreeModal>
        )}
        {openErrorModal && (
          <ErrorModal onClose={handleOnOpenErrorModal(false)}>
            <Column
              className={classes.errorContainer}
              alignItems="flex-start"
              fullWidth
              fullHeight
            >
              <Text {...parsedErrors} innerHtml />
            </Column>
          </ErrorModal>
        )}
        {isNotificationVisible && (
          <FieldNotification
            isOpen={isNotificationVisible}
            onClose={handleCloseNotification}
          />
        )}
        {openRevertAllModal && policy && (
          <QuestionModal
            name={policy.name}
            actionProps={{
              text: 'Revert',
              tx: 'revert.policy.title'
            }}
            onSubmit={onRevertToFederal}
            onClose={handleOnOpenRevertAllModal(false)}
          >
            {isReverted ? (
              <Spinner />
            ) : (
              <>
                <Text
                  text="Do you want to revert the “{name}” policy?"
                  tx="revert.policy.description"
                  values={{
                    name: <Text text={policy.name} preset="bodyBold" />
                  }}
                  preset="body"
                />
                <Text
                  text="This process cannot be undone."
                  tx="delete.policy.explanation"
                  preset="body"
                />
              </>
            )}
          </QuestionModal>
        )}
      </Content>
    </Column>
  )
}
