import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    backgroundColor: theme.colors.white,
    boxShadow: '0px -4px 8px #0000000f'
  },
  footer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    bottom: 0,
    color: theme.colors.text,
    whiteSpace: 'pre-line',
    padding: '24px 16px'
  },
  contactInfo: {
    marginTop: 16
  },
  col: {},
  navItem: {
    marginLeft: 24,
    paddingTop: 16,
    cursor: theme.cursor,
    '&:first-child': {
      marginLeft: 86
    }
  },
  copyright: {
    marginTop: 24
  },
  '@media screen and (max-width: 800px)': {
    navItem: {
      marginLeft: 16
    }
  }
}))
