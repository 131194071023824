import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import {
  CreatePolicyRequestParams,
  GetPoliciesRequestParams,
  PolicyCompareListParams
} from '../../store/actions/policy/policy.types'

import { getGeneralApiProblem } from '../api-problem'
import { alignPolicyToCorrectValues } from './utils'
import {
  Affordability,
  CreatePolicyResponse,
  CreatePolicyResults,
  DeletePolicyResults,
  UpdateAffordabilityResponse,
  UpdateAffordabilityResults,
  GetBauDefaultsResponse,
  GetBauDefaultsResults,
  GetCatalogResponse,
  GetCatalogResults,
  GetPoliciesResponse,
  GetPoliciesResults,
  GetPolicyPlotResponse,
  GetPolicyPlotResults,
  GetPolicyResponse,
  GetPolicyResults,
  GetRegionsResponse,
  GetRegionsResults,
  PolicyPlot,
  UpdatePolicyResponse,
  UpdatePolicyResults,
  GetRestrictionsResults,
  GetRestrictionsResponse,
  ExpandedSettings,
  GetComparePoliciesByIdsResponse,
  GetComparePoliciesByIdsResults,
  AdditionalGraph,
  UpdateAdditionalGraphResults,
  UpdateAdditionalGraphResponse,
  GetDefaultPolicyResults,
  GetDefaultPolicyResponse,
  GetPolicyReportResults,
  GetPolicyReportResponse
} from './policy.types'

// our "constructor"
export const policyService = (api: ApisauceInstance) => {
  /**
   * Get policies from api service
   */
  const getPolicies = async (
    params: GetPoliciesRequestParams | undefined
  ): Promise<GetPoliciesResults> => {
    if (!params) {
      return { kind: 'bad-data' }
    }

    const response = await api.get<GetPoliciesResponse>(
      '/organization/user/policies/list',
      params
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', policies: data, reset: !params.offset }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get policies by pages from api service
   */
  const getPoliciesByPages = async (
    params: GetPoliciesRequestParams | undefined
  ): Promise<GetPoliciesResults> => {
    if (!params) {
      return { kind: 'bad-data' }
    }

    const response = await api.get<GetPoliciesResponse>(
      '/organization/user/policies/list',
      params
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', policies: data, reset: !params.offset }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get policies by ids to compare from api service
   */
  const getComparePoliciesByIds = async (
    params: PolicyCompareListParams
  ): Promise<GetComparePoliciesByIdsResults> => {
    if (!params) {
      return { kind: 'bad-data' }
    }

    const response = await api.post<GetComparePoliciesByIdsResponse>(
      '/organization/user/policies/comparison',
      { policies: params.ids }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', policies: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const createPolicy = async (
    policy: CreatePolicyRequestParams | undefined
  ): Promise<CreatePolicyResults> => {
    if (!policy) {
      return { kind: 'unknown', temporary: true }
    }

    const alignedBody = alignPolicyToCorrectValues(policy)
    const response = await api.post<CreatePolicyResponse>(
      '/organization/user/policies',
      alignedBody
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', policy: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const updatePolicy = async (
    policy: ExpandedSettings | undefined
  ): Promise<UpdatePolicyResults> => {
    if (!policy) {
      return { kind: 'unknown', temporary: true }
    }

    const alignedBody = alignPolicyToCorrectValues(policy)
    const response = await api.put<UpdatePolicyResponse>(
      `/organization/user/policies/${policy.id}`,
      alignedBody
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', policy: data, reset: policy.reset }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getPolicy = async (id: string): Promise<GetPolicyResults> => {
    const response = await api.get<GetPolicyResponse>(
      `/organization/user/policies/${id}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', policy: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getDefaultPolicy = async (): Promise<GetDefaultPolicyResults> => {
    const response = await api.get<GetDefaultPolicyResponse>(
      '/organization/user/policies/current-defaults'
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', policy: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const deletePolicy = async (id: string): Promise<DeletePolicyResults> => {
    const response = await api.delete<any>(`/organization/user/policies/${id}`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.ok) {
        return { kind: 'ok', id }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getPolicyPlot = async (
    plot: PolicyPlot
  ): Promise<GetPolicyPlotResults> => {
    const response = await api.get<GetPolicyPlotResponse>(
      `/organization/user/policies/plot?id=${plot.id}&name=${plot.name}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        return { kind: 'ok', plot: response.data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getPolicyReport = async (
    id: string
  ): Promise<GetPolicyReportResults> => {
    if (!id) {
      return { kind: 'bad-data', temporary: true }
    }

    const response = await api.get<GetPolicyReportResponse>(
      `/organization/user/policies/report/${id}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        const { pdfReport } = data
        return { kind: 'ok', pdfReport }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getCatalog = async (): Promise<GetCatalogResults> => {
    const response = await api.get<GetCatalogResponse>('/core/catalog/list')

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', catalog: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getRegions = async (): Promise<GetRegionsResults> => {
    const response = await api.get<GetRegionsResponse>('/core/region/list')

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const regions = JSON.parse(JSON.stringify(response.data))
        return { kind: 'ok', regions }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getBauDefaults = async (): Promise<GetBauDefaultsResults> => {
    const response = await api.get<GetBauDefaultsResponse>(
      '/organization/user/policies/bau-defaults'
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', bauDefaults: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const updateAffordability = async (
    settings: Affordability
  ): Promise<UpdateAffordabilityResults> => {
    const body = snakecaseKeys(settings, {
      deep: true
    })
    const response = await api.post<UpdateAffordabilityResponse>(
      '/organization/user/policies/affordability',
      body
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', affordability: { ...settings, ...data } }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const updateAdditionalGraph = async (
    settings: AdditionalGraph
  ): Promise<UpdateAdditionalGraphResults> => {
    const body = snakecaseKeys(settings, {
      deep: true
    })
    const response = await api.post<UpdateAdditionalGraphResponse>(
      '/organization/user/policies/additional-graph',
      body
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', additionalGraph: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getRestrictions = async (): Promise<GetRestrictionsResults> => {
    const response = await api.get<GetRestrictionsResponse>(
      '/organization/user/policies/restrictions'
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', restrictions: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getPolicies,
    getPoliciesByPages,
    createPolicy,
    getPolicy,
    getPolicyReport,
    getDefaultPolicy,
    updatePolicy,
    deletePolicy,
    getPolicyPlot,
    getCatalog,
    getRegions,
    getBauDefaults,
    updateAffordability,
    getRestrictions,
    getComparePoliciesByIds,
    updateAdditionalGraph
  }
}
