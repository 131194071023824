import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Column, Icon, Row, Text } from '../..'
import { ComponentTextProps } from '../../text'
import { IconName } from '../../icon'
import { PlotIds } from '../policy-summary-list.types'
import { AnnualCostProps } from './widgets.types'
import { useStyle } from './widgets.styles'

export const AnnualCost: FC<AnnualCostProps> = ({
  stateFilter,
  region,
  phase1,
  phase2,
  totalCost,
  phase1Region,
  phase2Region,
  totalCostRegion,
  navigate,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const totalCostsLabelProps: ComponentTextProps = {
    tx: 'policy.annualCost',
    text: 'Policy Cost',
    color: 'text',
    preset: 'bodyBold'
  }

  const phase1CostLabelProps: ComponentTextProps = {
    tx: 'policy.summary.phase1Cost',
    text: 'Years 1 - 5 Cost',
    color: 'border',
    preset: 'caption'
  }

  const phase2CostLabelProps: ComponentTextProps = {
    tx: 'policy.summary.phase2Cost',
    text: 'Years 6 - 10 Cost',
    color: 'border',
    preset: 'caption'
  }

  const totalCostOfProgramLabelProps: ComponentTextProps = {
    tx: 'policy.summary.totalCostOfProgram',
    text: 'Total Cost',
    color: 'border',
    preset: 'caption'
  }

  const handleOnAnnualCost = () => {
    onClick(PlotIds.TOTAL_COSTS)
  }

  const isUSRegion = region.value === 1
  const defaultFilter = isUSRegion ? 3 : 1
  const filter = stateFilter || defaultFilter

  const isFilterUS = filter === 1 || filter === 3
  const isFilterRegion = filter === 1 || filter === 2

  return (
    <Column fullWidth>
      <Row className={classes.row} fullWidth justifyContent="space-between">
        <Row>
          <Icon name={IconName.MONETIZATION} fill="orange" />
          <Text className={classes.description} {...totalCostsLabelProps} />
        </Row>
        {navigate && (
          <Icon
            className={classes.breadcrumbs}
            name={IconName.BREAD_CRUMBS}
            fill="darkBlue"
            onClick={handleOnAnnualCost}
          />
        )}
      </Row>
      <Row className={classes.row} fullWidth justifyContent="space-between">
        <Row className={classes.mainCell} justifyContent="flex-start" />
        {isFilterUS && (
          <Row className={classes.cell} justifyContent="flex-start">
            <Text
              className={classes.description}
              text="US:"
              preset="caption"
              color="border"
            />
          </Row>
        )}
        {isFilterRegion && (
          <Row className={classes.cell} justifyContent="flex-start">
            <Text
              className={classes.description}
              text={`${region.abbreviation}:`}
              preset="caption"
              color="border"
            />
          </Row>
        )}
      </Row>
      <Row className={classes.row} fullWidth justifyContent="space-between">
        <Row className={classes.mainCell} justifyContent="flex-start">
          <Icon name={IconName.PHASE_1} />
          <Text className={classes.label} {...phase1CostLabelProps} />
        </Row>
        {isFilterUS && (
          <Row className={classes.cell} justifyContent="flex-start">
            <Text className={classes.description} text={phase1} preset="h2" />
          </Row>
        )}
        {isFilterRegion && (
          <Row className={classes.cell} justifyContent="flex-start">
            <Text
              className={classes.description}
              text={phase1Region}
              preset="h2"
            />
          </Row>
        )}
      </Row>
      <Row className={classes.row} fullWidth justifyContent="space-between">
        <Row className={classes.mainCell} justifyContent="flex-start">
          <Icon name={IconName.PHASE_2} />
          <Text className={classes.label} {...phase2CostLabelProps} />
        </Row>
        {isFilterUS && (
          <Row className={classes.cell} justifyContent="flex-start">
            <Text className={classes.description} text={phase2} preset="h2" />
          </Row>
        )}
        {isFilterRegion && (
          <Row className={classes.cell} justifyContent="flex-start">
            <Text
              className={classes.description}
              text={phase2Region}
              preset="h2"
            />
          </Row>
        )}
      </Row>
      <Row className={classes.row} fullWidth justifyContent="space-between">
        <Row className={classes.mainCell} justifyContent="flex-start">
          <Icon name={IconName.TOTAL_COST} />
          <Text className={classes.label} {...totalCostOfProgramLabelProps} />
        </Row>
        {isFilterUS && (
          <Row className={classes.cell} justifyContent="flex-start">
            <Text
              className={classes.description}
              text={String(totalCost)}
              preset="h2"
            />
          </Row>
        )}
        {isFilterRegion && (
          <Row className={classes.cell} justifyContent="flex-start">
            <Text
              className={classes.description}
              text={totalCostRegion}
              preset="h2"
            />
          </Row>
        )}
      </Row>
    </Column>
  )
}
