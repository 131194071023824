import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Column, Icon, IconName, Row, Text } from '../../../components'
import { LightTheme } from '../../../theme'
import { useStyle } from './empty-list.styles'

export const EmptyList: FC = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Column className={classes.container} justifyContent="flex-start" fullWidth>
      <Row className={classes.vector} justifyContent="flex-end" fullWidth>
        <Icon name={IconName.VECTOR} />
      </Row>
      <Row className={classes.description}>
        <Text
          text="You don’t have any policies yet. Let’s fix that!"
          tx="compare.emptylist.desc"
          preset="h2"
        />
      </Row>
      <Row className={classes.suggestion}>
        <Text text="Click on " tx="compare.emptyList.clickOn" preset="h2" />
        <Icon
          className={classes.icon}
          name={IconName.ADD}
          fill={theme.colors.blue}
          width={36}
          height={36}
        />
        <Text text="above." tx="compare.emptyList.above" preset="h2" />
      </Row>
      <Icon className={classes.emptyGroup} name={IconName.EMPTY_LIST} />
    </Column>
  )
}
