import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Row, Content, Column, Text, ContentSpinner } from '../../components'
import { CoreInfoCreators, State } from '../../store'
import { useStyle } from './consditions-privacy.styles'

export const ConditionsPrivacy = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const { conditionsOfUse } = useSelector((state: State) => ({
    conditionsOfUse: state.coreInfo.conditionsOfUse
  }))
  const [conditions, changeConditions] = useState('')

  useEffect(() => {
    if (!conditions) {
      dispatch(CoreInfoCreators.getCoreInfoRequest())
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      const page = document.querySelector('#root')

      if (page) {
        page.scrollTo(0, 0)
      }
    }
  }, [ref.current])

  useEffect(() => {
    if (conditionsOfUse) {
      changeConditions(conditionsOfUse)
    }

    return () => {
      changeConditions('')
    }
  }, [conditionsOfUse])

  if (!conditions) {
    return <ContentSpinner />
  }

  return (
    <Column className={classes.container} justifyContent="flex-start" fullWidth>
      <Content className={classes.content}>
        <Row
          ref={ref}
          className={classes.row}
          fullWidth
          alignItems="flex-start"
        >
          <Text
            contentClassName={classes.htmlText}
            text={conditions}
            innerHtml
          />
          <Row className={classes.gradient} fullWidth />
        </Row>
      </Content>
    </Column>
  )
}
