import React, { forwardRef } from 'react'

import { ColumnProps } from './column.types'
import { useStyle } from './column.styles'

export const Column = forwardRef<HTMLDivElement, ColumnProps>(
  (
    {
      alignItems,
      children,
      className,
      justifyContent,
      fullHeight,
      fullFilledHeight,
      fullWidth,
      ...props
    },
    ref
  ) => {
    const classes = useStyle({
      alignItems,
      justifyContent,
      fullHeight,
      fullWidth
    })

    const fullFilledHeightClass = fullFilledHeight && classes.fullFilledHeight

    return (
      <div
        className={`${className || ''} ${
          classes.column
        } ${fullFilledHeightClass}`}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  }
)
