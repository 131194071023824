import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'absolute',
    background: theme.colors.white,
    bottom: 0,
    zIndex: 3
  },
  details: {
    marginTop: 8
  },
  actions: {
    marginTop: 16,
  },
  btns: {
    '& > *:not(:first-child)': {
      marginLeft: 16
    }
  }
}))
