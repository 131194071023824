import React, { FC } from 'react'

import { Column } from '../../column'
import { Spinner } from '../../spinner'
import { Text } from '../../text'
import { getIsCopyProcess } from './helpers'
import { ModalSpinnerProps } from './modal-spinner.types'
import { useStyle } from './modal-spinner.styles'

export const ModalSpinner: FC<ModalSpinnerProps> = ({
  settings = {},
}) => {
  const classes = useStyle()
  const isCopy = getIsCopyProcess(settings)

  return isCopy
     ? (
      <Column fullHeight>
        <Text text="Working on it…" preset="h2" />
        <Spinner className={classes.loader} />
      </Column>
    ) : (
      <Spinner />
    )
}
