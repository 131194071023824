import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import { Row } from '../../row'
import { Policy, Restrictions } from '../../../services'
import { State } from '../../../store'
import { DROPDOWNS } from '../../affordability-tool/settings/constants'
import {
  Content,
  Column,
  Input,
  Dropdown,
  Text,
  RadioButtonGroup,
  DropdownItemData,
  CustomRadioButtonGroup
} from '../..'
import {
  getDropdownData,
  getSetting,
  getError,
  getIsValid,
  getCustomSettings,
  changeCurrentValues,
  getCurrentDropdown,
  getUpcomingSetting,
  getSettingValue,
  getBooleanValue,
  setError,
  handleOnCheckAllErrors,
  getSliderId,
  handleOnCheckDropdownErrors,
  handleOnCheckCustomRadioErrors,
  getLabel,
  formatValue
} from '../helpers'
import {
  dollarIconProps,
  kwhIconProps,
  kwhLabelProps,
  milesIconProps,
  milesOptionLabelProps,
  percentIconProps,
  qualifiedVehiclesLabelProps,
  yearsIconProps,
  percentLabelProps,
  oemIncentiveProps,
  incentiveGrayLabelProps,
  requirementLabelProps,
  dollarsProps,
  madeInAmericaIncProps,
  yearIconProps
} from '../label-props'
import {
  ASSEMBLED_IN_US_INCENTIVE_RADIO_IDS,
  customStyles,
  CUSTOM_RADIO_IDS,
  CUSTOM_RADIO_IDS_DEPENDENCIES,
  DECLINE_RATE_DROPDOWN_IDS,
  FPL_BONUS_NEW_BOOLEAN_RADIO_IDS,
  INCLUDE_FPL_BONUS_IN_CAP_RADIO_IDS,
  MANUFACTURERS_INCENTIVE_RADIO_IDS,
  NUMBER_VALUES_IDS,
  RADIO_ID_DEPENDENCIES,
  SPLIT_PROGRAM_RADIO_IDS,
  TIERED_INCENTIVE_RADIO_IDS,
  TIERED_INCENTIVE_TYPE_RADIO_IDS,
  USED_EV_TYPE_RADIO_IDS,
  USE_PRICE_ELASTICITY_RADIO_IDS
} from '../constants'
import { getEvIncValuesFlags } from './helpers'
import {
  EvIncentiveIds,
  EvIncentiveValues,
  InputsPolicyProps,
  InputTypeValue
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const EVIncentives: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions, catalog, bauDefaults } = useSelector(
    (state: State) => ({
      restrictions: state.policy.restrictions as Restrictions,
      catalog: state.policy.catalog,
      bauDefaults: state.policy.bauDefaults
    })
  )

  const dropdowns: any = useMemo(
    () => ({
      scenario: getDropdownData('scenario', catalog),
      fplRate: DROPDOWNS.oneHundredStepValues,
      secondFplUsed: DROPDOWNS.oneHundredStepValues
    }),
    [catalog]
  )

  // start of manipulating with default settings
  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: EvIncentiveValues) => getUpcomingSetting(id, settingsList),
    [settingsList]
  )
  // eof manipulating with default settings

  // start of manipulating with current settings
  const getEvIncentive = useCallback(
    (): Partial<Policy> => ({
      newEvStartingAmt: handleGetUpcomingSetting(
        EvIncentiveIds.INCENTIVE_VALUE
      ),
      newEvYears: handleGetUpcomingSetting(
        EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT
      ),
      newEvStartYear: handleGetUpcomingSetting(
        EvIncentiveIds.NEW_EV_START_YEAR
      ),
      newEvEndYears: handleGetUpcomingSetting(EvIncentiveIds.END_YEAR),
      scenario: handleGetUpcomingSetting(EvIncentiveIds.DECLINE_RATE),
      maxMarketShareValue: handleGetUpcomingSetting(EvIncentiveIds.MAX_SHARE),
      tieredIncentive: handleGetUpcomingSetting(
        EvIncentiveIds.TIERED_INCENTIVE
      ),
      tieredIncentiveType: handleGetUpcomingSetting(
        EvIncentiveIds.TIERED_INCENTIVE_TYPE
      ),
      minTierRange: handleGetUpcomingSetting(
        EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY
      ),
      minTierInc: handleGetUpcomingSetting(EvIncentiveIds.MINIMUM_INCENTIVE),
      stepTierRange: handleGetUpcomingSetting(EvIncentiveIds.RANGE_INCREMENT),
      stepTierInc: handleGetUpcomingSetting(EvIncentiveIds.INCENTIVE_INCREMENT),
      maxTierInc: handleGetUpcomingSetting(EvIncentiveIds.MAXIMUM_INCENTIVE),

      minTierKWh: handleGetUpcomingSetting(EvIncentiveIds.MIN_TIER_KWH),
      minTierIncKWh: handleGetUpcomingSetting(EvIncentiveIds.MIN_TIER_INC_KWH),
      stepTierKWh: handleGetUpcomingSetting(EvIncentiveIds.STEP_TIER_KWH),
      stepTierIncKWh: handleGetUpcomingSetting(
        EvIncentiveIds.STEP_TIER_INC_KWH
      ),

      tieredP1Years: handleGetUpcomingSetting(EvIncentiveIds.TIERED_P1_YEARS),
      p2MinTierKWh: handleGetUpcomingSetting(EvIncentiveIds.P2_MIN_TIER_KWH),
      p2MinTierIncKWh: handleGetUpcomingSetting(
        EvIncentiveIds.P2_MIN_TIER_INC_KWH
      ),
      p2StepTierKWh: handleGetUpcomingSetting(EvIncentiveIds.P2_STEP_TIER_KWH),
      p2StepTierIncKWh: handleGetUpcomingSetting(
        EvIncentiveIds.P2_STEP_TIER_INC_KWH
      ),
      p2MaxTierInc: handleGetUpcomingSetting(EvIncentiveIds.P2_MAX_TIER_INC),
      tieredP2Years: handleGetUpcomingSetting(EvIncentiveIds.TIERED_P2_YEARS),
      p3MinTierKWh: handleGetUpcomingSetting(EvIncentiveIds.P3_MIN_TIER_KWH),
      p3MinTierIncKWh: handleGetUpcomingSetting(
        EvIncentiveIds.P3_MIN_TIER_INC_KWH
      ),
      p3StepTierKWh: handleGetUpcomingSetting(EvIncentiveIds.P3_STEP_TIER_KWH),
      p3StepTierIncKWh: handleGetUpcomingSetting(
        EvIncentiveIds.P3_STEP_TIER_INC_KWH
      ),
      p3MaxTierInc: handleGetUpcomingSetting(EvIncentiveIds.P3_MAX_TIER_INC),

      fplBonusNewBoolean: handleGetUpcomingSetting(
        EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN
      ),
      fplBonusNewInc: handleGetUpcomingSetting(
        EvIncentiveIds.FPL_BONUS_NEW_INC
      ),
      fplBonusEndYears: handleGetUpcomingSetting(
        EvIncentiveIds.FPL_BONUS_END_YEARS
      ),

      includeFplCap: handleGetUpcomingSetting(
        EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP
      ),
      fplIncentiveCap: handleGetUpcomingSetting(
        EvIncentiveIds.FPL_INCENTIVE_CAP
      ),
      usedEvType: handleGetUpcomingSetting(EvIncentiveIds.USED_EV_TYPE),
      usedEvDollar: handleGetUpcomingSetting(EvIncentiveIds.USED_EV_DOLLAR),

      usedEvPerc: handleGetUpcomingSetting(
        EvIncentiveIds.INCENTIVE_VALUE_NEW_EV
      ),
      fplRate: handleGetUpcomingSetting(EvIncentiveIds.FEDERAL_POVERTY),
      usedEvEndYears: handleGetUpcomingSetting(
        EvIncentiveIds.USED_MAX_DURATION
      ),
      lmBoolean: handleGetUpcomingSetting(
        EvIncentiveIds.MANUFACTURERS_INCENTIVE
      ),
      lmBonusType: handleGetUpcomingSetting(
        EvIncentiveIds.BONUS_PERCENT_OR_DOLLAR
      ),
      lmIncDollar: handleGetUpcomingSetting(
        EvIncentiveIds.BONUS_INCENTIVE_DOLLAR
      ),
      lmIncPerc: handleGetUpcomingSetting(EvIncentiveIds.BONUS_INCENTIVE),
      secondLmIncDollar: handleGetUpcomingSetting(
        EvIncentiveIds.SECOND_LM_INC_DOLLAR
      ),
      secondLmIncYear: handleGetUpcomingSetting(
        EvIncentiveIds.SECOND_LM_INC_YEAR
      ),

      y1Lm: handleGetUpcomingSetting(EvIncentiveIds.FIRST_YEAR),
      y2Lm: handleGetUpcomingSetting(EvIncentiveIds.SECOND_YEAR),
      y3Lm: handleGetUpcomingSetting(EvIncentiveIds.THIRD_YEAR),
      y4Lm: handleGetUpcomingSetting(EvIncentiveIds.FOURTH_YEAR),
      y5Lm: handleGetUpcomingSetting(EvIncentiveIds.FIFTH_YEAR),
      y6Lm: handleGetUpcomingSetting(EvIncentiveIds.SIXTH_YEAR),
      programUsedBoolean: handleGetUpcomingSetting(
        EvIncentiveIds.SPLIT_PROGRAM
      ),
      fplBonusNewExpansionBoolean: handleGetUpcomingSetting(
        EvIncentiveIds.FPL_BONUS_EXPANSION
      ),
      assUsType: handleGetUpcomingSetting(EvIncentiveIds.ASS_US_TYPE),
      assUsReqYear: handleGetUpcomingSetting(EvIncentiveIds.ASS_US_REQ_YEAR),
      assUsBoolean: handleGetUpcomingSetting(
        EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE
      ),
      assUsInc: handleGetUpcomingSetting(EvIncentiveIds.ASSEMBLED_US_INCENTIVE),
      y1AssUs: handleGetUpcomingSetting(EvIncentiveIds.US_FIRST_YEAR),
      y2AssUs: handleGetUpcomingSetting(EvIncentiveIds.US_SECOND_YEAR),
      y3AssUs: handleGetUpcomingSetting(EvIncentiveIds.US_THIRD_YEAR),
      y4AssUs: handleGetUpcomingSetting(EvIncentiveIds.US_FOURTH_YEAR),
      y5AssUs: handleGetUpcomingSetting(EvIncentiveIds.US_FIFTH_YEAR),
      y6AssUs: handleGetUpcomingSetting(EvIncentiveIds.US_SIXTH_YEAR),

      assUsEndYears: handleGetUpcomingSetting(
        EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION
      ),
      cutoffModelYear: handleGetUpcomingSetting(
        EvIncentiveIds.LEADING_MANUFACTURERS
      ),
      programUsedEvIncentive: handleGetUpcomingSetting(
        EvIncentiveIds.ELIGIBLE_USED_EVS
      ),
      usedProgramExpansionYear: handleGetUpcomingSetting(
        EvIncentiveIds.ELIGIBILITY_EXPANDS
      ),
      secondFplUsed: handleGetUpcomingSetting(
        EvIncentiveIds.EXPANDED_ELIGIBILITY
      ),
      oemStartingAmt: handleGetUpcomingSetting(
        EvIncentiveIds.INCENTIVE_VALUE_OEM
      ),
      oemYears: handleGetUpcomingSetting(EvIncentiveIds.DURATION_OEM),
      dealerInc: handleGetUpcomingSetting(
        EvIncentiveIds.INCENTIVE_VALUE_DEALER
      ),
      dealerYears: handleGetUpcomingSetting(EvIncentiveIds.DURATION_DEALER),
      miAInc: handleGetUpcomingSetting(EvIncentiveIds.INCENTIVE_VALUE_AMERICA),
      miAProp: handleGetUpcomingSetting(EvIncentiveIds.VEHICLES_ELIGIBLE),
      domesticContentEndYears: handleGetUpcomingSetting(
        EvIncentiveIds.DOMESTIC_MAX_DURATION
      ),
      lmEndYears: handleGetUpcomingSetting(
        EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION
      ),
      usePriceElasticity: handleGetUpcomingSetting(
        EvIncentiveIds.USE_PRICE_ELASTICITY
      ),
      declineWtp: handleGetUpcomingSetting(EvIncentiveIds.DECLINE_WTP),
      avgUsedEvCost: handleGetUpcomingSetting(EvIncentiveIds.AVG_USED_EV_COST),
      fplBonusStartYear: handleGetUpcomingSetting(
        EvIncentiveIds.FPL_BONUS_START_YEAR
      ),
      usedEvStartYear: handleGetUpcomingSetting(
        EvIncentiveIds.USED_EV_START_YEAR
      ),
      minUsedEvAge: handleGetUpcomingSetting(EvIncentiveIds.MIN_USED_EV_AGE),
      lmStartYear: handleGetUpcomingSetting(
        EvIncentiveIds.LEADING_PROGRAM_START_YEAR
      ),
      assUsStartYear: handleGetUpcomingSetting(
        EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR
      ),
      oemStartYear: handleGetUpcomingSetting(EvIncentiveIds.OEM_START_YEAR),
      oemEndYears: handleGetUpcomingSetting(EvIncentiveIds.OEM_END_YEAR),
      domesticContentStartYear: handleGetUpcomingSetting(
        EvIncentiveIds.DOMESTIC_START_YEAR
      )
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getEvIncentive()
  const [evIncentive, changeEVIncentive] = useState(fields)

  // when we press 'save' we get updated data from main 'update-policy' component
  // so we need to update our defaults for 'undo' fields state
  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeEVIncentive(getEvIncentive())
    changeSettings(s)
  }, [defaults, updates])

  // METHODS
  const getIsExist = (id: EvIncentiveValues) => evIncentive[id] !== undefined
  const getIsDefault = (id: EvIncentiveValues) =>
    evIncentive[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (id: EvIncentiveValues, flag: InputTypeValue) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeEVIncentive({
      ...evIncentive,
      [id]: value
    })
    changeSettings(updSettings)
    onChange({ [id]: value })
  }

  const handleOnBlur = (id: EvIncentiveValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnRadioChange = (id: string) => (status: boolean) => {
    changeEVIncentive({
      ...evIncentive,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleOnDropdownChange = (id: EvIncentiveValues) => (
    value: DropdownItemData
  ) => {
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeEVIncentive({
      ...evIncentive,
      [id]: Number(value.id)
    })
    changeSettings(updSettings)
    onChange({ [id]: Number(value.id) })
  }

  const handleUndoClick = (id: EvIncentiveValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value

      changeEVIncentive({
        ...evIncentive,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnGetCurrentDropdown = useCallback(
    (value: number, id: string) =>
      getCurrentDropdown({
        dropdowns,
        value,
        id
      }),
    [dropdowns]
  )

  const handleOnGetError = useCallback(
    (id: EvIncentiveValues) => getError(id, error),
    [error]
  )
  const getEmptyDropdownStyle = (id: EvIncentiveIds) =>
    getIsExist(id) ? undefined : customStyles

  const getCorrectValueById = ({ settingId = '', value = false }) => {
    const hasSetting = String(value) && settingId

    if (!hasSetting) {
      return {}
    }

    const settingValue =
      hasSetting && NUMBER_VALUES_IDS.includes(settingId)
        ? getSettingValue(value)
        : value

    if (settingId && settings) {
      changeSettings({
        ...settings,
        [settingId]: settingValue
      })
    }
    return settingValue
  }

  const getSwitcherValue = ({ settingId = '', value = false }) => {
    const settingValue = getCorrectValueById({ settingId, value })
    const settingUpdate = settingId && { [settingId]: settingValue }
    return settingUpdate
  }

  const handleOnCustomRadioChange = (id: EvIncentiveValues) => (
    value: boolean
  ) => {
    const settingUpdate = getSwitcherValue({
      settingId: id,
      value
    }) as Partial<Policy>
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeEVIncentive({
      ...evIncentive,
      ...settingUpdate
    })
    changeSettings(updSettings)
    onChange(settingUpdate)
  }
  // eof METHODS

  // TODO: move variables to a separate handler
  const {
    isKWh,
    isMiles,
    isMaxShareAvailable,
    isModelSplitVisible,
    isFplBonusNewExpansionVisible,
    isBonusDollar,
    isTieredInc,
    arrayLength,
    isTieredWithShift,
    isTieredWithShiftAlt,
    isLmDollar,
    isLmDollarAlt,
    isFPLRate
  }: any = getEvIncValuesFlags({
    dropdowns,
    evIncentive,
    handleOnGetCurrentDropdown
  })

  const combinedClass = isMaxShareAvailable ? classes.combinedField : ''
  dropdowns.declineRate = isTieredInc
    ? dropdowns.scenario
    : dropdowns.scenario.slice(0, arrayLength - 4)

  // eof TODO: move variables to a separate handler

  dropdowns.federalPRate = isFPLRate
    ? dropdowns.fplRate.filter(
        (item: any) =>
          // @ts-ignore
          evIncentive.secondFplUsed >= item.id
      )
    : dropdowns.fplRate

  dropdowns.secondFederalPUsed = dropdowns.fplRate.filter(
    (item: any) =>
      evIncentive &&
      // @ts-ignore
      item.id > evIncentive.fplRate
  )

  const getIsUpdateInBauValues = useCallback(
    (key: keyof Policy) => {
      const isUndefined = typeof updates[key] === 'undefined'
      const isEqual = updates[key] === bauDefaults[key]

      return !isUndefined && isEqual
    },
    [bauDefaults, updates]
  )

  const getCustomRadioStatus = useCallback(
    (keys: string[], id: keyof Policy) => {
      const dependencyKeys = RADIO_ID_DEPENDENCIES[id]
      const isFirstOption =
        evIncentive[id] === 1 && keys.includes(dependencyKeys[1])
      const isSecondOption =
        evIncentive[id] === 2 && keys.includes(dependencyKeys[0])

      return isFirstOption || isSecondOption
    },
    [evIncentive]
  )

  const getCustomMultipleRadioStatus = useCallback(
    (keys: string[], id: keyof Policy) => {
      const dependencyKeys = CUSTOM_RADIO_IDS_DEPENDENCIES[id]
      const isFirstOption =
        evIncentive[id] === 1 &&
        dependencyKeys[1].find((item: string) => keys.includes(item))
      const isSecondOption =
        evIncentive[id] === 2 &&
        dependencyKeys[0].find((item: string) => keys.includes(item))

      return isFirstOption || isSecondOption
    },
    [evIncentive]
  )

  const getCustomAttentionIconState = useCallback(
    (id: keyof Policy): object => {
      const bauKeys = Object.keys(bauDefaults)
      const newKeys = Object.keys(updates)
      const dependencyKeys = RADIO_ID_DEPENDENCIES[id]
      const isBauSetted = dependencyKeys.find(getIsUpdateInBauValues)
      const handler = CUSTOM_RADIO_IDS.includes(id)
        ? getCustomMultipleRadioStatus
        : getCustomRadioStatus
      let radioStatus = handler(bauKeys, id)

      if (isBauSetted) {
        return {
          statusIcon: radioStatus,
          attentionText:
            'Underlying inputs were changed to BAU.\nOpen the section to review \nand/or discard changes.',
          attentionTx: 'policy.radio.warning'
        }
      }

      radioStatus = handler(newKeys, id)

      return {
        statusIcon: radioStatus,
        attentionText:
          'Underlying inputs were changed,\nthis may affect policy output.\nOpen the section to review \nand/or discard changes.',
        attentionTx: 'policy.radio.warning2'
      }
    },
    [
      bauDefaults,
      getCustomRadioStatus,
      getCustomMultipleRadioStatus,
      getIsUpdateInBauValues,
      updates
    ]
  )

  const getAttentionIconState = useCallback(
    (id: keyof Policy): object => {
      const isRadioOff = !evIncentive[id]
      const bauKeys = Object.keys(bauDefaults)
      const newKeys = Object.keys(updates)
      const dependencyKeys = RADIO_ID_DEPENDENCIES[id]
      const isBauSetted = dependencyKeys.find(getIsUpdateInBauValues)
      const isBauValuesFromDependency = dependencyKeys.find((depId: string) =>
        bauKeys.includes(depId)
      )
      let isIconVisible = Boolean(
        isRadioOff && isBauSetted && isBauValuesFromDependency
      )

      if (isBauSetted) {
        return {
          statusIcon: isIconVisible,
          attentionText:
            'Underlying inputs were changed to BAU.\nOpen the section to review \nand/or discard changes.',
          attentionTx: 'policy.radio.warning'
        }
      }

      const isNewValuesFromDependency = dependencyKeys.find((depId: string) =>
        newKeys.includes(depId)
      )
      isIconVisible = Boolean(isRadioOff && isNewValuesFromDependency)

      return {
        statusIcon: isIconVisible,
        attentionText:
          'Underlying inputs were changed,\nthis may affect policy output.\nOpen the section to review \nand/or discard changes.',
        attentionTx: 'policy.radio.warning2'
      }
    },
    [bauDefaults, evIncentive, updates, getIsUpdateInBauValues]
  )

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            text="New EV Incentive"
            preset="h3"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.INCENTIVE_VALUE}
            labelProps={getLabel(EvIncentiveIds.INCENTIVE_VALUE, restrictions)}
            defaultValue={evIncentive.newEvStartingAmt}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(EvIncentiveIds.INCENTIVE_VALUE)}
            description={
              restrictions[EvIncentiveIds.INCENTIVE_VALUE]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.INCENTIVE_VALUE)}
            isUndo={getIsDefault(EvIncentiveIds.INCENTIVE_VALUE)}
            errorProps={handleOnGetError(EvIncentiveIds.INCENTIVE_VALUE)}
            onBlur={handleOnBlur(EvIncentiveIds.INCENTIVE_VALUE)}
            onChange={handleOnChange(
              EvIncentiveIds.INCENTIVE_VALUE,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.INCENTIVE_VALUE)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT}
            labelProps={getLabel(
              EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT,
              restrictions
            )}
            isYears
            defaultValue={evIncentive.newEvYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT)}
            description={
              restrictions[EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT]
                ?.description
            }
            disabled={!getIsExist(EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT)}
            isUndo={getIsDefault(EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT)}
            errorProps={handleOnGetError(
              EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT
            )}
            onBlur={handleOnBlur(EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT)}
            onChange={handleOnChange(
              EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(
              EvIncentiveIds.DURATION_TO_REMAIN_CONSTANT
            )}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.NEW_EV_START_YEAR}
            labelProps={getLabel(
              EvIncentiveIds.NEW_EV_START_YEAR,
              restrictions
            )}
            isYear
            defaultValue={evIncentive.newEvStartYear}
            iconProps={yearIconProps}
            isFocused={getIsExist(EvIncentiveIds.NEW_EV_START_YEAR)}
            description={
              restrictions[EvIncentiveIds.NEW_EV_START_YEAR]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.NEW_EV_START_YEAR)}
            isUndo={getIsDefault(EvIncentiveIds.NEW_EV_START_YEAR)}
            errorProps={handleOnGetError(EvIncentiveIds.NEW_EV_START_YEAR)}
            onBlur={handleOnBlur(EvIncentiveIds.NEW_EV_START_YEAR)}
            onChange={handleOnChange(
              EvIncentiveIds.NEW_EV_START_YEAR,
              InputTypeValue.YEAR
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.NEW_EV_START_YEAR)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.END_YEAR}
            labelProps={getLabel(EvIncentiveIds.END_YEAR, restrictions)}
            isYears
            defaultValue={evIncentive.newEvEndYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(EvIncentiveIds.END_YEAR)}
            description={restrictions[EvIncentiveIds.END_YEAR]?.description}
            disabled={!getIsExist(EvIncentiveIds.END_YEAR)}
            isUndo={getIsDefault(EvIncentiveIds.END_YEAR)}
            errorProps={handleOnGetError(EvIncentiveIds.END_YEAR)}
            onBlur={handleOnBlur(EvIncentiveIds.END_YEAR)}
            onChange={handleOnChange(
              EvIncentiveIds.END_YEAR,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.END_YEAR)}
          />
          <Row
            className={`${classes.cell} ${combinedClass}`}
            alignContent="flex-start"
          >
            <Dropdown
              className={classes.dropdown}
              custom={getEmptyDropdownStyle(EvIncentiveIds.DECLINE_RATE)}
              label={getLabel(EvIncentiveIds.DECLINE_RATE, restrictions).text}
              preset="body"
              data={dropdowns.declineRate}
              defaultValue={handleOnGetCurrentDropdown(
                Number(evIncentive.scenario),
                EvIncentiveIds.DECLINE_RATE
              )}
              description={
                restrictions[EvIncentiveIds.DECLINE_RATE]?.description
              }
              disabled={!evIncentive.scenario}
              errorProps={handleOnCheckDropdownErrors({
                ids: DECLINE_RATE_DROPDOWN_IDS,
                error
              })}
              isUndo={getIsDefault(EvIncentiveIds.DECLINE_RATE)}
              onChange={handleOnDropdownChange(EvIncentiveIds.DECLINE_RATE)}
              onUndoClick={handleUndoClick(EvIncentiveIds.DECLINE_RATE)}
            />
            {isMaxShareAvailable && (
              <Input
                alignItems="flex-start"
                id={EvIncentiveIds.MAX_SHARE}
                labelProps={getLabel(EvIncentiveIds.MAX_SHARE, restrictions)}
                isPercent
                defaultValue={evIncentive.maxMarketShareValue}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.MAX_SHARE)}
                description={
                  restrictions[EvIncentiveIds.MAX_SHARE]?.description
                }
                disabled={!isMaxShareAvailable}
                isUndo={getIsDefault(EvIncentiveIds.MAX_SHARE)}
                errorProps={handleOnGetError(EvIncentiveIds.MAX_SHARE)}
                onBlur={handleOnBlur(EvIncentiveIds.MAX_SHARE)}
                onChange={handleOnChange(
                  EvIncentiveIds.MAX_SHARE,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.MAX_SHARE)}
              />
            )}
          </Row>
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(EvIncentiveIds.TIERED_INCENTIVE, restrictions)}
              value={evIncentive.tieredIncentive}
              description={
                restrictions[EvIncentiveIds.TIERED_INCENTIVE]?.description
              }
              disabled={!getIsExist(EvIncentiveIds.TIERED_INCENTIVE)}
              isUndo={getIsDefault(EvIncentiveIds.TIERED_INCENTIVE)}
              errorProps={handleOnCheckAllErrors({
                values: evIncentive,
                radioId: EvIncentiveIds.TIERED_INCENTIVE,
                ids: TIERED_INCENTIVE_RADIO_IDS,
                error
              })}
              {...getAttentionIconState(EvIncentiveIds.TIERED_INCENTIVE)}
              onChange={handleOnRadioChange(EvIncentiveIds.TIERED_INCENTIVE)}
              onUndoClick={handleUndoClick(EvIncentiveIds.TIERED_INCENTIVE)}
            />
          </Column>
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {evIncentive.tieredIncentive && (
            <Column
              className={`${classes.cell} ${classes.selectionControlsContainer}`}
              alignItems="flex-start"
            >
              <CustomRadioButtonGroup
                className={classes.customRadio}
                labelProps={getLabel(
                  EvIncentiveIds.TIERED_INCENTIVE_TYPE,
                  restrictions
                )}
                firstLabelProps={milesOptionLabelProps}
                secondLabelProps={kwhLabelProps}
                value={getBooleanValue(evIncentive.tieredIncentiveType)}
                description={
                  restrictions[EvIncentiveIds.TIERED_INCENTIVE_TYPE]
                    ?.description
                }
                isUndo={getIsDefault(EvIncentiveIds.TIERED_INCENTIVE_TYPE)}
                errorProps={handleOnCheckCustomRadioErrors({
                  values: evIncentive,
                  radioId: EvIncentiveIds.TIERED_INCENTIVE_TYPE,
                  ids: TIERED_INCENTIVE_TYPE_RADIO_IDS,
                  error
                })}
                {...getCustomAttentionIconState(
                  EvIncentiveIds.TIERED_INCENTIVE_TYPE
                )}
                onChange={handleOnCustomRadioChange(
                  EvIncentiveIds.TIERED_INCENTIVE_TYPE
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.TIERED_INCENTIVE_TYPE
                )}
              />
            </Column>
          )}
          {isMiles && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY}
                labelProps={getLabel(
                  EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY,
                  restrictions
                )}
                defaultValue={evIncentive.minTierRange}
                iconProps={milesIconProps}
                isFocused={getIsExist(EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY)}
                disabled={!evIncentive.tieredIncentive}
                description={
                  restrictions[EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY]
                    ?.description
                }
                isUndo={getIsDefault(EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY)}
                errorProps={handleOnGetError(
                  EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY
                )}
                onBlur={handleOnBlur(EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY)}
                onChange={handleOnChange(
                  EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.MINIMUM_INCENTIVE}
                labelProps={getLabel(
                  EvIncentiveIds.MINIMUM_INCENTIVE,
                  restrictions
                )}
                defaultValue={evIncentive.minTierInc}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(EvIncentiveIds.MINIMUM_INCENTIVE)}
                description={
                  restrictions[EvIncentiveIds.MINIMUM_INCENTIVE]?.description
                }
                disabled={!evIncentive.tieredIncentive}
                isUndo={getIsDefault(EvIncentiveIds.MINIMUM_INCENTIVE)}
                errorProps={handleOnGetError(EvIncentiveIds.MINIMUM_INCENTIVE)}
                onBlur={handleOnBlur(EvIncentiveIds.MINIMUM_INCENTIVE)}
                onChange={handleOnChange(
                  EvIncentiveIds.MINIMUM_INCENTIVE,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.MINIMUM_INCENTIVE)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.RANGE_INCREMENT}
                labelProps={getLabel(
                  EvIncentiveIds.RANGE_INCREMENT,
                  restrictions
                )}
                defaultValue={evIncentive.stepTierRange}
                iconProps={milesIconProps}
                isFocused={getIsExist(EvIncentiveIds.RANGE_INCREMENT)}
                description={
                  restrictions[EvIncentiveIds.RANGE_INCREMENT]?.description
                }
                disabled={!evIncentive.tieredIncentive}
                isUndo={getIsDefault(EvIncentiveIds.RANGE_INCREMENT)}
                errorProps={handleOnGetError(EvIncentiveIds.RANGE_INCREMENT)}
                onBlur={handleOnBlur(EvIncentiveIds.RANGE_INCREMENT)}
                onChange={handleOnChange(
                  EvIncentiveIds.RANGE_INCREMENT,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.RANGE_INCREMENT)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.INCENTIVE_INCREMENT}
                labelProps={getLabel(
                  EvIncentiveIds.INCENTIVE_INCREMENT,
                  restrictions
                )}
                defaultValue={evIncentive.stepTierInc}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(EvIncentiveIds.INCENTIVE_INCREMENT)}
                description={
                  restrictions[EvIncentiveIds.INCENTIVE_INCREMENT]?.description
                }
                disabled={!evIncentive.tieredIncentive}
                isUndo={getIsDefault(EvIncentiveIds.INCENTIVE_INCREMENT)}
                errorProps={handleOnGetError(
                  EvIncentiveIds.INCENTIVE_INCREMENT
                )}
                onBlur={handleOnBlur(EvIncentiveIds.INCENTIVE_INCREMENT)}
                onChange={handleOnChange(
                  EvIncentiveIds.INCENTIVE_INCREMENT,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.INCENTIVE_INCREMENT
                )}
              />
            </>
          )}
          {isKWh && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.MIN_TIER_KWH}
                labelProps={getLabel(EvIncentiveIds.MIN_TIER_KWH, restrictions)}
                defaultValue={evIncentive.minTierKWh}
                iconProps={kwhIconProps}
                isFocused={getIsExist(EvIncentiveIds.MIN_TIER_KWH)}
                description={
                  restrictions[EvIncentiveIds.MIN_TIER_KWH]?.description
                }
                disabled={!isKWh}
                isUndo={getIsDefault(EvIncentiveIds.MIN_TIER_KWH)}
                errorProps={handleOnGetError(EvIncentiveIds.MIN_TIER_KWH)}
                onBlur={handleOnBlur(EvIncentiveIds.MIN_TIER_KWH)}
                onChange={handleOnChange(
                  EvIncentiveIds.MIN_TIER_KWH,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.MIN_TIER_KWH)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.MIN_TIER_INC_KWH}
                labelProps={getLabel(
                  EvIncentiveIds.MIN_TIER_INC_KWH,
                  restrictions
                )}
                defaultValue={evIncentive.minTierIncKWh}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(EvIncentiveIds.MIN_TIER_INC_KWH)}
                description={
                  restrictions[EvIncentiveIds.MIN_TIER_INC_KWH]?.description
                }
                disabled={!isKWh}
                isUndo={getIsDefault(EvIncentiveIds.MIN_TIER_INC_KWH)}
                errorProps={handleOnGetError(EvIncentiveIds.MIN_TIER_INC_KWH)}
                onBlur={handleOnBlur(EvIncentiveIds.MIN_TIER_INC_KWH)}
                onChange={handleOnChange(
                  EvIncentiveIds.MIN_TIER_INC_KWH,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.MIN_TIER_INC_KWH)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.STEP_TIER_KWH}
                labelProps={getLabel(
                  EvIncentiveIds.STEP_TIER_KWH,
                  restrictions
                )}
                defaultValue={evIncentive.stepTierKWh}
                iconProps={kwhIconProps}
                isFocused={getIsExist(EvIncentiveIds.STEP_TIER_KWH)}
                description={
                  restrictions[EvIncentiveIds.STEP_TIER_KWH]?.description
                }
                disabled={!isKWh}
                isUndo={getIsDefault(EvIncentiveIds.STEP_TIER_KWH)}
                errorProps={handleOnGetError(EvIncentiveIds.STEP_TIER_KWH)}
                onBlur={handleOnBlur(EvIncentiveIds.STEP_TIER_KWH)}
                onChange={handleOnChange(
                  EvIncentiveIds.STEP_TIER_KWH,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.STEP_TIER_KWH)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.STEP_TIER_INC_KWH}
                labelProps={getLabel(
                  EvIncentiveIds.STEP_TIER_INC_KWH,
                  restrictions
                )}
                isDollar
                iconProps={dollarIconProps}
                defaultValue={evIncentive.stepTierIncKWh}
                isFocused={getIsExist(EvIncentiveIds.STEP_TIER_INC_KWH)}
                description={
                  restrictions[EvIncentiveIds.STEP_TIER_INC_KWH]?.description
                }
                disabled={!isKWh}
                isUndo={getIsDefault(EvIncentiveIds.STEP_TIER_INC_KWH)}
                errorProps={handleOnGetError(EvIncentiveIds.STEP_TIER_INC_KWH)}
                onBlur={handleOnBlur(EvIncentiveIds.STEP_TIER_INC_KWH)}
                onChange={handleOnChange(
                  EvIncentiveIds.STEP_TIER_INC_KWH,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.STEP_TIER_INC_KWH)}
              />
            </>
          )}
          {evIncentive.tieredIncentive && (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={EvIncentiveIds.MAXIMUM_INCENTIVE}
              labelProps={getLabel(
                EvIncentiveIds.MAXIMUM_INCENTIVE,
                restrictions
              )}
              defaultValue={evIncentive.maxTierInc}
              isDollar
              iconProps={dollarIconProps}
              isFocused={getIsExist(EvIncentiveIds.MAXIMUM_INCENTIVE)}
              description={
                restrictions[EvIncentiveIds.MAXIMUM_INCENTIVE]?.description
              }
              disabled={!evIncentive.tieredIncentive}
              isUndo={getIsDefault(EvIncentiveIds.MAXIMUM_INCENTIVE)}
              errorProps={handleOnGetError(EvIncentiveIds.MAXIMUM_INCENTIVE)}
              onBlur={handleOnBlur(EvIncentiveIds.MAXIMUM_INCENTIVE)}
              onChange={handleOnChange(
                EvIncentiveIds.MAXIMUM_INCENTIVE,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(EvIncentiveIds.MAXIMUM_INCENTIVE)}
            />
          )}
        </Row>
        {/* New, version 2.17 */}
        {evIncentive.tieredIncentive && (
          <>
            {isTieredWithShift && (
              <Row
                className={classes.subTitleContainer}
                alignItems="flex-start"
              >
                <Text className={classes.subTitle} text="Phase 2" preset="h3" />
              </Row>
            )}
            <Row
              className={`${classes.row} ${classes.responsiveRow}`}
              justifyContent="flex-start"
            >
              {isTieredWithShift && (
                <>
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.TIERED_P1_YEARS}
                    labelProps={getLabel(
                      EvIncentiveIds.TIERED_P1_YEARS,
                      restrictions
                    )}
                    defaultValue={evIncentive.tieredP1Years}
                    isFocused={getIsExist(EvIncentiveIds.TIERED_P1_YEARS)}
                    description={
                      restrictions[EvIncentiveIds.TIERED_P1_YEARS]?.description
                    }
                    disabled={!isTieredWithShift}
                    isUndo={getIsDefault(EvIncentiveIds.TIERED_P1_YEARS)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.TIERED_P1_YEARS
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.TIERED_P1_YEARS)}
                    onChange={handleOnChange(
                      EvIncentiveIds.TIERED_P1_YEARS,
                      InputTypeValue.YEARS
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.TIERED_P1_YEARS
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P2_MIN_TIER_KWH}
                    labelProps={getLabel(
                      EvIncentiveIds.P2_MIN_TIER_KWH,
                      restrictions
                    )}
                    defaultValue={evIncentive.p2MinTierKWh}
                    iconProps={kwhIconProps}
                    isFocused={getIsExist(EvIncentiveIds.P2_MIN_TIER_KWH)}
                    description={
                      restrictions[EvIncentiveIds.P2_MIN_TIER_KWH]?.description
                    }
                    disabled={!isTieredWithShift}
                    isUndo={getIsDefault(EvIncentiveIds.P2_MIN_TIER_KWH)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P2_MIN_TIER_KWH
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P2_MIN_TIER_KWH)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P2_MIN_TIER_KWH,
                      InputTypeValue.PERCENT
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P2_MIN_TIER_KWH
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P2_MIN_TIER_INC_KWH}
                    labelProps={getLabel(
                      EvIncentiveIds.P2_MIN_TIER_INC_KWH,
                      restrictions
                    )}
                    defaultValue={evIncentive.p2MinTierIncKWh}
                    isDollar
                    iconProps={dollarIconProps}
                    description={
                      restrictions[EvIncentiveIds.P2_MIN_TIER_INC_KWH]
                        ?.description
                    }
                    isFocused={getIsExist(EvIncentiveIds.P2_MIN_TIER_INC_KWH)}
                    disabled={!isTieredWithShift}
                    isUndo={getIsDefault(EvIncentiveIds.P2_MIN_TIER_INC_KWH)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P2_MIN_TIER_INC_KWH
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P2_MIN_TIER_INC_KWH)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P2_MIN_TIER_INC_KWH,
                      InputTypeValue.PERCENT
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P2_MIN_TIER_INC_KWH
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P2_STEP_TIER_KWH}
                    labelProps={getLabel(
                      EvIncentiveIds.P2_STEP_TIER_KWH,
                      restrictions
                    )}
                    iconProps={kwhIconProps}
                    defaultValue={evIncentive.p2StepTierKWh}
                    isFocused={getIsExist(EvIncentiveIds.P2_STEP_TIER_KWH)}
                    description={
                      restrictions[EvIncentiveIds.P2_STEP_TIER_KWH]?.description
                    }
                    disabled={!isTieredWithShift}
                    isUndo={getIsDefault(EvIncentiveIds.P2_STEP_TIER_KWH)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P2_STEP_TIER_KWH
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P2_STEP_TIER_KWH)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P2_STEP_TIER_KWH,
                      InputTypeValue.PERCENT
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P2_STEP_TIER_KWH
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P2_STEP_TIER_INC_KWH}
                    labelProps={getLabel(
                      EvIncentiveIds.P2_STEP_TIER_INC_KWH,
                      restrictions
                    )}
                    isDollar
                    iconProps={dollarIconProps}
                    defaultValue={evIncentive.p2StepTierIncKWh}
                    isFocused={getIsExist(EvIncentiveIds.P2_STEP_TIER_INC_KWH)}
                    description={
                      restrictions[EvIncentiveIds.P2_STEP_TIER_INC_KWH]
                        ?.description
                    }
                    disabled={!isTieredWithShift}
                    isUndo={getIsDefault(EvIncentiveIds.P2_STEP_TIER_INC_KWH)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P2_STEP_TIER_INC_KWH
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P2_STEP_TIER_INC_KWH)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P2_STEP_TIER_INC_KWH,
                      InputTypeValue.PERCENT
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P2_STEP_TIER_INC_KWH
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P2_MAX_TIER_INC}
                    labelProps={getLabel(
                      EvIncentiveIds.P2_MAX_TIER_INC,
                      restrictions
                    )}
                    isDollar
                    iconProps={dollarIconProps}
                    defaultValue={evIncentive.p2MaxTierInc}
                    isFocused={getIsExist(EvIncentiveIds.P2_MAX_TIER_INC)}
                    description={
                      restrictions[EvIncentiveIds.P2_MAX_TIER_INC]?.description
                    }
                    disabled={!isTieredWithShift}
                    isUndo={getIsDefault(EvIncentiveIds.P2_MAX_TIER_INC)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P2_MAX_TIER_INC
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P2_MAX_TIER_INC)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P2_MAX_TIER_INC,
                      InputTypeValue.DOLLAR
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P2_MAX_TIER_INC
                    )}
                  />
                </>
              )}
            </Row>
          </>
        )}
        {/* new block */}
        {evIncentive.tieredIncentive && (
          <>
            {isTieredWithShiftAlt && (
              <Row
                className={classes.subTitleContainer}
                alignItems="flex-start"
              >
                <Text className={classes.subTitle} text="Phase 3" preset="h3" />
              </Row>
            )}
            <Row
              className={`${classes.row} ${classes.responsiveRow}`}
              justifyContent="flex-start"
            >
              {isTieredWithShiftAlt && (
                <>
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.TIERED_P2_YEARS}
                    labelProps={getLabel(
                      EvIncentiveIds.TIERED_P2_YEARS,
                      restrictions
                    )}
                    defaultValue={evIncentive.tieredP2Years}
                    isFocused={getIsExist(EvIncentiveIds.TIERED_P2_YEARS)}
                    description={
                      restrictions[EvIncentiveIds.TIERED_P2_YEARS]?.description
                    }
                    disabled={!isTieredWithShiftAlt}
                    isUndo={getIsDefault(EvIncentiveIds.TIERED_P2_YEARS)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.TIERED_P2_YEARS
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.TIERED_P2_YEARS)}
                    onChange={handleOnChange(
                      EvIncentiveIds.TIERED_P2_YEARS,
                      InputTypeValue.YEARS
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.TIERED_P2_YEARS
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P3_MIN_TIER_KWH}
                    labelProps={getLabel(
                      EvIncentiveIds.P3_MIN_TIER_KWH,
                      restrictions
                    )}
                    defaultValue={evIncentive.p3MinTierKWh}
                    iconProps={kwhIconProps}
                    isFocused={getIsExist(EvIncentiveIds.P3_MIN_TIER_KWH)}
                    description={
                      restrictions[EvIncentiveIds.P3_MIN_TIER_KWH]?.description
                    }
                    disabled={!isTieredWithShiftAlt}
                    isUndo={getIsDefault(EvIncentiveIds.P3_MIN_TIER_KWH)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P3_MIN_TIER_KWH
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P3_MIN_TIER_KWH)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P3_MIN_TIER_KWH,
                      InputTypeValue.PERCENT
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P3_MIN_TIER_KWH
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P3_MIN_TIER_INC_KWH}
                    labelProps={getLabel(
                      EvIncentiveIds.P3_MIN_TIER_INC_KWH,
                      restrictions
                    )}
                    defaultValue={evIncentive.p3MinTierIncKWh}
                    isDollar
                    iconProps={dollarIconProps}
                    isFocused={getIsExist(EvIncentiveIds.P3_MIN_TIER_INC_KWH)}
                    description={
                      restrictions[EvIncentiveIds.P3_MIN_TIER_INC_KWH]
                        ?.description
                    }
                    disabled={!isTieredWithShiftAlt}
                    isUndo={getIsDefault(EvIncentiveIds.P3_MIN_TIER_INC_KWH)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P3_MIN_TIER_INC_KWH
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P3_MIN_TIER_INC_KWH)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P3_MIN_TIER_INC_KWH,
                      InputTypeValue.PERCENT
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P3_MIN_TIER_INC_KWH
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P3_STEP_TIER_KWH}
                    labelProps={getLabel(
                      EvIncentiveIds.P3_STEP_TIER_KWH,
                      restrictions
                    )}
                    iconProps={kwhIconProps}
                    defaultValue={evIncentive.p3StepTierKWh}
                    isFocused={getIsExist(EvIncentiveIds.P3_STEP_TIER_KWH)}
                    description={
                      restrictions[EvIncentiveIds.P3_STEP_TIER_KWH]?.description
                    }
                    disabled={!isTieredWithShiftAlt}
                    isUndo={getIsDefault(EvIncentiveIds.P3_STEP_TIER_KWH)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P3_STEP_TIER_KWH
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P3_STEP_TIER_KWH)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P3_STEP_TIER_KWH,
                      InputTypeValue.PERCENT
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P3_STEP_TIER_KWH
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P3_STEP_TIER_INC_KWH}
                    labelProps={getLabel(
                      EvIncentiveIds.P3_STEP_TIER_INC_KWH,
                      restrictions
                    )}
                    isDollar
                    iconProps={dollarIconProps}
                    defaultValue={evIncentive.p3StepTierIncKWh}
                    isFocused={getIsExist(EvIncentiveIds.P3_STEP_TIER_INC_KWH)}
                    description={
                      restrictions[EvIncentiveIds.P3_STEP_TIER_INC_KWH]
                        ?.description
                    }
                    disabled={!isTieredWithShiftAlt}
                    isUndo={getIsDefault(EvIncentiveIds.P3_STEP_TIER_INC_KWH)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P3_STEP_TIER_INC_KWH
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P3_STEP_TIER_INC_KWH)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P3_STEP_TIER_INC_KWH,
                      InputTypeValue.PERCENT
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P3_STEP_TIER_INC_KWH
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.P3_MAX_TIER_INC}
                    labelProps={getLabel(
                      EvIncentiveIds.P3_MAX_TIER_INC,
                      restrictions
                    )}
                    isDollar
                    iconProps={dollarIconProps}
                    defaultValue={evIncentive.p3MaxTierInc}
                    isFocused={getIsExist(EvIncentiveIds.P3_MAX_TIER_INC)}
                    description={
                      restrictions[EvIncentiveIds.P3_MAX_TIER_INC]?.description
                    }
                    disabled={!isTieredWithShiftAlt}
                    isUndo={getIsDefault(EvIncentiveIds.P3_MAX_TIER_INC)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.P3_MAX_TIER_INC
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.P3_MAX_TIER_INC)}
                    onChange={handleOnChange(
                      EvIncentiveIds.P3_MAX_TIER_INC,
                      InputTypeValue.DOLLAR
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.P3_MAX_TIER_INC
                    )}
                  />
                </>
              )}
            </Row>
          </>
        )}
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN, restrictions)}
              value={evIncentive.fplBonusNewBoolean}
              disabled={!getIsExist(EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN)}
              isUndo={getIsDefault(EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN)}
              description={
                restrictions[EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN]?.description
              }
              errorProps={handleOnCheckAllErrors({
                values: evIncentive,
                radioId: EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN,
                ids: FPL_BONUS_NEW_BOOLEAN_RADIO_IDS,
                error
              })}
              {...getAttentionIconState(EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN)}
              onChange={handleOnRadioChange(
                EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN
              )}
              onUndoClick={handleUndoClick(
                EvIncentiveIds.FPL_BONUS_NEW_BOOLEAN
              )}
            />
          </Column>
          {evIncentive.fplBonusNewBoolean && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.FPL_BONUS_NEW_INC}
                labelProps={getLabel(
                  EvIncentiveIds.FPL_BONUS_NEW_INC,
                  restrictions
                )}
                defaultValue={evIncentive.fplBonusNewInc}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(EvIncentiveIds.FPL_BONUS_NEW_INC)}
                description={
                  restrictions[EvIncentiveIds.FPL_BONUS_NEW_INC]?.description
                }
                disabled={!evIncentive.fplBonusNewBoolean}
                isUndo={getIsDefault(EvIncentiveIds.FPL_BONUS_NEW_INC)}
                errorProps={handleOnGetError(EvIncentiveIds.FPL_BONUS_NEW_INC)}
                onBlur={handleOnBlur(EvIncentiveIds.FPL_BONUS_NEW_INC)}
                onChange={handleOnChange(
                  EvIncentiveIds.FPL_BONUS_NEW_INC,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.FPL_BONUS_NEW_INC)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.FPL_BONUS_START_YEAR}
                labelProps={getLabel(
                  EvIncentiveIds.FPL_BONUS_START_YEAR,
                  restrictions
                )}
                isYear
                defaultValue={evIncentive.fplBonusStartYear}
                iconProps={yearIconProps}
                isFocused={getIsExist(EvIncentiveIds.FPL_BONUS_START_YEAR)}
                description={
                  restrictions[EvIncentiveIds.FPL_BONUS_START_YEAR]?.description
                }
                disabled={!evIncentive.fplBonusNewBoolean}
                isUndo={getIsDefault(EvIncentiveIds.FPL_BONUS_START_YEAR)}
                errorProps={handleOnGetError(
                  EvIncentiveIds.FPL_BONUS_START_YEAR
                )}
                onBlur={handleOnBlur(EvIncentiveIds.FPL_BONUS_START_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.FPL_BONUS_START_YEAR,
                  InputTypeValue.YEAR
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.FPL_BONUS_START_YEAR
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.FPL_BONUS_END_YEARS}
                labelProps={getLabel(
                  EvIncentiveIds.FPL_BONUS_END_YEARS,
                  restrictions
                )}
                isYears
                defaultValue={evIncentive.fplBonusEndYears}
                iconProps={yearsIconProps}
                isFocused={getIsExist(EvIncentiveIds.FPL_BONUS_END_YEARS)}
                description={
                  restrictions[EvIncentiveIds.FPL_BONUS_END_YEARS]?.description
                }
                disabled={!evIncentive.fplBonusNewBoolean}
                isUndo={getIsDefault(EvIncentiveIds.FPL_BONUS_END_YEARS)}
                errorProps={handleOnGetError(
                  EvIncentiveIds.FPL_BONUS_END_YEARS
                )}
                onBlur={handleOnBlur(EvIncentiveIds.FPL_BONUS_END_YEARS)}
                onChange={handleOnChange(
                  EvIncentiveIds.FPL_BONUS_END_YEARS,
                  InputTypeValue.YEARS
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.FPL_BONUS_END_YEARS
                )}
              />
            </>
          )}
        </Row>

        {evIncentive.fplBonusNewBoolean && (
          <Row
            className={`${classes.row} ${classes.responsiveRow}`}
            justifyContent="flex-start"
          >
            <Column
              className={`${classes.cell} ${classes.selectionControlsContainer}`}
              alignItems="flex-start"
            >
              <RadioButtonGroup
                {...getLabel(
                  EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP,
                  restrictions
                )}
                value={evIncentive.includeFplCap}
                description={
                  restrictions[EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP]
                    ?.description
                }
                disabled={!getIsExist(EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP)}
                isUndo={getIsDefault(EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP)}
                errorProps={handleOnCheckAllErrors({
                  values: evIncentive,
                  radioId: EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP,
                  ids: INCLUDE_FPL_BONUS_IN_CAP_RADIO_IDS,
                  error
                })}
                {...getAttentionIconState(
                  EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP
                )}
                onChange={handleOnRadioChange(
                  EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.INCLUDE_FPL_BONUS_IN_CAP
                )}
              />
            </Column>
            {evIncentive.includeFplCap && (
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.INCENTIVE_VALUE_NEW_EV}
                labelProps={getLabel(
                  EvIncentiveIds.INCENTIVE_VALUE_NEW_EV,
                  restrictions
                )}
                defaultValue={evIncentive.fplIncentiveCap}
                isDollar
                iconProps={dollarIconProps}
                description={
                  restrictions[EvIncentiveIds.FPL_INCENTIVE_CAP]?.description
                }
                isFocused={getIsExist(EvIncentiveIds.FPL_INCENTIVE_CAP)}
                disabled={!getIsExist(EvIncentiveIds.FPL_INCENTIVE_CAP)}
                isUndo={getIsDefault(EvIncentiveIds.FPL_INCENTIVE_CAP)}
                errorProps={handleOnGetError(EvIncentiveIds.FPL_INCENTIVE_CAP)}
                onBlur={handleOnBlur(EvIncentiveIds.FPL_INCENTIVE_CAP)}
                onChange={handleOnChange(
                  EvIncentiveIds.FPL_INCENTIVE_CAP,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.FPL_INCENTIVE_CAP)}
              />
            )}
          </Row>
        )}

        {/* Used EV Incentive */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            text="Used EV Incentive"
            preset="h3"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <CustomRadioButtonGroup
              className={classes.customRadio}
              labelProps={getLabel(EvIncentiveIds.USED_EV_TYPE, restrictions)}
              firstLabelProps={percentLabelProps}
              secondLabelProps={dollarsProps}
              value={getBooleanValue(evIncentive.usedEvType)}
              description={
                restrictions[EvIncentiveIds.USED_EV_TYPE]?.description
              }
              isUndo={getIsDefault(EvIncentiveIds.USED_EV_TYPE)}
              errorProps={handleOnCheckAllErrors({
                values: evIncentive,
                radioId: EvIncentiveIds.USED_EV_TYPE,
                ids: USED_EV_TYPE_RADIO_IDS,
                error
              })}
              {...getCustomAttentionIconState(EvIncentiveIds.USED_EV_TYPE)}
              onChange={handleOnCustomRadioChange(EvIncentiveIds.USED_EV_TYPE)}
              onUndoClick={handleUndoClick(EvIncentiveIds.USED_EV_TYPE)}
            />
          </Column>
          {evIncentive.usedEvType === 1 ? (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={EvIncentiveIds.INCENTIVE_VALUE_NEW_EV}
              labelProps={getLabel(
                EvIncentiveIds.INCENTIVE_VALUE_NEW_EV,
                restrictions
              )}
              isPercent
              defaultValue={evIncentive.usedEvPerc}
              iconProps={percentIconProps}
              isFocused={getIsExist(EvIncentiveIds.INCENTIVE_VALUE_NEW_EV)}
              description={
                restrictions[EvIncentiveIds.INCENTIVE_VALUE_NEW_EV]?.description
              }
              disabled={!getIsExist(EvIncentiveIds.INCENTIVE_VALUE_NEW_EV)}
              isUndo={getIsDefault(EvIncentiveIds.INCENTIVE_VALUE_NEW_EV)}
              errorProps={handleOnGetError(
                EvIncentiveIds.INCENTIVE_VALUE_NEW_EV
              )}
              onBlur={handleOnBlur(EvIncentiveIds.INCENTIVE_VALUE_NEW_EV)}
              onChange={handleOnChange(
                EvIncentiveIds.INCENTIVE_VALUE_NEW_EV,
                InputTypeValue.PERCENT
              )}
              onUndoClick={handleUndoClick(
                EvIncentiveIds.INCENTIVE_VALUE_NEW_EV
              )}
            />
          ) : (
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={EvIncentiveIds.USED_EV_DOLLAR}
              labelProps={getLabel(EvIncentiveIds.USED_EV_DOLLAR, restrictions)}
              defaultValue={evIncentive.usedEvDollar}
              isDollar
              iconProps={dollarIconProps}
              isFocused={getIsExist(EvIncentiveIds.USED_EV_DOLLAR)}
              description={
                restrictions[EvIncentiveIds.USED_EV_DOLLAR]?.description
              }
              disabled={!getIsExist(EvIncentiveIds.USED_EV_DOLLAR)}
              isUndo={getIsDefault(EvIncentiveIds.USED_EV_DOLLAR)}
              errorProps={handleOnGetError(EvIncentiveIds.USED_EV_DOLLAR)}
              onBlur={handleOnBlur(EvIncentiveIds.USED_EV_DOLLAR)}
              onChange={handleOnChange(
                EvIncentiveIds.USED_EV_DOLLAR,
                InputTypeValue.DOLLAR
              )}
              onUndoClick={handleUndoClick(EvIncentiveIds.USED_EV_DOLLAR)}
            />
          )}
          <Row className={classes.cell}>
            <Dropdown
              className={classes.dropdown}
              custom={getEmptyDropdownStyle(EvIncentiveIds.FEDERAL_POVERTY)}
              data={dropdowns.federalPRate}
              preset="body"
              defaultValue={handleOnGetCurrentDropdown(
                evIncentive.fplRate || 100,
                'fplRate'
              )}
              description={
                restrictions[EvIncentiveIds.FEDERAL_POVERTY]?.description
              }
              iconProps={percentIconProps}
              label={
                getLabel(EvIncentiveIds.FEDERAL_POVERTY, restrictions).text
              }
              disabled={!getIsExist(EvIncentiveIds.FEDERAL_POVERTY)}
              isUndo={getIsDefault(EvIncentiveIds.FEDERAL_POVERTY)}
              onChange={handleOnDropdownChange(EvIncentiveIds.FEDERAL_POVERTY)}
              onUndoClick={handleUndoClick(EvIncentiveIds.FEDERAL_POVERTY)}
            />
          </Row>
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.USED_EV_START_YEAR}
            labelProps={getLabel(
              EvIncentiveIds.USED_EV_START_YEAR,
              restrictions
            )}
            isYear
            defaultValue={evIncentive.usedEvStartYear}
            iconProps={yearIconProps}
            isFocused={getIsExist(EvIncentiveIds.USED_EV_START_YEAR)}
            description={
              restrictions[EvIncentiveIds.USED_EV_START_YEAR]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.USED_EV_START_YEAR)}
            isUndo={getIsDefault(EvIncentiveIds.USED_EV_START_YEAR)}
            errorProps={handleOnGetError(EvIncentiveIds.USED_EV_START_YEAR)}
            onBlur={handleOnBlur(EvIncentiveIds.USED_EV_START_YEAR)}
            onChange={handleOnChange(
              EvIncentiveIds.USED_EV_START_YEAR,
              InputTypeValue.YEAR
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.USED_EV_START_YEAR)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.USED_MAX_DURATION}
            labelProps={getLabel(
              EvIncentiveIds.USED_MAX_DURATION,
              restrictions
            )}
            isYears
            defaultValue={evIncentive.usedEvEndYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(EvIncentiveIds.USED_MAX_DURATION)}
            description={
              restrictions[EvIncentiveIds.USED_MAX_DURATION]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.USED_MAX_DURATION)}
            isUndo={getIsDefault(EvIncentiveIds.USED_MAX_DURATION)}
            errorProps={handleOnGetError(EvIncentiveIds.USED_MAX_DURATION)}
            onBlur={handleOnBlur(EvIncentiveIds.USED_MAX_DURATION)}
            onChange={handleOnChange(
              EvIncentiveIds.USED_MAX_DURATION,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.USED_MAX_DURATION)}
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(EvIncentiveIds.USE_PRICE_ELASTICITY, restrictions)}
              value={evIncentive.usePriceElasticity}
              disabled={!getIsExist(EvIncentiveIds.USE_PRICE_ELASTICITY)}
              description={
                restrictions[EvIncentiveIds.USE_PRICE_ELASTICITY]?.description
              }
              isUndo={getIsDefault(EvIncentiveIds.USE_PRICE_ELASTICITY)}
              errorProps={handleOnCheckAllErrors({
                values: evIncentive,
                radioId: EvIncentiveIds.USE_PRICE_ELASTICITY,
                ids: USE_PRICE_ELASTICITY_RADIO_IDS,
                error
              })}
              {...getAttentionIconState(EvIncentiveIds.USE_PRICE_ELASTICITY)}
              onChange={handleOnRadioChange(
                EvIncentiveIds.USE_PRICE_ELASTICITY
              )}
              onUndoClick={handleUndoClick(EvIncentiveIds.USE_PRICE_ELASTICITY)}
            />
          </Column>
          {evIncentive.usePriceElasticity && (
            <>
              <Column
                className={`${classes.cell} ${classes.selectionControlsContainer}`}
                alignItems="flex-start"
              >
                <RadioButtonGroup
                  {...getLabel(EvIncentiveIds.DECLINE_WTP, restrictions)}
                  value={evIncentive.declineWtp}
                  description={
                    restrictions[EvIncentiveIds.DECLINE_WTP]?.description
                  }
                  disabled={!getIsExist(EvIncentiveIds.DECLINE_WTP)}
                  isUndo={getIsDefault(EvIncentiveIds.DECLINE_WTP)}
                  onChange={handleOnRadioChange(EvIncentiveIds.DECLINE_WTP)}
                  onUndoClick={handleUndoClick(EvIncentiveIds.DECLINE_WTP)}
                />
              </Column>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.AVG_USED_EV_COST}
                labelProps={getLabel(
                  EvIncentiveIds.AVG_USED_EV_COST,
                  restrictions
                )}
                isDollar
                iconProps={dollarIconProps}
                description={
                  restrictions[EvIncentiveIds.AVG_USED_EV_COST]?.description
                }
                defaultValue={evIncentive.avgUsedEvCost}
                isFocused={getIsExist(EvIncentiveIds.AVG_USED_EV_COST)}
                disabled={!getIsExist(EvIncentiveIds.AVG_USED_EV_COST)}
                isUndo={getIsDefault(EvIncentiveIds.AVG_USED_EV_COST)}
                errorProps={handleOnGetError(EvIncentiveIds.AVG_USED_EV_COST)}
                onBlur={handleOnBlur(EvIncentiveIds.AVG_USED_EV_COST)}
                onChange={handleOnChange(
                  EvIncentiveIds.AVG_USED_EV_COST,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.AVG_USED_EV_COST)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.MIN_USED_EV_AGE}
                labelProps={getLabel(
                  EvIncentiveIds.MIN_USED_EV_AGE,
                  restrictions
                )}
                isYears
                iconProps={yearsIconProps}
                description={
                  restrictions[EvIncentiveIds.MIN_USED_EV_AGE]?.description
                }
                defaultValue={evIncentive.minUsedEvAge}
                isFocused={getIsExist(EvIncentiveIds.MIN_USED_EV_AGE)}
                isUndo={getIsDefault(EvIncentiveIds.MIN_USED_EV_AGE)}
                errorProps={handleOnGetError(EvIncentiveIds.MIN_USED_EV_AGE)}
                onBlur={handleOnBlur(EvIncentiveIds.MIN_USED_EV_AGE)}
                onChange={handleOnChange(
                  EvIncentiveIds.MIN_USED_EV_AGE,
                  InputTypeValue.YEARS
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.MIN_USED_EV_AGE)}
              />
            </>
          )}
        </Row>
        {/* Labor & Assembly Incentive */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            text="Labor & Assembly Incentive"
            preset="h3"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(
                EvIncentiveIds.MANUFACTURERS_INCENTIVE,
                restrictions
              )}
              value={evIncentive.lmBoolean}
              description={
                restrictions[EvIncentiveIds.MANUFACTURERS_INCENTIVE]
                  ?.description
              }
              disabled={!getIsExist(EvIncentiveIds.MANUFACTURERS_INCENTIVE)}
              isUndo={getIsDefault(EvIncentiveIds.MANUFACTURERS_INCENTIVE)}
              errorProps={handleOnCheckAllErrors({
                values: evIncentive,
                radioId: EvIncentiveIds.MANUFACTURERS_INCENTIVE,
                ids: MANUFACTURERS_INCENTIVE_RADIO_IDS,
                error
              })}
              {...getAttentionIconState(EvIncentiveIds.MANUFACTURERS_INCENTIVE)}
              onChange={handleOnRadioChange(
                EvIncentiveIds.MANUFACTURERS_INCENTIVE
              )}
              onUndoClick={handleUndoClick(
                EvIncentiveIds.MANUFACTURERS_INCENTIVE
              )}
            />
          </Column>
        </Row>
        {evIncentive.lmBoolean && (
          <>
            <Row
              className={`${classes.row} ${classes.responsiveRow}`}
              justifyContent="flex-start"
            >
              <Column
                className={`${classes.cell} ${classes.selectionControlsContainer}`}
                alignItems="flex-start"
              >
                <CustomRadioButtonGroup
                  className={classes.customRadio}
                  labelProps={getLabel(
                    EvIncentiveIds.BONUS_PERCENT_OR_DOLLAR,
                    restrictions
                  )}
                  firstLabelProps={percentLabelProps}
                  secondLabelProps={dollarsProps}
                  value={getBooleanValue(evIncentive.lmBonusType)}
                  description={
                    restrictions[EvIncentiveIds.BONUS_PERCENT_OR_DOLLAR]
                      ?.description
                  }
                  isUndo={getIsDefault(EvIncentiveIds.BONUS_PERCENT_OR_DOLLAR)}
                  {...getCustomAttentionIconState(
                    EvIncentiveIds.BONUS_PERCENT_OR_DOLLAR
                  )}
                  onChange={handleOnCustomRadioChange(
                    EvIncentiveIds.BONUS_PERCENT_OR_DOLLAR
                  )}
                  onUndoClick={handleUndoClick(
                    EvIncentiveIds.BONUS_PERCENT_OR_DOLLAR
                  )}
                />
              </Column>
              {!isBonusDollar && (
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={EvIncentiveIds.BONUS_INCENTIVE}
                  labelProps={getLabel(
                    EvIncentiveIds.BONUS_INCENTIVE,
                    restrictions
                  )}
                  description={
                    restrictions[EvIncentiveIds.BONUS_INCENTIVE]?.description
                  }
                  isPercent
                  defaultValue={evIncentive.lmIncPerc}
                  iconProps={percentIconProps}
                  isFocused={getIsExist(EvIncentiveIds.BONUS_INCENTIVE)}
                  disabled={!evIncentive.lmBoolean}
                  isUndo={getIsDefault(EvIncentiveIds.BONUS_INCENTIVE)}
                  errorProps={handleOnGetError(EvIncentiveIds.BONUS_INCENTIVE)}
                  onBlur={handleOnBlur(EvIncentiveIds.BONUS_INCENTIVE)}
                  onChange={handleOnChange(
                    EvIncentiveIds.BONUS_INCENTIVE,
                    InputTypeValue.PERCENT
                  )}
                  onUndoClick={handleUndoClick(EvIncentiveIds.BONUS_INCENTIVE)}
                />
              )}
              {isLmDollar && (
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={EvIncentiveIds.BONUS_INCENTIVE_DOLLAR}
                  labelProps={getLabel(
                    EvIncentiveIds.BONUS_INCENTIVE_DOLLAR,
                    restrictions
                  )}
                  defaultValue={evIncentive.lmIncDollar}
                  isDollar
                  iconProps={dollarIconProps}
                  isFocused={getIsExist(EvIncentiveIds.BONUS_INCENTIVE_DOLLAR)}
                  description={
                    restrictions[EvIncentiveIds.BONUS_INCENTIVE_DOLLAR]
                      ?.description
                  }
                  disabled={!evIncentive.lmBoolean}
                  isUndo={getIsDefault(EvIncentiveIds.BONUS_INCENTIVE_DOLLAR)}
                  errorProps={handleOnGetError(
                    EvIncentiveIds.BONUS_INCENTIVE_DOLLAR
                  )}
                  onBlur={handleOnBlur(EvIncentiveIds.BONUS_INCENTIVE_DOLLAR)}
                  onChange={handleOnChange(
                    EvIncentiveIds.BONUS_INCENTIVE_DOLLAR,
                    InputTypeValue.DOLLAR
                  )}
                  onUndoClick={handleUndoClick(
                    EvIncentiveIds.BONUS_INCENTIVE_DOLLAR
                  )}
                />
              )}
              {isLmDollarAlt && (
                <>
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.SECOND_LM_INC_DOLLAR}
                    labelProps={getLabel(
                      EvIncentiveIds.SECOND_LM_INC_DOLLAR,
                      restrictions
                    )}
                    defaultValue={evIncentive.secondLmIncDollar}
                    isDollar
                    iconProps={dollarIconProps}
                    isFocused={getIsExist(EvIncentiveIds.SECOND_LM_INC_DOLLAR)}
                    description={
                      restrictions[EvIncentiveIds.SECOND_LM_INC_DOLLAR]
                        ?.description
                    }
                    disabled={!evIncentive.lmBoolean}
                    isUndo={getIsDefault(EvIncentiveIds.SECOND_LM_INC_DOLLAR)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.SECOND_LM_INC_DOLLAR
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.SECOND_LM_INC_DOLLAR)}
                    onChange={handleOnChange(
                      EvIncentiveIds.SECOND_LM_INC_DOLLAR,
                      InputTypeValue.DOLLAR
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.SECOND_LM_INC_DOLLAR
                    )}
                  />
                  <Input
                    alignItems="flex-start"
                    className={classes.cell}
                    id={EvIncentiveIds.SECOND_LM_INC_YEAR}
                    labelProps={getLabel(
                      EvIncentiveIds.SECOND_LM_INC_YEAR,
                      restrictions
                    )}
                    isYears
                    defaultValue={evIncentive.secondLmIncYear}
                    iconProps={yearsIconProps}
                    isFocused={getIsExist(EvIncentiveIds.SECOND_LM_INC_YEAR)}
                    description={
                      restrictions[EvIncentiveIds.SECOND_LM_INC_YEAR]
                        ?.description
                    }
                    disabled={!evIncentive.lmBoolean}
                    isUndo={getIsDefault(EvIncentiveIds.SECOND_LM_INC_YEAR)}
                    errorProps={handleOnGetError(
                      EvIncentiveIds.SECOND_LM_INC_YEAR
                    )}
                    onBlur={handleOnBlur(EvIncentiveIds.SECOND_LM_INC_YEAR)}
                    onChange={handleOnChange(
                      EvIncentiveIds.SECOND_LM_INC_YEAR,
                      InputTypeValue.YEARS
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.SECOND_LM_INC_YEAR
                    )}
                  />
                </>
              )}
            </Row>
          </>
        )}

        {/* Qualified Percentage of Vehicles */}
        {evIncentive.lmBoolean && (
          <>
            <Row
              className={classes.subTitleContainer}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Text text={qualifiedVehiclesLabelProps.text} preset="h3" />
            </Row>
            <Row
              className={`${classes.row} ${classes.responsiveRow}`}
              justifyContent="flex-start"
            >
              <Input
                alignItems="flex-start"
                id={EvIncentiveIds.FIRST_YEAR}
                className={classes.cell}
                labelProps={getLabel(EvIncentiveIds.FIRST_YEAR, restrictions)}
                isPercent
                defaultValue={evIncentive.y1Lm}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.FIRST_YEAR)}
                description={
                  restrictions[EvIncentiveIds.FIRST_YEAR]?.description
                }
                disabled={!getIsExist(EvIncentiveIds.FIRST_YEAR)}
                isUndo={getIsDefault(EvIncentiveIds.FIRST_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.FIRST_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.FIRST_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.FIRST_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.FIRST_YEAR)}
              />
              <Input
                alignItems="flex-start"
                id={EvIncentiveIds.SECOND_YEAR}
                className={classes.cell}
                labelProps={getLabel(EvIncentiveIds.SECOND_YEAR, restrictions)}
                isPercent
                defaultValue={evIncentive.y2Lm}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.SECOND_YEAR)}
                description={
                  restrictions[EvIncentiveIds.SECOND_YEAR]?.description
                }
                disabled={!getIsExist(EvIncentiveIds.SECOND_YEAR)}
                isUndo={getIsDefault(EvIncentiveIds.SECOND_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.SECOND_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.SECOND_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.SECOND_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.SECOND_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.THIRD_YEAR}
                labelProps={getLabel(EvIncentiveIds.THIRD_YEAR, restrictions)}
                isPercent
                defaultValue={evIncentive.y3Lm}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.THIRD_YEAR)}
                description={
                  restrictions[EvIncentiveIds.THIRD_YEAR]?.description
                }
                disabled={!getIsExist(EvIncentiveIds.THIRD_YEAR)}
                isUndo={getIsDefault(EvIncentiveIds.THIRD_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.THIRD_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.THIRD_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.THIRD_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.THIRD_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.FOURTH_YEAR}
                labelProps={getLabel(EvIncentiveIds.FOURTH_YEAR, restrictions)}
                isPercent
                defaultValue={evIncentive.y4Lm}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.FOURTH_YEAR)}
                description={
                  restrictions[EvIncentiveIds.FOURTH_YEAR]?.description
                }
                disabled={!getIsExist(EvIncentiveIds.FOURTH_YEAR)}
                isUndo={getIsDefault(EvIncentiveIds.FOURTH_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.FOURTH_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.FOURTH_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.FOURTH_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.FOURTH_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.FIFTH_YEAR}
                labelProps={getLabel(EvIncentiveIds.FIFTH_YEAR, restrictions)}
                isPercent
                defaultValue={evIncentive.y5Lm}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.FIFTH_YEAR)}
                description={
                  restrictions[EvIncentiveIds.FIFTH_YEAR]?.description
                }
                disabled={!getIsExist(EvIncentiveIds.FIFTH_YEAR)}
                isUndo={getIsDefault(EvIncentiveIds.FIFTH_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.FIFTH_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.FIFTH_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.FIFTH_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.FIFTH_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.SIXTH_YEAR}
                labelProps={getLabel(EvIncentiveIds.SIXTH_YEAR, restrictions)}
                isPercent
                defaultValue={evIncentive.y6Lm}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.SIXTH_YEAR)}
                description={
                  restrictions[EvIncentiveIds.SIXTH_YEAR]?.description
                }
                disabled={!getIsExist(EvIncentiveIds.SIXTH_YEAR)}
                isUndo={getIsDefault(EvIncentiveIds.SIXTH_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.SIXTH_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.SIXTH_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.SIXTH_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.SIXTH_YEAR)}
              />
            </Row>
            <Row
              className={`${classes.row} ${classes.responsiveRow}`}
              justifyContent="flex-start"
            >
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.LEADING_PROGRAM_START_YEAR}
                labelProps={getLabel(
                  EvIncentiveIds.LEADING_PROGRAM_START_YEAR,
                  restrictions
                )}
                isYear
                defaultValue={evIncentive.lmStartYear}
                iconProps={yearIconProps}
                isFocused={getIsExist(
                  EvIncentiveIds.LEADING_PROGRAM_START_YEAR
                )}
                description={
                  restrictions[EvIncentiveIds.LEADING_PROGRAM_START_YEAR]
                    ?.description
                }
                disabled={
                  !getIsExist(EvIncentiveIds.LEADING_PROGRAM_START_YEAR)
                }
                isUndo={getIsDefault(EvIncentiveIds.LEADING_PROGRAM_START_YEAR)}
                errorProps={handleOnGetError(
                  EvIncentiveIds.LEADING_PROGRAM_START_YEAR
                )}
                onBlur={handleOnBlur(EvIncentiveIds.LEADING_PROGRAM_START_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.LEADING_PROGRAM_START_YEAR,
                  InputTypeValue.YEAR
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.LEADING_PROGRAM_START_YEAR
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION}
                labelProps={getLabel(
                  EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION,
                  restrictions
                )}
                isYears
                defaultValue={evIncentive.lmEndYears}
                iconProps={yearsIconProps}
                isFocused={getIsExist(
                  EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION
                )}
                description={
                  restrictions[EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION]
                    ?.description
                }
                disabled={
                  !getIsExist(EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION)
                }
                isUndo={getIsDefault(
                  EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION
                )}
                errorProps={handleOnGetError(
                  EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION
                )}
                onBlur={handleOnBlur(
                  EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION
                )}
                onChange={handleOnChange(
                  EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION,
                  InputTypeValue.YEARS
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION
                )}
              />
            </Row>
          </>
        )}
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          {evIncentive.lmBoolean && (
            <Column
              className={`${classes.cell} ${classes.selectionControlsContainer}`}
              alignItems="flex-start"
            >
              <RadioButtonGroup
                {...getLabel(EvIncentiveIds.SPLIT_PROGRAM, restrictions)}
                value={evIncentive.programUsedBoolean}
                description={
                  restrictions[EvIncentiveIds.SPLIT_PROGRAM]?.description
                }
                disabled={!getIsExist(EvIncentiveIds.SPLIT_PROGRAM)}
                isUndo={getIsDefault(EvIncentiveIds.SPLIT_PROGRAM)}
                errorProps={handleOnCheckAllErrors({
                  values: evIncentive,
                  radioId: EvIncentiveIds.SPLIT_PROGRAM,
                  ids: SPLIT_PROGRAM_RADIO_IDS,
                  error
                })}
                {...getAttentionIconState(EvIncentiveIds.SPLIT_PROGRAM)}
                onChange={handleOnRadioChange(EvIncentiveIds.SPLIT_PROGRAM)}
                onUndoClick={handleUndoClick(EvIncentiveIds.SPLIT_PROGRAM)}
              />
            </Column>
          )}
          {isModelSplitVisible && (
            <>
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.LEADING_MANUFACTURERS}
                labelProps={getLabel(
                  EvIncentiveIds.LEADING_MANUFACTURERS,
                  restrictions
                )}
                isYear
                iconProps={yearIconProps}
                defaultValue={evIncentive.cutoffModelYear}
                isFocused={getIsExist(EvIncentiveIds.LEADING_MANUFACTURERS)}
                description={
                  restrictions[EvIncentiveIds.LEADING_MANUFACTURERS]
                    ?.description
                }
                disabled={!evIncentive.programUsedBoolean}
                isUndo={getIsDefault(EvIncentiveIds.LEADING_MANUFACTURERS)}
                errorProps={handleOnGetError(
                  EvIncentiveIds.LEADING_MANUFACTURERS
                )}
                onBlur={handleOnBlur(EvIncentiveIds.LEADING_MANUFACTURERS)}
                onChange={handleOnChange(
                  EvIncentiveIds.LEADING_MANUFACTURERS,
                  InputTypeValue.YEAR
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.LEADING_MANUFACTURERS
                )}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.ELIGIBLE_USED_EVS}
                labelProps={getLabel(
                  EvIncentiveIds.ELIGIBLE_USED_EVS,
                  restrictions
                )}
                defaultValue={evIncentive.programUsedEvIncentive}
                isDollar
                iconProps={dollarIconProps}
                isFocused={getIsExist(EvIncentiveIds.ELIGIBLE_USED_EVS)}
                description={
                  restrictions[EvIncentiveIds.ELIGIBLE_USED_EVS]?.description
                }
                disabled={!evIncentive.programUsedBoolean}
                isUndo={getIsDefault(EvIncentiveIds.ELIGIBLE_USED_EVS)}
                errorProps={handleOnGetError(EvIncentiveIds.ELIGIBLE_USED_EVS)}
                onBlur={handleOnBlur(EvIncentiveIds.ELIGIBLE_USED_EVS)}
                onChange={handleOnChange(
                  EvIncentiveIds.ELIGIBLE_USED_EVS,
                  InputTypeValue.DOLLAR
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.ELIGIBLE_USED_EVS)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.ELIGIBILITY_EXPANDS}
                labelProps={getLabel(
                  EvIncentiveIds.ELIGIBILITY_EXPANDS,
                  restrictions
                )}
                isYear
                iconProps={yearIconProps}
                defaultValue={evIncentive.usedProgramExpansionYear}
                isFocused={getIsExist(EvIncentiveIds.ELIGIBILITY_EXPANDS)}
                description={
                  restrictions[EvIncentiveIds.ELIGIBILITY_EXPANDS]?.description
                }
                disabled={!evIncentive.programUsedBoolean}
                isUndo={getIsDefault(EvIncentiveIds.ELIGIBILITY_EXPANDS)}
                errorProps={handleOnGetError(
                  EvIncentiveIds.ELIGIBILITY_EXPANDS
                )}
                onBlur={handleOnBlur(EvIncentiveIds.ELIGIBILITY_EXPANDS)}
                onChange={handleOnChange(
                  EvIncentiveIds.ELIGIBILITY_EXPANDS,
                  InputTypeValue.YEAR
                )}
                onUndoClick={handleUndoClick(
                  EvIncentiveIds.ELIGIBILITY_EXPANDS
                )}
              />
              <Row className={classes.cell}>
                <Dropdown
                  className={classes.dropdown}
                  custom={getEmptyDropdownStyle(
                    EvIncentiveIds.EXPANDED_ELIGIBILITY
                  )}
                  data={dropdowns.secondFederalPUsed}
                  preset="body"
                  defaultValue={handleOnGetCurrentDropdown(
                    evIncentive.secondFplUsed || 100,
                    'secondFplUsed'
                  )}
                  iconProps={percentIconProps}
                  label={
                    getLabel(EvIncentiveIds.EXPANDED_ELIGIBILITY, restrictions)
                      .text
                  }
                  description={
                    restrictions[EvIncentiveIds.EXPANDED_ELIGIBILITY]
                      ?.description
                  }
                  disabled={!evIncentive.programUsedBoolean}
                  isUndo={getIsDefault(EvIncentiveIds.EXPANDED_ELIGIBILITY)}
                  onChange={handleOnDropdownChange(
                    EvIncentiveIds.EXPANDED_ELIGIBILITY
                  )}
                  onUndoClick={handleUndoClick(
                    EvIncentiveIds.EXPANDED_ELIGIBILITY
                  )}
                />
              </Row>
              {isFplBonusNewExpansionVisible && (
                <Column
                  className={`${classes.cell} ${classes.selectionControlsContainer}`}
                  alignItems="flex-start"
                >
                  <RadioButtonGroup
                    {...getLabel(
                      EvIncentiveIds.FPL_BONUS_EXPANSION,
                      restrictions
                    )}
                    value={evIncentive.fplBonusNewExpansionBoolean}
                    description={
                      restrictions[EvIncentiveIds.FPL_BONUS_EXPANSION]
                        ?.description
                    }
                    disabled={!getIsExist(EvIncentiveIds.FPL_BONUS_EXPANSION)}
                    isUndo={getIsDefault(EvIncentiveIds.FPL_BONUS_EXPANSION)}
                    onChange={handleOnRadioChange(
                      EvIncentiveIds.FPL_BONUS_EXPANSION
                    )}
                    onUndoClick={handleUndoClick(
                      EvIncentiveIds.FPL_BONUS_EXPANSION
                    )}
                  />
                </Column>
              )}
            </>
          )}
        </Row>
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            text="U.S. Assembly Incentive"
            preset="h3"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(
                EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE,
                restrictions
              )}
              value={evIncentive.assUsBoolean}
              description={
                restrictions[EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE]
                  ?.description
              }
              disabled={!getIsExist(EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE)}
              isUndo={getIsDefault(EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE)}
              errorProps={handleOnCheckAllErrors({
                values: evIncentive,
                radioId: EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE,
                ids: ASSEMBLED_IN_US_INCENTIVE_RADIO_IDS,
                error
              })}
              {...getAttentionIconState(
                EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE
              )}
              onChange={handleOnRadioChange(
                EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE
              )}
              onUndoClick={handleUndoClick(
                EvIncentiveIds.ASSEMBLED_IN_US_INCENTIVE
              )}
            />
          </Column>
          {evIncentive.assUsBoolean && (
            <>
              <Column
                className={`${classes.cell} ${classes.selectionControlsContainer}`}
                alignItems="flex-start"
              >
                <CustomRadioButtonGroup
                  className={classes.customRadio}
                  labelProps={getLabel(
                    EvIncentiveIds.ASS_US_TYPE,
                    restrictions
                  )}
                  firstLabelProps={incentiveGrayLabelProps}
                  secondLabelProps={requirementLabelProps}
                  value={getBooleanValue(evIncentive.assUsType)}
                  description={
                    restrictions[EvIncentiveIds.ASS_US_TYPE]?.description
                  }
                  isUndo={getIsDefault(EvIncentiveIds.ASS_US_TYPE)}
                  {...getCustomAttentionIconState(EvIncentiveIds.ASS_US_TYPE)}
                  onChange={handleOnCustomRadioChange(
                    EvIncentiveIds.ASS_US_TYPE
                  )}
                  onUndoClick={handleUndoClick(EvIncentiveIds.ASS_US_TYPE)}
                />
              </Column>
              {evIncentive.assUsType === 1 ? (
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={EvIncentiveIds.ASSEMBLED_US_INCENTIVE}
                  labelProps={getLabel(
                    EvIncentiveIds.ASSEMBLED_US_INCENTIVE,
                    restrictions
                  )}
                  isFocused={getIsExist(EvIncentiveIds.ASSEMBLED_US_INCENTIVE)}
                  disabled={!evIncentive.assUsBoolean}
                  isDollar
                  iconProps={dollarIconProps}
                  isUndo={getIsDefault(EvIncentiveIds.ASSEMBLED_US_INCENTIVE)}
                  defaultValue={evIncentive.assUsInc}
                  description={
                    restrictions[EvIncentiveIds.ASSEMBLED_US_INCENTIVE]
                      ?.description
                  }
                  errorProps={handleOnGetError(
                    EvIncentiveIds.ASSEMBLED_US_INCENTIVE
                  )}
                  onBlur={handleOnBlur(EvIncentiveIds.ASSEMBLED_US_INCENTIVE)}
                  onChange={handleOnChange(
                    EvIncentiveIds.ASSEMBLED_US_INCENTIVE,
                    InputTypeValue.DOLLAR
                  )}
                  onUndoClick={handleUndoClick(
                    EvIncentiveIds.ASSEMBLED_US_INCENTIVE
                  )}
                />
              ) : (
                <Input
                  alignItems="flex-start"
                  className={classes.cell}
                  id={EvIncentiveIds.ASS_US_REQ_YEAR}
                  labelProps={getLabel(
                    EvIncentiveIds.ASS_US_REQ_YEAR,
                    restrictions
                  )}
                  isYear
                  iconProps={yearIconProps}
                  defaultValue={evIncentive.assUsReqYear}
                  isFocused={getIsExist(EvIncentiveIds.ASS_US_REQ_YEAR)}
                  disabled={!evIncentive.assUsBoolean}
                  description={
                    restrictions[EvIncentiveIds.ASS_US_REQ_YEAR]?.description
                  }
                  isUndo={getIsDefault(EvIncentiveIds.ASS_US_REQ_YEAR)}
                  errorProps={handleOnGetError(EvIncentiveIds.ASS_US_REQ_YEAR)}
                  onBlur={handleOnBlur(EvIncentiveIds.ASS_US_REQ_YEAR)}
                  onChange={handleOnChange(
                    EvIncentiveIds.ASS_US_REQ_YEAR,
                    InputTypeValue.YEAR
                  )}
                  onUndoClick={handleUndoClick(EvIncentiveIds.ASS_US_REQ_YEAR)}
                />
              )}
            </>
          )}
        </Row>
        {evIncentive.assUsBoolean && (
          <>
            <Row
              className={classes.subTitleContainer}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Text text={qualifiedVehiclesLabelProps.text} preset="h3" />
            </Row>
            <Row
              className={`${classes.row} ${classes.responsiveRow}`}
              justifyContent="flex-start"
            >
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.US_FIRST_YEAR}
                labelProps={getLabel(
                  EvIncentiveIds.US_FIRST_YEAR,
                  restrictions
                )}
                isPercent
                defaultValue={evIncentive.y1AssUs}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.US_FIRST_YEAR)}
                description={
                  restrictions[EvIncentiveIds.US_FIRST_YEAR]?.description
                }
                disabled={!evIncentive.assUsBoolean}
                isUndo={getIsDefault(EvIncentiveIds.US_FIRST_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.US_FIRST_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.US_FIRST_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.US_FIRST_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.US_FIRST_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.US_SECOND_YEAR}
                labelProps={getLabel(
                  EvIncentiveIds.US_SECOND_YEAR,
                  restrictions
                )}
                isPercent
                defaultValue={evIncentive.y2AssUs}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.US_SECOND_YEAR)}
                description={
                  restrictions[EvIncentiveIds.US_SECOND_YEAR]?.description
                }
                disabled={!evIncentive.assUsBoolean}
                isUndo={getIsDefault(EvIncentiveIds.US_SECOND_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.US_SECOND_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.US_SECOND_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.US_SECOND_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.US_SECOND_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.US_THIRD_YEAR}
                labelProps={getLabel(
                  EvIncentiveIds.US_THIRD_YEAR,
                  restrictions
                )}
                isPercent
                defaultValue={evIncentive.y3AssUs}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.US_THIRD_YEAR)}
                description={
                  restrictions[EvIncentiveIds.US_THIRD_YEAR]?.description
                }
                disabled={!evIncentive.assUsBoolean}
                isUndo={getIsDefault(EvIncentiveIds.US_THIRD_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.US_THIRD_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.US_THIRD_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.US_THIRD_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.US_THIRD_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.US_FOURTH_YEAR}
                labelProps={getLabel(
                  EvIncentiveIds.US_FOURTH_YEAR,
                  restrictions
                )}
                isPercent
                defaultValue={evIncentive.y4AssUs}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.US_FOURTH_YEAR)}
                description={
                  restrictions[EvIncentiveIds.US_FOURTH_YEAR]?.description
                }
                disabled={!evIncentive.assUsBoolean}
                isUndo={getIsDefault(EvIncentiveIds.US_FOURTH_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.US_FOURTH_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.US_FOURTH_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.US_FOURTH_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.US_FOURTH_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.US_FIFTH_YEAR}
                labelProps={getLabel(
                  EvIncentiveIds.US_FIFTH_YEAR,
                  restrictions
                )}
                isPercent
                defaultValue={evIncentive.y5AssUs}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.US_FIFTH_YEAR)}
                description={
                  restrictions[EvIncentiveIds.US_FIFTH_YEAR]?.description
                }
                disabled={!evIncentive.assUsBoolean}
                isUndo={getIsDefault(EvIncentiveIds.US_FIFTH_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.US_FIFTH_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.US_FIFTH_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.US_FIFTH_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.US_FIFTH_YEAR)}
              />
              <Input
                alignItems="flex-start"
                className={classes.cell}
                id={EvIncentiveIds.US_SIXTH_YEAR}
                labelProps={getLabel(
                  EvIncentiveIds.US_SIXTH_YEAR,
                  restrictions
                )}
                isPercent
                defaultValue={evIncentive.y6AssUs}
                iconProps={percentIconProps}
                isFocused={getIsExist(EvIncentiveIds.US_SIXTH_YEAR)}
                description={
                  restrictions[EvIncentiveIds.US_SIXTH_YEAR]?.description
                }
                disabled={!evIncentive.assUsBoolean}
                isUndo={getIsDefault(EvIncentiveIds.US_SIXTH_YEAR)}
                errorProps={handleOnGetError(EvIncentiveIds.US_SIXTH_YEAR)}
                onBlur={handleOnBlur(EvIncentiveIds.US_SIXTH_YEAR)}
                onChange={handleOnChange(
                  EvIncentiveIds.US_SIXTH_YEAR,
                  InputTypeValue.PERCENT
                )}
                onUndoClick={handleUndoClick(EvIncentiveIds.US_SIXTH_YEAR)}
              />
            </Row>
          </>
        )}
        {evIncentive.assUsBoolean && (
          <Row
            className={`${classes.row} ${classes.responsiveRow}`}
            justifyContent="flex-start"
          >
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR}
              labelProps={getLabel(
                EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR,
                restrictions
              )}
              isYear
              defaultValue={evIncentive.assUsStartYear}
              iconProps={yearIconProps}
              isFocused={getIsExist(
                EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR
              )}
              description={
                restrictions[EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR]
                  ?.description
              }
              disabled={!evIncentive.assUsBoolean}
              isUndo={getIsDefault(EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR)}
              errorProps={handleOnGetError(
                EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR
              )}
              onBlur={handleOnBlur(EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR)}
              onChange={handleOnChange(
                EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR,
                InputTypeValue.YEAR
              )}
              onUndoClick={handleUndoClick(
                EvIncentiveIds.ASSEMBLED_PROGRAM_START_YEAR
              )}
            />
            <Input
              alignItems="flex-start"
              className={classes.cell}
              id={EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION}
              labelProps={getLabel(
                EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION,
                restrictions
              )}
              isYears
              defaultValue={evIncentive.assUsEndYears}
              iconProps={yearsIconProps}
              isFocused={getIsExist(
                EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION
              )}
              description={
                restrictions[EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION]
                  ?.description
              }
              disabled={!evIncentive.assUsBoolean}
              isUndo={getIsDefault(
                EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION
              )}
              errorProps={handleOnGetError(
                EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION
              )}
              onBlur={handleOnBlur(
                EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION
              )}
              onChange={handleOnChange(
                EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION,
                InputTypeValue.YEARS
              )}
              onUndoClick={handleUndoClick(
                EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION
              )}
            />
          </Row>
        )}
        {/* OEM Incentive */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            {...oemIncentiveProps}
            preset="h3"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.INCENTIVE_VALUE_OEM}
            labelProps={getLabel(
              EvIncentiveIds.INCENTIVE_VALUE_OEM,
              restrictions
            )}
            defaultValue={evIncentive.oemStartingAmt}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(EvIncentiveIds.INCENTIVE_VALUE_OEM)}
            description={
              restrictions[EvIncentiveIds.INCENTIVE_VALUE_OEM]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.INCENTIVE_VALUE_OEM)}
            isUndo={getIsDefault(EvIncentiveIds.INCENTIVE_VALUE_OEM)}
            errorProps={handleOnGetError(EvIncentiveIds.INCENTIVE_VALUE_OEM)}
            onBlur={handleOnBlur(EvIncentiveIds.INCENTIVE_VALUE_OEM)}
            onChange={handleOnChange(
              EvIncentiveIds.INCENTIVE_VALUE_OEM,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.INCENTIVE_VALUE_OEM)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.DURATION_OEM}
            labelProps={getLabel(EvIncentiveIds.DURATION_OEM, restrictions)}
            isYears
            defaultValue={evIncentive.oemYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(EvIncentiveIds.DURATION_OEM)}
            description={restrictions[EvIncentiveIds.DURATION_OEM]?.description}
            disabled={!getIsExist(EvIncentiveIds.DURATION_OEM)}
            isUndo={getIsDefault(EvIncentiveIds.DURATION_OEM)}
            errorProps={handleOnGetError(EvIncentiveIds.DURATION_OEM)}
            onBlur={handleOnBlur(EvIncentiveIds.DURATION_OEM)}
            onChange={handleOnChange(
              EvIncentiveIds.DURATION_OEM,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.DURATION_OEM)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.OEM_START_YEAR}
            labelProps={getLabel(EvIncentiveIds.OEM_START_YEAR, restrictions)}
            isYear
            defaultValue={evIncentive.oemStartYear}
            iconProps={yearIconProps}
            isFocused={getIsExist(EvIncentiveIds.OEM_START_YEAR)}
            description={
              restrictions[EvIncentiveIds.OEM_START_YEAR]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.OEM_START_YEAR)}
            isUndo={getIsDefault(EvIncentiveIds.OEM_START_YEAR)}
            errorProps={handleOnGetError(EvIncentiveIds.OEM_START_YEAR)}
            onBlur={handleOnBlur(EvIncentiveIds.OEM_START_YEAR)}
            onChange={handleOnChange(
              EvIncentiveIds.OEM_START_YEAR,
              InputTypeValue.YEAR
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.OEM_START_YEAR)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.OEM_END_YEAR}
            labelProps={getLabel(EvIncentiveIds.OEM_END_YEAR, restrictions)}
            isYears
            defaultValue={evIncentive.oemEndYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(EvIncentiveIds.OEM_END_YEAR)}
            description={restrictions[EvIncentiveIds.OEM_END_YEAR]?.description}
            disabled={!getIsExist(EvIncentiveIds.OEM_END_YEAR)}
            isUndo={getIsDefault(EvIncentiveIds.OEM_END_YEAR)}
            errorProps={handleOnGetError(EvIncentiveIds.OEM_END_YEAR)}
            onBlur={handleOnBlur(EvIncentiveIds.OEM_END_YEAR)}
            onChange={handleOnChange(
              EvIncentiveIds.OEM_END_YEAR,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.OEM_END_YEAR)}
          />
        </Row>

        {/* Dealer Incentive */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            text="Dealer Incentive"
            preset="h3"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.INCENTIVE_VALUE_DEALER}
            labelProps={getLabel(
              EvIncentiveIds.INCENTIVE_VALUE_DEALER,
              restrictions
            )}
            defaultValue={evIncentive.dealerInc}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(EvIncentiveIds.INCENTIVE_VALUE_DEALER)}
            description={
              restrictions[EvIncentiveIds.INCENTIVE_VALUE_DEALER]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.INCENTIVE_VALUE_DEALER)}
            isUndo={getIsDefault(EvIncentiveIds.INCENTIVE_VALUE_DEALER)}
            errorProps={handleOnGetError(EvIncentiveIds.INCENTIVE_VALUE_DEALER)}
            onBlur={handleOnBlur(EvIncentiveIds.INCENTIVE_VALUE_DEALER)}
            onChange={handleOnChange(
              EvIncentiveIds.INCENTIVE_VALUE_DEALER,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.INCENTIVE_VALUE_DEALER)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.DURATION_DEALER}
            labelProps={getLabel(EvIncentiveIds.DURATION_DEALER, restrictions)}
            isYears
            defaultValue={evIncentive.dealerYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(EvIncentiveIds.DURATION_DEALER)}
            description={
              restrictions[EvIncentiveIds.DURATION_DEALER]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.DURATION_DEALER)}
            isUndo={getIsDefault(EvIncentiveIds.DURATION_DEALER)}
            errorProps={handleOnGetError(EvIncentiveIds.DURATION_DEALER)}
            onBlur={handleOnBlur(EvIncentiveIds.DURATION_DEALER)}
            onChange={handleOnChange(
              EvIncentiveIds.DURATION_DEALER,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.DURATION_DEALER)}
          />
        </Row>

        {/* Domestic Content Incentive */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            className={classes.subTitle}
            {...madeInAmericaIncProps}
            preset="h3"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.INCENTIVE_VALUE_AMERICA}
            labelProps={getLabel(
              EvIncentiveIds.INCENTIVE_VALUE_AMERICA,
              restrictions
            )}
            defaultValue={evIncentive.miAInc}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(EvIncentiveIds.INCENTIVE_VALUE_AMERICA)}
            description={
              restrictions[EvIncentiveIds.INCENTIVE_VALUE_AMERICA]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.INCENTIVE_VALUE_AMERICA)}
            isUndo={getIsDefault(EvIncentiveIds.INCENTIVE_VALUE_AMERICA)}
            errorProps={handleOnGetError(
              EvIncentiveIds.INCENTIVE_VALUE_AMERICA
            )}
            onBlur={handleOnBlur(EvIncentiveIds.INCENTIVE_VALUE_AMERICA)}
            onChange={handleOnChange(
              EvIncentiveIds.INCENTIVE_VALUE_AMERICA,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(
              EvIncentiveIds.INCENTIVE_VALUE_AMERICA
            )}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.VEHICLES_ELIGIBLE}
            labelProps={getLabel(
              EvIncentiveIds.VEHICLES_ELIGIBLE,
              restrictions
            )}
            isPercent
            defaultValue={evIncentive.miAProp}
            iconProps={percentIconProps}
            isFocused={getIsExist(EvIncentiveIds.VEHICLES_ELIGIBLE)}
            description={
              restrictions[EvIncentiveIds.VEHICLES_ELIGIBLE]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.VEHICLES_ELIGIBLE)}
            isUndo={getIsDefault(EvIncentiveIds.VEHICLES_ELIGIBLE)}
            errorProps={handleOnGetError(EvIncentiveIds.VEHICLES_ELIGIBLE)}
            onBlur={handleOnBlur(EvIncentiveIds.VEHICLES_ELIGIBLE)}
            onChange={handleOnChange(
              EvIncentiveIds.VEHICLES_ELIGIBLE,
              InputTypeValue.PERCENT
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.VEHICLES_ELIGIBLE)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.DOMESTIC_START_YEAR}
            labelProps={getLabel(
              EvIncentiveIds.DOMESTIC_START_YEAR,
              restrictions
            )}
            isYear
            defaultValue={evIncentive.domesticContentStartYear}
            iconProps={yearIconProps}
            isFocused={getIsExist(EvIncentiveIds.DOMESTIC_START_YEAR)}
            description={
              restrictions[EvIncentiveIds.DOMESTIC_START_YEAR]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.DOMESTIC_START_YEAR)}
            isUndo={getIsDefault(EvIncentiveIds.DOMESTIC_START_YEAR)}
            errorProps={handleOnGetError(EvIncentiveIds.DOMESTIC_START_YEAR)}
            onBlur={handleOnBlur(EvIncentiveIds.DOMESTIC_START_YEAR)}
            onChange={handleOnChange(
              EvIncentiveIds.DOMESTIC_START_YEAR,
              InputTypeValue.YEAR
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.DOMESTIC_START_YEAR)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EvIncentiveIds.DOMESTIC_MAX_DURATION}
            labelProps={getLabel(
              EvIncentiveIds.DOMESTIC_MAX_DURATION,
              restrictions
            )}
            isYears
            defaultValue={evIncentive.domesticContentEndYears}
            iconProps={yearsIconProps}
            isFocused={getIsExist(EvIncentiveIds.DOMESTIC_MAX_DURATION)}
            description={
              restrictions[EvIncentiveIds.DOMESTIC_MAX_DURATION]?.description
            }
            disabled={!getIsExist(EvIncentiveIds.DOMESTIC_MAX_DURATION)}
            isUndo={getIsDefault(EvIncentiveIds.DOMESTIC_MAX_DURATION)}
            errorProps={handleOnGetError(EvIncentiveIds.DOMESTIC_MAX_DURATION)}
            onBlur={handleOnBlur(EvIncentiveIds.DOMESTIC_MAX_DURATION)}
            onChange={handleOnChange(
              EvIncentiveIds.DOMESTIC_MAX_DURATION,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(EvIncentiveIds.DOMESTIC_MAX_DURATION)}
          />
        </Row>
      </Content>
    </Column>
  )
}
