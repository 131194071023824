import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Icon, IconName, Row } from '..'
import { Button } from '../button'
import { Column } from '../column'
import { Modal } from '../modal'
import { Text, ComponentTextProps } from '../text'
import { InfoModalProps } from './info-modal.types'
import { useStyle } from './info-modal.styles'

export const InfoModal: FC<InfoModalProps> = ({
  children,
  titleProps,
  actionProps,
  isActions = false,
  onSubmit,
  onClose
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const deleteLabelProps: ComponentTextProps = {
    text: 'Delete',
    tx: 'delete.policy.delete',
    ...actionProps
  }

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }
    onClose()
  }

  return (
    <Modal className={classes.modal} onClose={onClose}>
      <Column fullWidth fullHeight justifyContent="space-between">
        <Row className={classes.title} justifyContent="flex-start" fullWidth>
          <Icon name={IconName.ALERT_CIRCLE} width={40} height={40} />
          <Text
            className={classes.title}
            {...titleProps}
            color="black"
            preset="h1"
          />
        </Row>
        <Column
          className={classes.description}
          justifyContent="flex-start"
          alignItems="flex-start"
          fullHeight
          fullWidth
        >
          {children}
        </Column>
        {isActions && (
          <Row justifyContent="space-around" fullWidth>
            <Button preset="accent" onClick={handleOnSubmit}>
              <Text {...deleteLabelProps} preset="body" />
            </Button>
            <Button preset="secondary" onClick={onClose}>
              <Text text="Cancel" tx="btn.cancel" preset="body" />
            </Button>
          </Row>
        )}
      </Column>
    </Modal>
  )
}
