import { createUseStyles } from 'react-jss'
import { DropdownItemProps } from '.'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 16,
    minWidth: ({ isScrollable }: Partial<DropdownItemProps>) =>
      isScrollable ? 'auto' : 200,
    width: 'calc(100% - 32px)',
    backgroundColor: theme.colors.white,
    color: theme.colors.text,
    cursor: theme.cursor,
    '& > *': {
      cursor: theme.cursor
    },
    '&:hover': {
      backgroundColor: theme.colors.footerBackground,
      '& > *': {
        color: theme.colors.blue
      }
    }
  },
  disabled: {
    cursor: 'default',
    backgroundColor: theme.colors.white,
    '& > *': {
      cursor: 'default',
      color: theme.colors.grey
    },
    '&:hover': {
      backgroundColor: theme.colors.white,
      '& > *': {
        color: `${theme.colors.grey}!important`
      }
    }
  }
}))
