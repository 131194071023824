import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { DropCartProps } from './drop-card.types'
import { useStyle } from './drop-card.styles'

export const DropCart: FC<DropCartProps> = ({
  className = '',
  onDragOver,
  onDrop
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Row
      className={`${className} ${classes.container}`}
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <Icon name={IconName.ADD} fill={theme.colors.border} />
    </Row>
  )
}
