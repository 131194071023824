import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'react-jss'

import {
  Column,
  Row,
  Text,
  RadioButtonGroup,
  Checkbox,
  Dropdown,
  DropdownItemData
} from '../..'
import { LightTheme } from '../../../theme'
import { PolicyCreators } from '../../../store'
import { Affordability } from '../../../services'
import { useResponsive } from '../../../providers'
import {
  evTypeBevLabelProps,
  evTypePhevLabelProps,
  financeTypeLabelProps,
  householdSizeLabelProps,
  leaseProps,
  newOrUsedLabelProps,
  newProps,
  purchaseProps,
  usedProps,
  vehicleTypeLabelProps,
  AffordabilityIds,
  AffordabilityValues,
  getCurrentDropdown
} from '../../update-policy'
import { ComponentTextProps } from '../../text'
import {
  BOOLEAN_WEIGHT,
  customStyles,
  DROPDOWNS,
  NUMBER_VALUES_IDS
} from './constants'
import {
  AffordabilitySettingsProps,
  DropdownValueProps
} from './affordability-settings.types'
import { useStyle } from './affordability-settings.styles'

export const AffordabilitySettings: FC<AffordabilitySettingsProps> = ({
  id,
  policy,
  isCalculator = false
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { isTablet } = useResponsive()
  const dispatch = useDispatch()
  const [settings, changeSettings] = useState<Affordability | undefined>()
  const [updates, changeUpdates] = useState({})

  const titlePreset = isTablet ? 'body' : 'h2'
  const newLabelProps: ComponentTextProps = {
    ...newProps,
    preset: 'subTitleSetting'
  }
  const usedLabelProps: ComponentTextProps = {
    ...usedProps,
    preset: 'subTitleSetting'
  }

  const getValue = (settingId: AffordabilityValues) =>
    settings ? settings[settingId] === 1 : false
  const getSettingValue = (value: boolean) =>
    value ? BOOLEAN_WEIGHT[String(value)] : BOOLEAN_WEIGHT.false

  const getCorrectValueById = ({ settingId = '', value = false }) => {
    const hasSetting = String(value) && settingId

    if (!hasSetting) {
      return {}
    }

    const settingValue =
      hasSetting && NUMBER_VALUES_IDS.includes(settingId)
        ? getSettingValue(value)
        : value

    if (settingId && settings) {
      changeSettings({
        ...settings,
        [settingId]: settingValue
      })
    }
    return settingValue
  }

  const getDropdownValue = ({ settingId, value }: DropdownValueProps) => {
    const settingUpdate = { [settingId]: Number(value.id) }
    if (settings) {
      changeSettings({
        ...settings,
        ...settingUpdate
      })
    }
    changeUpdates({
      ...updates,
      ...settingUpdate
    })

    return settingUpdate
  }

  const getSwitcherValue = ({ settingId = '', value = false }) => {
    const settingValue = getCorrectValueById({ settingId, value })
    const settingUpdate = settingId && { [settingId]: settingValue }
    changeUpdates({
      ...updates,
      ...settingUpdate
    })

    return settingUpdate
  }

  const handleOnGetCurrentDropdown = (value: number, settingId: string) =>
    getCurrentDropdown({
      dropdowns: DROPDOWNS,
      value,
      id: settingId
    })

  const updateAffordability = (settingUpdate?: any) => {
      if (policy) {
        const {
          newOrUsed,
          purchaseOrLease,
          evTypeBev,
          evTypePhev,
          hhsize
        } = policy
        // first request should be without png and policy update
        const isUpdatePng = settingUpdate ? !isCalculator : false

        dispatch(
          PolicyCreators.updateAffordabilityRequest({
            policy: id,
            newOrUsed,
            purchaseOrLease,
            evTypeBev,
            evTypePhev,
            hhsize,
            updatePng: isUpdatePng,
            ...updates,
            ...settingUpdate
          })
        )
      }
    }

  const handleOnChange = (settingId: AffordabilityValues) => (
    value: boolean
  ) => {
    const settingUpdate = getSwitcherValue({ settingId, value })
    updateAffordability(settingUpdate)
  }

  const handleOnDropdownChange = (settingId: AffordabilityValues) => (
    value: DropdownItemData
  ) => {
    const settingUpdate = getDropdownValue({ settingId, value })
    updateAffordability(settingUpdate)
  }

  useEffect(() => {
    if (policy && id) {
      const {
        newOrUsed,
        purchaseOrLease,
        evTypeBev,
        evTypePhev,
        hhsize
      } = policy
      updateAffordability()
      changeSettings({
        policy: id,
        newOrUsed,
        purchaseOrLease,
        evTypeBev,
        evTypePhev,
        hhsize
      })
    }

    return () => {
      changeSettings(undefined)
    }
  }, [policy])

  if (!settings) {
    return null
  }

  return (
    <Row
      className={classes.settings}
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Column alignItems="flex-start">
        <Text {...financeTypeLabelProps} preset={titlePreset} />
        <RadioButtonGroup
          className={classes.col}
          firstLabelProps={purchaseProps}
          secondLabelProps={leaseProps}
          value={getValue(AffordabilityIds.PURCHASE_OR_LEASE)}
          onChange={handleOnChange(AffordabilityIds.PURCHASE_OR_LEASE)}
        />
      </Column>
      <Column alignItems="flex-start">
        <Text {...newOrUsedLabelProps} preset={titlePreset} />
        <RadioButtonGroup
          className={classes.col}
          firstLabelProps={newLabelProps}
          secondLabelProps={usedLabelProps}
          value={getValue(AffordabilityIds.NEW_OR_USED)}
          onChange={handleOnChange(AffordabilityIds.NEW_OR_USED)}
        />
      </Column>
      <Column alignItems="flex-start">
        <Text {...vehicleTypeLabelProps} preset={titlePreset} />
        <Column alignItems="flex-start">
          <Checkbox
            {...evTypeBevLabelProps}
            className={classes.setting}
            defaultChecked={settings[AffordabilityIds.EV_TYPE_BEV]}
            onChange={handleOnChange(AffordabilityIds.EV_TYPE_BEV)}
          />
          <Checkbox
            {...evTypePhevLabelProps}
            className={classes.setting}
            defaultChecked={settings[AffordabilityIds.EV_TYPE_PHEV]}
            onChange={handleOnChange(AffordabilityIds.EV_TYPE_PHEV)}
          />
        </Column>
      </Column>
      <Column alignItems="flex-start">
        <Text {...householdSizeLabelProps} preset={titlePreset} />
        <Column className={classes.setting} alignItems="flex-start" fullWidth>
          <Dropdown
            className={classes.dropdown}
            containerClassName={classes.dropdownContent}
            custom={customStyles}
            data={DROPDOWNS.hhsize}
            preset="body"
            defaultValue={handleOnGetCurrentDropdown(
              settings[AffordabilityIds.HOUSEHOLD_SIZE],
              'hhsize'
            )}
            onChange={handleOnDropdownChange(AffordabilityIds.HOUSEHOLD_SIZE)}
          />
        </Column>
      </Column>
    </Row>
  )
}
