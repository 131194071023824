import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const CaretDown: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.53 8.97a.75.75 0 010 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 111.06-1.06L12 12.44l3.47-3.47a.75.75 0 011.06 0z"
        fill={props.fill || theme.colors.text}
      />
    </svg>
  )
}
