import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    borderRadius: '0 0 12px 12px',
    '& >:last-child': {
      borderBottom: 'none'
    }
  }
}))
