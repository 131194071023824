import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  FocusEvent,
  useCallback
} from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Text } from '../text'
import { Hint } from '../hint'
import { Row } from '../row'
import { InfoModal } from '../info-modal'
import { formatDollars, formatToFloat, formatToNumber } from './helper'
import { FieldValueType, InputProps } from './input.types'
import { useStyle } from './input.styles'

export const Input = ({
  alignItems,
  justifyContent,
  className = '',
  placeholder = '',
  isSuccess,
  labelProps,
  error,
  isFocused = false,
  children,
  iconName,
  iconProps,
  errorProps,
  textIcon,
  defaultValue,
  isUndo = false,
  disabled = false,
  statusIcon = false,
  attentionTx,
  attentionText = '',
  isDollar = false,
  isYears = false,
  isYear = false,
  isEvs = false,
  isPercent = false,
  description = '',
  isRegular = false,
  onIconClick,
  onChange,
  onBlur,
  onUndoClick,
  ...props
}: InputProps) => {
  const isRegularInput = !description ? true : isRegular
  const theme: LightTheme = useTheme()
  const classes = useStyle({
    alignItems,
    justifyContent,
    isUndo,
    isSuccess,
    isFocused,
    isRegular: isRegularInput,
    theme
  })
  const ref = useRef<HTMLInputElement>(null)
  const [isFocusSetted, changeFocusSetted] = useState(isFocused)
  const [openModal, changeOpenModal] = useState(false)
  const labelPreset = isFocusSetted ? 'caption' : 'body'
  const [value, changeValue] = useState(defaultValue)
  // TODO: rewrite
  const defDisabled = disabled ? true : undefined
  const [isDisabled, changeIsDisabled] = useState(defDisabled)
  const updatedFocusStyle = isFocusSetted ? classes.labelUp : classes.labelDown
  const disabledStyle = isDisabled ? classes.disabled : ''
  const disabledInputStyle = isDisabled ? classes.disabledInput : ''
  const errorStyle = errorProps ? classes.error : ''
  const [focusStyle, changeFocusStyle] = useState('')

  const checkIsEmpty = (fieldValue: FieldValueType) =>
    fieldValue === undefined || !String(fieldValue).length

  const onFocus = (event?: FocusEvent<HTMLInputElement>) => {
    if (event) {
      changeFocusStyle(classes.focused)
    }
    changeFocusSetted(true)
  }

  const handleOnBlur = (event?: FocusEvent<HTMLInputElement>) => {
    const isValueEmpty = checkIsEmpty(value)
    const isDefValueEmpty = checkIsEmpty(defaultValue)
    changeFocusStyle('')

    if (event && onBlur) {
      onBlur(event)
    }

    if (isValueEmpty || isDefValueEmpty) {
      changeFocusSetted(false)
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }

    let alignedValue: string | number = e.target.value
    if (isDollar) {
      alignedValue = formatDollars(e.target.value)
    }

    const isNumbers = isYears || isEvs || isYear
    if (isNumbers) {
      alignedValue = formatToNumber(e.target.value)
    }

    if (isPercent) {
      alignedValue = formatToFloat(e.target.value)
    }

    changeValue(alignedValue)
  }

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    [openModal]
  )

  const handleOnClick = () =>
    description ? handleOnOpenModal(true)() : undefined

  useEffect(() => {
    const isExist = !checkIsEmpty(defaultValue)
    if (isExist) {
      onFocus()
    } else {
      handleOnBlur()
    }

    if (isDollar) {
      const alignedValue = formatDollars(String(defaultValue))
      changeValue(alignedValue)
      return
    }

    const isNumbers = isYears || isEvs || isYear
    if (isNumbers) {
      const alignedValue = formatToNumber(String(defaultValue))
      changeValue(alignedValue)
      return
    }

    if (isPercent) {
      const alignedValue = formatToFloat(String(defaultValue))
      changeValue(alignedValue)
      return
    }

    changeValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (isFocused) {
      onFocus()
    } else {
      handleOnBlur()
    }
  }, [isFocused])

  useEffect(() => {
    if (errorProps && ref.current) {
      ref.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      })
    }
  }, [errorProps])

  useEffect(() => {
    // TODO: rewrite
    const newDisabled = disabled ? true : undefined
    changeIsDisabled(newDisabled)
  }, [disabled])

  const handleUndoClick = () => {
    if (onUndoClick) {
      onUndoClick()
    }

    changeValue(defaultValue)
  }

  const isFieldIconVisible = iconName && value
  const isUndoVisible = isUndo && value !== undefined
  const isModalVisible = !isRegular && openModal

  return (
    <Column className={`${className}`}>
      <Row
        className={`${errorStyle} ${focusStyle} ${classes.container}`}
        justifyContent="flex-start"
        fullWidth
      >
        <input
          {...props}
          ref={ref}
          className={`${classes.input} ${classes.fullWidth} ${disabledInputStyle}`}
          placeholder={placeholder}
          value={value}
          disabled={isDisabled}
          autoComplete="off"
          onBlur={handleOnBlur}
          onFocus={onFocus}
          onChange={handleOnChange}
        />

        {labelProps && (
          <Text
            className={`${classes.floatingLabel} ${updatedFocusStyle} ${disabledStyle}`}
            preset={labelPreset}
            {...labelProps}
            onClick={handleOnClick}
          />
        )}

        {errorProps && (
          <Text
            preset="subTitleMini"
            className={classes.errorContainer}
            {...errorProps}
          />
        )}

        {statusIcon && (
          <Row className={classes.textIcon}>
            <Icon name={IconName.ALERT_CIRCLE} />
            {attentionTx && (
              <Hint
                className={classes.hint}
                tx={attentionTx}
                text={attentionText}
              />
            )}
          </Row>
        )}
        {isFieldIconVisible && (
          <Row className={classes.iconName} onClick={onIconClick}>
            {iconName && <Icon name={iconName} fill="darkBlue" />}
          </Row>
        )}

        {iconProps && (
          <Row className={classes.textIcon}>
            <Text preset="bodyBold" color="border" {...iconProps} />
          </Row>
        )}

        {isUndoVisible && (
          <Column className={classes.undoIcon} onClick={handleUndoClick}>
            <Icon name={IconName.UNDO} fill="purple" />
            <Text tx="reset.title" text="reset" color="purple" preset="title" />
          </Column>
        )}

        {isModalVisible && labelProps && (
          <InfoModal titleProps={labelProps} onClose={handleOnOpenModal(false)}>
            {description && <Text text={description} preset="body" />}
          </InfoModal>
        )}

        {children}
      </Row>
    </Column>
  )
}
