import { hasCoincidence } from '../helpers'
import {
  EvIncentiveIds,
  GetEvIncValuesFlagsProps,
  GetMsrpValuesFlagsProps
} from '../update-policy.types'

export const getMsrpValuesFlags = ({
  msrpCap
}: GetMsrpValuesFlagsProps): object => {
  const isMsrpVisible = msrpCap.usMsrpCapType !== 1
  const isIncomeVisible = msrpCap.usIncomeCapType === 2
  const isTieredVisible = msrpCap.usMsrpCapType === 3
  const isUsSoftCapVisible = isMsrpVisible || msrpCap.usIncomeCapType !== 1
  const isMagiVisible = msrpCap.usIncomeCapType === 3
  const isMagiPhase1Visible =
    msrpCap.magiPhaseOption && Number(msrpCap.magiPhaseOption) > 1
  const isMagiPhase2Visible = Number(msrpCap.magiPhaseOption) === 3
  const isAssUsApplyUsed = msrpCap.assUsType === 2 && msrpCap.applyCapsToUsed

  return {
    isMsrpVisible,
    isIncomeVisible,
    isTieredVisible,
    isUsSoftCapVisible,
    isMagiVisible,
    isMagiPhase1Visible,
    isMagiPhase2Visible,
    isAssUsApplyUsed
  }
}

export const getEvIncValuesFlags = ({
  dropdowns,
  evIncentive,
  handleOnGetCurrentDropdown
}: GetEvIncValuesFlagsProps): object => {
  const isKWh =
    evIncentive.tieredIncentive && evIncentive.tieredIncentiveType === 2
  const isMiles = evIncentive.tieredIncentive && !isKWh

  const isMaxShareAvailable = hasCoincidence({
    option: 'share',
    value: Number(evIncentive.scenario),
    dropdownId: EvIncentiveIds.DECLINE_RATE,
    getDropdown: handleOnGetCurrentDropdown
  })
  const isModelSplitVisible =
    evIncentive.lmBoolean && evIncentive.programUsedBoolean
  const isFplBonusNewExpansionVisible =
    evIncentive.fplBonusNewBoolean && isModelSplitVisible

  const isBonusDollar = evIncentive.lmBonusType === 2

  const isTieredInc = evIncentive.tieredIncentive
  const arrayLength = dropdowns.scenario.length

  const isTieredWithShift = hasCoincidence({
    option: 'tiered_with_shift',
    value: Number(evIncentive.scenario),
    dropdownId: EvIncentiveIds.DECLINE_RATE,
    getDropdown: handleOnGetCurrentDropdown
  })

  const isTieredWithShiftAlt = hasCoincidence({
    option: 'tiered_with_shift_alt',
    value: Number(evIncentive.scenario),
    dropdownId: EvIncentiveIds.DECLINE_RATE,
    getDropdown: handleOnGetCurrentDropdown
  })

  const isTieredWithShiftAltMarket = hasCoincidence({
    option: 'tiered_with_shift_alt_market_share',
    value: Number(evIncentive.scenario),
    dropdownId: EvIncentiveIds.DECLINE_RATE,
    getDropdown: handleOnGetCurrentDropdown
  })

  const isTieredIncAndTieredWithShiftAlt =
    evIncentive.tieredIncentive && isTieredWithShiftAlt
  const isLmDollar = isBonusDollar
  const isLmDollarAlt =
    isBonusDollar &&
    evIncentive.tieredIncentive &&
    (isTieredIncAndTieredWithShiftAlt || isTieredWithShiftAltMarket)
  const isFPLRate = evIncentive.lmBoolean && evIncentive.programUsedBoolean

  return {
    isKWh,
    isMiles,
    isMaxShareAvailable,
    isModelSplitVisible,
    isFplBonusNewExpansionVisible,
    isBonusDollar,
    isTieredInc,
    arrayLength,
    isTieredWithShift,
    isTieredWithShiftAlt,
    isTieredIncAndTieredWithShiftAlt,
    isLmDollar,
    isLmDollarAlt,
    isFPLRate
  }
}
