import { createUseStyles } from 'react-jss'
import { ToggleStyleProps } from '.'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'relative',
    width: 56,
    height: 32,
    borderRadius: '32px',
    backgroundColor: ({ value }: ToggleStyleProps) =>
      value ? theme.colors.orange : theme.colors.disabled
  },
  dot: {
    backgroundColor: theme.colors.white,
    borderRadius: 28,
    height: 28,
    left: ({ value }: ToggleStyleProps) => (value ? 'calc(100% - 30px)' : 2),
    position: 'absolute',
    top: 2,
    transition: 'left 0.25s ease-in-out',
    width: 28
  }
}))
