import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Policy } from '../../../services'
import { Content, Column, SliderMenu, Row, Text } from '../..'
import { FrameHTMLComponent } from '../../inner-html-component'
import { AdditionalGraphSettings } from '../additional-graph-settings'
import { EXTENEDED_PLOTS } from '../constants'
import { getIsIncludesSubstring, getLabelsPropsById, getPlot } from '../helpers'
import { PlotIdsValues, PlotSliderProps } from '../policy-summary-list.types'
import { useStyle } from './plot-slider.styles'

export const PlotSlider: FC<PlotSliderProps> = ({
  policy,
  policies,
  openedPlot = '',
  additional,
  stateFilter,
  reset,
  changeSelectedAddGraph,
  ...plotData
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const plotRef = useRef<HTMLDivElement>(null)
  const [data, changeData] = useState(plotData)
  const [plot, changePlot] = useState(plotData.plotId)
  const { plotId, iconName, iconColor } = data
  const [isOpen, changeOpen] = useState(false)
  const [selectedPolicy, changeSelectedPolicy] = useState<Policy>()

  const labelPropsById: any = useMemo(() => getLabelsPropsById(plotId), [
    plotId
  ])

  const handleOnPlotChange = useCallback(
    (id: PlotIdsValues) => {
      changePlot(id)

      if (changeSelectedAddGraph) {
        changeSelectedAddGraph(id)
      }
    },
    [changeSelectedAddGraph]
  )

  const settings: any = useMemo(
    () => ({
      cobenefits: <AdditionalGraphSettings onChange={handleOnPlotChange} />
    }),
    [handleOnPlotChange]
  )

  const handleOnClick = useCallback((state: boolean) => {
    changeOpen(state)
  }, [])

  const onPolicySelect = (idx: number) => () => {
    if (policies) {
      changeSelectedPolicy(policies[idx])
    }
  }

  useEffect(() => {
    const isOpened = getIsIncludesSubstring(plotId, openedPlot)
    const isPreviouslyOpened = isOpen || isOpened
    const isScrolledPlotOpened =
      plotRef.current &&
      openedPlot &&
      getIsIncludesSubstring(plotRef.current.id, openedPlot)

    if (openedPlot) {
      changeOpen(isPreviouslyOpened)
    }

    if (isScrolledPlotOpened && plotRef.current) {
      plotRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    if (reset) {
      reset()
    }
  }, [openedPlot])

  useEffect(() => {
    if (policies) {
      changeSelectedPolicy(policies[0])
    }
  }, [policies])

  useEffect(() => {
    const isExtended = EXTENEDED_PLOTS.includes(plotId)
    const currentPlot = getPlot({
      filter: stateFilter,
      additional
    })

    if (isExtended && currentPlot) {
      changeData(currentPlot)
      changePlot(currentPlot.plotId)
    }
  }, [stateFilter])

  return (
    <Column ref={plotRef} id={plot} className={classes.container}>
      <Content className={classes.noPadding}>
        <Row className={classes.plotSlider}>
          <SliderMenu
            {...labelPropsById}
            className={classes.container}
            containerClassName={classes.content}
            isOpen={isOpen}
            preset="h2"
            iconName={iconName}
            iconColor={iconColor}
            dropdownIconName="darkBlue"
            onClick={handleOnClick}
          >
            <Column className={classes.noPadding}>
              <Content className={classes.noPadding}>
                <Row
                  className={classes.tabs}
                  fullWidth
                  justifyContent="flex-start"
                >
                  {policies?.map((pol: Policy, idx: number) => {
                    const selected =
                      selectedPolicy && pol.id === selectedPolicy.id
                        ? classes.selected
                        : ''
                    return (
                      <Row
                        key={`tab-${pol.name}`}
                        className={`${classes.tab} ${selected}`}
                        onClick={onPolicySelect(idx)}
                      >
                        <Text text={pol.name} />
                      </Row>
                    )
                  })}
                </Row>
                <Row fullWidth justifyContent="flex-start">
                  {settings[plotId]}
                </Row>
                <Column className={`${classes.frame}`} justifyContent="center">
                  {selectedPolicy ? (
                    <FrameHTMLComponent
                      className={classes.frame}
                      url={selectedPolicy[plot]}
                    />
                  ) : (
                    policy && (
                      <FrameHTMLComponent
                        className={classes.frame}
                        url={policy[plot]}
                      />
                    )
                  )}
                </Column>
              </Content>
            </Column>
          </SliderMenu>
        </Row>
      </Content>
    </Column>
  )
}
