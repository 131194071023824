import { Policy, RegionProperties } from '../../services'
import { Color } from '../../theme'
import { PresetName } from '../button'
import { IconName } from '../icon'
import { ComponentTextProps, TextTranslate } from '../text'

export enum PlotIds {
  TOTAL_COSTS = 'totalCosts',
  GHG_PLOT = 'ghgPlot',
  EV_ADOPTION = 'evAdoption',
  EV_AFFORDABILITY = 'evAffordability',
  ADDITIONAL_GRAPH = 'additionalGraph',

  TOTAL_COSTS_US = 'totalCostsUs',
  TOTAL_COSTS_REGION = 'totalCostsRegion',
  EV_ADOPTION_US = 'evAdoptionUs',
  EV_ADOPTION_REGION = 'evAdoptionRegion',

  KWH_CONSUMPTION = 'kwhConsumption',
  EVI_INSTALLED = 'eviInstalled',
  EVI_COST_BY_TYPE = 'eviCostByType',
  COBENEFITS = 'cobenefits',
  JOBS_CREATED = 'jobsCreated',
  FLEET_SHARE = 'fleetShare',
  FLEET_AGE = 'fleetAge',
  ROI_OVER_TIME = 'roiOverTime',
  BONUS_FPL_DIFFUSION = 'bonusFplDiffusion',
  FPL_AND_NEW_VEHICLES = 'fplAndNewVehicles',
  FPL_PARTICIPATION = 'fplParticipation',
  USED_PLUS_NEW = 'usedPlusNew',
  ZEV_MANDATE = 'zevMandate',
  GHG_COMPARISON = 'ghgComparison',
  GASOLINE_CONSUMPTION = 'gasolineConsumption',
  GHG_REDUCTIONS = 'ghgReductions'
}
export interface SliderListProps {
  isLoaded: boolean
  policy?: Policy
  region?: RegionProperties
  affordabilityPlot?: string
  changeSelectedAddGraph: (id: PlotIdsValues) => void
}

export interface Graph {
  plotId: PlotIds
  plotName: PlotIds
}

export interface SliderProps {
  id: number
  policy?: Policy
  plotId: PlotIds
  plotName: PlotIds
  iconName: IconName
  iconColor: Color
  openedPlot?: string
}

export interface PlotProps extends SliderProps {
  additional?: SliderProps[]
}

export interface PlotSliderProps extends SliderProps {
  isLoaded?: boolean
  stateFilter: number
  additional?: SliderProps[]
  affordabilityPlot?: string
  policies?: Policy[]
  reset?: () => void
  changeSelectedAddGraph?: (id: PlotIdsValues) => void
}

export type PlotIdsValues = keyof Record<PlotIds, string>

export type PolicyPlotNamesProps = {
  [key: string]: TextTranslate
}

export type StateFiltersProps = {
  getPreset: (id: number) => keyof PresetName | undefined
  handleOnClick: any
  isUSRegion?: boolean
}

export type GetPlotProps = {
  filter: number
  additional?: SliderProps[]
}

export type GetLabelsProps = {
  [key in PlotIds]: ComponentTextProps
}

export type GetPresetProps = {
  id: number
  stateFilter: number
}
