import { createUseStyles } from 'react-jss'
import { DropdownProps, StyleProps } from '.'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: ({ disabled }: Partial<DropdownProps>) =>
      disabled ? 'default' : theme.cursor,
    height: ({ custom }: StyleProps) => (!custom ? 48 : custom.container),
    borderBottom: ({ open, isUndo }: StyleProps) =>
      open || isUndo
        ? `1px solid ${theme.colors.blue}`
        : `1px solid ${theme.colors.grey}`
  },
  label: {
    transformOrigin: '0 0',
    transform: ({ value }) =>
      value ? 'scale(0.9) translateY(-4px)' : 'scale(1)',
    transition: 'transform 0.25s ease-in-out',
    color: ({ open, isUndo }: StyleProps) =>
      open || isUndo ? theme.colors.blue : theme.colors.darkBlue,
    '&:hover': {
      color: ({ description }: StyleProps) =>
        !description ? theme.colors.darkBlue : theme.colors.orange,
      cursor: ({ description }: StyleProps) =>
        !description ? 'default' : 'help'
    }
  },
  disabled: {
    color: theme.colors.inactive
  },
  disabledLabel: {
    color: `${theme.colors.border}!important`
  },
  undoIcon: {
    position: 'absolute',
    right: -40,
    bottom: 10,
    cursor: theme.cursor,
    '& > *:not(:first-child)': {
      marginTop: 6
    }
  },
  error: {
    borderBottom: `1px solid ${theme.colors.red}!important`
  },
  textIcon: {
    position: 'absolute',
    color: theme.colors.border,
    padding: 0,
    right: 40,
    bottom: 10,
    cursor: theme.cursor,
    '&:hover > *': {
      visibility: 'visible'
    }
  }
}))
