import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  row: {
    position: 'relative',
    width: 'calc(100% - 64px)',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  container: {
    borderRadius: 12
  },
  content: {
    borderTop: `1px solid ${theme.colors.secondButton}`
  },
  subTitleContainer: {
    padding: '16px 0',
    width: 'calc(100% - 64px)'
  },
  horizontalSeparator: {
    borderBottom: `1px solid ${theme.colors.orange}`
  },
  subTitle: {
    width: '100%',
    color: theme.colors.inactive,
    borderBottom: `1px solid ${theme.colors.orange}`
  },
  noPadding: {
    padding: 0
  },
  slider: {
    borderRadius: '0 0 12px 12px'
  },
  cell: {
    maxWidth: 280,
    minWidth: 280,
    padding: '16px 0',
    '&:not(:first-child)': {
      marginLeft: 88
    },
    '&:nth-child(3n + 3) + &': {
      marginLeft: 0
    }
  },
  separator: {
    borderTop: `1px solid ${theme.colors.button}`
  },
  selectionControlsContainer: {
    height: 48,
    justifyContent: 'space-between'
  },
  selectionControls: {},
  combinedField: {
    alignItems: 'flex-end',
    '& > *:not(:first-child)': {
      marginLeft: 50
    },
    '& > *:last-child': {
      minWidth: 78,
      width: 78
    }
  },
  dropdown: {
    top: 0,
    width: '100%',
    overflow: 'auto',
    borderRadius: 0,
    borderBottom: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  border: {
    borderTop: `1px solid ${theme.colors.border}`
  },
  noBorderRadius: {
    borderRadius: 0
  },
  plotSlider: {
    padding: '8px 0'
  },
  frame: {
    width: 'calc(100% - 64px)',
    height: 492
  },
  switchers: {
    padding: '8px 0',
    '& > *:not(:first-child)': {
      marginLeft: 16
    }
  },
  switcher: {
    width: 'auto',
    padding: '0 16px'
  },
  customRadio: {
    marginLeft: 0,
    height: 'auto',
    '& > *:not(:first-child)': {
      marginLeft: 50
    }
  },
  responsiveRow: {},
  warning: {},
  '@media screen and (max-width: 1170px)': {
    responsiveRow: {
      justifyContent: 'flex-start',
      justifyItems: 'flex-start',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      listStyleType: 'none',
      columnGap: 30,
      rowGap: 0
    },
    cell: {
      '&:not(:first-child)': {
        marginLeft: 0
      }
    },
    row: {
      alignItems: 'flex-start'
    },
    warning: {
      top: '-26px'
    },
    frame: {
      width: '100%'
    }
  },
  '@media screen and (max-width: 800px)': {
    frame: {
      width: '130%',
      transform: 'scale(0.7)'
    }
  }
}))
