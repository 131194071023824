import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Button } from '../../button'
import { Row } from '../../row'
import { Text } from '../../text'
import { STATE_FILTERS } from '../constants'
import { StateFiltersProps } from '../policy-summary-list.types'
import { useStyle } from '../../update-policy/inputs.styles'

export const StateFilters = ({
  isUSRegion,
  getPreset,
  handleOnClick
}: StateFiltersProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return !isUSRegion ? (
    <Row justifyContent="space-between" fullWidth>
      <Row className={classes.switchers}>
        {STATE_FILTERS.map((filter) => (
          <Row key={`filter_${filter.id}`}>
            <Button
              className={classes.switcher}
              isSmall
              preset={getPreset(filter.id)}
              onClick={handleOnClick(filter.id)}
            >
              <Text {...filter.textProps} preset="body" />
            </Button>
          </Row>
        ))}
      </Row>
    </Row>
  ) : null
}
