import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { Column, Row } from '..'

import { LightTheme } from '../../theme'
import { SpinnerProps } from './spinner.types'
import { useStyle } from './spinner.styles'

export const Spinner: FC<SpinnerProps> = ({
  className = '',
  preset = 'medium',
  isLoading = true
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return isLoading ? (
    <Row className={`${className} ${classes[preset]}`}>
      <Column fullHeight fullWidth className={classes.circle}></Column>
    </Row>
  ) : null
}
