import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Row } from '../../row'
import { Icon, IconName } from '../../icon'
import { RightCaretProps } from './right-caret.types'
import { useStyle } from './right-caret.styles'

export const RightCaret = ({
  open,
  custom,
  iconName,
  fill,
  onClick
}: RightCaretProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ open, custom, theme })

  return (
    <Row onClick={onClick}>
      {iconName ? (
        <Icon
          className={classes.caret}
          name={iconName}
          fill={theme.colors.border}
        />
      ) : (
        <Icon
          className={`${classes.caret} ${classes.caretDown}`}
          name={IconName.CARET_DOWN}
          fill={fill}
        />
      )}
    </Row>
  )
}
