import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Extension: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 4.75a2.5 2.5 0 015 0h4c1.1 0 2 .9 2 2v4a2.5 2.5 0 010 5v4c0 1.1-.9 2-2 2h-3.8v-.3c0-1.49-1.21-2.7-2.7-2.7-1.49 0-2.7 1.21-2.7 2.7v.3h-3.8c-1.1 0-2-.9-2-2v-3.8h.3c1.49 0 2.7-1.21 2.7-2.7 0-1.49-1.21-2.7-2.7-2.7h-.29v-3.8c0-1.1.89-2 1.99-2h4zm3 0c0-.28-.22-.5-.5-.5s-.5.22-.5.5v2h-6l.01 2.12c1.75.68 2.99 2.39 2.99 4.38 0 1.99-1.25 3.7-3 4.38v2.12h2.12c.68-1.75 2.39-3 4.38-3 1.99 0 3.7 1.25 4.38 3h2.12v-6h2c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-2v-6h-6v-2z"
        fill={props.fill || theme.colors.red}
      />
    </svg>
  )
}
