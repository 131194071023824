import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const BreadCrumbs: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.28 16.53a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06l4-4a.75.75 0 111.06 1.06L8.81 12l3.47 3.47a.75.75 0 010 1.06z"
        fill={props.fill || theme.colors.hoverGrey}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.53 16.28a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06l4-4a.75.75 0 111.06 1.06l-3.47 3.47 3.47 3.47a.75.75 0 010 1.06z"
        fill={props.fill || theme.colors.hoverGrey}
      />
    </svg>
  )
}
