import { createUseStyles } from 'react-jss'
import { ColumnProps } from '.'

export const useStyle = createUseStyles({
  column: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: (props: ColumnProps) => props.alignItems || 'center',
    justifyContent: (props: ColumnProps) => props.justifyContent || 'center',
    height: ({ fullHeight }: ColumnProps) => (fullHeight ? '100%' : 'auto'),
    width: ({ fullWidth }: ColumnProps) => (fullWidth ? '100%' : 'auto')
  },
  fullFilledHeight: {
    height: '100vh'
  }
})
