import { CustomStyles } from '../../dropdown'

export const DROPDOWNS = {
  hhsize: [
    {
      id: 1,
      value: 1,
      name: '1',
      onClick: () => {}
    },
    {
      id: 2,
      value: 2,
      name: '2',
      onClick: () => {}
    },
    {
      id: 3,
      value: 3,
      name: '3',
      onClick: () => {}
    },
    {
      id: 4,
      value: 4,
      name: '4',
      onClick: () => {}
    }
  ],
  oneHundredStepValues: [
    {
      id: 100,
      value: 100,
      name: '100',
      onClick: () => {}
    },
    {
      id: 200,
      value: 200,
      name: '200',
      onClick: () => {}
    },
    {
      id: 300,
      value: 300,
      name: '300',
      onClick: () => {}
    },
    {
      id: 400,
      value: 400,
      name: '400',
      onClick: () => {}
    }
  ]
}

export const customStyles: CustomStyles = {
  container: 36,
  caret: 4,
  content: 37
}

export const BOOLEAN_WEIGHT: any = {
  true: 1,
  false: 2
}

export const NUMBER_VALUES_IDS = ['purchaseOrLease', 'newOrUsed']
