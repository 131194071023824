import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const TotalCost: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle
        opacity="0.2"
        cx="16"
        cy="16"
        r="16"
        fill={props.fill || theme.colors.orange}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.39 14.9C14.12 14.31 13.39 13.7 13.39 12.75C13.39 11.66 14.4 10.9 16.09 10.9C17.87 10.9 18.53 11.75 18.59 13H20.8C20.73 11.28 19.68 9.7 17.59 9.19V7H14.59V9.16C12.65 9.58 11.09 10.84 11.09 12.77C11.09 15.08 13 16.23 15.79 16.9C18.29 17.5 18.79 18.38 18.79 19.31C18.79 20 18.3 21.1 16.09 21.1C14.03 21.1 13.22 20.18 13.11 19H10.91C11.03 21.19 12.67 22.42 14.59 22.83V25H17.59V22.85C19.54 22.48 21.09 21.35 21.09 19.3C21.09 16.46 18.66 15.49 16.39 14.9Z"
        fill={props.fill || theme.colors.orange}
      />
    </svg>
  )
}
