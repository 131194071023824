import React, { FC, useEffect, useState, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'

import { LightTheme } from '../../../theme'
import {
  Content,
  Column,
  Input,
  Text,
  Dropdown,
  DropdownItemData,
  getDropdownData,
  getCustomSettings,
  getUpcomingSetting,
  getSetting,
  changeCurrentValues,
  getIsValid,
  getCurrentDropdown,
  getError,
  setError,
  getSliderId,
  RadioButtonGroup,
  getLabel,
  formatValue
} from '../..'
import { Policy, Restrictions } from '../../../services'
import { State } from '../../../store'
import { Row } from '../../row'
import { customStyles } from '../constants'
import {
  dollarIconProps,
  evsIconProps,
  publicDCFCProps,
  publicLevelProps,
  workLevelProps,
  yearsIconProps
} from '../label-props'
import {
  EviIncentiveValues,
  EviIncentiveIds,
  InputsPolicyProps,
  InputTypeValue
} from '../update-policy.types'
import { useStyle } from '../inputs.styles'

export const EVIIncentive: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { restrictions, catalog } = useSelector((state: State) => ({
    restrictions: state.policy.restrictions as Restrictions,
    catalog: state.policy.catalog
  }))

  const dropdowns = {
    eviScenario: getDropdownData('eviScenario', catalog)
  }

  const { customDefaults, settingsList, customSettings } = getCustomSettings({
    defaults,
    updates
  })
  const [settings, changeSettings] = useState(customSettings)
  const handleGetUpcomingSetting = useCallback(
    (id: EviIncentiveValues) => getUpcomingSetting(id, settingsList),
    [settingsList]
  )

  // start of manipulating with current settings
  const getEviIncentive = useCallback(
    (): Partial<Policy> => ({
      eviOverbuildRate: handleGetUpcomingSetting(
        EviIncentiveIds.OVER_BUILD_RATE
      ),
      eviScenario: handleGetUpcomingSetting(EviIncentiveIds.EVI_DECLINE_RATE),
      eviIncentiveYears: handleGetUpcomingSetting(
        EviIncentiveIds.EVI_INCENTIVE_DURATION
      ),
      startingWl2: handleGetUpcomingSetting(
        EviIncentiveIds.INCENTIVE_WORK_LEVEL
      ),
      workl2Rate: handleGetUpcomingSetting(EviIncentiveIds.RATE_WORK_LEVEL),
      startingPl2: handleGetUpcomingSetting(
        EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL
      ),
      publ2Rate: handleGetUpcomingSetting(EviIncentiveIds.RATE_PUBLIC_LEVEL),
      startingDc: handleGetUpcomingSetting(EviIncentiveIds.INCENTIVE_PUBLIC),
      pubdcRate: handleGetUpcomingSetting(EviIncentiveIds.RATE_PUBLIC),
      includeEviCosts: handleGetUpcomingSetting(
        EviIncentiveIds.INCLUDE_EVI_COSTS
      )
    }),
    [handleGetUpcomingSetting]
  )
  const fields = getEviIncentive()
  const [eviIncentive, changeEVIIncentive] = useState(fields)

  useEffect(() => {
    const { customSettings: s } = getCustomSettings({
      defaults,
      updates
    })
    changeEVIIncentive(getEviIncentive())
    changeSettings(s)
  }, [defaults, updates])
  // eof of manipulating with current settings

  // METHODS
  const getIsExist = (id: EviIncentiveValues) => eviIncentive[id] !== undefined
  const getIsDefault = (id: EviIncentiveValues) =>
    eviIncentive[id] !== getSetting(id, customDefaults)?.value

  const handleOnChange = (id: EviIncentiveValues, flag: InputTypeValue) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const value = formatValue(target.value, flag)
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeEVIIncentive({
      ...eviIncentive,
      [id]: value
    })
    changeSettings(updSettings)
    onChange({ [id]: value })
  }

  const handleOnBlur = (id: EviIncentiveValues) => (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const { isValid, rule } = getIsValid({ id, value, restrictions })
    const sliderId: any = getSliderId(id)

    setError({
      id,
      isValid,
      rule,
      sliderId,
      onError
    })
  }

  const handleOnDropdownChange = (id: EviIncentiveValues) => (
    value: DropdownItemData
  ) => {
    const updSettings = changeCurrentValues({
      id,
      isOverrided: true,
      settings
    })
    changeEVIIncentive({
      ...eviIncentive,
      [id]: Number(value.id)
    })
    changeSettings(updSettings)
    onChange({ [id]: Number(value.id) })
  }

  const handleUndoClick = (id: EviIncentiveValues) => () => {
    if (customDefaults.length) {
      const setting = getSetting(id, customDefaults)
      const updSettings = changeCurrentValues({
        id,
        isOverrided: false,
        settings
      })
      const sliderId: any = getSliderId(id)
      const startValue = !setting ? undefined : setting.value

      changeEVIIncentive({
        ...eviIncentive,
        [id]: startValue
      })
      changeSettings(updSettings)
      onChange({ [id]: startValue })
      setError({
        id,
        isValid: true,
        rule: {},
        sliderId,
        onError
      })
    }
  }

  const handleOnRadioChange = (id: string) => (status: boolean) => {
    changeEVIIncentive({
      ...eviIncentive,
      [id]: status
    })
    onChange({ [id]: status })
  }

  const handleOnGetCurrentDropdown = (value: number, id: string) =>
    getCurrentDropdown({
      dropdowns,
      value,
      id
    })
  const handleOnGetError = (id: EviIncentiveValues) => getError(id, error)
  const getEmptyDropdownStyle = (id: EviIncentiveIds) =>
    getIsExist(id) ? undefined : customStyles
  // eof METHODS

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Column
            className={`${classes.cell} ${classes.selectionControlsContainer}`}
            alignItems="flex-start"
          >
            <RadioButtonGroup
              {...getLabel(EviIncentiveIds.INCLUDE_EVI_COSTS, restrictions)}
              value={eviIncentive.includeEviCosts}
              description={
                restrictions[EviIncentiveIds.INCLUDE_EVI_COSTS]?.description
              }
              disabled={!getIsExist(EviIncentiveIds.INCLUDE_EVI_COSTS)}
              isUndo={getIsDefault(EviIncentiveIds.INCLUDE_EVI_COSTS)}
              onChange={handleOnRadioChange(EviIncentiveIds.INCLUDE_EVI_COSTS)}
              onUndoClick={handleUndoClick(EviIncentiveIds.INCLUDE_EVI_COSTS)}
            />
          </Column>
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EviIncentiveIds.OVER_BUILD_RATE}
            labelProps={getLabel(EviIncentiveIds.OVER_BUILD_RATE, restrictions)}
            defaultValue={eviIncentive.eviOverbuildRate}
            isFocused={getIsExist(EviIncentiveIds.OVER_BUILD_RATE)}
            description={
              restrictions[EviIncentiveIds.OVER_BUILD_RATE]?.description
            }
            disabled={!getIsExist(EviIncentiveIds.OVER_BUILD_RATE)}
            isUndo={getIsDefault(EviIncentiveIds.OVER_BUILD_RATE)}
            errorProps={handleOnGetError(EviIncentiveIds.OVER_BUILD_RATE)}
            onBlur={handleOnBlur(EviIncentiveIds.OVER_BUILD_RATE)}
            onChange={handleOnChange(
              EviIncentiveIds.OVER_BUILD_RATE,
              InputTypeValue.PERCENT
            )}
            onUndoClick={handleUndoClick(EviIncentiveIds.OVER_BUILD_RATE)}
          />
          <Row className={classes.cell}>
            <Dropdown
              className={classes.dropdown}
              custom={getEmptyDropdownStyle(EviIncentiveIds.EVI_DECLINE_RATE)}
              label={
                getLabel(EviIncentiveIds.EVI_DECLINE_RATE, restrictions).text
              }
              data={dropdowns.eviScenario}
              defaultValue={handleOnGetCurrentDropdown(
                Number(eviIncentive.eviScenario),
                'eviScenario'
              )}
              description={
                restrictions[EviIncentiveIds.EVI_DECLINE_RATE]?.description
              }
              preset="body"
              disabled={!eviIncentive.eviScenario}
              isUndo={getIsDefault(EviIncentiveIds.EVI_DECLINE_RATE)}
              onChange={handleOnDropdownChange(
                EviIncentiveIds.EVI_DECLINE_RATE
              )}
              onUndoClick={handleUndoClick(EviIncentiveIds.EVI_DECLINE_RATE)}
            />
          </Row>
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EviIncentiveIds.EVI_INCENTIVE_DURATION}
            labelProps={getLabel(
              EviIncentiveIds.EVI_INCENTIVE_DURATION,
              restrictions
            )}
            isYears
            iconProps={yearsIconProps}
            defaultValue={eviIncentive.eviIncentiveYears}
            isFocused={getIsExist(EviIncentiveIds.EVI_INCENTIVE_DURATION)}
            description={
              restrictions[EviIncentiveIds.EVI_INCENTIVE_DURATION]?.description
            }
            disabled={!getIsExist(EviIncentiveIds.EVI_INCENTIVE_DURATION)}
            isUndo={getIsDefault(EviIncentiveIds.EVI_INCENTIVE_DURATION)}
            errorProps={handleOnGetError(
              EviIncentiveIds.EVI_INCENTIVE_DURATION
            )}
            onBlur={handleOnBlur(EviIncentiveIds.EVI_INCENTIVE_DURATION)}
            onChange={handleOnChange(
              EviIncentiveIds.EVI_INCENTIVE_DURATION,
              InputTypeValue.YEARS
            )}
            onUndoClick={handleUndoClick(
              EviIncentiveIds.EVI_INCENTIVE_DURATION
            )}
          />
        </Row>

        {/* Work Level 2 */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text {...workLevelProps} className={classes.subTitle} preset="h3" />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EviIncentiveIds.INCENTIVE_WORK_LEVEL}
            labelProps={getLabel(
              EviIncentiveIds.INCENTIVE_WORK_LEVEL,
              restrictions
            )}
            defaultValue={eviIncentive.startingWl2}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(EviIncentiveIds.INCENTIVE_WORK_LEVEL)}
            description={
              restrictions[EviIncentiveIds.INCENTIVE_WORK_LEVEL]?.description
            }
            disabled={!getIsExist(EviIncentiveIds.INCENTIVE_WORK_LEVEL)}
            isUndo={getIsDefault(EviIncentiveIds.INCENTIVE_WORK_LEVEL)}
            errorProps={handleOnGetError(EviIncentiveIds.INCENTIVE_WORK_LEVEL)}
            onBlur={handleOnBlur(EviIncentiveIds.INCENTIVE_WORK_LEVEL)}
            onChange={handleOnChange(
              EviIncentiveIds.INCENTIVE_WORK_LEVEL,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(EviIncentiveIds.INCENTIVE_WORK_LEVEL)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EviIncentiveIds.RATE_WORK_LEVEL}
            labelProps={getLabel(EviIncentiveIds.RATE_WORK_LEVEL, restrictions)}
            isEvs
            defaultValue={eviIncentive.workl2Rate}
            iconProps={evsIconProps}
            isFocused={getIsExist(EviIncentiveIds.RATE_WORK_LEVEL)}
            description={
              restrictions[EviIncentiveIds.RATE_WORK_LEVEL]?.description
            }
            disabled={!getIsExist(EviIncentiveIds.RATE_WORK_LEVEL)}
            isUndo={getIsDefault(EviIncentiveIds.RATE_WORK_LEVEL)}
            errorProps={handleOnGetError(EviIncentiveIds.RATE_WORK_LEVEL)}
            onBlur={handleOnBlur(EviIncentiveIds.RATE_WORK_LEVEL)}
            onChange={handleOnChange(
              EviIncentiveIds.RATE_WORK_LEVEL,
              InputTypeValue.EVS
            )}
            onUndoClick={handleUndoClick(EviIncentiveIds.RATE_WORK_LEVEL)}
          />
        </Row>

        {/* Public Level 2 */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text
            {...publicLevelProps}
            className={classes.subTitle}
            preset="h3"
          />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL}
            labelProps={getLabel(
              EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL,
              restrictions
            )}
            defaultValue={eviIncentive.startingPl2}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL)}
            description={
              restrictions[EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL]?.description
            }
            disabled={!getIsExist(EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL)}
            isUndo={getIsDefault(EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL)}
            errorProps={handleOnGetError(
              EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL
            )}
            onBlur={handleOnBlur(EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL)}
            onChange={handleOnChange(
              EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(
              EviIncentiveIds.INCENTIVE_PUBLIC_LEVEL
            )}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EviIncentiveIds.RATE_PUBLIC_LEVEL}
            labelProps={getLabel(
              EviIncentiveIds.RATE_PUBLIC_LEVEL,
              restrictions
            )}
            isEvs
            defaultValue={eviIncentive.publ2Rate}
            iconProps={evsIconProps}
            isFocused={getIsExist(EviIncentiveIds.RATE_PUBLIC_LEVEL)}
            description={
              restrictions[EviIncentiveIds.RATE_PUBLIC_LEVEL]?.description
            }
            disabled={!getIsExist(EviIncentiveIds.RATE_PUBLIC_LEVEL)}
            isUndo={getIsDefault(EviIncentiveIds.RATE_PUBLIC_LEVEL)}
            errorProps={handleOnGetError(EviIncentiveIds.RATE_PUBLIC_LEVEL)}
            onBlur={handleOnBlur(EviIncentiveIds.RATE_PUBLIC_LEVEL)}
            onChange={handleOnChange(
              EviIncentiveIds.RATE_PUBLIC_LEVEL,
              InputTypeValue.EVS
            )}
            onUndoClick={handleUndoClick(EviIncentiveIds.RATE_PUBLIC_LEVEL)}
          />
        </Row>

        {/* Public DCFC */}
        <Row className={classes.subTitleContainer} alignItems="flex-start">
          <Text {...publicDCFCProps} className={classes.subTitle} preset="h3" />
        </Row>
        <Row
          className={`${classes.row} ${classes.responsiveRow}`}
          justifyContent="flex-start"
        >
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EviIncentiveIds.INCENTIVE_PUBLIC}
            labelProps={getLabel(
              EviIncentiveIds.INCENTIVE_PUBLIC,
              restrictions
            )}
            defaultValue={eviIncentive.startingDc}
            isDollar
            iconProps={dollarIconProps}
            isFocused={getIsExist(EviIncentiveIds.INCENTIVE_PUBLIC)}
            description={
              restrictions[EviIncentiveIds.INCENTIVE_PUBLIC]?.description
            }
            disabled={!getIsExist(EviIncentiveIds.INCENTIVE_PUBLIC)}
            isUndo={getIsDefault(EviIncentiveIds.INCENTIVE_PUBLIC)}
            errorProps={handleOnGetError(EviIncentiveIds.INCENTIVE_PUBLIC)}
            onBlur={handleOnBlur(EviIncentiveIds.INCENTIVE_PUBLIC)}
            onChange={handleOnChange(
              EviIncentiveIds.INCENTIVE_PUBLIC,
              InputTypeValue.DOLLAR
            )}
            onUndoClick={handleUndoClick(EviIncentiveIds.INCENTIVE_PUBLIC)}
          />
          <Input
            alignItems="flex-start"
            className={classes.cell}
            id={EviIncentiveIds.RATE_PUBLIC}
            labelProps={getLabel(EviIncentiveIds.RATE_PUBLIC, restrictions)}
            isEvs
            defaultValue={eviIncentive.pubdcRate}
            iconProps={evsIconProps}
            isFocused={getIsExist(EviIncentiveIds.RATE_PUBLIC)}
            description={restrictions[EviIncentiveIds.RATE_PUBLIC]?.description}
            disabled={!getIsExist(EviIncentiveIds.RATE_PUBLIC)}
            isUndo={getIsDefault(EviIncentiveIds.RATE_PUBLIC)}
            errorProps={handleOnGetError(EviIncentiveIds.RATE_PUBLIC)}
            onBlur={handleOnBlur(EviIncentiveIds.RATE_PUBLIC)}
            onChange={handleOnChange(
              EviIncentiveIds.RATE_PUBLIC,
              InputTypeValue.EVS
            )}
            onUndoClick={handleUndoClick(EviIncentiveIds.RATE_PUBLIC)}
          />
        </Row>
      </Content>
    </Column>
  )
}
