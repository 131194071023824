import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { CardListItemStyleProps } from '.'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 16,
    opacity: ({ disabled }: CardListItemStyleProps) => (disabled ? 0.6 : 1),
    width: 260,
    cursor: theme.cursor,
    boxShadow: '2px 4px 6px 0px #0000000f',
    '&:hover': {
      boxShadow: '2px 4px 8px #00000029'
    }
  },
  header: {
    backgroundColor: theme.colors.tableRow,
    borderBottom: `1px solid ${theme.colors.grey}`,
    padding: 16,
    width: 'calc(100% - 32px)',
    borderRadius: '16px 16px 0 0'
  },
  info: {
    padding: '8px 16px',
    width: 'calc(100% - 32px)',
    backgroundColor: theme.colors.white,
    borderRadius: '0 0 16px 16px'
  },
  name: {
    height: 42,
    marginTop: 8
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: theme.cursor
    }
  },
  field: {
    marginLeft: 16
  },
  column: {
    padding: '6px 0'
  },
  text: {
    cursor: theme.cursor,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'break-spaces'
  },
  sub: {
    marginTop: 2
  },
  flex: {
    display: 'flex'
  }
}))
