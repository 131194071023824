import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  back: {
    marginTop: 12
  },
  row: {
    padding: '0 28px'
  },
  column: {
    padding: 0,
    '& > *': {
      marginTop: 16
    }
  },
  content: {
    padding: '0 0 24px 0',
    '& > *': {
      marginTop: 16
    }
  },
  processBtns: {
    marginLeft: 32
  },
  info: {
    '& > *:not(:first-child)': {
      marginLeft: 8
    },
    '& > *': {
      textDecoration: 'none'
    }
  },
  fullFilledHeight: {
    height: '100vh'
  },
  btn: {
    width: 180,
    textDecoration: 'none'
  },
  description: {
    padding: '0 16px'
  },
  inputsList: {
    padding: '16px 0',
    '& > *:not(:first-child)': {
      marginTop: 8
    }
  },
  inputItem: {
    marginLeft: 12
  },
  editIcon: {
    cursor: theme.cursor
  },
  disabled: {
    position: 'absolute',
    clip: '-moz-initial',
    width: 1,
    height: 1,
    margin: 0,
    overflow: 'hidden'
  },
  modal: {
    backgroundColor: theme.colors.inactive,
    position: 'fixed',
    top: 0,
    left: '100%',
    right: '100%',
    bottom: 0,
    overflowY: 'scroll',
    zIndex: 10
  },
  modalAbsolute: {
    backgroundColor: theme.colors.inactive,
    position: 'absolute',
    top: 0,
    left: '100%',
    right: '100%',
    bottom: 0,
    overflowY: 'scroll',
    zIndex: 10
  },
  modalContainer: {},
  sarModal: {
    padding: '0 16px',
    '& > *:not(:first-child)': {
      marginTop: 16
    }
  },
  gradient: {
    position: 'absolute',
    bottom: '-30px',
    height: 32,
    background:
      'linear-gradient(180deg, #F5F6F6 0%, rgba(245, 246, 246, 0) 100%)',
    zIndex: 2
  },
  scrollContainer: {
    height: '100%'
  },
  inputsTitle: {
    marginTop: 12
  },
  loader: {
    marginTop: 24
  },
  spinnerContainer: {
    width: 40,
    height: 40
  },
  replyAll: {
    marginLeft: 8
  },
  revert: {
    cursor: theme.cursor,
    '& > *': {
      cursor: theme.cursor
    }
  },
  errorContainer: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 10
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.grey
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb &:hover': {
      background: theme.colors.hoverGrey
    }
  },
  controls: {},
  sticky: {
    position: 'fixed',
    top: 0,
    zIndex: 2,
    background: theme.colors.lightBackground
  },
  '@media screen and (max-width: 1170px)': {
    controls: {
      '& > *:not(:first-child)': {
        marginTop: 16
      }
    }
  },
  '@media screen and (max-width: 800px)': {
    controls: {
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginTop: 16
      }
    }
  }
}))
