import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%'
  },
  spinner: {
    position: 'absolute',
    height: 504
  },
  loadMore: {
    marginTop: 16,
    padding: '8px 0'
  },
  bottomGradient: {
    position: 'absolute',
    bottom: 16,
    height: 24,
    background:
      'linear-gradient(-180deg, #F5F6F6 0%, rgba(245, 246, 246, 0) 100%)',
    zIndex: 2
  },
  goUp: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    backgroundColor: theme.colors.headerGradient,
    borderRadius: '50%',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    zIndex: 3,
    '& > *': {
      transform: 'rotate(-90deg)'
    }
  }
}))
