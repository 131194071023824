import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Column } from '../column'
import { Text } from '../text'
import { Hint } from '../hint'
import { InfoModal } from '../info-modal'
import { CheckboxProps } from './checkbox.types'
import { useStyle } from './checkbox.styles'

export const Checkbox: FC<CheckboxProps> = ({
  className = '',
  id,
  defaultChecked,
  value,
  preset,
  statusIcon = false,
  disabled = false,
  isUndo = false,
  tx,
  text = '',
  attentionTx,
  attentionText = '',
  description = '',
  onChange,
  onBlur,
  onUndoClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, description })
  const [checked, changeChecked] = useState(defaultChecked)
  const [openModal, changeOpenModal] = useState(false)

  const icon = checked ? IconName.CHECK_BOX_ACTIVE : IconName.CHECK_BOX_INACTIVE
  const iconColor = isUndo ? theme.colors.blue : theme.colors.darkBlue

  const defDisabled = disabled ? 'disabled' : undefined
  const [isDisabled, changeIsDisabled] = useState(defDisabled)
  const disabledStyle = isDisabled ? classes.disabled : ''
  const disabledIconColor = isDisabled ? theme.colors.inactive : iconColor

  useEffect(() => {
    changeChecked(defaultChecked)
  }, [defaultChecked])

  useEffect(() => {
    const updatedStatus = disabled ? 'disabled' : undefined
    changeIsDisabled(updatedStatus)
  }, [disabled])

  const handleOnChange = () => {
    if (!isDisabled) {
      const newStatus = !checked
      changeChecked(newStatus)

      if (onChange) {
        onChange(newStatus)
      }
    }
  }

  const handleOnBlur = () => {
    if (onBlur) {
      onBlur(checked)
    }
  }

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    [openModal]
  )

  const handleOnClick = () =>
    description ? handleOnOpenModal(true)() : undefined

  return (
    <Row id={id} className={className} justifyContent="flex-start">
      <Icon
        name={icon}
        fill={disabledIconColor}
        onClick={handleOnChange}
        onMouseLeave={handleOnBlur}
      />
      {value && (
        <Text
          className={`${classes.value} ${disabledStyle}`}
          color="white"
          preset="body"
          text={value}
        />
      )}
      {text && (
        <Text
          text={text}
          tx={tx}
          preset={preset}
          className={classes.text}
          onClick={handleOnClick}
        />
      )}

      {isUndo && (
        <Column className={classes.undoIcon} onClick={onUndoClick}>
          <Icon name={IconName.UNDO} fill="purple" />
          <Text tx="reset.title" text="reset" color="purple" preset="title" />
        </Column>
      )}

      {statusIcon && (
        <Row className={classes.textIcon}>
          <Icon name={IconName.ALERT_CIRCLE} />
          {attentionTx && (
            <Hint
              className={classes.hint}
              preset="smallSubTitleSetting"
              tx={attentionTx}
              text={attentionText}
            />
          )}
        </Row>
      )}

      {openModal && (
        <InfoModal
          titleProps={{
            text,
            tx
          }}
          onClose={handleOnOpenModal(false)}
        >
          {description && <Text text={description} preset="body" />}
        </InfoModal>
      )}
    </Row>
  )
}
