import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { DropdownItemsStyleProps } from '..'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  itemsContainer: {
    borderRadius: 12,
    position: 'absolute',
    zIndex: 1,
    overflow: 'hidden',
    border: `2px solid ${theme.colors.background}`,
    boxShadow: '2px 4px 8px #00000014'
  },
  left: {
    top: 0,
    right: ({ width }: DropdownItemsStyleProps) => width
  },
  right: {
    top: 0,
    left: ({ width }: DropdownItemsStyleProps) => width
  },
  bottom: {
    top: ({ height }: DropdownItemsStyleProps) => height
  }
}))
