import React, { FC, useState, useEffect, useCallback } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import {
  Content,
  IconName,
  Column,
  PolicySettings,
  SliderMenu,
  InputsPolicyProps,
  VALUES_BY_BLOCKS,
  SLIDER_VALUES,
  ActionTypeName,
  ObjectProps
} from '../..'
import { SliderHeader } from '../../slider-header'
import { ReductionCharacteristics } from './reduction-characteristicts'
import { VehicleFleetCharacteristics } from './vehicle-fleet-characteristics'
import { ModelCharacteristics } from './model-characteristics'
import { AdditionalGraphParameters } from './additional-graph-parameters'
import {
  generalInputsAdvancedProps,
  ghgReductionCharacteristicsProps,
  vehicleFleetCharactProps
} from '../label-props'
import { useStyle } from '../inputs.styles'

// TODO: remove Affordability comp when add inputs to Affordability plot
const data: PolicySettings = {
  id: '1',
  groupId: 'advanced',
  ...generalInputsAdvancedProps,
  types: [
    {
      ...vehicleFleetCharactProps,
      sliderId: 'vehicleFleetCharacteristics',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onSetToBAU,
        onError
      }) => (
        <VehicleFleetCharacteristics
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onSetToBAU={onSetToBAU}
          onError={onError}
        />
      )
    },
    {
      ...ghgReductionCharacteristicsProps,
      sliderId: 'reductionCharacteristics',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }: InputsPolicyProps) => (
        <ReductionCharacteristics
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      )
    },
    {
      tx: 'policy.modelCharacteristics',
      text: 'Model Characteristics',
      sliderId: 'modelCharacteristics',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }) => (
        <ModelCharacteristics
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      )
    },
    {
      tx: 'policy.additionalGraphParams',
      text: 'Additional Graph Parameters',
      sliderId: 'additionalGraphParameters',
      comp: ({
        defaults,
        updates,
        error,
        defaultPolicy,
        onChange,
        onError
      }) => (
        <AdditionalGraphParameters
          defaults={defaults}
          updates={updates}
          error={error}
          defaultPolicy={defaultPolicy}
          onChange={onChange}
          onError={onError}
        />
      ),
      warningTextProps: {
        text:
          'This input relates only to respective additional graphs. The model calculation main output will not be impacted.',
        tx: 'policy.addGraph.warning'
      }
    }
  ]
}

export const GeneralInputsAdvanced: FC<InputsPolicyProps> = ({
  defaults,
  updates,
  error,
  defaultPolicy,
  onSetToBAU,
  onChange,
  onError
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [isRevert, changeIsRevert] = useState(false)
  const [slidersStates, changeSlidersState] = useState<ObjectProps>({
    vehicleFleetCharacteristics: false,
    reductionCharacteristics: false,
    modelCharacteristics: false,
    additionalGraphParameters: false
  })

  const settingsLength = data.types.length
  const getLastSliderClass = (key: number) => {
    const isLast = key === settingsLength - 1
    return isLast ? classes.slider : ''
  }
  const [isOpen, changeIsOpen] = useState(false)

  const revert = useCallback(
    (array: string[]) => {
      const idsToRevert: any = {}
      array.forEach((id: any) => {
        const isKeyExist = id in updates
        const isValueUpdated =
          defaultPolicy && Object.keys(updates).length && isKeyExist
        if (isValueUpdated) {
          idsToRevert[id] = defaultPolicy[id]
        }
      })
      const isIdsExist = Object.keys(idsToRevert).length
      if (isIdsExist) {
        onChange(idsToRevert, ActionTypeName.REVERT)
      }
    },
    [defaultPolicy, updates, onChange]
  )

  const handleOnRevert = useCallback(() => {
    revert(VALUES_BY_BLOCKS.generalAdvanced)
  }, [revert])

  const handleOnRevertPartly = useCallback(
    (sliderId: string) => () => {
      revert(SLIDER_VALUES[sliderId])
    },
    [revert]
  )

  const getIsRevertPartly = useCallback(
    (sliderId: string): boolean => {
      const isUpdated = SLIDER_VALUES[sliderId].find((id: string) =>
        Object.keys(updates).includes(id)
      )
      return Boolean(isUpdated)
    },
    [updates]
  )

  const handleOnStateChange = (state: boolean) => {
    if (!state && error) {
      const { id: errorId } = error
      onError({ errorId, reset: true })
    }
  }

  const updateSliderState = (sliderId: string, value: boolean) => {
    changeSlidersState({ ...slidersStates, [sliderId]: value })
  }

  const handleOnClick = () => {
    const state = !isOpen
    changeIsOpen(state)
    changeSlidersState({
      vehicleFleetCharacteristics: state,
      reductionCharacteristics: state,
      modelCharacteristics: state,
      additionalGraphParameters: state
    })
  }

  useEffect(() => {
    if (defaults) {
      const isUpdated = VALUES_BY_BLOCKS.generalAdvanced.find((id: string) =>
        Object.keys(updates).includes(id)
      )

      changeIsRevert(Boolean(isUpdated))
    }
  }, [defaultPolicy, updates, defaults])

  useEffect(() => {
    const isAllOpened = Object.values(slidersStates).every((el: boolean) => el)

    changeIsOpen(isAllOpened)
  }, [slidersStates])

  useEffect(() => {
    const sliders: ObjectProps = {}
    data.types.forEach((type) => {
      const { sliderId } = type
      const isOpened = error
        ? error.sliderId === sliderId
        : slidersStates[sliderId]

      sliders[sliderId] = isOpened
    })
    changeSlidersState(sliders)
  }, [error])

  return (
    <Column className={classes.noPadding}>
      <Content className={classes.noPadding}>
        <SliderHeader
          text={data.text}
          tx={data.tx}
          iconName={IconName.EXTENSION}
          iconFill="orange"
          isButtonClicked={isOpen}
          isRevert={isRevert}
          handleRevert={handleOnRevert}
          onClick={handleOnClick}
        />

        {data.types.map((type, idx) => {
          const Component = type.comp as React.FC<InputsPolicyProps>
          const { sliderId } = type

          return (
            <SliderMenu
              key={type.text}
              isOpen={slidersStates[sliderId]}
              className={`${classes.border} ${getLastSliderClass(idx)}`}
              text={type.text}
              tx={type.tx}
              warningTextProps={type.warningTextProps}
              dropdownIconName="darkBlue"
              sliderId={type.sliderId}
              isRevert={getIsRevertPartly(sliderId)}
              onRevert={handleOnRevertPartly(type.sliderId)}
              onClick={handleOnStateChange}
              onStateChange={updateSliderState}
            >
              <Component
                defaults={defaults}
                updates={updates}
                error={error}
                defaultPolicy={defaultPolicy}
                onSetToBAU={onSetToBAU}
                onChange={onChange}
                onError={onError}
              />
            </SliderMenu>
          )
        })}
      </Content>
    </Column>
  )
}
