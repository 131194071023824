import { Policy } from '../../services'
import { CustomStyles } from '../dropdown'
import {
  AdditionalGraphParametersIds,
  CommercialFleetIncentiveIds,
  EvIncentiveIds,
  EvIncentiveRegularIds,
  ModelCharacteristicsIds,
  MsrpCapIds,
  RegionalMsrpCapIds,
  RegulatoryPoliciesIds,
  SliderValuesProps,
  VehicleFleetCharacteristicsIds
} from './update-policy.types'

export const customStyles: CustomStyles = {
  container: 40,
  caret: 0,
  content: 37
}

export const BOOLEAN_WEIGHT: any = {
  true: 1,
  false: 2
}

export const NUMBER_VALUES_IDS = [
  'tieredIncentiveType',
  'lmBonusType',
  'fplParticipationType',
  'regionTieredIncentiveType',
  'fplAnnualCumulative',
  'usedEvType',
  'assUsType',
  'excludeEvCafe',
  'secondSalesType',
  'magiCapUsedOption'
]

export const VALUES_TO_DELETE: Array<keyof Policy> = [
  'updatedAt',
  'createdAt',
  'totalCosts',
  'ghgPlot',
  'evAffordability',
  'evAdoption',
  'additionalGraph',
  'cobenefits',
  'eviCostByType',
  'eviInstalled',
  'fleetShare',
  'jobsCreated',
  'kwhConsumption',
  'totalCostsUs',
  'totalCostsRegion',
  'evAdoptionUs',
  'evAdoptionRegion',
  'bonusFplDiffusion',
  'fleetAge',
  'roiOverTime',
  'fplAndNewVehicles',
  'fplParticipation',
  'usedPlusNew',
  'zevMandate',
  'totalCostsPng',
  'ghgPlotPng',
  'evAdoptionPng',
  'evAffordabilityPng',
  'kwhConsumptionPng',
  'eviInstalledPng',
  'eviCostByTypePng',
  'cobenefitsPng',
  'jobsCreatedPng',
  'fleetSharePng',
  'fleetAgePng',
  'roiOverTimePng',
  'bonusFplDiffusionPng',
  'fplAndNewVehiclesPng',
  'fplParticipationPng',
  'usedPlusNewPng',
  'zevMandatePng',
  'ghgComparison',
  'ghgComparisonPng',
  'gasolineConsumption',
  'gasolineConsumptionPng',
  'ghgReductions',
  'ghgReductionsPng',
  'pdfReport'
]

export const allowedNationalDeclineRateOptions = [
  'exponential',
  'linear',
  'sigmoidal',
  'tiered'
]

export const allowedRegionalDeclineRateOptions = [
  'exponential',
  'linear',
  'sigmoidal'
]

export const INCENTIVES_RADIO_IDS = [
  ModelCharacteristicsIds.CONSUMER_DISCOUNT_RATE,
  ModelCharacteristicsIds.TAX_CREDIT_NEW_EV_INC,
  ModelCharacteristicsIds.TAX_CREDIT_DOMESTIC
]

export const FLEET_AGE_YEAR_ALL_RADIO_IDS = [
  AdditionalGraphParametersIds.FLEET_AGE_YEAR
]

export const FLEET_EVI_INCENTIVE_RADIO_IDS = [
  CommercialFleetIncentiveIds.DURATION,
  CommercialFleetIncentiveIds.VEHICLES_EXCLUDED,
  CommercialFleetIncentiveIds.EVI_INCENTIVE
]

export const EXCLUDE_EV_CAFE_RADIO_IDS = [
  VehicleFleetCharacteristicsIds.PHEV_LAST_YEAR
]

export const OVERRIDE_RETIREMENT_RADIO_IDS = [
  VehicleFleetCharacteristicsIds.FLEET_GROWTH_RATE
]

export const ICEV_BAN_BOOLEAN_RADIO_IDS = [
  RegulatoryPoliciesIds.ICEV_BAN_YEAR,
  RegulatoryPoliciesIds.OEM_EFFECTIVE_THRESHOLD
]

export const TIERED_INCENTIVE_RADIO_IDS = [
  EvIncentiveIds.MAXIMUM_INCENTIVE,
  EvIncentiveIds.TIERED_INCENTIVE_TYPE,

  EvIncentiveIds.TIERED_P1_YEARS,
  EvIncentiveIds.P2_MIN_TIER_KWH,
  EvIncentiveIds.P2_MIN_TIER_INC_KWH,
  EvIncentiveIds.P2_STEP_TIER_KWH,
  EvIncentiveIds.P2_STEP_TIER_INC_KWH,
  EvIncentiveIds.P2_MAX_TIER_INC,

  EvIncentiveIds.TIERED_P2_YEARS,
  EvIncentiveIds.P3_MIN_TIER_KWH,
  EvIncentiveIds.P3_MIN_TIER_INC_KWH,
  EvIncentiveIds.P3_STEP_TIER_KWH,
  EvIncentiveIds.P3_STEP_TIER_INC_KWH,
  EvIncentiveIds.P3_MAX_TIER_INC,

  EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY,
  EvIncentiveIds.MINIMUM_INCENTIVE,
  EvIncentiveIds.RANGE_INCREMENT,
  EvIncentiveIds.INCENTIVE_INCREMENT,

  EvIncentiveIds.MIN_TIER_KWH,
  EvIncentiveIds.MIN_TIER_INC_KWH,
  EvIncentiveIds.STEP_TIER_KWH,
  EvIncentiveIds.STEP_TIER_INC_KWH
]

export const TIERED_INCENTIVE_TYPE_RADIO_IDS = [
  EvIncentiveIds.MINIMUM_RANGE_TO_QUALIFY,
  EvIncentiveIds.MINIMUM_INCENTIVE,
  EvIncentiveIds.RANGE_INCREMENT,
  EvIncentiveIds.INCENTIVE_INCREMENT,

  EvIncentiveIds.MIN_TIER_KWH,
  EvIncentiveIds.MIN_TIER_INC_KWH,
  EvIncentiveIds.STEP_TIER_KWH,
  EvIncentiveIds.STEP_TIER_INC_KWH
]

export const FPL_BONUS_NEW_BOOLEAN_RADIO_IDS = [
  EvIncentiveIds.FPL_BONUS_NEW_INC,
  EvIncentiveIds.FPL_BONUS_END_YEARS,
  EvIncentiveIds.FPL_INCENTIVE_CAP
]

export const INCLUDE_FPL_BONUS_IN_CAP_RADIO_IDS = [
  EvIncentiveIds.FPL_INCENTIVE_CAP
]

export const APPLY_CAPS_TO_USED_RADIO_IDS = [
  MsrpCapIds.MAGI_CAP_USED_OPTION,
  MsrpCapIds.BONUS_USED_BATTERY_INCENTIVE,
  MsrpCapIds.AVERAGE_ANNUAL_BATTERY_DEG,
  MsrpCapIds.EXCLUDE_NON_QUALIFIED_SELLERS,
  MsrpCapIds.PERCENTAGE_EXCLUDED
]

export const USED_EV_TYPE_RADIO_IDS = [
  EvIncentiveIds.INCENTIVE_VALUE_NEW_EV,
  EvIncentiveIds.USED_EV_DOLLAR
]

export const EXCLUDE_NON_QUALIFIED_SELLERS_RADIO_IDS = [
  MsrpCapIds.PERCENTAGE_EXCLUDED
]

export const EXCLUDED_SECOND_TIME_SALES_RADIO_IDS = [
  MsrpCapIds.SECOND_TIME_SALES_TYPE
]

export const REGION_TIERED_MSRP_CAP_BOOL_RADIO_IDS = [
  RegionalMsrpCapIds.REGION_MIN_TIER_RANGE_CAP,
  RegionalMsrpCapIds.REGION_MIN_TIER_INC_SOFT_CAP,
  RegionalMsrpCapIds.REGION_STEP_TIER_INC_SOFT_CAP,
  RegionalMsrpCapIds.REGION_MAX_TIER_INC_SOFT_CAP,
  RegionalMsrpCapIds.REGION_STEP_TIER_RANGE_SOFT_CAP
]

export const SECOND_TIME_SALES_TYPE_RADIO_IDS = [MsrpCapIds.YEAR_RATE_RICH_50]

export const USE_PRICE_ELASTICITY_RADIO_IDS = [EvIncentiveIds.AVG_USED_EV_COST]

export const MANUFACTURERS_INCENTIVE_RADIO_IDS = [
  EvIncentiveIds.BONUS_INCENTIVE,
  EvIncentiveIds.BONUS_INCENTIVE_DOLLAR,
  EvIncentiveIds.SECOND_LM_INC_DOLLAR,
  EvIncentiveIds.SECOND_LM_INC_YEAR,
  EvIncentiveIds.BONUS_INCENTIVE,
  EvIncentiveIds.BONUS_INCENTIVE_DOLLAR,

  EvIncentiveIds.FIRST_YEAR,
  EvIncentiveIds.SECOND_YEAR,
  EvIncentiveIds.THIRD_YEAR,
  EvIncentiveIds.FOURTH_YEAR,
  EvIncentiveIds.FIFTH_YEAR,
  EvIncentiveIds.SIXTH_YEAR,
  EvIncentiveIds.LEADING_PROGRAM_MAX_DURATION,
  EvIncentiveIds.LEADING_MANUFACTURERS,
  EvIncentiveIds.ELIGIBLE_USED_EVS,
  EvIncentiveIds.ELIGIBILITY_EXPANDS
]

export const SPLIT_PROGRAM_RADIO_IDS = [
  EvIncentiveIds.LEADING_MANUFACTURERS,
  EvIncentiveIds.ELIGIBLE_USED_EVS,
  EvIncentiveIds.ELIGIBILITY_EXPANDS
]

export const ASSEMBLED_IN_US_INCENTIVE_RADIO_IDS = [
  EvIncentiveIds.ASSEMBLED_US_INCENTIVE,
  EvIncentiveIds.ASS_US_REQ_YEAR,
  EvIncentiveIds.US_FIRST_YEAR,
  EvIncentiveIds.US_SECOND_YEAR,
  EvIncentiveIds.US_THIRD_YEAR,
  EvIncentiveIds.US_FOURTH_YEAR,
  EvIncentiveIds.US_FIFTH_YEAR,
  EvIncentiveIds.US_SIXTH_YEAR,
  EvIncentiveIds.ASSEMBLED_PROGRAM_MAX_DURATION
]

export const REGION_SALES_TAX_BOOLEAN_RADIO_IDS = [
  EvIncentiveRegularIds.REGION_SALES_TAX_END_YEARS,
  EvIncentiveRegularIds.REGION_SALES_INCLUDE_BEV,
  EvIncentiveRegularIds.REGION_SALES_INCLUDE_PHEV,
  EvIncentiveRegularIds.REGION_SALES_INCLUDE_NEW,
  EvIncentiveRegularIds.REGION_SALES_INCLUDE_USED
]

export const COMMERCIAL_FLEET_BOOLEAN_RADIO_IDS = [
  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_START_YEAR,
  CommercialFleetIncentiveIds.COMMERCIAL_FLEET_END_YEAR,
  CommercialFleetIncentiveIds.ASSUMED_EV_SALES_TO_FLEETS,
  CommercialFleetIncentiveIds.EXCLUDE_LEASED_VEHICLES,
  CommercialFleetIncentiveIds.EV_SALES_TO_FLEETS
]

export const EXCLUDE_COMMERCIAL_BOOLEAN_RADIO_IDS = [
  CommercialFleetIncentiveIds.COMMERCIAL_EV_INC
]

export const REGIONAL_TIERED_INCENTIVE_RADIO_IDS = [
  EvIncentiveRegularIds.REGION_TIERED_INC_TYPE,
  EvIncentiveRegularIds.MAXIMUM_INCENTIVE,
  EvIncentiveRegularIds.REGION_MIN_TIER_RANGE,
  EvIncentiveRegularIds.REGION_MIN_TIER_INC,
  EvIncentiveRegularIds.REGION_STEP_TIER_RANGE,
  EvIncentiveRegularIds.REGION_STEP_TIER_INC,
  EvIncentiveRegularIds.REGION_MIN_TIER_KWH,
  EvIncentiveRegularIds.REGION_MIN_TIER_INC_KWH,
  EvIncentiveRegularIds.REGION_STEP_TIER_KWH,
  EvIncentiveRegularIds.REGION_STEP_TIER_INC_KWH,
  EvIncentiveRegularIds.NJ_FILTER_ELIGIBILITY
]

export const REGION_FPL_BONUS_NEW_BOOLEAN_RADIO_IDS = [
  EvIncentiveRegularIds.REGION_FPL_BONUS_NEW_INC,
  EvIncentiveRegularIds.REGION_FPL_BONUS_END_YEARS
]

export const MSRP_CAP_DROPDOWN_IDS = [
  MsrpCapIds.MSRP,
  MsrpCapIds.TIERED_MSRP_CAP,
  MsrpCapIds.TIERED_MSRP_SOFT_CAP
]

export const INCOME_CAP_DROPDOWN_IDS = [
  MsrpCapIds.INCOME_CAP,
  MsrpCapIds.MAGI_PHASE_OPTION,
  MsrpCapIds.MAGI_CAP_SINGLE,
  MsrpCapIds.MAGI_CAP_HEADHOUSE,
  MsrpCapIds.MAGI_CAP_JOINT
]

export const REGION_CAP_TYPE_DROPDOWN_IDS = [
  RegionalMsrpCapIds.MSRP,
  RegionalMsrpCapIds.REGION_SOFT_CAP,
  RegionalMsrpCapIds.TIERED_MSRP_CAP,
  RegionalMsrpCapIds.TIERED_MSRP_SOFT_CAP,
  RegionalMsrpCapIds.REGION_MIN_TIER_RANGE_CAP,
  RegionalMsrpCapIds.REGION_MIN_TIER_INC_SOFT_CAP,
  RegionalMsrpCapIds.REGION_STEP_TIER_INC_SOFT_CAP,
  RegionalMsrpCapIds.REGION_MAX_TIER_INC_SOFT_CAP,
  RegionalMsrpCapIds.REGION_STEP_TIER_RANGE_SOFT_CAP
]

export const REGION_INCOME_CAP_TYPE_DROPDOWN_IDS = [
  RegionalMsrpCapIds.INCOME_CAP,
  RegionalMsrpCapIds.MAGI_PHASE_OPTION,
  RegionalMsrpCapIds.REGION_SINGLE_CAP,
  RegionalMsrpCapIds.REGION_HOH_CAP,
  RegionalMsrpCapIds.REGION_JOINT_CAP
]

export const DECLINE_RATE_DROPDOWN_IDS = [EvIncentiveIds.MAX_SHARE]

export const REGION_DECLINE_RATE_DROPDOWN_IDS = [
  EvIncentiveRegularIds.MAX_SHARE
]

export const SCC_CALC_DROPDOWN_IDS = [
  AdditionalGraphParametersIds.USER_SCC_INPUT,
  AdditionalGraphParametersIds.USER_SCC_RATE
]

export const FORECASTED_MPG_DROPDOWN_IDS = [VehicleFleetCharacteristicsIds.MPG]

export const SLIDER_VALUES: SliderValuesProps = {
  general: ['startYear', 'years', 'region'],
  vehicleFleetCharacteristics: [
    'cafeStandardOption',
    'input2050Mpg',
    'vmtGrowth',
    'newVehGrowth',
    'excludeEvCafe',
    'phevLastYear',
    'overrideRetirement',
    'fleetGrowthRate'
  ],
  reductionCharacteristics: [
    'ghgBoolean',
    'electricityDecarbRate',
    'fuelSavings',
    'health'
  ],
  modelCharacteristics: [
    'diffusionCurveScalar',
    'discountRate',
    'discountCosts',
    'taxCreditBoolean',
    'consumerDiscountRate',
    'taxCreditNewEvInc',
    'taxCreditDomestic',
    'taxCreditLeadingManufacturer',
    'taxCreditSoftCap',
    'taxCreditUsedEvInc',
    'taxCreditSar',
    'taxCreditRegionFplBonus',
    'taxCreditAssembledInUs',
    'taxCreditFplBonus',
    'taxCreditRegionSoftCap',
    'taxCreditRegionSar',
    'taxCreditRegionUsedEvInc',
    'taxCreditRegionNewEvInc',
    'batteryImprovementRate',
    'firstYearDiscount',
    'taxCreditTieredIncentiveP2',
    'taxCreditTieredIncentiveP3'
  ],
  additionalGraphParameters: [
    'benefitsCarbon',
    'benefitsFuel',
    'benefitsHealth',
    'benefitsOm',
    'sccCalc',
    'userSccInput',
    'userSccRate',
    'fleetAgeYear',
    'fleetAgeYearAll',
    'includeEv',
    'includeIcev',
    'includeFleet',
    'includeNew',
    'fplParticipationType',
    'fplAnnualCumulative'
  ],
  msrpCap: [
    'usCapType',
    'msrpCap',
    'incomeCap',
    'softCapEndYears',
    'tieredMsrpCap',
    'tieredMsrpSoftCap',
    'softCapStartYear',
    'singleCap',
    'HoHCap',
    'jointCap',
    'magiSoftCap',
    'newEvStartYear',
    'applyCapsToUsed',
    'magiCapUsedOption',
    'bonusUsedBatteryIncentive',
    'batteryDegradationRate',
    'excludeNondealersUsed',
    'percentExcludedUsed',
    'excludeSecondTimeSales',
    'secondSalesType',
    'fiftyPercYear',
    'assUsApplyUsed',
    'assUsType',
    'usMsrpCapType',
    'usSoftCap',
    'usIncomeCapType',
    'magiPhaseOption',
    'magiPhase1Years',
    'singleCapPhase2',
    'hoHCapPhase2',
    'jointCapPhase2',
    'magiPhase2Years',
    'singleCapPhase3',
    'hoHCapPhase3',
    'jointCapPhase3'
  ],
  evIncentives: [
    'newEvStartingAmt',
    'newEvYears',
    'newEvStartYear',
    'newEvEndYears',
    'scenario',
    'maxMarketShareValue',
    'tieredIncentive',
    'tieredIncentiveType',
    'minTierRange',
    'minTierInc',
    'stepTierRange',
    'stepTierInc',
    'maxTierInc',
    'minTierKWh',
    'minTierIncKWh',
    'stepTierKWh',
    'stepTierIncKWh',
    'tieredP1Years',
    'p2MinTierKWh',
    'p2MinTierIncKWh',
    'p2StepTierKWh',
    'p2StepTierIncKWh',
    'p2MaxTierInc',
    'tieredP2Years',
    'p3MinTierKWh',
    'p3MinTierIncKWh',
    'p3StepTierKWh',
    'p3StepTierIncKWh',
    'p3MaxTierInc',
    'fplBonusNewBoolean',
    'fplBonusNewInc',
    'fplBonusEndYears',
    'includeFplCap',
    'fplIncentiveCap',
    'usedEvType',
    'usedEvDollar',
    'usedEvPerc',
    'fplRate',
    'usedEvEndYears',
    'lmBoolean',
    'lmBonusType',
    'lmIncDollar',
    'lmIncPerc',
    'secondLmIncDollar',
    'secondLmIncYear',
    'y1Lm',
    'y2Lm',
    'y3Lm',
    'y4Lm',
    'y5Lm',
    'y6Lm',
    'programUsedBoolean',
    'fplBonusNewExpansionBoolean',
    'assUsType',
    'assUsReqYear',
    'assUsBoolean',
    'assUsInc',
    'y1AssUs',
    'y2AssUs',
    'y3AssUs',
    'y4AssUs',
    'y5AssUs',
    'y6AssUs',
    'assUsEndYears',
    'cutoffModelYear',
    'programUsedEvIncentive',
    'usedProgramExpansionYear',
    'secondFplUsed',
    'oemStartingAmt',
    'oemYears',
    'dealerInc',
    'dealerYears',
    'miAInc',
    'miAProp',
    'domesticContentEndYears',
    'lmEndYears',
    'usePriceElasticity',
    'declineWtp',
    'avgUsedEvCost',
    'fplBonusStartYear',
    'usedEvStartYear',
    'minUsedEvAge',
    'lmStartYear',
    'assUsStartYear',
    'oemStartYear',
    'oemEndYear',
    'domesticContentStartYear'
  ],
  eviIncentive: [
    'eviOverbuildRate',
    'eviScenario',
    'eviIncentiveYears',
    'startingWl2',
    'workl2Rate',
    'startingPl2',
    'publ2Rate',
    'startingDc',
    'pubdcRate',
    'includeEviCosts'
  ],
  commercialFleetIncentive: [
    'fleetEviBoolean',
    'fleetYears',
    'fleetSalesPercent',
    'fleetExcludedProp',
    'fleetEviIncentive',
    'commercialFleetBoolean',
    'commercialFleetEndYear',
    'commercialFleetStartYear',
    'commercialFleetIncPercent',
    'excludeLeaseMsrp',
    'regionExcludeCommercialBoolean',
    'regionCommercialEvIncentive'
  ],
  commercialFleetBoolean: [
    'commercialFleetEndYear',
    'commercialFleetStartYear',
    'commercialFleetIncPercent'
  ],
  scrapReplace: [
    'saRStartYear',
    'maximumSaRAge',
    'saREndYears',
    'nationalSaRIncentiveFpl',
    'nationalFplSaRNew',
    'nationalFplSaRUsed',
    'nationalSaRIncentiveNonfpl',
    'nationalNonfplSaRNew',
    'nationalNonfplSaRUsed'
  ],
  regulatoryPolicies: [
    'zevMandateGoal',
    'zevMandateYear',
    'noncomplianceCost',
    'additionalOemIncentive',
    'oem100Threshold',
    'icevBanBoolean',
    'icevBanYear'
  ],
  regionMsrpCap: [
    'regionMsrpCapType',
    'regionMsrpCap',
    'regionIncomeCapType',
    'regionIncomeCap',
    'regionSoftCapEndYears',
    'regionTieredMsrpCap',
    'regionTieredMsrpSoftCap',
    'regionSoftCapStartYear',
    'regionTieredMsrpSoftCapBoolean',
    'regionMinTierRangeSoftCap',
    'regionMinTierIncSoftCap',
    'regionStepTierIncSoftCap',
    'regionMaxTierIncSoftCap',
    'regionStepTierRangeSoftCap',
    'regionSoftCap',
    'regionSingleCap',
    'regionHoHCap',
    'regionJointCap',
    'regionMagiPhaseOption',
    'regionMagiPhase1Years',
    'regionSingleCapPhase2',
    'regionHoHCapPhase2',
    'regionJointCapPhase2',
    'regionMagiPhase2Years',
    'regionSingleCapPhase3',
    'regionHoHCapPhase3',
    'regionJointCapPhase3'
  ],
  regionEvIncentives: [
    'regionNewEvInc',
    'regionNewEvYears',
    'regionNewEvEndYears',
    'regionScenario',
    'regionMaxMarketShare',
    'regionSalesTaxBoolean',
    'regionSalesTaxEndYears',
    'regionTieredIncentive',
    'regionTieredIncentiveType',
    'regionMaxTierInc',
    'regionMinTierRange',
    'regionMinTierInc',
    'regionStepTierRange',
    'regionStepTierInc',
    'regionMinTierKWh',
    'regionMinTierIncKWh',
    'regionStepTierKWh',
    'regionStepTierIncKWh',
    'regionFplBonusNewBoolean',
    'regionFplBonusNewInc',
    'regionFplBonusEndYears',
    'regionUsedEvInc',
    'regionFplRate',
    'regionUsedEvYears',
    'nonFinancialYears',
    'hovLaneAccess',
    'designatedParking',
    'freeTolls',
    'freeFerries',
    'other',
    'otherNonfinancialValue',
    'regionNewEvStartYear',
    'regionFplBonusStartYear',
    'regionUsedEvStartYear',
    'regionUsedEvEndYears',
    'regionSalesTaxIncludeBev',
    'regionSalesTaxIncludePhev',
    'regionSalesTaxIncludeNew',
    'regionSalesTaxIncludeUsed',
    'njFilterEligibilityBoolean'
  ],
  regionScrapReplace: [
    'regionAgeVehicle',
    'regionSaRStartYear',
    'regionSaREndYears',
    'regionSaRIncentiveFpl',
    'regionFplSaRNew',
    'regionFplSaRUsed',
    'regionSaRIncentiveNonfpl',
    'regionNonfplSaRNew',
    'regionNonfplSaRUsed'
  ]
}

export const VALUES_BY_BLOCKS = {
  general: [...SLIDER_VALUES.general],
  generalAdvanced: [
    ...SLIDER_VALUES.vehicleFleetCharacteristics,
    ...SLIDER_VALUES.reductionCharacteristics,
    ...SLIDER_VALUES.modelCharacteristics,
    ...SLIDER_VALUES.additionalGraphParameters
  ],
  nationalInputs: [
    ...SLIDER_VALUES.msrpCap,
    ...SLIDER_VALUES.evIncentives,
    ...SLIDER_VALUES.eviIncentive,
    ...SLIDER_VALUES.commercialFleetIncentive,
    ...SLIDER_VALUES.regulatoryPolicies,
    ...SLIDER_VALUES.scrapReplace
  ],
  regionalInputs: [
    ...SLIDER_VALUES.regionMsrpCap,
    ...SLIDER_VALUES.regionEvIncentives,
    ...SLIDER_VALUES.regionScrapReplace
  ]
}

export const ENTIRE_MODEL_IDS = [
  ...VALUES_BY_BLOCKS.general,
  ...VALUES_BY_BLOCKS.generalAdvanced,
  ...VALUES_BY_BLOCKS.nationalInputs,
  ...VALUES_BY_BLOCKS.regionalInputs
]

export const RADIO_ID_DEPENDENCIES: any = {
  fleetAgeYearAll: ['fleetAgeYear'],
  fleetEviBoolean: [
    'fleetYears',
    'fleetSalesPercent',
    'fleetExcludedProp',
    'fleetEviIncentive'
  ],
  tieredIncentive: [
    'tieredIncentiveType', // radio
    'maxTierInc',

    'tieredP1Years',
    'p2MinTierKWh',
    'p2MinTierIncKWh',
    'p2StepTierKWh',
    'p2StepTierIncKWh',
    'p2MaxTierInc',

    'tieredP2Years',
    'p3MinTierKWh',
    'p3MinTierIncKWh',
    'p3StepTierKWh',
    'p3StepTierIncKWh',
    'p3MaxTierInc',

    'minTierRange',
    'minTierInc',
    'stepTierRange',
    'stepTierInc',
    'minTierKWh',
    'minTierIncKWh',
    'stepTierKWh',
    'stepTierIncKWh'
  ],
  tieredIncentiveType: [
    'minTierRange',
    'minTierInc',
    'stepTierRange',
    'stepTierInc',

    'minTierKWh',
    'minTierIncKWh',
    'stepTierKWh',
    'stepTierIncKWh'
  ],
  fplBonusNewBoolean: [
    'fplBonusNewInc',
    'fplBonusEndYears',
    'includeFplCap', // radio
    'fplIncentiveCap'
  ],
  includeFplCap: ['fplIncentiveCap'],
  usedEvType: ['usedEvPerc', 'usedEvDollar'],
  usePriceElasticity: [
    'declineWtp', // radio
    'avgUsedEvCost'
  ],
  lmBoolean: [
    'lmBonusType',

    'lmIncPerc',
    'lmIncDollar',
    'secondLmIncDollar',
    'secondLmIncYear',

    'y1Lm',
    'y2Lm',
    'y3Lm',
    'y4Lm',
    'y5Lm',
    'y6Lm',
    'lmEndYears',
    'programUsedBoolean',
    'cutoffModelYear',
    'programUsedEvIncentive',
    'usedProgramExpansionYear',
    'secondFederalPUsed',
    'fplBonusNewExpansionBoolean'
  ],
  lmBonusType: [
    'lmIncPerc',
    'lmIncDollar',
    'secondLmIncDollar',
    'secondLmIncYear'
  ],
  programUsedBoolean: [
    'cutoffModelYear',
    'programUsedEvIncentive',
    'usedProgramExpansionYear',
    'secondFederalPUsed',
    'fplBonusNewExpansionBoolean'
  ],
  assUsBoolean: [
    'assUsType',
    'assUsInc',
    'assUsReqYear',
    'y1AssUs',
    'y2AssUs',
    'y3AssUs',
    'y4AssUs',
    'y5AssUs',
    'y6AssUs',
    'assUsEndYears'
  ],
  assUsType: ['assUsInc', 'assUsReqYear'],
  icevBanBoolean: ['icevBanYear', 'oem100Threshold'],
  regionSalesTaxBoolean: [
    'regionSalesTaxEndYears',
    'regionSalesTaxIncludeBev',
    'regionSalesTaxIncludePhev',
    'regionSalesTaxIncludeNew',
    'regionSalesTaxIncludeUsed'
  ],
  regionTieredIncentive: [
    'regionTieredIncentiveType',
    'regionMaxTierInc',
    'regionMinTierRange',
    'regionMinTierInc',
    'regionStepTierRange',
    'regionStepTierInc',
    'regionMinTierKWh',
    'regionMinTierIncKWh',
    'regionStepTierKWh',
    'regionStepTierIncKWh',
    'njFilterEligibilityBoolean'
  ],
  regionTieredIncentiveType: [
    'regionMinTierRange',
    'regionMinTierInc',
    'regionStepTierRange',
    'regionStepTierInc',
    'regionMinTierKWh',
    'regionMinTierIncKWh',
    'regionStepTierKWh',
    'regionStepTierIncKWh'
  ],
  regionFplBonusNewBoolean: ['regionFplBonusNewInc', 'regionFplBonusEndYears'],
  excludeEvCafe: ['phevLastYear'],
  overrideRetirement: ['fleetGrowthRate'],
  commercialFleetBoolean: [
    'commercialFleetStartYear',
    'commercialFleetEndYear',
    'commercialFleetIncPercent',
    'excludeLeaseMsrp',
    'fleetSalesPercent'
  ],
  regionExcludeCommercialBoolean: ['regionCommercialEvIncentive'],
  excludeNondealersUsed: ['percentExcludedUsed'],
  applyCapsToUsed: [
    'magiCapUsedOption',
    'bonusUsedBatteryIncentive',
    'batteryDegradationRate',
    'excludeNondealersUsed',
    'excludeSecondTimeSales',
    'percentExcludedUsed'
  ],
  excludeSecondTimeSales: ['secondSalesType'],
  secondSalesType: ['fiftyPercYear'],
  regionTieredMsrpSoftCapBoolean: [
    'regionMinTierRangeSoftCap',
    'regionMinTierIncSoftCap',
    'regionStepTierIncSoftCap',
    'regionMaxTierIncSoftCap',
    'regionStepTierRangeSoftCap'
  ]
}

export const CUSTOM_RADIO_IDS_DEPENDENCIES: any = {
  tieredIncentiveType: [
    ['minTierRange', 'minTierInc', 'stepTierRange', 'stepTierInc'],
    ['minTierKWh', 'minTierIncKWh', 'stepTierKWh', 'stepTierIncKWh']
  ],
  regionTieredIncentiveType: [
    [
      'regionMinTierRange',
      'regionMinTierInc',
      'regionStepTierRange',
      'regionStepTierInc'
    ],
    [
      'regionMinTierKWh',
      'regionMinTierIncKWh',
      'regionStepTierKWh',
      'regionStepTierIncKWh'
    ]
  ]
}

export const CUSTOM_RADIO_IDS = [
  'tieredIncentiveType',
  'regionTieredIncentiveType'
]
