import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  noPadding: {
    padding: 0
  },
  container: {
    borderRadius: 12
  },
  frame: {
    height: 550,
    width: 'calc(100% - 64px)',
    padding: '24px 32px',
    borderRadius: 12,
    backgroundColor: theme.colors.white
  },
  col: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  plotSlider: {
    padding: '8px 0'
  },
  content: {
    borderTop: `1px solid ${theme.colors.secondButton}`
  },
  warning: {
    whiteSpace: 'pre',
    textAlign: 'center'
  },
  info: {
    '& > *:not(:first-child)': {
      marginLeft: 24
    },
    '& > *': {
      textDecoration: 'none'
    }
  },
  loader: {
    marginTop: 24
  },
  modal: {
    backgroundColor: theme.colors.inactive,
    position: 'fixed',
    top: 0,
    left: '100%',
    right: '100%',
    bottom: 0,
    overflowY: 'scroll',
    zIndex: 10
  },
  back: {
    marginTop: 12
  },
  spinnerContainer: {
    width: 40,
    height: 40
  },
  column: {
    padding: 0,
    '& > *': {
      padding: '16px 0 24px 0'
    }
  },
  tabs: {
    width: 'calc(100% - 64px)'
  },
  tab: {
    padding: '0 48px 16px 48px'
  },
  selected: {
    borderBottom: `1px solid ${theme.colors.blue}`,
    '& > *': {
      color: theme.colors.blue
    }
  },
  '@media screen and (max-width: 800px)': {
    frame: {
      height: 565
    }
  }
}))
