import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    marginTop: 16
  },
  filterContainer: {
    marginLeft: 8
  },
  filterMargin: {
    marginLeft: 8
  }
})
