import { ComponentTextProps } from '../text'

export const creatingIssueLabelProps: ComponentTextProps = {
  text: 'The policy name is already in use. It must be unique.',
  tx: 'create.policy.process.error',
  color: 'red'
}

export const serverIssueLabelProps: ComponentTextProps = {
  tx: 'policy.smthWentWrong',
  text: 'Something went wrong',
  color: 'red'
}

export const labelProps: ComponentTextProps = {
  text: 'Policy Name',
  tx: 'create.policy.name.label',
  color: 'darkBlue'
}

export const errorProps: ComponentTextProps = {
  text: 'Please enter policy name',
  tx: 'create.policy.name.error',
  color: 'red'
}
