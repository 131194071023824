import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  noPadding: {
    padding: 0
  },
  row: {
    position: 'relative',
    width: 'calc(100% - 64px)',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  cell: {
    maxWidth: 276,
    minWidth: 276,
    padding: '12px 0',
    '&:not(:first-child)': {
      marginLeft: 92
    },
    '&:nth-child(3n + 3) + &': {
      marginLeft: 0
    }
  },
  container: {
    padding: 16
  },
  setting: {
    marginTop: 8
  },
  dropdown: {
    top: 0,
    width: '100%',
    overflow: 'auto',
    borderRadius: 0,
    borderBottom: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  dropdownContent: {
    top: 37
  },
  col: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  settings: {
    '& > *:not(:first-child)': {
      marginLeft: 136
    }
  },
  '@media screen and (max-width: 1170px)': {
    settings: {
      '& > *:not(:first-child)': {
        marginLeft: 100
      }
    }
  },
  '@media screen and (max-width: 800px)': {
    settings: {
      '& > *:not(:first-child)': {
        marginLeft: 72
      }
    }
  }
})
