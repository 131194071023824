import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTheme } from 'react-jss'

import { Content, Column, FrameHTMLComponent } from '..'
import { LightTheme } from '../../theme'
import { PolicyCreators, State } from '../../store'
import { Spinner } from '../spinner'
import { PolicyProps } from '../update-policy'
import { AffordabilitySettings } from './settings/affordability-settings'
import { useStyle } from './affordability-tool.styles'

export const AffordabilityTool: FC = () => {
  const theme: LightTheme = useTheme()
  const dispatch = useDispatch()
  const { id } = useParams<PolicyProps>()
  const { isLoaded, plot, policy } = useSelector((state: State) => ({
    ...state.affordability,
    policy: state.policy.data.find((item) => item.id === id)
  }))
  const classes = useStyle({ theme, isLoaded })

  useEffect(() => {
    if (!policy && id) {
      dispatch(PolicyCreators.getPolicyRequest({ id }))
    }
  }, [id])

  return (
    <Column fullHeight fullWidth className={classes.container}>
      <Content className={classes.noPadding}>
        <Column
          fullHeight
          fullWidth
          className={classes.frame}
        >
          <AffordabilitySettings id={id} policy={policy} isCalculator />
          {isLoaded ? (
            <FrameHTMLComponent srcDoc={plot} />
          ) : (
            <Column fullHeight>
              <Spinner />
            </Column>
          )}
        </Column>
      </Content>
    </Column>
  )
}
