import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Save: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3h12l4 4v12c0 1.1-.9 2-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm14 16V7.83L16.17 5H5v14h14zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm3-6H6v4h9V6z"
        fill={props.fill || theme.colors.white}
      />
    </svg>
  )
}
