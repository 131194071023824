import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '0 16px',
    minHeight: 660
  },
  content: {
    marginTop: 16,
    padding: 0
  },
  row: {
    width: 'calc(1110px - 420px)',
    padding: '16px 210px 0'
  },
  htmlText: {
    minHeight: 748,
    height: 748,
    padding: '16px 0',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    height: 24,
    width: 'calc(1110px - 420px)',
    background: `linear-gradient(0deg, ${theme.colors.white} 0%, rgba(245, 246, 246, 0) 100%)`,
    zIndex: 2
  },
  '@media screen and (min-height: 1078px) and (max-height: 1400px)': {
    htmlText: {
      minHeight: 1100,
      height: 1100
    }
  }
}))
