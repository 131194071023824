import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { RadioStyleProps } from './radio-button-group.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  row: {
    marginTop: 24,
    width: 'calc(100% - 64px)',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  noPadding: {
    padding: 0
  },
  cell: {
    maxWidth: 280,
    minWidth: 280,
    flex: 1,
    '&:not(:first-child)': {
      marginLeft: 60
    },
    '&:nth-child(3n + 3) + &': {
      marginLeft: 0
    },
    '&:nth-child(1n + 3)': {
      marginTop: 28
    }
  },
  cellCol: {
    maxWidth: 280,
    minWidth: 280
  },
  title: {
    '&:hover': {
      color: ({ description }: RadioStyleProps) =>
        !description ? theme.colors.text : theme.colors.orange,
      cursor: ({ description }: RadioStyleProps) =>
        !description ? 'default' : 'help'
    }
  },
  radioContainer: {
    height: ({ isAutoHeight }: RadioStyleProps) => (isAutoHeight ? 'auto' : 60),
    width: ({ isAutoHeight }: RadioStyleProps) =>
      isAutoHeight ? '100%' : 'auto'
  },
  radioBtns: {
    '& > *:not(:first-child)': {
      marginLeft: 50
    }
  },
  radio: {
    '& > *:not(:first-child)': {
      marginLeft: 16
    }
  },
  colRadio: {
    marginTop: 8
  },
  undoIcon: {
    position: 'absolute',
    right: -40,
    bottom: 10,
    cursor: theme.cursor,
    '& > *:not(:first-child)': {
      marginTop: 6
    }
  },
  warning: {
    position: 'absolute',
    color: theme.colors.border,
    padding: 0,
    right: -4,
    bottom: 10,
    cursor: theme.cursor,
    '&:hover > *': {
      visibility: 'visible'
    }
  },
  hint: {
    maxWidth: 300,
    padding: '6px 8px',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    position: 'absolute',
    left: '-74px',
    bottom: '130%',
    visibility: 'hidden',
    zIndex: 2
  }
}))
