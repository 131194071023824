import React, { FC, useState, useEffect, FocusEvent } from 'react'
import { useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Column,
  IconName,
  Input,
  Row,
  Text,
  ContentSpinner
} from '../../../../components'
import { ForgotPasswordCreators, State } from '../../../../store'
import { LightTheme } from '../../../../theme'
import {
  parametersErrorProps,
  createPassErrorProps,
  passwordLabelProps,
  reEnterPasswordLabelProps,
  passMatchProps
} from '../../labels'
import {
  FieldIds,
  CreatePasswordProps,
  ParamsProps,
  RecoveryPasswordData,
  TypesValue,
  RecoveryPasswordIds,
  RecoveryPasswordErrorsProps
} from './create-password.types'
import { useStyle } from './create-password.styles'

const DEFAULT_FIELD_TYPES: TypesValue = {
  password: 'password',
  repeatPassword: 'password'
}

export const CreatePassword: FC<CreatePasswordProps> = ({
  className = '',
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoaded, tokenResponse, errorResponse } = useSelector(
    (state: State) => ({
      ...state.app,
      tokenResponse: state.createPassword.created,
      errorResponse: state.createPassword.response
    })
  )
  const { uid, token } = useParams<ParamsProps>()
  const [isSent, changeIsSent] = useState<boolean>(false)
  const [isRequestFailed, changeIsRequestFailed] = useState<boolean>(false)
  const [error, changeError] = useState<RecoveryPasswordErrorsProps>({
    password: undefined,
    repeatPassword: undefined
  })
  const [recoveryData, changeRecoveryData] = useState<RecoveryPasswordData>({
    password: '',
    repeatPassword: ''
  })
  const [types, changeTypes] = useState<TypesValue>(DEFAULT_FIELD_TYPES)

  const isResetDisabled =
    recoveryData.password !== recoveryData.repeatPassword ||
    !recoveryData.password ||
    !recoveryData.repeatPassword

  const goToLogin = () => {
    history.push('/')
  }

  const goToForgot = () => {
    history.push('/forgot-password')
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = e.target

    changeRecoveryData({
      ...recoveryData,
      [id]: value
    })
  }

  const onIconClick = (id: FieldIds) => {
    const isPassword = types[id] !== 'input'
    const type = isPassword ? 'input' : 'password'

    changeTypes({
      ...types,
      [id]: type
    })
  }

  const getIconName = (id: FieldIds): IconName =>
    types[id] === 'input' ? IconName.VISIBILITY : IconName.VISIBILITY_OFF

  const onPassIconClick = () => onIconClick('password')
  const onRepeatPassIconClick = () => onIconClick('repeatPassword')

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const id = (event.target as Element).id as RecoveryPasswordIds
    const { value } = event.target
    // Minimum eight characters, at least one letter and one number
    const regexp = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$')
    const isPasswordCorrect = regexp.test(value)
    const isPassMatch = recoveryData.password !== recoveryData.repeatPassword
    const isTypedData =
      Boolean(recoveryData.password) && Boolean(recoveryData.repeatPassword)

    if (isPassMatch && isTypedData) {
      changeError({
        ...error,
        repeatPassword: passMatchProps
      })
      return
    }

    if (!isPasswordCorrect) {
      changeError({
        ...error,
        [id]: parametersErrorProps
      })
      return
    }

    changeError({
      ...error,
      [id]: ''
    })
  }

  const onSubmit = () => {
    if (!isResetDisabled) {
      dispatch(
        ForgotPasswordCreators.postCreatePasswordRequest({
          newPass1: recoveryData.password,
          newPass2: recoveryData.repeatPassword,
          uid,
          token
        })
      )
    } else {
      const passwordErrors = !recoveryData.password
        ? createPassErrorProps
        : undefined
      const repeatPasswordErrors = !recoveryData.repeatPassword
        ? createPassErrorProps
        : undefined

      changeError({
        password: passwordErrors,
        repeatPassword: repeatPasswordErrors
      })
    }
  }

  useEffect(() => {
    const isErrorExist = Object.keys(errorResponse).length
    if (isErrorExist) {
      changeIsRequestFailed(true)
    }

    if (tokenResponse && !isErrorExist) {
      changeIsSent(true)
    }
  }, [tokenResponse, errorResponse])

  useEffect(() => {
    return () => {
      dispatch(ForgotPasswordCreators.postCreatePasswordRequest())
    }
  }, [])

  const isFormVisible = !isSent && !isRequestFailed

  if (!isLoaded) {
    return <ContentSpinner />
  }

  return (
    <Column {...props} className={`${className} ${classes.signIn}`}>
      <Column fullHeight fullWidth justifyContent="space-between">
        {isRequestFailed ? (
          <>
            <Row fullWidth justifyContent="flex-start">
              <Text
                text="Ooops!"
                tx="oops.title"
                preset="h1"
                color="text"
                className={classes.title}
              />
            </Row>
            <Column fullHeight fullWidth justifyContent="space-between">
              <Row
                fullWidth
                justifyContent="flex-start"
                className={classes.container}
              >
                <Text
                  tx="password.incorrectToken"
                  text="It looks like your password recovery link is out of date - request a new one."
                  color="text"
                  preset="body"
                />
              </Row>
              <Row>
                <Button className={classes.button} onClick={goToForgot}>
                  <Text text="Ok" tx="btn.ok" preset="body" />
                </Button>
              </Row>
            </Column>
          </>
        ) : null}
        {isSent ? (
          <>
            <Row fullWidth justifyContent="flex-start">
              <Text
                text="Successful password creation!"
                tx="password.create.success.title"
                preset="h1"
                color="text"
                className={classes.title}
              />
            </Row>
            <Column fullHeight fullWidth justifyContent="space-between">
              <Row
                fullWidth
                justifyContent="flex-start"
                className={classes.container}
              >
                <Text
                  tx="password.reset.success"
                  text="You can now use your new password to login in to your account!"
                  color="text"
                  preset="body"
                />
              </Row>
              <Row>
                <Button className={classes.button} onClick={goToLogin}>
                  <Text text="Login" tx="login.title" preset="body" />
                </Button>
              </Row>
            </Column>
          </>
        ) : null}
        {isFormVisible ? (
          <>
            <Row fullWidth justifyContent="flex-start">
              <Text
                text="Create your password"
                tx="password.create.title"
                preset="h1"
                color="text"
                className={classes.title}
              />
            </Row>
            <Row
              fullWidth
              justifyContent="flex-start"
              className={classes.container}
            >
              <Text
                text="Your new password"
                tx="password.create.desc"
                preset="body"
                color="text"
              />
            </Row>
            <Column fullWidth fullHeight justifyContent="space-between">
              <Column fullWidth>
                <Row fullWidth className={classes.inputContainer}>
                  <Input
                    id="password"
                    className={classes.input}
                    alignItems="flex-start"
                    isRegular
                    labelProps={passwordLabelProps}
                    defaultValue={recoveryData.password}
                    type={types.password}
                    iconName={getIconName('password')}
                    isFocused={Boolean(recoveryData.password)}
                    errorProps={error.password}
                    onChange={onChange}
                    onBlur={onBlur}
                    onIconClick={onPassIconClick}
                  />
                </Row>
                <Row fullWidth className={classes.inputContainer}>
                  <Input
                    id="repeatPassword"
                    className={classes.input}
                    alignItems="flex-start"
                    isRegular
                    labelProps={reEnterPasswordLabelProps}
                    defaultValue={recoveryData.repeatPassword}
                    type={types.repeatPassword}
                    iconName={getIconName('repeatPassword')}
                    isFocused={Boolean(recoveryData.repeatPassword)}
                    errorProps={error.repeatPassword}
                    onChange={onChange}
                    onBlur={onBlur}
                    onIconClick={onRepeatPassIconClick}
                  />
                </Row>
              </Column>
              <Row>
                <Button
                  className={classes.button}
                  preset="accent"
                  disabled={isResetDisabled}
                  onClick={onSubmit}
                >
                  <Text
                    text="Create Password"
                    tx="btn.createPassword"
                    preset="body"
                  />
                </Button>
              </Row>
            </Column>
          </>
        ) : null}
      </Column>
    </Column>
  )
}
