import React, { FC } from 'react'

import { IconProps } from './types'

export const Timelapse: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 1.3335C4.31967 1.3335 1.33301 4.32016 1.33301 8.00016C1.33301 11.6802 4.31967 14.6668 7.99967 14.6668C11.6797 14.6668 14.6663 11.6802 14.6663 8.00016C14.6663 4.32016 11.6797 1.3335 7.99967 1.3335ZM10.8264 5.1735C10.0464 4.3935 9.02642 4.00016 7.99975 4.00016V8.00016L5.17309 10.8268C6.73309 12.3868 9.26642 12.3868 10.8331 10.8268C12.3931 9.26683 12.3931 6.7335 10.8264 5.1735ZM2.66634 8.00016C2.66634 10.9468 5.05301 13.3335 7.99967 13.3335C10.9463 13.3335 13.333 10.9468 13.333 8.00016C13.333 5.0535 10.9463 2.66683 7.99967 2.66683C5.05301 2.66683 2.66634 5.0535 2.66634 8.00016Z"
        fill="#1F9D7F"
      />
    </svg>
  )
}
