import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Column } from '../../column'
import { CardListItem } from '../card-list-item'
import { CardItemsListProps } from './card-items-list.types'
import { useStyle } from './card-items-list.styles'

export const CardItemsList = ({
  data,
  draggable,
  onAddToCompare
}: CardItemsListProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Column className={classes.container} justifyContent="flex-start">
      {data.map((policy) => {
        const isDraggable = Boolean(draggable && policy.draggable)
        const disabled = draggable && !policy.draggable

        return (
          <CardListItem
            key={policy.id}
            {...policy}
            disabled={disabled}
            draggable={isDraggable}
            onAddToCompare={onAddToCompare}
          />
        )
      })}
    </Column>
  )
}
