import { ComponentTextProps } from '../text'
import { PLOTS_LABELS } from './constants'
import {
  GetLabelsProps,
  GetPlotProps,
  GetPresetProps,
  PlotIds,
  SliderProps
} from './policy-summary-list.types'

export const getPlot = ({ filter, additional }: GetPlotProps) =>
  additional && additional.find((item: SliderProps) => item.id === filter)

export const getLabelsPropsById = (
  id?: PlotIds
): ComponentTextProps | Partial<GetLabelsProps> => {
  if (id) {
    return PLOTS_LABELS[id] as ComponentTextProps
  }

  return PLOTS_LABELS
}

// plots in slider are changed, we need to check different plot names
// e.x 'totalCosts', 'totalCostsUs
export const getIsIncludesSubstring = (string: string, subString: string) =>
  string.includes(subString) || subString.includes(string)

export const getFilterPreset = ({ id, stateFilter }: GetPresetProps) =>
  stateFilter === id ? 'secondary' : 'defaultSmall'
