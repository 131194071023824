import { Policy } from '../../../services'
import { PolicyState } from './policy.types'

export const changeDraggableState = (
  store: PolicyState,
  id: string,
  state: boolean
) =>
  store.data.map((policy) => {
    return policy.id === id ? { ...policy, draggable: state } : policy
  })

export const setDraggableState = (data: Policy[]) => {
  return data.map((item) => ({ ...item, draggable: true }))
}

export const checkOnPrevioslyDraggableData = (
  data: Policy[],
  previous: string[]
) => {
  const draggableData = setDraggableState(data).map((item) => {
    const prevDraggableItem = previous.find((prevItem) => prevItem === item.id)

    if (prevDraggableItem) {
      return {
        ...item,
        draggable: false
      }
    }

    return item
  })

  return draggableData
}
