import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'
import { WidgetProps } from '../widgets'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  size: {
    width: 120,
    height: 120,
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressBar: {
    zIndex: 2,
    '&:nth-child(1)': {
      strokeDasharray: 345,
      strokeDashoffset: 345,
      transform: 'rotate(-90deg)'
    }
  },
  circle: {
    width: '100%',
    height: '100%',
    fill: 'none',
    stroke: theme.colors.blue,
    strokeWidth: 10,
    strokeLinecap: 'round',
    transform: 'translate(5px, 5px)'
  },
  content: {
    position: 'relative',
    borderRadius: '50%',
    zIndex: 2
  },
  number: {
    borderRadius: '50%'
  },
  mainCircle: {
    fill: 'none',
    strokeWidth: 10,
    strokeLinecap: 'round',
    transform: 'translate(5px, 5px)',
    strokeDasharray: 345,
    strokeDashoffset: 0,
    stroke: theme.colors.grey
  },
  innerCircle: {
    color: theme.colors.border,
    strokeDashoffset: ({ value }: WidgetProps) =>
      `calc(345px - (345px * ${value}) / 100)`,
    stroke: theme.colors.green
  }
}))
