import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { SliderProps } from './years-slider.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  slider: {
    position: 'relative',
    padding: '24px 0'
  },
  cell: {
    padding: '12px 0'
  },
  container: {
    maxWidth: ({ isSmall }: SliderProps) => (isSmall ? 288 : 644),
    minWidth: ({ isSmall }: SliderProps) => (isSmall ? 288 : 644)
  },
  title: {
    '&:hover': {
      color: ({ description }: SliderProps) =>
        !description ? theme.colors.darkBlue : theme.colors.orange,
      cursor: ({ description }: SliderProps) =>
        !description ? 'default' : 'help'
    }
  },
  basic: {
    position: 'absolute',
    height: 1,
    backgroundColor: theme.colors.grey
  },
  filled: {
    position: 'absolute',
    height: 1,
    backgroundColor: ({ mainColor }: SliderProps) => mainColor,
    width: ({ width }: SliderProps) => width || 0
  },
  pointers: {
    position: 'absolute'
  },
  values: {
    marginTop: 16,
    width: 6,
    cursor: ({ disabled }: SliderProps) => (disabled ? 'default' : theme.cursor)
  },
  text: {
    marginTop: 8,
    cursor: ({ disabled }: SliderProps) => (disabled ? 'default' : theme.cursor)
  },
  selected: {
    borderRadius: '50%'
  }
}))
