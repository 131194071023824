import React from 'react'

import { UpdatePolicy, Column } from '../../components'

export const UpdatePolicyPage = () => {
  return (
    <Column justifyContent="flex-start">
      <UpdatePolicy />
    </Column>
  )
}
