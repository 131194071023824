import { IconName } from '../icon'
import { ComponentTextProps } from '../text'
import {
  additionalGraphLabelProps,
  evAdoptionLabelProps,
  evAffordabilityLabelProps,
  eviCostByTypeLabelProps,
  eviInstalledLabelProps,
  ghgPlotLabelProps,
  kwhConsumptionLabelProps,
  totalCostsLabeltalCosts
} from './labels'
import {
  GetLabelsProps,
  PlotIds,
  PlotProps,
  PolicyPlotNamesProps
} from './policy-summary-list.types'

export const PLOTS_LABELS: Partial<GetLabelsProps> = {
  totalCosts: totalCostsLabeltalCosts,
  totalCostsUs: totalCostsLabeltalCosts,
  totalCostsRegion: totalCostsLabeltalCosts,
  ghgPlot: ghgPlotLabelProps,
  evAdoption: evAdoptionLabelProps,
  evAdoptionUs: evAdoptionLabelProps,
  evAdoptionRegion: evAdoptionLabelProps,
  evAffordability: evAffordabilityLabelProps,
  eviInstalled: eviInstalledLabelProps,
  eviCostByType: eviCostByTypeLabelProps,
  kwhConsumption: kwhConsumptionLabelProps,
  cobenefits: additionalGraphLabelProps
}

export const EXTENEDED_PLOTS = [
  PlotIds.TOTAL_COSTS,
  PlotIds.TOTAL_COSTS_US,
  PlotIds.TOTAL_COSTS_REGION,
  PlotIds.EV_ADOPTION,
  PlotIds.EV_ADOPTION_US,
  PlotIds.EV_ADOPTION_REGION,
  PlotIds.USED_PLUS_NEW,
  PlotIds.ZEV_MANDATE,
  PlotIds.GHG_COMPARISON,
  PlotIds.GASOLINE_CONSUMPTION,
  PlotIds.GHG_REDUCTIONS,
  PlotIds.KWH_CONSUMPTION
]

export const GENERAL_PLOTS: PlotProps[] = [
  {
    id: 0,
    plotId: PlotIds.TOTAL_COSTS,
    plotName: PlotIds.TOTAL_COSTS,
    iconName: IconName.MONETIZATION,
    iconColor: 'orange',
    additional: [
      {
        id: 1,
        plotId: PlotIds.TOTAL_COSTS,
        plotName: PlotIds.TOTAL_COSTS,
        iconName: IconName.MONETIZATION,
        iconColor: 'orange'
      },
      {
        id: 2,
        plotId: PlotIds.TOTAL_COSTS_REGION,
        plotName: PlotIds.TOTAL_COSTS_REGION,
        iconName: IconName.MONETIZATION,
        iconColor: 'orange'
      },
      {
        id: 3,
        plotId: PlotIds.TOTAL_COSTS_US,
        plotName: PlotIds.TOTAL_COSTS_US,
        iconName: IconName.MONETIZATION,
        iconColor: 'orange'
      }
    ]
  },
  {
    id: 1,
    plotId: PlotIds.GHG_PLOT,
    plotName: PlotIds.GHG_PLOT,
    iconName: IconName.FILTER_DRAMA,
    iconColor: 'blue'
  },
  {
    id: 2,
    plotId: PlotIds.EV_ADOPTION,
    plotName: PlotIds.EV_ADOPTION,
    iconName: IconName.EV_STATION,
    iconColor: 'red',
    additional: [
      {
        id: 1,
        plotId: PlotIds.EV_ADOPTION,
        plotName: PlotIds.EV_ADOPTION,
        iconName: IconName.EV_STATION,
        iconColor: 'red'
      },
      {
        id: 2,
        plotId: PlotIds.EV_ADOPTION_REGION,
        plotName: PlotIds.EV_ADOPTION_REGION,
        iconName: IconName.EV_STATION,
        iconColor: 'red'
      },
      {
        id: 3,
        plotId: PlotIds.EV_ADOPTION_US,
        plotName: PlotIds.EV_ADOPTION_US,
        iconName: IconName.EV_STATION,
        iconColor: 'red'
      }
    ]
  },
  {
    id: 3,
    plotId: PlotIds.EVI_INSTALLED,
    plotName: PlotIds.EVI_INSTALLED,
    iconName: IconName.DONUT_SMALL,
    iconColor: 'blue'
  },
  {
    id: 4,
    plotId: PlotIds.EVI_COST_BY_TYPE,
    plotName: PlotIds.EVI_COST_BY_TYPE,
    iconName: IconName.MONEY,
    iconColor: 'blue'
  },
  {
    id: 5,
    plotId: PlotIds.KWH_CONSUMPTION,
    plotName: PlotIds.KWH_CONSUMPTION,
    iconName: IconName.BATTERY,
    iconColor: 'blue'
  }
]

export const EV_AFFORDABILITY: PlotProps = {
  id: 3,
  plotId: PlotIds.EV_AFFORDABILITY,
  plotName: PlotIds.EV_AFFORDABILITY,
  iconName: IconName.DIRECTION_CAR,
  iconColor: 'purple'
}

export const ADDITIONAL_GRAPH: PlotProps[] = [
  {
    id: 4,
    plotId: PlotIds.COBENEFITS,
    plotName: PlotIds.COBENEFITS,
    iconName: IconName.BAR_CHART,
    iconColor: 'strongPink'
  }
]

export const generalInputBasicLabelProps: ComponentTextProps = {
  tx: 'policy.overview',
  text: 'Overview'
}

export const federalPolicyStateLevelLabelProps: ComponentTextProps = {
  tx: 'policy.summary.federalPolicyStateLevel',
  text: 'Share of Federal Policy at State Level'
}

export const statePolicyOnlyLabelProps: ComponentTextProps = {
  tx: 'policy.summary.statePolicyOnly',
  text: 'State Policy only'
}

export const stateAndFedPolicyCombinedLabelProps: ComponentTextProps = {
  tx: 'policy.summary.stateAndFedPolicyCombined',
  text: 'State and Fed Policy combined'
}

export const STATE_FILTERS = [
  {
    id: 1,
    textProps: stateAndFedPolicyCombinedLabelProps
  },
  {
    id: 2,
    textProps: statePolicyOnlyLabelProps
  },
  {
    id: 3,
    textProps: federalPolicyStateLevelLabelProps
  }
]

export const policyPlotNames: PolicyPlotNamesProps = {
  kwhConsumption: 'plot.kwhConsumption',
  eviInstalled: 'plot.eviInstalled',
  eviCostByType: 'plot.eviCostByType',
  cobenefits: 'plot.cobenefits',
  jobsCreated: 'plot.jobsCreated',
  fleetShare: 'plot.fleetShare',
  fleetAge: 'plot.fleetAge',
  roiOverTime: 'plot.roiOverTime',
  bonusFplDiffusion: 'plot.bonusFplDiffusion',
  fplAndNewVehicles: 'plot.fplAndNewVehicles',
  fplParticipation: 'plot.fplParticipation',
  usedPlusNew: 'plot.usedPlusNew',
  zevMandate: 'plot.zevMandate',
  ghgComparison: 'plot.ghgComparison',
  gasolineConsumption: 'plot.gasolineConsumption',
  ghgReductions: 'plot.ghgReductions'
}
