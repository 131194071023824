import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    borderRadius: 12,
    cursor: theme.cursor,
    height: 80,
    width: '100%'
  },
  text: {
    marginTop: 8
  }
}))
