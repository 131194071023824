import React, { ChangeEvent, FC, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PolicyCreators, State } from '../../store'
import { Button } from '../button'
import { Column } from '../column'
import { Input } from '../input'
import { Modal } from '../modal'
import { Text, ComponentTextProps } from '../text'
import {
  creatingIssueLabelProps,
  errorProps,
  labelProps,
  serverIssueLabelProps
} from './labels'
import { ModalSpinner } from './modal-spinner'
import { CreateNewPolicyModalProps } from './create-new-policy-modal.types'
import { useStyle } from './create-new-policy-modal.styles'

export const CreateNewPolicyModal: FC<CreateNewPolicyModalProps> = ({
  prevName = '',
  titleLabelProps = {
    text: 'Creating New Policy',
    tx: 'create.policy.title'
  },
  submitLabelProps = {
    text: 'Create',
    tx: 'create.policy.create'
  },
  settings = {},
  onSubmit,
  onClose,
  onAutoClose = onClose
}) => {
  const dispatch = useDispatch()
  const [name, changeName] = useState(prevName)
  const { isLoaded, errorMessage } = useSelector((state: State) => ({
    ...state.app,
    isLoaded: state.policy.isCreateLoaded,
    errorMessage: state.policy.createError
  }))
  const [error, changeError] = useState<ComponentTextProps | undefined>()
  const classes = useStyle()

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    changeName(value)

    if (value) {
      changeError(undefined)
    }
  }, [])

  const handleOnSubmit = useCallback(() => {
    if (name) {
      if (onSubmit) {
        onSubmit({ name })
        onClose()
      }

      if (!prevName) {
        const options = {
          ...settings,
          name
        }
        dispatch(PolicyCreators.createPolicyRequest(options))
      }
      // here could be onClose() request but
      // we have to see error message after dispatch
    } else {
      changeError(!name ? errorProps : undefined)
    }
  }, [onSubmit, onClose, name, prevName, settings])

  useEffect(() => {
    if (errorMessage) {
      const errorText =
        errorMessage === 'server'
          ? serverIssueLabelProps
          : creatingIssueLabelProps

      changeError(errorText)
    } else {
      changeError(undefined)
    }

    return () => {
      changeError(undefined)
    }
  }, [errorMessage])

  useEffect(() => {
    return () => {
      dispatch(PolicyCreators.createPolicyRequest())
    }
  }, [])

  const isBtnDisabled = !name || prevName === name

  return (
    <Modal onClose={onAutoClose}>
      {isLoaded ? (
        <Column fullWidth fullHeight justifyContent="space-between">
          <Column className={classes.title} fullWidth>
            <Text
              preset="h1"
              {...titleLabelProps}
            />
            <Input
              alignItems="flex-start"
              className={classes.input}
              errorProps={error}
              labelProps={labelProps}
              defaultValue={name}
              isFocused={Boolean(name)}
              onChange={handleOnChange}
            />
          </Column>
          <Button
            preset="accent"
            disabled={isBtnDisabled}
            onClick={handleOnSubmit}
          >
            <Text
              preset="body"
              {...submitLabelProps}
            />
          </Button>
        </Column>
      ) : (
        <ModalSpinner settings={settings} />
      )}
    </Modal>
  )
}
